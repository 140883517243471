import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import {
  MEDIA_CONFIG_URL,
  MEDIA_ITEMS_CONFIG_URL,
  PHOTO_GALLERY_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import { MdPermMedia } from "react-icons/md";
import AddPhotoForm from "./AddPhotoForm";
import PhotosList from "./PhotosList";
import useAuth from "../../../../authentication/hooks/useAuth";
import UploadPhotosforAlbums from "./UploadPhotosforAlbums";
import ViewPhotos from "./ViewPhotos";
import deleteFile from "../../../../utilities/spacesFile/deleteFileController";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function PhotoGalleryConfig() {
  const { auth } = useAuth();

  const [photoGalleryList, setPhotoGalleryList] = useState([]);
  const [mediaAlbumList, setMediaAlbumList] = useState([]);
  const [mediaItemList, setMediaItemList] = useState([]);
  const [editPhotoDetails, setEditPhotoDetails] = useState({});

  const [showDelete, setShowDelete] = useState(false);

  const [albumId, setAlbumId] = useState();

  const [photoObj, setPhotoObj] = useState({});

  const [viewPhotos, setViewPhotos] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editPhotoDetails).length > 0 ? true : false;

  const [showUploadPhotosForm, setShowUploadPhotosForm] = useState(false);

  const [mediaId, setMediaId] = useState();

  const getPhotoGalleryList = async () => {
    try {
      const response = await axiosPrivate.get(PHOTO_GALLERY_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setPhotoGalleryList(response.data.photosList);
      response.status === 202 &&
        showToast("No photo list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getMediaAlbumList = async () => {
    try {
      const response = await axiosPrivate.get(MEDIA_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 &&
        setMediaAlbumList(response.data.mediaAlbum_List);
      response.status === 202 &&
        showToast(
          "No Media Album list in the System found in the system",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getMediaItemsList = async () => {
    try {
      const response = await axiosPrivate.get(MEDIA_ITEMS_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 &&
        setMediaItemList(response.data.mediaItems_List);
      response.status === 202 &&
        showToast(
          "No Media Album list in the System found in the system",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const deleteFileHandler = async (file_name) => {
    await deleteFile(file_name);
  };

  const deletePhoto = async () => {
    try {
      const data = {};
      data.media_item_id = photoObj.media_items_id;

      const response = await axiosPrivate.post(
        `${MEDIA_ITEMS_CONFIG_URL}/deleteMediaItemsDetails`,
        data,
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Photo Deleted deleted", "success");
        let file_name = photoObj.media_item_url.replace(
          "https://files.sikkimsabcco.com/",
          ""
        );

        deleteFileHandler(file_name);
      }

      response.status === 202 &&
        showToast(
          "Processing request, if the photo is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getMediaItemsList();
      setShowDelete(false);
    }
  };

  const deleteAlbumDetails = async () => {
    try {
      const data = {};
      data.media_album_id = mediaId;
      const response = await axiosPrivate.post(
        `${MEDIA_CONFIG_URL}/deleteMediaAlbumDetails`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Album deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the album is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getMediaAlbumList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    getPhotoGalleryList();
    setEditPhotoDetails({});
    getMediaAlbumList();
    getMediaItemsList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />

      {viewPhotos && viewPhotos ? (
        <ViewPhotos
          setViewPhotos={setViewPhotos}
          albumId={albumId}
          mediaItemList={mediaItemList}
          setPhotoObj={setPhotoObj}
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          deletePhoto={deletePhoto}
          getMediaItemsList={getMediaItemsList}
        />
      ) : (
        <>
          {!showUploadPhotosForm ? (
            <>
              <div className="flex items-center justify-between">
                <H2withIcon>
                  <IconContext.Provider value={{ className: "text-secondary" }}>
                    <MdPermMedia />
                  </IconContext.Provider>
                  &nbsp; Photo Gallery
                </H2withIcon>
                {!showAddForm && (
                  <AddButton
                    label="Add Album"
                    onclick={() => setShowAddForm(true)}
                  />
                )}
              </div>
              <GenericModal
                open={showAddForm}
                setOpen={setShowAddForm}
                title={`${isEdit ? "Update Album" : "Add Album"}`}
                isAdd={true}
              >
                <AddPhotoForm
                  getMediaAlbumList={getMediaAlbumList}
                  editPhotoDetails={editPhotoDetails}
                  setEditPhotoDetails={setEditPhotoDetails}
                  setShowAddForm={setShowAddForm}
                />
              </GenericModal>

              <ContainerShadow>
                {photoGalleryList?.length === 0 &&
                mediaAlbumList?.length === 0 ? (
                  <p>Album yet to be added...</p>
                ) : (
                  <div className="grid grid-cols-1">
                    <PhotosList
                      mediaAlbumList={mediaAlbumList}
                      setEditPhotoDetails={setEditPhotoDetails}
                      setShowAddForm={setShowAddForm}
                      setShowUploadPhotosForm={setShowUploadPhotosForm}
                      setViewPhotos={setViewPhotos}
                      setAlbumId={setAlbumId}
                      setMediaId={setMediaId}
                      setShowDelete={setShowDelete}
                    />
                  </div>
                )}
              </ContainerShadow>
            </>
          ) : (
            <UploadPhotosforAlbums
              setShowUploadPhotosForm={setShowUploadPhotosForm}
              editPhotoDetails={editPhotoDetails}
              setEditPhotoDetails={setEditPhotoDetails}
              showUploadPhotosForm={showUploadPhotosForm}
              getMediaItemsList={getMediaItemsList}
            />
          )}
        </>
      )}

      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message={"The User data would be deleted permenantly. Are you sure?"}
        onDelete={deleteAlbumDetails}
        setShowDelete={setShowDelete}
      />
    </Dashboard>
  );
}
