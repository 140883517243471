/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./counter.styles.css";

const Countdown = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const countdownNumberEl = document.getElementById("countdown-number");

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown <= 1 ? 3 : prevCountdown - 1
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div id="countdown">
      <div id="countdown-number">{countdown}</div>
      <svg className="svgBody">
        <circle r="45" cx="48" cy="48"></circle>{" "}
        {/* Adjust the radius and center accordingly */}
      </svg>
    </div>
  );
};

export default Countdown;
