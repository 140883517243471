import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CATEGORY_CONFIG_URL,
  DESIGNATION_CONFIG_URL,
  EMPLOYEE_MANAGEMENT_CONFIG_URL,
  SEND_MAIL_URL,
  USER_MANAGEMENT_CONFIG_URL,
} from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import useGetWithoutParameter from "../../../hook/getApi/useGetWithoutParameters";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import Passphoto from "../../../reusable-components/inputs/FileUploader/Passphoto";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import { designationDD } from "../../../utilities/constants/constants";
import {
  reContact,
  reEmail,
} from "../../../utilities/constants/regularexpressions";
import showToast from "../../../utilities/notification/NotificationModal";
import CapturePhoto from "../../../reusable-components/inputs/FileUploader/CapturePhoto";
import uploadFileController from "../../../utilities/spacesFile/uploadFileController";

const AddEmployeeForm = ({
  getEmployeeList,
  editEmployeeDetails,
  setEditEmployeeDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [userFile, setUserFile] = useState();
  const [fileUploadError, setFileUploadError] = useState(false);

  const [selectedRole, setSelectedRole] = useState();
  const [selectedDesignation, setSelectedDesignation] = useState();
  const [selectedSupervisingCategory, setSelectedSupervisingCategory] =
    useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [credentialObj, setCredentialObj] = useState({});

  const [filename, setFilename] = useState("");
  const [passphoto, setPassphoto] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [designation_List, setDesignation_List] = useState([]);
  const [userId, setUserId] = useState();

  const isEdit = Object.keys(editEmployeeDetails)?.length > 0;

  const defaultValues = {
    employeeimage: !isEdit ? "" : editEmployeeDetails?.employeeimage,
    employeename: !isEdit ? "" : editEmployeeDetails?.employeename,
    employeeid: !isEdit ? "" : editEmployeeDetails?.employeeid,
    employeephone: !isEdit ? "" : editEmployeeDetails?.employeephone,
    employeeemail: !isEdit ? "" : editEmployeeDetails?.employeeemail,
    employeeaddress: !isEdit ? "" : editEmployeeDetails?.employeeaddress,
    supervisingcategory: !isEdit
      ? ""
      : editEmployeeDetails?.supervisingcategory,

    employeedesignation: !isEdit
      ? ""
      : {
          label: editEmployeeDetails?.employeedesignation,
          value: editEmployeeDetails?.employeedesignation,
        },
    roleid: !isEdit
      ? ""
      : editEmployeeDetails?.roles.map((roleObj) => {
          return { value: roleObj?.roleid, label: roleObj.rolename };
        }) || selectedRole,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  //getting role list
  let getRoleListUrl = `${USER_MANAGEMENT_CONFIG_URL}/roleConfig`;
  const { response: rolelist } = useGetWithoutParameter(getRoleListUrl);

  let roleListDD = [];
  rolelist &&
    rolelist?.roleList?.map((rolesObj) => {
      let roleObj = {
        value: rolesObj.roleid,
        label: rolesObj.rolename,
      };
      rolesObj.roleid != 1 && roleListDD.push(roleObj);
    });

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];
          for (let i = 0; i < response?.data?.categoryList?.length; i++) {
            let categoryObj = {
              value: response.data.categoryList[i].categoryid,
              label: response.data.categoryList[i].categoryname,
            };
            categoryDD.push(categoryObj);
          }
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  const getUserIdWithEmail = async (employee_email) => {
    try {
      const response = await axiosPrivate.get(
        `${USER_MANAGEMENT_CONFIG_URL}/getUserIdConfig`,
        {
          params: {
            username: employee_email,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data) {
          setUserId(response?.data?.userId?.userid);
        } else {
          setUserId();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDesignationList = async () => {
    try {
      const response = await axiosPrivate.get(DESIGNATION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.designationList?.length > 0) {
          let designationDD = [];
          for (let i = 0; i < response?.data?.designationList?.length; i++) {
            let designationObj = {
              value: response.data.designationList[i].designation_name,
              label: response.data.designationList[i].designation_name,
            };
            designationDD.push(designationObj);
          }
          setDesignation_List(designationDD);
        } else {
          setDesignation_List([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      let response = "";
      let rolesId = [];

      if (passphoto) {
        data.employeeimage = await uploadFile(passphoto);
      } else {
        data.employeeimage = editEmployeeDetails?.employeeimage;
      }

      selectedRole.map((roleObj) => {
        let roleidObj = {
          roleid: parseInt(Object.values(roleObj)),
        };
        rolesId.push(roleidObj);
      });
      data.roleid = JSON.stringify(rolesId);

      credentialObj.type = data?.roleid?.label;

      data.employeedesignation = data?.employeedesignation?.value;
      data.supervisingcategory = JSON.stringify(data?.supervisingcategory);

      if (!isEdit) {
        data.createdby = auth?.userid;

        response = await axiosPrivate.post(
          EMPLOYEE_MANAGEMENT_CONFIG_URL,
          data,
          {
            signal: controller.signal,
          }
        );
      } else {
        data.employeemanagementid = editEmployeeDetails?.employeemanagementid;
        data.employeeimage =
          data.employeeimage || editEmployeeDetails?.employeeimage || "";
        data.updatedby = auth.userid;

        response = await axiosPrivate.put(
          EMPLOYEE_MANAGEMENT_CONFIG_URL,
          data,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditEmployeeDetails({});
          showToast("Employee has been updated successfully", "success");
        } else {
          showToast("Employee has been added successfully", "success");
        }
        getEmployeeList();
      } else {
        if (isEdit) {
          if (response.data.updateEmployeeDetails.includes("unique_employee")) {
            showToast(
              "Employee already exists. Cannot insert duplicate Employee",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveEmployeeDetails.includes("unique_employee")) {
            showToast(
              "Employee already exists. Cannot insert duplicate Employee",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("Unable to add employee", error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isEdit && isMounted) {
      setPassphoto(editEmployeeDetails?.employeeimage || "");
    } else {
      setPassphoto("");
    }
    return () => {
      isMounted = false;
    };
  }, [editEmployeeDetails?.employeeimage]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDesignationList();
      getCategoryList();
      if (isEdit) {
        getUserIdWithEmail(editEmployeeDetails?.employeeemail);
      }
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isEdit]);

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-4 gap-3">
          <div className="col-span-3 grid grid-cols-3 gap-x-3 items-start">
            <Input
              defaultName="employeeid"
              register={register}
              name="Employee Id"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter employee Id"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.employeeid}
              setValue={setValue}
            />
            <Input
              defaultName="employeename"
              register={register}
              name="Employee Name"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter employee name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.employeename}
              setValue={setValue}
            />
            <Dropdown
              defaultName="employeedesignation"
              register={register}
              labelname="Employee Designation"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-lg text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={designation_List}
              defaultValue={defaultValues.employeedesignation}
              setValue={setValue}
              setSelected={setSelectedDesignation}
              selected={selectedDesignation}
              maxMenuHeight={120}
            />
            <Dropdown
              defaultName="roleid"
              register={register}
              labelname="Employee Role"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-lg text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={roleListDD}
              defaultValue={defaultValues.roleid}
              setValue={setValue}
              setSelected={setSelectedRole}
              selected={selectedRole}
              maxMenuHeight={120}
              isMulti={true}
              closeMenuOnSelect={false}
            />
            <Dropdown
              defaultName="supervisingcategory"
              register={register}
              labelname="Supervising Category"
              required={false}
              pattern={null}
              errors={errors}
              classes={`rounded-lg text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={categoryList}
              defaultValue={defaultValues.supervisingcategory}
              setValue={setValue}
              setSelected={setSelectedSupervisingCategory}
              selected={selectedSupervisingCategory}
              maxMenuHeight={120}
              isMulti={true}
              closeMenuOnSelect={false}
            />
            <Input
              defaultName="employeephone"
              register={register}
              name="Employee Contact no"
              required={true}
              pattern={reContact}
              errors={errors}
              placeholder="Enter employee contact no"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.employeephone}
              setValue={setValue}
            />
            <Input
              defaultName="employeeemail"
              register={register}
              name="Employee Email"
              required={true}
              pattern={reEmail}
              errors={errors}
              placeholder="Enter employee email"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.employeeemail}
              setValue={setValue}
            />
            <div className="col-span-2">
              <TextArea
                defaultName="employeeaddress"
                register={register}
                name="Employee Address"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter employee address"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                onChangeInput={null}
                defaultValue={defaultValues.employeeaddress}
                setValue={setValue}
              />
            </div>
          </div>
          <div className="col-span-1 ">
            {/* <Passphoto
              register={register}
              defaultName="employeeimage"
              name="Passphoto"
              required={false}
              pattern={null}
              placeholder="Lonee Image"
              type="file"
              errors={errors}
              classes={`rounded px-3 py-2 text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              file={passphoto}
              setPhotoBase64={setPassphoto}
              setFilename={setFilename}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.imployeeimage : ""}
              setFile={setUserFile}
              fileUploadError={fileUploadError}
              setFileUploadError={setFileUploadError}
            /> */}
            <CapturePhoto
              register={register}
              defaultName="employeeimage"
              name="Passphoto"
              required={isEdit ? false : true}
              pattern={null}
              placeholder="Guarantor Image"
              type="file"
              errors={errors}
              classes={`rounded px-3 py-2 text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              // file={passphoto}
              // setPhotoBase64={setPassphoto}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.employeeimage : ""}
              setFile={setPassphoto}
              fileUploadError={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditEmployeeDetails({});
              setValue("employeename", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Employee Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddEmployeeForm;
