import React, { useEffect, useState, Fragment } from "react";
import Select from "react-select";

export default function FilterDropdown({
  defaultName,
  required,
  classes,
  placeholder,
  data,
  defaultValue,
  setSelected,
  selected,
  maxMenuHeight,
  ...rest
}) {
  return (
    <>
      <div className={classes}>
        <Select
          maxMenuHeight={maxMenuHeight}
          isClearable={true}
          options={data}
          value={selected}
          isMulti={rest.isMulti}
          closeMenuOnSelect={rest.closeMenuOnSelect}
          placeholder={placeholder}
          {...rest}
          onChange={(e) => {
            setSelected(e);
          }}
        />
      </div>
    </>
  );
}
