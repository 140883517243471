import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import CashCounter_List from "./CashCounter_List";
import AddCashCounterForm from "./AddCashCounterForm";
import {
  CASHCOUNTER_CONFIG_URL,
  MST_CASHCOUNTER_CONFIG_URL,
} from "../../../api/api_routing_urls";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";

export default function CashCounter_Config() {
  const [showAddForm, setShowAddForm] = useState(false);
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [expenseListDD, setExpenseListDD] = useState();
  const [expenseList, setExpenseList] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [cashCounterDetailsId, setCashCounterDetailsId] = useState(false);

  const [editCashCounterDetails, setEditCashCounterDetails] = useState();

  const getCashCounter_ExpenseList = async () => {
    try {
      const response = await axiosPrivate.get(MST_CASHCOUNTER_CONFIG_URL, {
        signal: controller.signal,
      });

      let expenseListDD = [];

      if (response?.status === 200) {
        response?.data?.cashCounterList?.map((ExpenseListObj) => {
          expenseListDD.push({
            label: ExpenseListObj.expense_name,
            value: ExpenseListObj.expense_id,
          });
        });
        setExpenseListDD(expenseListDD);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCashCounterDetails = async () => {
    try {
      const response = await axiosPrivate.get(CASHCOUNTER_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setExpenseList(response?.data?.cashCounterList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCashCounterDetails = async () => {
    try {
      const data = {};

      data.cashcounter_details_id = cashCounterDetailsId;
      const response = await axiosPrivate.post(
        `${CASHCOUNTER_CONFIG_URL}/deleteCashCounterDetails`,
        data,
        {
          signal: controller.signal,
        }
      );

      response.status === 200 &&
        showToast(
          "Cash Counter Detials have been deleted successfully",
          "success"
        );
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getCashCounterDetails();
      setShowDelete(false);
    }
  };

  let isEdit =
    editCashCounterDetails === undefined || editCashCounterDetails === null
      ? false
      : Object?.keys(editCashCounterDetails)?.length > 0
      ? true
      : false;

  useEffect(() => {
    getCashCounter_ExpenseList();
    getCashCounterDetails();
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Cash Counter "}
        setShowAddForm={setShowAddForm}
      />

      {showAddForm === true ? (
        <>
          <GenericModal
            open={showAddForm}
            setOpen={setShowAddForm}
            title={` ${
              isEdit ? "Update Expenses Detail " : "Add Expenses Detail"
            }`}
            isAdd={true}
            // isLarge={true}
          >
            <AddCashCounterForm
              setShowAddForm={setShowAddForm}
              expenseListDD={expenseListDD}
              getCashCounterDetails={getCashCounterDetails}
              editCashCounterDetails={editCashCounterDetails}
              setEditCashCounterDetails={setEditCashCounterDetails}
            />
          </GenericModal>
        </>
      ) : (
        <>
          <CashCounter_List
            expenseList={expenseList}
            setCashCounterDetailsId={setCashCounterDetailsId}
            setShowDelete={setShowDelete}
            setEditCashCounterDetails={setEditCashCounterDetails}
            setShowAddForm={setShowAddForm}
          />
        </>
      )}

      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message={"The User data would be deleted permenantly. Are you sure?"}
        onDelete={deleteCashCounterDetails}
        setShowDelete={setShowDelete}
      />
    </Dashboard>
  );
}
