import axios from "../../api/axios";
// import useAuth from "./useAuth";

import { useDispatch } from "react-redux";

import { setCompleteLoaneeDetails } from "../../features/loaneeDetails/LoaneeDetails";

const useRepaymentRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      const response = await axios.get("/refreshRepayment", {
        withCredentials: true,
      });

      dispatch(
        setCompleteLoaneeDetails({
          loanee_id: response?.data?.loaneeDetailsForRepayment?.loanee_id,
          loan_number: response?.data?.loaneeDetailsForRepayment?.loan_number,
          contact_number: response?.data?.loaneeDetailsForRepayment?.contactno,
          applicantname:
            response?.data?.loaneeDetailsForRepayment?.applicantname,
          accessToken: response?.data?.accessToken,
        })
      );

      return response?.data?.accessToken;
    } catch (error) {
      console.error("useRepaymentRefreshToken", error);
      return "";
    }
  };
  return refresh;
};

export default useRepaymentRefreshToken;
