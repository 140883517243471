import React from "react";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
} from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdOutlineFilterAlt } from "react-icons/md";
import FilterDropdown from "../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../reusable-components/search/SearchField";
import { useState } from "react";
import showToast from "../../../utilities/notification/NotificationModal";
import { useEffect } from "react";
import Dashboard from "../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import LoaneeList from "./LonaeeList";
import { Paging } from "../../../reusable-components/paging/Paging";
import DemandNoticeListConfig from "./DemandNoticeListConfig";
import Button from "../../../reusable-components/buttons/Button";

export default function LoaneeListConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [categoryList, setCategoryList] = useState([]);
  const [repaymentList, setRepaymentList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [searchText, setSearchText] = useState("");

  const [loaneeId, setLoaneeId] = useState();
  const [showDemandNoticeList, setShowDemandNoticeList] = useState(false);
  const [loanNumber, setLoanNumber] = useState();

  const [loaneeDetails, setLonaeeDetails] = useState();

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [constituencyList, setConstituencyList] = useState([]);
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const [showDirectDeptNoticeList, setShowDirectDeptNoticeList] =
    useState(false);

  const [showIndirectDeptNoticeList, setShowIndirectDeptNoticeList] =
    useState(false);
  const [showOTSNoticeList, setShowOTSNoticeList] = useState(false);

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get constituency list
  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getSearchedRepaymentList = async (
    page_no,
    page_size,
    search_text,
    category_type,
    districtId,
    constituencyId
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getRepaymentSearchListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type,
            decreestatus: false,
            onetimesettlementstatus: false,
            district_id: districtId || 0,
            constituency_id: constituencyId || 0,
            applicant_gender: "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setRepaymentList(response?.data?.repaymentList);

        const totalNumberOfApplicant =
          response?.data?.repaymentList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Record found in the system", "warning");
      setRepaymentList(response?.data?.repaymentList);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get District List
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    getCategoryList();

    getConstituencyList();
    getDistrictList();

    // setAddRepaymentDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    getSearchedRepaymentList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label || "",
      selectedDistrictName?.value,
      selectedConstituencyName?.value
    );
  }, [
    pageNo,
    pageSize?.value,
    selectedCategory?.label,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
  ]);

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        {showDemandNoticeList ? (
          <>
            <DemandNoticeListConfig
              loaneeId={loaneeId}
              setShowDemandNoticeList={setShowDemandNoticeList}
              loanNumber={loanNumber}
              loaneeDetails={loaneeDetails}
            />
          </>
        ) : (
          <>
            <div className="flex items-center justify-between gap-2">
              <H2withIcon>
                <MdAssignmentTurnedIn />{" "}
                <span className="pl-1.5">Loanee List for Demand Notice</span>
              </H2withIcon>

              <>
                <div className="flex gap-2">
                  <Button
                    className={
                      "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
                    }
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  >
                    Apply Filters
                    <MdOutlineFilterAlt size={18} />
                  </Button>

                  <SearchField
                    placeholder="Search"
                    defaultValue={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onClick={() => {
                      setPageNo(1);
                      getSearchedRepaymentList(
                        pageNo,
                        pageSize?.value,
                        searchText || "",
                        selectedCategory?.label || "",
                        selectedDistrictName?.value,
                        selectedConstituencyName?.value
                      );
                    }}
                    classes={"bg-primary "}
                    onKeyDown={(e) => {
                      if (e?.key === "Enter") {
                        getSearchedRepaymentList(
                          pageNo,
                          pageSize?.value,
                          searchText || "",
                          selectedCategory?.label || "",
                          selectedDistrictName?.value,
                          selectedConstituencyName?.value
                        );
                      }
                    }}
                  />
                </div>
              </>
            </div>

            {showFilters && (
              <section className="grid grid-cols-1 md:grid-cols-3 gap-2 bg-slate-100 p-3 mt-3">
                <FilterDropdown
                  defaultName="filterCategory"
                  required={true}
                  classes={`text-sm w-full`}
                  placeholder={"Select Category..."}
                  data={categoryList}
                  defaultValue={null}
                  setSelected={setSelectedCategory}
                  selected={selectedCategory}
                  maxMenuHeight={256}
                />

                <FilterDropdown
                  defaultName="filterDistrict"
                  required={true}
                  classes={`text-sm w-full`}
                  placeholder={"Select District..."}
                  data={districtList}
                  defaultValue={null}
                  setSelected={setSelectedDistrictName}
                  selected={selectedDistrictName}
                  maxMenuHeight={256}
                />
                <FilterDropdown
                  defaultName="filterConstituency"
                  required={true}
                  classes={`text-sm w-full`}
                  placeholder={"Select Constituency..."}
                  data={constituencyList}
                  defaultValue={null}
                  setSelected={setSelectedConstituencyName}
                  selected={selectedConstituencyName}
                  maxMenuHeight={256}
                />
              </section>
            )}

            <div className="grid grid-cols-1 mt-3">
              <LoaneeList
                repaymentList={repaymentList}
                setLoaneeId={setLoaneeId}
                setShowDemandNoticeList={setShowDemandNoticeList}
                setLoanNumber={setLoanNumber}
                setLonaeeDetails={setLonaeeDetails}
                setShowDirectDeptNoticeList={setShowDirectDeptNoticeList}
                setShowIndirectDeptNoticeList={setShowIndirectDeptNoticeList}
                setShowOTSNoticeList={setShowOTSNoticeList}
              />
            </div>

            <Paging
              maxMenuHeight={100}
              selected={pageSize}
              setSelected={setPageSize}
              pageNo={pageNo}
              onPageChangeHandler={onPageChangeHandler}
              numberOfPages={numberOfPages}
            />
          </>
        )}
      </Dashboard>
    </>
  );
}
