// developed by Jesson

import React from "react";

const PhotoViewInside = ({
  photo_title,
  selectedPhotoArray,
  setSelectedPhotoTitle,
}) => {
  return (
    <>
      <div className="">
        <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31] pb-10">
          {photo_title} Photos
        </div>
        <button
          className="text-blue-600 bg-white rounded px-6 text-sm lg:text-lg"
          onClick={() => setSelectedPhotoTitle("")}
        >
          &lt; Back to Albums
        </button>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 py-6">
          {selectedPhotoArray?.length > 0 &&
            selectedPhotoArray?.map((photoObj) => {
              return (
                <>
                  {photoObj?.media_item_url ? (
                    <div className="flex flex-col ">
                      <div className="flex justify-center md:flex-row md:max-w-xl bg-white">
                        <img
                          className="h-70 w-full object-cover rounded-md"
                          src={photoObj?.media_item_url}
                          alt="Event Album Photos"
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default PhotoViewInside;
