import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../reusable-components/buttons/Button";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import H2 from "../../../reusable-components/headings/H2";
import H3 from "../../../reusable-components/headings/H3";
import H5 from "../../../reusable-components/headings/H5";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import {
  button,
  cancel_button,
  disabled_button,
} from "../../../theme/lightTheme";
import {
  contactnoPattern,
  emailPattern,
} from "../../../utilities/generalFunctions/regexPatterns";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { IconContext } from "react-icons";
import { FaCheckCircle } from "react-icons/fa";
import showToast from "../../../utilities/notification/NotificationModal";
import axios from "../../../api/axios";
import { FEEDBACK_URL } from "../../../api/api_routing_urls";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import Captcha from "../../../utilities/captcah/Captcha";

const Feedback = () => {
  const controller = new AbortController();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [successSubmission, setSuccessSubmission] = useState(false);

  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");

  const defaultValues = {
    fullname: "",
    contactno: "",
    email: "",
    address: "",
    message: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isValidating },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    data.captcha = captchaInput;

    try {
      let response = await axios.post(FEEDBACK_URL, data, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        setSuccessSubmission(true);
        setIsCaptchaValid(false);
        reset();
      }
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Feedback</H1>
          </div>
        </div>
      </HeroBanner>
      <div className="container mx-auto max-w-7xl mb-4">
        <ContainerShadow>
          {successSubmission ? (
            <p className="text-center">
              <IconContext.Provider
                value={{
                  className: "text-green-900 text-5xl text-center mx-auto",
                }}
              >
                <FaCheckCircle />
              </IconContext.Provider>
              <H3>Thank You !!!</H3>
              <div className="max-w-md mx-auto">
                <H5>
                  Your feedback is important to us. We'll look into your
                  feedback as soon as possible.
                  <br />
                  <br /> Thank you for helping us improve
                </H5>
              </div>
            </p>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-3 gap-3">
                <div className="col-span-3 md:col-span-1">
                  <Input
                    defaultName="fullname"
                    register={register}
                    name="Full Name"
                    required={true}
                    pattern={null}
                    errors={errors}
                    placeholder="Enter full name"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`rounded-sm px-3 py-2 text-sm w-full`}
                    onChangeInput={null}
                    defaultValue={defaultValues.fullname}
                    setValue={setValue}
                  />
                </div>
                <div className="col-span-3 md:col-span-1">
                  <Input
                    defaultName="contactno"
                    register={register}
                    name="Phone Number"
                    required={true}
                    pattern={contactnoPattern}
                    errors={errors}
                    placeholder="Enter phone number"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`rounded-sm px-3 py-2 text-sm w-full`}
                    onChangeInput={null}
                    defaultValue={defaultValues.contactno}
                    setValue={setValue}
                  />
                </div>
                <div className="col-span-3 md:col-span-1">
                  <Input
                    defaultName="email"
                    register={register}
                    name="Email Address"
                    required={true}
                    pattern={emailPattern}
                    errors={errors}
                    placeholder="Enter email address"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`rounded-sm px-3 py-2 text-sm w-full`}
                    onChangeInput={null}
                    defaultValue={defaultValues.email}
                    setValue={setValue}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 md:col-span-1">
                  <TextArea
                    defaultName="address"
                    register={register}
                    name="Address"
                    required={true}
                    pattern={null}
                    errors={errors}
                    placeholder="Address"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`rounded-sm px-3 py-2 text-sm w-full resize-none h-32`}
                    onChangeInput={null}
                    defaultValue={defaultValues.address}
                    setValue={setValue}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextArea
                    defaultName="message"
                    register={register}
                    name="Feedback"
                    required={true}
                    pattern={null}
                    errors={errors}
                    placeholder="Feedback"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`rounded-sm px-3 py-2 text-sm w-full resize-none h-32`}
                    onChangeInput={null}
                    defaultValue={defaultValues.message}
                    setValue={setValue}
                  />
                </div>
              </div>
              <div className="flex justify-end w-full mt-3">
                <Captcha
                  setCaptchaSuccess={setCaptchaSuccess}
                  captchaReset={captchaReset}
                  setCaptchaInput={setCaptchaInput}
                />
              </div>
              <div className="flex justify-end w-full mt-3">
                {!captchaSuccess ? (
                  <Button className={disabled_button} disabled={true}>
                    Send Feedback
                  </Button>
                ) : (
                  <SubmitButton
                    className={button}
                    isSubmitting={isSubmitting}
                    label={"Submit Feedback"}
                  />
                )}
              </div>
            </form>
          )}
        </ContainerShadow>
      </div>
    </div>
  );
};

export default Feedback;
