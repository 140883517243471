import React from "react";
import {
  AiOutlineFile,
  AiOutlineFileImage,
  AiOutlineFilePdf,
} from "react-icons/ai";

const ApplicantDocumentPreview = ({ file_name, file_type, file_url, key }) => {
  return (
    <>
      <div key={key}>
        <a
          href={file_url}
          target="_blank"
          className="cursor-pointer  text-slate-800 flex justify-center flex-col items-center px-1"
        >
          {file_name?.includes("pdf") ? (
            <AiOutlineFilePdf size={34} className="text-red-600 " />
          ) : file_name?.includes("jpg") || file_name?.includes("png") ? (
            <AiOutlineFileImage size={34} className="text-blue-500 " />
          ) : (
            <AiOutlineFile size={34} className="text-blue-500 " />
          )}

          <span className="text-xs font-semibold mt-1">
            {file_type ? file_type : "File Type"}
          </span>
          <span className="text-xs">
            {file_name
              ? `(${
                  file_name?.length > 14
                    ? `${file_name?.substring(0, 14)}...`
                    : file_name
                })`
              : "File Name"}
          </span>
        </a>
      </div>
    </>
  );
};

export default ApplicantDocumentPreview;
