import React from "react";
import { NavLink } from "react-router-dom";
import {
  MdBarChart,
  MdDocumentScanner,
  MdEditNote,
  MdOutlineDashboard,
} from "react-icons/md";
import { FaRegAddressCard, FaTachometerAlt } from "react-icons/fa";
import SidebarIcons from "../../common/SidebarIcons";
import SidebarLinkGroup from "../../common/SidebarLinkGroup";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineLineChart } from "react-icons/ai";
import ReportSidebar from "../common/ReportSidebar";

export default function AssistantManagerSidebar({
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
}) {
  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/assistantmanager/dashboard" &&
          "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/assistantmanager/dashboard"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/assistantmanager/dashboard"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/assistantmanager/dashboard"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineDashboard size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Dashboard
            </span>
          </div>
        </NavLink>
      </li>

      {/* Application */}
      <SidebarLinkGroup activecondition={pathname?.includes("application-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("application-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("application-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <IoDocumentTextOutline size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Application
                    </span>
                  </div>
                  {/* Icon */}
                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/application-details"
                      className={`block 
                       ${
                         pathname.includes("details")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Applicant Details
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/application-deathcasedetails"
                      className={`block 
                       ${
                         pathname.includes("details")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Death Case Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Repayment */}
      <SidebarLinkGroup activecondition={pathname.includes("loanEntry-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("loanEntry-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("loanEntry-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Loan Entry
                    </span>
                  </div>
                  {/* Icon */}
                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/loanEntry-repayment"
                      className={`block 
                       ${
                         pathname.includes("repayment") &&
                         !pathname.includes("repaymentHistory")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Repayment
                      </span>
                    </NavLink>
                  </li>
                </ul>
                {/* <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/loanEntry-decreeRepayment"
                      className={`block 
                       ${
                         pathname.includes("decreeRepayment")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Decree Case Repayment
                      </span>
                    </NavLink>
                  </li>
                </ul> */}
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/loanEntry-oneTimeSettlement"
                      className={`block 
                       ${
                         pathname.includes("oneTimeSettlement")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        One Time Settlement
                      </span>
                    </NavLink>
                  </li>
                </ul>
                {/* <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/loanEntry-repaymentHistory"
                      className={`block 
                       ${
                         pathname.includes("repaymentHistory")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Repayment History
                      </span>
                    </NavLink>
                  </li>
                </ul> */}
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      <SidebarLinkGroup activecondition={pathname.includes("defaulter-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("defaulter-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <FaRegAddressCard size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Defaulter Details
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/defaulter-defaulterList"
                      className={`block 
                       ${
                         pathname.includes("defaulter-defaulterList")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter List
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/defaulter-uploadDefaulterDetails"
                      className={`block 
                       ${
                         pathname.includes("defaulter-uploadDefaulterDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      {/* ReportList */}
      <ReportSidebar
        pathname={pathname}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
        accessRole={"assistantmanager"}
      />

      <SidebarLinkGroup activecondition={pathname.includes("notice")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("notice")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("notice")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Notice
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/demand_notice"
                      className={`block 
                       ${
                         pathname.includes("demand_notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Demand Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/direct-dept-notice_notice"
                      className={`block 
                       ${
                         pathname.includes("direct-")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Direct Departmental Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/indirect1-dept-notice"
                      className={`block 
                       ${
                         pathname.includes("indirect1-dept-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Indirect Departmental Notice
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/assistantmanager/ots-notice"
                      className={`block 
                       ${
                         pathname.includes("ots-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        OTS Notice
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>
    </ul>
  );
}
