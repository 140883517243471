import React, { useEffect, Fragment, useState } from "react";
import { ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { USER_MANAGEMENT_CONFIG_URL } from "../../../api/api_routing_urls";
import AddUserForm from "./AddUserForm";
import UserList from "./UserList";
import useAuth from "../../../authentication/hooks/useAuth";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";

const UserManagementConfig = () => {
  const { auth } = useAuth();

  const [userList, setUserList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState({});

  const [showDelete, setShowDelete] = useState(false);
  const [userID, setUserID] = useState("");

  const isEdit = Object.keys(editUserDetails).length > 0 ? true : false;
  const controller = new AbortController();
  const getUserList = async () => {
    try {
      const response = await axiosPrivate.get(USER_MANAGEMENT_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setUserList(response?.data?.userList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const deleteUserWithId = async () => {
    try {
      const response = await axiosPrivate.delete(
        USER_MANAGEMENT_CONFIG_URL,
        { params: { userid: userID } },
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("User deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the user is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getUserList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getUserList();
    setEditUserDetails({});

    return () => {
      isMounted = false;
      controller?.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"User Management"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        isLarge={true}
        title={` ${isEdit ? "Update User" : "Add User"}`}
        isAdd={true}
      >
        <AddUserForm
          getUserList={getUserList}
          editUserDetails={editUserDetails}
          setEditUserDetails={setEditUserDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <UserList
          userList={userList}
          setEditUserDetails={setEditUserDetails}
          setShowAddForm={setShowAddForm}
          setUserID={setUserID}
          setShowDelete={setShowDelete}
        />
      </div>

      <Fragment>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteUserWithId}
        />
      </Fragment>
    </Dashboard>
  );
};

export default UserManagementConfig;
