import React from "react";
import { useForm } from "react-hook-form";
import { BANK_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function AddBankForm({
  getBankList,
  setShowAddForm,
  editBankDetails,
  setEditBankDetails,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editBankDetails).length > 0 ? true : false;

  // const isEdit = false;

  const defaultValues = {
    bankname: !isEdit ? "" : editBankDetails?.bankname,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const onSubmit = async (data) => {

    try {
      let response = "";
      if (!isEdit) {
        data.created_by = auth.userid;
        response = await axiosPrivate.post(BANK_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updated_by = auth.userid;
        data.bankid = editBankDetails?.bankid;
        response = await axiosPrivate.put(BANK_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditBankDetails({});
          showToast("Bank has been updated successfully", "success");
        } else {
          showToast("Bank has been added successfully", "success");
        }
        getBankList();
      } else {
        if (isEdit) {
          if (response.data.updateDistrictDetails.includes("unique_bank")) {
            showToast(
              "Bank already exists. Cannot insert duplicate bank",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveBankDetails.includes("unique_bank")) {
            showToast(
              "Bank already exists. Cannot insert duplicate district",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            defaultName="bankname"
            register={register}
            name="Bank Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter bank name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.bankname}
            setValue={setValue}
          />
          <div className="flex justify-between">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                //   setEditDistrictDetails({});
                //   setValue("districtname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Bank Update Bank"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
