import React from "react";
import ApplicantDataPreview from "../applicant-components/ApplicantDataPreview";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import user_image from "../../../../assets/userimage.png";
import { MdOutlineMail, MdOutlinePhoneAndroid } from "react-icons/md";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import ApplicantDocumentPreview from "../applicant-components/ApplicantDocumentPreview";

export default function PrintApplicantDetailsRemarks({
  applicantDetail,
  schemeName,
  districtName,
  constituencyName,
  subDivisionName,
  gramPanchayatName,
  selected_Loanee,
}) {
  return (
    <section className="text-[11px]">
      <section className="p-3 rounded-sm md:p-6">
        <div className=" xl: text-black">
          <ApplicantDataPreview
            label="Application Date"
            dataValue={getFormattedDateDDMMMYYYYDate(
              applicantDetail?.createdat
            )}
          />
        </div>
        <section className=" grid grid-cols-4 gap-1 py-3 my-3  border-black">
          <div className="flex gap-6 col-span-2 ">
            <div>
              <img
                src={
                  applicantDetail?.applicantimage
                    ? applicantDetail?.applicantimage
                    : user_image
                }
                className="w-24 md:w-36 h-24 md:h-36 rounded-sm"
                alt="User"
              />
            </div>

            <div className="flex flex-col justify-between  text-black">
              <div>
                <div className="font-medium text-slate-700 ">
                  {applicantDetail?.applicantname}
                </div>

                <div className="flex gap-1 items-center text-slate-600">
                  <MdOutlinePhoneAndroid size={14} />
                  <span className="md:font-medium ">
                    {applicantDetail?.contactno
                      ? applicantDetail?.contactno
                      : "---"}
                    {applicantDetail?.alternativecontactno
                      ? `/ ${applicantDetail?.alternativecontactno}`
                      : "---"}
                  </span>
                </div>

                <div className="flex gap-1 items-center text-black">
                  <MdOutlineMail size={14} />
                  <span className="md:font-medium  ">
                    {applicantDetail?.applicantemail
                      ? applicantDetail?.applicantemail
                      : "---"}
                  </span>
                </div>
                <div className=" 2xl: py-1 w-full  mt-1 rounded-sm ">
                  <ApplicantDataPreview
                    label="Loan ID"
                    dataValue={
                      applicantDetail?.loanee_id
                        ? applicantDetail?.loanee_id
                        : "---"
                    }
                  />
                  <ApplicantDataPreview
                    label="Loan No"
                    dataValue={
                      applicantDetail?.loan_number
                        ? applicantDetail?.loan_number
                        : "---"
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <span className=" border-b  font-semibold">General details:</span>

            <div className="pt-3 ">
              <ApplicantDataPreview
                label="Category"
                dataValue={applicantDetail?.categoryname}
              />

              <ApplicantDataPreview
                label="D.O.B"
                dataValue={getFormattedDateDDMMMYYYYDate(
                  applicantDetail?.applicantdob
                )}
              />

              <ApplicantDataPreview
                label="Gender"
                dataValue={applicantDetail?.applicantgender}
              />
              <ApplicantDataPreview
                label={applicantDetail?.applicantrelative}
                dataValue={applicantDetail?.applicantrelativename}
              />
            </div>
          </div>

          <div>
            <span className=" border-b  font-semibold">Scheme details:</span>

            <div className="pt-3">
              <ApplicantDataPreview
                label="Scheme"
                dataValue={schemeName || "---"}
              />
              <ApplicantDataPreview
                label="Scheme short name"
                dataValue={applicantDetail?.schemeshortname || "---"}
              />
              <ApplicantDataPreview
                label="Interest rate"
                dataValue={
                  applicantDetail?.loan_int_rate ||
                  applicantDetail?.scheme_int_rate ||
                  "---"
                }
              />
              <ApplicantDataPreview
                label="Loan applied"
                dataValue={`${formatINRCurrency(
                  applicantDetail?.loanappliedamt >=
                    applicantDetail?.loan_amount
                    ? applicantDetail?.loanappliedamt
                    : applicantDetail?.loan_amount
                )}`}
              />
            </div>
          </div>
        </section>

        <fieldset className="border border-black p-3 grid grid-cols-2">
          <legend className="px-2 border ml-3 rounded-xs  text-black  border-black  p-1">
            Applicant details
          </legend>

          <div>
            <ApplicantDataPreview
              label="Annual Income"
              dataValue={formatINRCurrency(applicantDetail?.annualincome)}
            />

            <ApplicantDataPreview
              label=" Area Type"
              dataValue={applicantDetail?.applicantarea}
            />

            <ApplicantDataPreview
              label="District "
              dataValue={districtName || "---"}
            />
            <ApplicantDataPreview
              label="Constituency"
              dataValue={constituencyName || "---"}
            />

            <ApplicantDataPreview
              label="Area"
              dataValue={selected_Loanee?.label}
            />

            <ApplicantDataPreview
              label="Pin Code"
              dataValue={applicantDetail?.pincode}
            />

            <ApplicantDataPreview
              label="Post Office"
              dataValue={applicantDetail?.postoffice}
            />
          </div>

          <div>
            <ApplicantDataPreview
              label="Police Station"
              dataValue={applicantDetail?.policestation}
            />

            <ApplicantDataPreview
              label="Ward"
              dataValue={
                applicantDetail?.municipalward ||
                applicantDetail?.grampanchayatward ||
                "---"
              }
            />
            <ApplicantDataPreview
              label="Sub Division"
              dataValue={
                subDivisionName || applicantDetail?.subdivisionname || "---"
              }
            />

            <ApplicantDataPreview
              label="Emp. Generation"
              dataValue={applicantDetail?.employmentgeneration}
            />
            <ApplicantDataPreview
              label="Education"
              dataValue={applicantDetail?.education}
            />
            {/* 
          <ApplicantDataPreview
            label="Contact No"
            dataValue={applicantDetail?.contactno}
          /> */}

            {applicantDetail?.grampanchayatname ? (
              <ApplicantDataPreview
                label="Gram Panchayat"
                dataValue={
                  gramPanchayatName ||
                  applicantDetail?.grampanchayatname ||
                  "---"
                }
              />
            ) : null}

            {applicantDetail?.nagarpanchayatname ? (
              <ApplicantDataPreview
                label="Nagar Panchayat"
                dataValue={applicantDetail?.nagarpanchayatname || "---"}
              />
            ) : null}

            <ApplicantDataPreview
              label="Location"
              dataValue={applicantDetail?.applicantlocation}
            />
          </div>
        </fieldset>

        {applicantDetail?.bankname === null ? null : (
          <>
            <fieldset className="border p-3 grid grid-cols-1  md:grid-cols-3 gap-x-9 gap-1 mt-3 ">
              <legend className="px-2 border border-black ml-3 rounded-xs  text-black  p-1">
                Bank details
              </legend>
              <ApplicantDataPreview
                label="Bank Name"
                dataValue={applicantDetail?.bankname}
              />
              <ApplicantDataPreview
                label="Account Name"
                dataValue={applicantDetail?.accountname}
              />
              <ApplicantDataPreview
                label="Account No"
                dataValue={applicantDetail?.accountnumber}
              />
              <ApplicantDataPreview
                label="IFSC Code"
                dataValue={applicantDetail?.ifsccode}
              />
              <ApplicantDataPreview
                label="Phone No"
                dataValue={applicantDetail?.phoneno}
              />
            </fieldset>
          </>
        )}

        <fieldset className="border border-black p-3 grid grid-cols-1  md:grid-cols-2 gap-x-9 gap-1 mt-3 ">
          <legend className="px-2 border border-black ml-3 rounded-xs  text-black  p-1">
            Documents
          </legend>
          {applicantDetail?.loaneedocuments &&
          applicantDetail?.loaneedocuments?.length > 0 ? (
            <>
              <div className=" col-span-1 md:col-span-2  ">
                <div className=" flex flex-wrap gap-9 border-b border-black pb-3">
                  {applicantDetail?.loaneedocuments?.map((docObj, index) =>
                    docObj?.docurl ? (
                      <ApplicantDocumentPreview
                        file_name={docObj?.docname}
                        file_type={docObj?.doctype}
                        file_url={docObj?.docurl}
                        key={index}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </>
          ) : null}

          <div className="col-span-1 md:col-span-2 text-center  text-black my-1  ">
            (Physical documents list)
          </div>

          <div className="flex flex-wrap gap-3 col-span-1 md:col-span-2  text-black font-semibold ">
            {applicantDetail?.filechecklist &&
              applicantDetail?.filechecklist?.map((preSacntionFileObj, index) =>
                preSacntionFileObj.check === true ? (
                  <div className="bg-slate-50 py-1  px-3 border rounded-full">
                    {index + 1}. {preSacntionFileObj.file}
                  </div>
                ) : null
              )}
          </div>
        </fieldset>

        {/* Guarantor details */}
        {/* <div className="border-black border text-balck  px-3">
          {applicantDetail?.guarantordetails ? (
            <GuarantorDetails
              guarantor_details={applicantDetail?.guarantordetails}
            />
          ) : null}
        </div> */}

        {applicantDetail?.approval_details[0].approval_details
          ?.disbursmentamount > 0 ? (
          <>
            <section className="mt-9 border border-black p-3  bg-slate-50  lg:w-10/12 lg:mx-auto">
              <div className="text-center text-primary font-semibold  ">
                Disbursment Details
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:mt-2 lg:p-3 border border-black bg-green-50  p-3">
                <ApplicantDataPreview
                  label="Cheque Id"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.chequeid
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.chequeid
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Cheque Issue Date"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.chequedate
                      ? `${getFormattedDateDDMMMYYYYDate(
                          applicantDetail?.approval_details[0].approval_details
                            ?.chequedate
                        )}`
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Disbursment Amount"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.disbursmentamount
                      ? new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(
                          applicantDetail?.approval_details[0].approval_details
                            ?.disbursmentamount
                        )
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Interest Rate"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.interestrate
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.interestrate
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Tenure Period"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.tenure_period
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.tenure_period
                      : "---"
                  }
                />
              </div>
              <div>
                <div className=" flex flex-wrap gap-6 pt-3">
                  {applicantDetail?.loaneedocuments
                    ?.filter(
                      (objFilter) => objFilter?.doctype === "DGM Accounts"
                    )
                    ?.map((docObj, index) =>
                      docObj?.docurl ? (
                        <ApplicantDocumentPreview
                          file_name={docObj?.docname}
                          file_type={docObj?.doctype}
                          file_url={docObj?.docurl}
                          key={index}
                        />
                      ) : null
                    )}
                </div>
              </div>
            </section>
          </>
        ) : null}
      </section>
    </section>
  );
}
