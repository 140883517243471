import axios from "../../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    setAuth({});
    localStorage.removeItem("roleid");
    // clearCookie("jwt", {
    //   httpOnly: true,
    //   sameSite: "None",
    //   secure: true,
    // });

    // try {
    //   Cookies.remove("jwt", {
    //     path: "/",
    //     domain: "localhost",
    //     httpOnly: true,
    //   });

    //   // removeCookie("jwt", { path: "/", domain: "localhost", httpOnly: true });
    //   // localStorage.clear();
    // } catch (error) {
    //   console.error(error);
    // }

    try {
      await axios("/logout", {
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
