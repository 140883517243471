import axios from "../../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const role_id = localStorage?.roleid;

  const refresh = async () => {
    try {
      const response = await axios.get("/refresh", {
        params: { roleid: role_id || 3 },
        withCredentials: true,
      });

      let userdetails = {
        username: response.data.userdetails.username,
        role: response.data.userdetails.roleid,
        accessToken: response.data.userdetails.accesstoken,
        photo: response.data.userdetails.photo,
        rolename: response.data.userdetails.rolename,
        fullname: response.data.userdetails.fullname,
        userid: response.data.userdetails.userid,
      };

      setAuth((prev) => ({ ...prev, ...userdetails }));

      // dispatch(
      //   setCompleteLoaneeDetails({
      //     loanee_id: loaneeDetailsForOtp?.loanee_id,
      //     loan_number: loaneeDetailsForOtp?.loan_number,
      //     contact_number: loaneeDetailsForOtp?.contactno,
      //     applicantname: loaneeDetailsForOtp?.applicantname,
      //     accessToken: accessToken,
      //   })
      // );

      return response.data.userdetails.accesstoken;
    } catch (error) {
      return "";
    }
  };
  return refresh;
};

export default useRefreshToken;
