import React from "react";
import Button from "../../../reusable-components/buttons/Button";

export default function NavButtons() {
  return (
    <div className={`flex flex-row justify-between items-center gap-2`}>
      <Button
        className="flex items-center rounded-sm px-5 py-1.5  text-lg  bg-ternary hover:bg-green-300 text-primary hover:text-primary"
        onClick={() => window.location.replace("/login")}
        aria_label="Login Button"
      >
        <span className="text-xs md:text-sm font-medium">Login</span>
      </Button>
    </div>
  );
}
