import React from "react";
import { act } from "react-dom/test-utils";
import { formatINRCurrency } from "../../../utilities/currency/currency";

export default function CategoryWise_DetailsCard({
  total_disbursed,
  payement_received,
  due_amount,
  total_borrowers,
}) {
  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4 my-3 gap-3 text-sm">
      <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#E2E7F0] text-slate-900 ">
        <p className="text-center text-[#0C3E97] font-semibold">
          {" "}
          Total Borrower
        </p>
        {total_borrowers?.map((totalBorrower_obj) => (
          <span className="text-[#0C3E97]">
            <span className="font-semibold">
              {totalBorrower_obj.categoryname}
            </span>{" "}
            :{totalBorrower_obj.borrower}
          </span>
        ))}
      </div>

      <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#EDE5EB] text-slate-900 ">
        <p className="text-center text-[#782363]  font-semibold">
          Total Disbursed
        </p>
        {total_disbursed?.map((disbursedAmount_Obj) => (
          <span className="text-[#782363]">
            <span className="font-semibold">
              {disbursedAmount_Obj?.categoryname}
            </span>
            : &nbsp;
            {formatINRCurrency(disbursedAmount_Obj?.di_amount)}
          </span>
        ))}
      </div>

      <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#E5EDE7] text-slate-900 ">
        <p className="text-center text-[#237838]  font-semibold">
          {" "}
          Payment Received{" "}
        </p>
        {payement_received?.map((totalDisbursed_Obj) => (
          <span className="text-[#237838]">
            <span className="font-semibold">
              {totalDisbursed_Obj?.categoryname}
            </span>
            : &nbsp;
            {formatINRCurrency(totalDisbursed_Obj?.rp_amount)}
          </span>
        ))}
      </div>

      <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#F0EBE2] text-slate-900  ">
        <p className="text-center text-[#97650C]  font-semibold">
          {" "}
          Due Amount{" "}
        </p>
        {due_amount?.map((dueAmount_Obj) => (
          <span className="text-[#97650C]  ">
            <span className="font-semibold">{dueAmount_Obj?.categoryname}</span>
            : &nbsp;
            {formatINRCurrency(dueAmount_Obj?.due_amount)}
          </span>
        ))}
      </div>
    </div>
  );
}
