import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function ProcedureDecisionMakingpage() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Procedures followed in Decision-making process</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        {/* <div className="border  inline-flex justify-end items-center gap-4">
          <span>
            <IoReturnDownBack />
          </span>
          <span>back</span>
        </div> */}

        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 lg:mx-0 py-10 lg:py-0 text-xs md:text-sm">
          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2">
            Process of Decision making
          </p>

          <p>
            (i) The Management head of the Corporation is Managing Director. He
            is the over all in-charge of the Corporation and the executive
            representative of the Corporation in matters concerning the
            Corporation. He represents the Corporation in all Centre and State
            Government functions and meetings and is the only authority to
            sanction and approve the expenses of the Corporation. He sanctions
            and approves all the loans to SABCCO beneficiaries under various
            schemes of the Corporation. He is also responsible in framing
            policies and guidelines for the Corporation and implements the same
            with the approval of the Board of Directors. He coordinates with the
            Centre and State Governments and Apex Corporations in matters
            concerning the Corporation. He is also entrusted with the powers to
            delegate authority and certain functions to the officers and staff
            of the Corporation as and when required.
          </p>

          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2 pt-6">
            Final decision making authority
          </p>

          <p>
            (ii). Final decision-making authority of the Corporation is the
            Board of Directors of SABCCO. The Power of the Board of Directors of
            SABCCO are as under:
          </p>

          <ul
            style={{ listStyleType: "lower-alpha" }}
            className="pl-4 md:pl-10 lg:mb-24"
          >
            <li className="pt-2">
              To purchase, take on lease or otherwise acquire for the Company,
              property, rights or privileges which the Company is authorized to
              acquire at such price, and generally on such terms and conditions
              as it thinks fit.
            </li>
            <li>
              To appoint and at discretion remove or suspend such managers,
              secretaries, Officers, clerks, agents and servants of permanent,
              temporary of special services as it may from time to time, think
              fit, and to determine their powers and duties and fix their
              salaries or emoluments and to require security in such instance
              and to such instances and to such amount as it thinks fit.
            </li>
            <li>
              To institute, conduct, defend, compound, or abandon any legal
              proceedings by or against the Company or its officers, or
              otherwise concerning the affairs of the Company, and also to
              compound and allow time for payment of satisfaction of any claims
              or demands by or against the Company.
            </li>
            <li>
              To refer any claim or demand by or against the Company for
              arbitration, and Award.
            </li>
            <li>
              To make and give receipt, release and other discharge for money
              payable to Company and for the claims and demands of the Company.
            </li>
            <li>
              To determine who shall be entitled to sign on the Company’s
              behalf, bills, notes, Receipts, acceptance, endorsement, cheques,
              release, contracts and documents
            </li>
            <li>
              To provide from time to time, for the management of the affairs of
              the Company In such manner as it thinks fit, and in particular to
              appoint any person to be the attorney or agent of the Company with
              such terms as may be deemed fit
            </li>
            <li>
              To invest, subject to such general or special directives, if any,
              given by the Governor in this behalf, in securities or in any
              other Scheduled Banks or Banks To be specifically decided by the
              Board for having call deposits and opening current accounts and
              deal with any of the moneys of the Company upon such investments
              authorized by the Memorandum of Association of the Company (not
              being shares in this Company) and in such manner as it thinks fit
              and from and from time to time to vary or release such investment
            </li>
            <li>
              Subject to the provision regarding consent of the Governor, to
              sell or dispose of or transfer the property, if any, of the
              Company or any part thereof for such consideration as the Company
              may deem proper and in particular for shares, debentures or
              securities of any other Company having objects altogether or in
              part similar to those of the Company.
            </li>
            <li>
              To executive in the name and on behalf of the Company in favour of
              any Director or other person who may incur or be about to incur
              any liability. For the benefit of the Company such mortgages of
              the Company’s property (present and future) as it thinks fit and
              any such mortgage may contain a power, covenants and provisions as
              shall be agreed upon.
            </li>

            <li>
              To make, vary, repeal from time-to-time bye-laws or the regulation
              of the business of the Company, its officers and servants
            </li>
            <li>
              To give, award or allow any bonus, pension, gratuity or
              compensation to any Employee of the Company, the deceased
              employees’ having spouse, children or Dependents, that may appear
              to the Directors to be just and proper, whether Such employee, his
              widow, children or dependents, have or have not a legal claim.
            </li>

            <li>
              To enter into all such negotiations and contracts and rescind and
              vary all such Contracts and execute and do all such acts, deeds
              and things in the same on behalf of the Company as they may
              consider expedient for or in relation to any of the matters
              aforesaid or otherwise for the purpose of the Company, and
            </li>
            <li className="">
              To delegate all or any of the powers, authorities and discretion
              for the time being invested in the Board of Directors, subject,
              however, to the ultimate control and authority being retained by
              it.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
