import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { EMPLOYEE_MANAGEMENT_CONFIG_URL } from "../../../api/api_routing_urls";
import AddEmployeeForm from "./AddEmployeeForm";
import EmployeeList from "./EmployeeList";
import useAuth from "../../../authentication/hooks/useAuth";

const EmployeeManagementConfig = () => {
  const { auth } = useAuth();

  const [employeeList, setEmployeeList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editEmployeeDetails, setEditEmployeeDetails] = useState({});
  const isEdit = Object.keys(editEmployeeDetails).length > 0 ? true : false;
  const controller = new AbortController();

  const getEmployeeList = async () => {
    try {
      const response = await axiosPrivate.get(EMPLOYEE_MANAGEMENT_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 && setEmployeeList(response?.data?.employeeList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    getEmployeeList();
    setEditEmployeeDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Employee Management"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        isLarge={true}
        title={` ${isEdit ? "Update Employee" : "Add Employee"}`}
        isAdd={true}
      >
        <AddEmployeeForm
          getEmployeeList={getEmployeeList}
          editEmployeeDetails={editEmployeeDetails}
          setEditEmployeeDetails={setEditEmployeeDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <EmployeeList
          employeeList={employeeList}
          setEditEmployeeDetails={setEditEmployeeDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default EmployeeManagementConfig;
