// developed by Jesson
import userPhoto from "../../assets/userimage.png";
import React from "react";

const EmployeeInfoCard = ({
  employeephoto,
  employeename,
  employeedesignation,
}) => {
  return (
    <>
      <section className="card-region rounded-md shadow-lg bg-slate-50 w-64 h-full pb-9">
        <div className="pt-9 flex justify-center">
          <img
            src={employeephoto || userPhoto}
            className="rounded-full"
            height="50%"
            width="50%"
          />
        </div>
        <div className="flex flex-col justify-start pt-9 text-left ">
          <p className="text-lg 2xl:text-3xl text-slate-700 font-bold cursor-default text-center">
            {employeename}
          </p>
          <p className="text-xs lg:text-sm text-slate-600 font-semibold cursor-default text-center">
            {employeedesignation}
          </p>
        </div>
      </section>

      <style>
        {`
            img {
              transition: transform 0.30s ease-in-out;
            }
           .card-region:hover img {
              transform: scale(1.09, 1.09);
            }
          `}
      </style>
    </>
  );
};

export default EmployeeInfoCard;
