import { CiCalculator2, CiMoneyCheck1 } from "react-icons/ci";
import { AiOutlineBranches } from "react-icons/ai";
import { IoCashOutline } from "react-icons/io5";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";

export default function ReportCardToPrint({ principal, interest, title }) {
  return (
    <>
      <div className="bg-slate-100 p-2 flex border rounded-sm">
        <div className="col-span-2 pr-2">
          <p className="font-medium text-[10px]">{title}</p>

          <>{title === "Cheque" && <CiMoneyCheck1 size={16} />}</>
          <>{title === "Branch" && <AiOutlineBranches size={14} />}</>
          <>{title === "Cash" && <IoCashOutline size={14} />}</>
          <>{title === "Total" && <CiCalculator2 size={14} />}</>
        </div>
        <div className="border-l-slate-300 border-l col-span-5 pl-2 text-[9px]">
          <div className="flex items-center">
            <div className="w-10 tracking-tighter">Principal : </div>
            <span className="font-medium text-[9px] ">
              {formatINRCurrency(+principal || 0)}
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-10 ">Inerest : </div>
            <span className="font-medium text-[9px]">
              {formatINRCurrency(+interest || 0)}
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-10 ">Total : </div>
            <span className="font-medium text-[9px]">
              {formatINRCurrency(+principal + +interest || 0)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
