import React from "react";
import Button from "../../../reusable-components/buttons/Button";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../theme/lightTheme";

export default function EmployeeList({
  employeeList,
  setEditEmployeeDetails,
  setShowAddForm,
}) {
  const onClickEdit = (employee) => {
    setEditEmployeeDetails(employee);
    setShowAddForm(true);
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-3 py-2 text-left font-medium text-slate-900  "
          >
            Employee Name
          </th>
          <th
            scope="col"
            className="px-3 py-2 text-left font-medium text-slate-900  "
          >
            Employee Contact
          </th>
          <th
            scope="col"
            className="px-3 py-2 text-left font-medium text-slate-900  "
          >
            Employee Role
          </th>
          <th
            scope="col"
            className="px-3 py-2 text-left font-medium text-slate-900  "
          >
            Employee Designation
          </th>
          {/* <th
            scope="col"
            className="px-3 py-2 text-left font-medium text-slate-900  "
          >
            Supervising Category
          </th> */}
          <th
            scope="col"
            className="px-3 py-2 text-right font-medium text-slate-900  "
          >
            Actions
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {employeeList?.length > 0 &&
          employeeList?.map((employeeObj, index) => (
            <tr key={index}>
              <td className="px-3 py-2 whitespace-nowrap text-sm">
                {employeeObj?.employeename}
              </td>
              <td className="px-3 py-2 whitespace-nowrap text-sm ">
                <span className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  {employeeObj?.employeephone}
                </span>
                <span className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  {employeeObj?.employeeemail}
                </span>
              </td>
              {employeeObj?.roles && (
                <td className="px-3 py-2 whitespace-nowrap text-xs">
                  {employeeObj?.roles?.map((role) => (
                    <span className="bg-slate-100 p-1.5 mr-1.5 rounded-xs">
                      {role?.rolename || "-"}
                    </span>
                  ))}
                </td>
              )}
              <td className="px-3 py-2 whitespace-nowrap text-sm">
                {employeeObj?.employeedesignation}
              </td>
              {/* <td className="px-3 py-2 whitespace-nowrap text-xs">
                {employeeObj?.supervisingcategory?.map(
                  (supervisingCategoryObj) => (
                    <span className="bg-slate-100 p-1.5 mr-1.5 rounded-xs lowercase">
                      {supervisingCategoryObj?.label + " "}
                    </span>
                  )
                )}
              </td> */}
              <td className="px-3 py-2 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(employeeObj)}
                    aria-label="Edit User Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
