import React from "react";
import "./model.css";
const LoadingModal = ({ message }) => {
  return (
    <div className="z-60 modal pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-slate-900 opacity-50 z-60"></div>
      <div className="bg-white rounded-sm z-60 w-2/5 top-20 max-h-11/12 overflow-y-hidden max-w-xs p-5 flex flex-col items-center justify-center shadow-lg">
        <p className="flex items-center">
          <svg
            className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className=""
              cx="12"
              cy="12"
              r="10"
              stroke="#003459"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="text-primary">{message}</span>
        </p>
        <small className="text-slate-700 ">
          Please wait{" "}
          <span className="text-lg font-semibold animate-pulse">...</span>
        </small>
      </div>
    </div>
  );
};

export default LoadingModal;
