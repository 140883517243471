import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  SEND_MAIL_URL,
  USER_MANAGEMENT_CONFIG_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import Passphoto from "../../../reusable-components/inputs/FileUploader/Passphoto";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import { reEmail } from "../../../utilities/constants/regularexpressions";
import showToast from "../../../utilities/notification/NotificationModal";

const AddUserForm = ({
  getUserList,
  editUserDetails,
  setEditUserDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [selectedRole, setSelectedRole] = useState();

  const [roleList, setRoleList] = useState([]);

  const [filename, setFilename] = useState("");
  const [passphoto, setPassphoto] = useState("");

  const [userFile, setUserFile] = useState();

  const [credentialObj, setCredentialObj] = useState({});
  const [fileUploadError, setFileUploadError] = useState(false);

  const isEdit = Object.keys(editUserDetails)?.length > 0;

  const defaultValues = {
    username: !isEdit ? "" : editUserDetails?.username,
    roleid: !isEdit ? "" : selectedRole,
    fullname: !isEdit ? "" : editUserDetails?.fullname,
    photo: !isEdit ? "" : editUserDetails?.photo,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  async function uploadImageToCloudinary(fileObj) {
    try {
      let data = new FormData();
      data.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET);
      data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
      data.append("file", fileObj);
      data.append("folder", "sabcco/images");
      data.append("context", fileObj?.name);
      const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`;
      let result = await axios.post(url, data);
      if (result) {
        return result.data.secure_url;
      }
    } catch (error) {
      console.error("unable to upload ", error);
    }
  }

  const getRoleList = async (role_id) => {
    try {
      const response = await axiosPrivate.get(
        `${USER_MANAGEMENT_CONFIG_URL}/roleConfig`,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.roleList?.length > 0) {
          let roleDD = [];
          // for (let i = 0; i < response?.data?.roleList?.length; i++) {

          response?.data?.roleList.map((rolesObj) => {
            let roleObj = {
              value: rolesObj?.roleid,
              label: rolesObj?.rolename,
            };
            roleDD.push(roleObj);

            if (rolesObj?.roleid === role_id) {
              setSelectedRole({
                value: rolesObj?.roleid,
                label: rolesObj?.rolename,
              });
            }
          });

          setRoleList(roleDD);
        } else {
          setRoleList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      let response = "";
      let rolesId = [];

      credentialObj.type = data?.roleid?.label;

      if (userFile) {
        await uploadImageToCloudinary(userFile).then((url) => {
          data.photo = url;
        });
      }
      selectedRole.map((roleObj) => {
        let roleidObj = {
          roleid: parseInt(Object.values(roleObj)),
        };
        rolesId.push(roleidObj);
      });
      data.roleid = JSON.stringify(rolesId);

      if (!isEdit) {
        response = await axiosPrivate.post(
          USER_MANAGEMENT_CONFIG_URL,
          {
            username: data.username,
            roleid: data.roleid,
            photo: data.photo,
            fullname: data.fullname,
          },
          {
            signal: controller.signal,
          }
        );
      } else {
        data.isdisabled = editUserDetails?.isdisabled;
        data.userid = editUserDetails?.userid;

        response = await axiosPrivate.put(USER_MANAGEMENT_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditUserDetails({});
          showToast("User has been updated successfully", "success");
        } else {
          showToast("User has been added successfully", "success");
        }
        getUserList();
      } else {
        if (isEdit) {
          if (response.data.updateUserDetails.includes("unique_user")) {
            showToast(
              "User already exists. Cannot insert duplicate user",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveUserDetails.includes("unique_user")) {
            showToast(
              "User already exists. Cannot insert duplicate user",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error submiting data ", error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPassphoto(editUserDetails?.photo);
    } else {
      setPassphoto("");
    }
  }, [editUserDetails?.photo]);

  useEffect(() => {
    getRoleList(0);
    if (isEdit) {
      getRoleList(parseInt(editUserDetails?.roleid));
    }
  }, [isEdit]);

  return (
    <section>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div className="col-span-1">
            <Passphoto
              register={register}
              defaultName="photo"
              name="Passphoto"
              required={false}
              pattern={null}
              placeholder="Lonee Image"
              type="file"
              errors={errors}
              classes={`rounded px-3 py-2 text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              file={passphoto}
              setPhotoBase64={setPassphoto}
              setFilename={setFilename}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.photo : ""}
              setFile={setUserFile}
              fileUploadError={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
          </div>
          <div className="col-span-2 grid grid-cols-1  md:grid-cols-2 gap-x-3 items-center">
            <Input
              defaultName="username"
              register={register}
              name="User Name (email)"
              required={true}
              pattern={reEmail}
              errors={errors}
              placeholder="Enter user name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.username}
              setValue={setValue}
            />

            <Dropdown
              defaultName="roleid"
              register={register}
              labelname="User Role"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-lg text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={roleList}
              defaultValue={defaultValues.roleid}
              setValue={setValue}
              setSelected={setSelectedRole}
              selected={selectedRole}
              maxMenuHeight={120}
              isMulti={true}
              closeMenuOnSelect={false}
            />
            <Input
              defaultName="fullname"
              register={register}
              name="Full Name"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter full name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.fullname}
              setValue={setValue}
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditUserDetails({});
              setValue("username", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel User Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddUserForm;
