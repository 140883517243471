import React, { useEffect, useState } from "react";
import user_image from "../../../assets/userimage.png";
import logo_image from "../../../assets/logoplaceholder.jpeg";
import {
  dataURLtoFile,
  getBase64,
} from "../../../utilities/fileFunctions/imageFunctions";
import Error from "../../outputs/Error";
import { fileTypeChecker } from "../../../utilities/validateDocuments/validateDocument";
import { fileNameExtension } from "../../../utilities/constants/regularexpressions";

export default function Passphoto({
  register,
  defaultName,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  file,
  setPhotoBase64,
  setFilename,
  setValue,
  isEdit,
  oldFileOnEdit,
  fileUploadError,
  setFileUploadError,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: { value: required, message: `${name} is required` },
    pattern: pattern,
  });

  // const onChangePassphoto = async (e) => {
  //   // rest?.setFile(e.target.files[0]);
  //   // getBase64(e.target.files[0], (result) => {
  //   //   setPhotoBase64(result);
  //   //   setValue(defaultName, result);
  //   // });
  //   // setFilename(e.target.files[0].name);

  //   try {
  //     let fileType = "";
  //     await fileTypeChecker(e.target.files[0])
  //       .then((file) => {
  //         fileType = file;
  //         setFileUploadError(false);
  //       })
  //       .catch((err) => {
  //         setFileUploadError(true);
  //         console.error("Invalide File:", err);
  //       });

  //     if (
  //       fileType !== "unknown" &&
  //       fileType === "IMG" &&
  //       fileNameExtension.test(e.target.files[0].name) //testing file name
  //     ) {
  //       rest?.setFile(e.target.files[0]);
  //       setValue(defaultName, e.target.files[0]);
  //       getBase64(e.target.files[0], (result) => {
  //         setPhotoBase64(result);
  //       });

  //       setFilename(e.target.files[0].name);
  //     } else {
  //       setFileUploadError(true);
  //     }
  //   } catch (error) {
  //     console.error("some error", error);
  //   }
  // };

  const processSelectedImage = async (selectedFile) => {
    try {
      const fileType = await fileTypeChecker(selectedFile);

      if (fileType === "IMG" && fileNameExtension.test(selectedFile.name)) {
        rest?.setFile(selectedFile);
        setValue(defaultName, selectedFile);
        setFileUploadError(false);

        getBase64(selectedFile, (result) => {
          setPhotoBase64(result);
        });

        setFilename(selectedFile.name);
      } else {
        setFileUploadError(true);
      }
    } catch (error) {
      console.error("Error processing selected image", error);
    }
  };

  const onChangePassphoto = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    processSelectedImage(selectedFile);
  };

  return (
    <div className="flex flex-col max-full mb-4 justify-center items-center">
      <p className="font-medium text-left">
        {name} {required && <span className="text-red-700">*</span>}
      </p>
      <img
        src={
          file !== "" ? file : name === "Passphoto" ? user_image : logo_image
        }
        className="h-36 w-36"
        alt="User"
      />

      <button
        onClick={() => document.getElementById(defaultName).click()}
        type="button"
        className={`px-3 py-2 shadow-lg rounded-lg mt-2 text-sm ${
          errors[defaultName]
            ? "bg-red-700 text-white"
            : "bg-green-300 text-black"
        }`}
      >
        Upload {name}
      </button>
      {/* <img src */}

      {errors[defaultName] && (
        <Error
          classes="flex flex-row gap-1 justify-center items-center max-w-sm w-full mt-2"
          message={`${name} is required`}
        />
      )}
      {fileUploadError && (
        <Error
          classes="flex flex-row gap-1 max-w-sm w-full mt-2"
          message={`Uploaded ${name} is invalid or has invalid name`}
        />
      )}
      <input
        id={defaultName}
        // defaultValue={file !== "" ? dataURLtoFile(file) : null}
        onChange={(e) => {
          if (e.target.value === "") {
            setPhotoBase64(oldFileOnEdit);
            setError(defaultName, {
              type: "required",
              message: `${name} is required`,
            });
          } else {
            onChangePassphoto(e);
            clearError(defaultName);
          }
          onChange(e);
        }}
        {...props}
        {...rest}
        className={`${classes} invisible h-0 border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      />
    </div>
  );
}
