import React from "react";

const TableWrapper = (props) => {
  return (
    <div className="flex flex-col 3xl:h-[40rem]  2xl:h-[40rem] lg:h-[26rem]">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
          <div className="border border-slate-300">
            <table className="min-w-full divide-y divide-slate-200">
              {props.children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWrapper;
