// developed by Jesson

import React from "react";
import { useState } from "react";

import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

import employeephotosample from "../../../assets/dumuser.webp";
import EmployeeInfoCard from "../../../reusable-components/cards/EmployeeInfoCard";
import showToast from "../../../utilities/notification/NotificationModal";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import {
  EMPLOYEE_DETAIL_URL,
  EMPLOYEE_MANAGEMENT_CONFIG_URL,
  EMP_DETAILS_URL,
} from "../../../api/api_routing_urls";
import { useEffect } from "react";

const EmployeesList = () => {
  const { auth } = useAuth();
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  // const [employeeList, setEmployeeList] = useState([]);
  const [empDetailsList, setEmpDetailsList] = useState([]);
  const [gmDetailsList, setGmDetailsList] = useState([]);
  const [mdDetailsList, setMdDetailsList] = useState([]);

  // const getEmployeeList = async () => {
  //   try {
  //     const response = await axiosPrivate.get(EMPLOYEE_DETAIL_URL, {
  //       signal: controller.signal,
  //     });

  //     response.status === 200 && setEmployeeList(response?.data?.employeeList);
  //     response.status === 202 &&
  //       showToast("No state list found in the system", "error");
  //   } catch (error) {
  //     console.error(error);
  //     if (!error?.response) {
  //       showToast("No Server Response");
  //     } else if (error.response.status === 422) {
  //       showToast("Some of the required inputs were not provided", "error");
  //     } else {
  //       showToast(
  //         "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
  //         "error"
  //       );
  //     }
  //   }
  // };

  const getEmpDetailsList = async () => {
    try {
      const response = await axiosPrivate.get(EMP_DETAILS_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        const md_details = response?.data?.empDetails_List?.filter(
          (emp) => emp.emp_designation === "Managing Director"
        )[0];

        setMdDetailsList(md_details);

        const gm_details = response?.data?.empDetails_List?.filter(
          (emp) => emp.emp_designation === "General Manager"
        )[0];

        setGmDetailsList(gm_details);

        let emp_list = response?.data?.empDetails_List?.filter(
          (emp) =>
            emp.emp_designation !== "Managing Director" &&
            emp.emp_designation !== "General Manager"
        );

        setEmpDetailsList(emp_list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEmpDetailsList();
  }, []);
  return (
    <>
      <div className="bg-white">
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
            <div className="text-center">
              <H1>Our Employees</H1>
            </div>
          </div>
        </HeroBanner>

        <div className="container mx-auto my-20">
          <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31]">
            Employee List
          </div>
          <div className="flex justify-center gap-6 py-8">
            <EmployeeInfoCard
              employeephoto={mdDetailsList?.emp_photo}
              employeename={mdDetailsList?.emp_name}
              employeedesignation={mdDetailsList?.emp_designation}
            />
            <EmployeeInfoCard
              employeephoto={gmDetailsList?.emp_photo}
              employeename={gmDetailsList?.emp_name}
              employeedesignation={gmDetailsList?.emp_designation}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9 justify-items-center py-20">
            {empDetailsList.length > 0 ? (
              <>
                {empDetailsList?.map((emp_Obj) => (
                  <>
                    <EmployeeInfoCard
                      employeephoto={emp_Obj?.emp_photo}
                      employeename={emp_Obj?.emp_name}
                      employeedesignation={emp_Obj?.emp_designation}
                    />
                  </>
                ))}
              </>
            ) : (
              <div className="border font-medium ">
                No list found in the server.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeesList;
