import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";
import Error from "../outputs/Error";
import sanitizeHtml from "sanitize-html";

export default function RichTextArea({
  defaultName,
  register,
  name,
  showInput,
  classes,
  data,
  required,
  pattern,
  errors,
  setError,
  clearError,
  setValue,
  setChangesMade,
  ...rest
}) {
  const [isEdited, setIsEdited] = useState(false);

  data && !isEdited && setValue(defaultName, data);

  const editorRef = useRef(null);

  return (
    <div
      className={`${
        showInput === undefined || showInput === true
          ? "flex flex-col"
          : "hidden"
      } w-full mb-4 justify-center items-start`}
    >
      <p className="font-bold text-left">
        {name} {required && <span className="text-red-700">*</span>}
      </p>
      <Editor
        {...register(defaultName, { required: true, pattern: pattern })}
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey="yswic6g2qr74tj6fasoc2jookqv6bm11uqsxrvsuuyzpy35q"
        scriptLoading={{ async: true }}
        initialValue={data}
        init={{
          height: 500,
          width: "100%",
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onEditorChange={(e) => {
          const dirty = e;
          const clean = sanitizeHtml(dirty);

          setIsEdited(true);
          if (e !== undefined) {
            setValue(defaultName, clean);
          } else {
            setValue(defaultName, "");
          }
        }}
      />
      {errors[defaultName] && errors[defaultName].type === "required" && (
        <Error
          classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
          message={`${name} is required`}
        />
      )}
      {errors[defaultName] && errors[defaultName].type === "pattern" && (
        <Error
          classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
          message={`${name} is not valid`}
        />
      )}
      {errors[defaultName] && errors[defaultName].type === "manual" && (
        <Error
          classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
          message={`${name} is not available`}
        />
      )}
    </div>
  );
}
