import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import {
  CATEGORY_CONFIG_URL,
  SCHEMES_CONFIG_URL,
  SCHEME_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import { ToastContainer } from "react-toastify";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import AddSchemeForm from "./AddSchemeForm";
import { MdMediation } from "react-icons/md";
import SchemesList from "./SchemesList";
import useAuth from "../../../../authentication/hooks/useAuth";

const SchemesConfig = () => {
  const { auth } = useAuth();

  const [showAddForm, setShowAddForm] = useState(false);
  const [editSchemeDetails, setEditSchemeDetails] = useState({});
  const [schemesList, setSchemesList] = useState([]);
  const [categoryDDList, setCategoryDDList] = useState([]);

  const [schemeList, setSchemeList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const isEdit =
    editSchemeDetails !== undefined
      ? Object.keys(editSchemeDetails).length > 0
      : false;

  const getSchemesList = async (isMounted) => {
    try {
      const response = await axiosPrivate.get(SCHEMES_CONFIG_URL, {
        signal: controller.signal,
      });

      isMounted && setSchemesList(response.data.schemesList);
      response.status === 202 &&
        showToast("Schemes details not found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getCategoryList = async (isMounted) => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });
      let categoryDD = [];
      if (response.data.categoryList.length > 0) {
        for (let i = 0; i < response.data.categoryList.length; i++) {
          categoryDD.push({
            value: response.data.categoryList[i].categoryid,
            label: response.data.categoryList[i].categoryname,
          });
        }
        isMounted && setCategoryDDList(categoryDD);
      }
    } catch (error) {
      setCategoryDDList([]);
    }
  };

  const getSchemeList = async () => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setSchemeList(response.data.schemeList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    getSchemesList(isMounted);
    getCategoryList(isMounted);

    getSchemeList();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <MdMediation />
          </IconContext.Provider>
          &nbsp; Schemes List
        </H2withIcon>
        {!showAddForm && (
          <AddButton
            label={`Add Scheme`}
            onclick={() => setShowAddForm(true)}
          />
        )}
      </div>
      {showAddForm ? (
        <AddSchemeForm
          getSchemesList={getSchemesList}
          editSchemeDetails={editSchemeDetails}
          setEditSchemeDetails={setEditSchemeDetails}
          categoryDDList={categoryDDList}
          setShowAddForm={setShowAddForm}
        />
      ) : (
        <ContainerShadow>
          {schemesList.length === 0 ? (
            <p>Schemes yet to be added</p>
          ) : (
            <div className="grid grid-cols-1">
              <SchemesList
                schemesList={schemesList}
                setEditSchemeDetails={setEditSchemeDetails}
                categoryDDList={categoryDDList}
                setShowAddForm={setShowAddForm}
              />
            </div>
          )}
        </ContainerShadow>
      )}
    </Dashboard>
  );
};

export default SchemesConfig;
