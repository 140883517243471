import React, { Fragment } from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { Menu } from "@headlessui/react";
import { FiEye, FiSettings } from "react-icons/fi";
import { Transition } from "react-transition-group";
import { MdOutlineDeleteOutline } from "react-icons/md";

export default function DefaulterDocsList({
  defaulterDocsList,
  setViewDefaulterDocs,
  setDefaulterDocsDetails,
  setDefaulterDocsId,
  setShowDelete,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleView = (defaulterDocsObj) => {
    setViewDefaulterDocs(true);
    setDefaulterDocsDetails(defaulterDocsObj);
  };

  const handleDelete = (defaulter_Docs_Id) => {
    // console.log("delete pressed", defaulter_Docs_Id);
    setDefaulterDocsId(defaulter_Docs_Id);
    setShowDelete(true);
  };

  return (
    <div className="pt-10 relative">
      <TableWrapper>
        <thead className="bg-white text-sm ">
          <tr className="bg-stone-100  text-base font-semibold text-slate-600">
            <th scope="col" className="px-6 py-3 text-left     ">
              SL. No
            </th>
            <th scope="col" className="px-6 py-3 text-left     ">
              Uploaded Date
            </th>
            {/* <th scope="col" className="px-6 py-3 text-left     ">
              Particulars
            </th> */}
            <th scope="col" className="px-6 py-3 text-left     ">
              Defaulter Duration
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {defaulterDocsList?.length > 0 &&
            defaulterDocsList
              .slice(0)
              .reverse()
              ?.map((defaulterDocsObj, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? `bg-white` : `bg-stone-100`
                  } `}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {defaulterDocsObj?.date}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {defaulterDocsObj?.particulars}
                </td> */}

                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {defaulterDocsObj?.defaulter_type}
                  </td>

                  <td className="px-4 py-4 whitespace-nowrap text-sm ">
                    {
                      <div className="flex justify-center">
                        <Menu as="div" className="block relative ">
                          <div>
                            <Menu.Button className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                              <div className="flex items-center gap-3">
                                <FiSettings size={16} />
                                <p>Manage</p>
                              </div>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      onClick={() =>
                                        handleView(defaulterDocsObj)
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm cursor-pointer"
                                      )}
                                    >
                                      <div className="flex items-center gap-3 text-black hover:text-primary">
                                        <FiEye size={16} />
                                        <p className="">View</p>
                                      </div>
                                    </div>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      onClick={() =>
                                        handleDelete(
                                          defaulterDocsObj?.defaulter_docs_id
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm cursor-pointer"
                                      )}
                                    >
                                      <div className="flex items-center gap-3 text-black hover:text-primary">
                                        <MdOutlineDeleteOutline size={16} />
                                        <p className="">Delete</p>
                                      </div>
                                    </div>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    }
                  </td>
                </tr>
              ))}
        </tbody>
      </TableWrapper>
    </div>
  );
}
