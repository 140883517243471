import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_ENDPOINT_URL
    : process.env.REACT_APP_ENDPOINT_URL_ONLINE;

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);

// console.log("URL", BASE_URL);
console.log("URL", BASE_URL);

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
