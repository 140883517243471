import React from "react";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../../reusable-components/headings/H1";
import SchemesList from "./SchemesList";

const OurScheme = () => {
  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
          <div className="text-center">
            <H1>Our Schemes</H1>
          </div>
        </div>
      </HeroBanner>
      <SchemesList />
    </>
  );
};

export default OurScheme;
