import React from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { DESIGNATION_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";

export default function AddDesignationForm({
  setShowAddForm,
  getDesignationList,
  editDesignationDetails,
  setEditDesignationDetails,
}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // const isEdit = Object.keys(editDesignationDetails)?.length > 0 ? true : false;

  let isEdit =
    editDesignationDetails === undefined || editDesignationDetails === null
      ? false
      : Object?.keys(editDesignationDetails)?.length > 0
      ? true
      : false;

  const defaultValues = {
    designation_name: !isEdit ? "" : editDesignationDetails?.designation_name,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let response = "";
      let sendDataObj = {};

      sendDataObj.designation_name = data.designation_name;
      if (!isEdit) {
        sendDataObj.created_by = auth.userid;

        response = await axiosPrivate.post(
          `${DESIGNATION_CONFIG_URL}/saveDesignationDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      } else {
        sendDataObj.updated_by = auth.userid;
        sendDataObj.designation_id = editDesignationDetails?.designation_id;

        response = await axiosPrivate.post(
          `${DESIGNATION_CONFIG_URL}/updateDesignationDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast("Designation has been updated successfully", "success");
        } else {
          showToast("Designation has been added successfully", "success");
        }
        getDesignationList();
      } else {
        if (isEdit) {
          if (
            response.data.updateConstituencyDetails.includes(
              "unique_desigantion"
            )
          ) {
            showToast(
              "Designation already exists. Cannot insert duplicate designation",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveConstituencyDetails.includes("unique_designation")
          ) {
            showToast(
              "Designation already exists. Cannot insert duplicate designation",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            defaultName="designation_name"
            register={register}
            name="Designation Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter designation name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.designation_name}
            setValue={setValue}
          />

          <div className="flex justify-between">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                setEditDesignationDetails({});
                setValue("designation_name", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Area Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
