/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { BiChevronLeft } from "react-icons/bi";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { useForm } from "react-hook-form";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateDDMMYYYY,
  getFormattedDateInputDate,
} from "../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import { number_in_words } from "../../../utilities/generalFunctions/numberToWrod";
import AddButton from "../../../reusable-components/buttons/AddButton";
import { NOTICE_URL } from "../../../api/api_routing_urls";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../authentication/hooks/useAuth";
import showToast from "../../../utilities/notification/NotificationModal";
import { PrintReport } from "../ReportsList/PrintReport/PrintReport";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";

export default function GenerateDemandNotice({
  setShowGenerateDemandNotice,
  onChange,
  totalDueAmount,
  principleDueWithIntData,
  loaneeDetails,
  getPrincipleDueWithInterestUntilDate,
  loaneReferenceNumberDetails,
  setLoaneeReferenceNumberDetails,
  getDemandNoticeList,
  PenalInterest_Amount,
  ioiTotalDue,
  isLoading,
  showFinalNotice,
  selectedGuarantor,
  setSelectedGuarantor,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const [noticeType, setNoticeType] = useState("");

  const [refNumber, setRefNumber] = useState();

  let TotalOfScheduleI;
  let TotalOfScheduleII;

  if (loaneeDetails?.categoryname === "OBC") {
    TotalOfScheduleI =
      +principleDueWithIntData?.principle_due +
      +principleDueWithIntData?.interest_due_amount +
      +PenalInterest_Amount +
      +ioiTotalDue;
  } else {
    TotalOfScheduleI =
      +principleDueWithIntData?.principle_due +
      +principleDueWithIntData?.interest_due_amount;
  }

  if (loaneeDetails?.categoryname === "OBC") {
    TotalOfScheduleII =
      +principleDueWithIntData?.principle_outstanding_amount +
      +principleDueWithIntData?.interest_due_amount +
      +PenalInterest_Amount +
      +ioiTotalDue;
  } else {
    TotalOfScheduleII =
      +principleDueWithIntData?.principle_outstanding_amount +
      +principleDueWithIntData?.interest_due_amount;
  }

  let TotalOfScheduleI_In_Words = number_in_words(TotalOfScheduleI);
  let TotalOfScheduleII_In_Words = number_in_words(TotalOfScheduleII);

  useEffect(() => {
    if (showFinalNotice == true) {
      setNoticeType("Final Notice");
    } else {
      setNoticeType("Demand Notice");
    }
  }, []);

  const handleGenerate_ReferenceNo = async () => {
    try {
      let response = "";
      response = await axiosPrivate.post(
        `${NOTICE_URL}/saveNoticeDetails`,
        {
          loanee_id: loaneeDetails?.loanee_id,
          category_type: loaneeDetails?.categoryname,
          generated_date: selectedDate,
          createdby: auth.userid,
          notice_type: noticeType,
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        getDemandNoticeList();
        setRefNumber(response?.data?.saveNoticeDetail?.ref_num);
        showToast("Reference Number has been added successfully", "success");
      } else if (response.data.saveNoticeDetail?.reason === "Already Exist") {
        showToast(`${response.data.saveNoticeDetail?.message}`, "warning");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        return;
      }
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getDemandNoticeList();
    }
  };

  useMemo(() => {
    getPrincipleDueWithInterestUntilDate(selectedDate);
    setRefNumber("");
  }, [selectedDate]);

  useEffect(() => {
    setRefNumber(loaneReferenceNumberDetails?.reference_number || "");
  }, [loaneReferenceNumberDetails?.reference_number]);

  return (
    <>
      <div className="flex justify-between">
        <H2withIcon>
          <MdAssignmentTurnedIn />
          <span className="pl-1.5">
            {showFinalNotice
              ? "Generate Final Notice"
              : " Generate Demand Notice"}
          </span>
        </H2withIcon>

        <span
          className="flex items-center cursor-pointer hover:underline underline-offset-2 "
          onClick={() => {
            setShowGenerateDemandNotice(false);
            setRefNumber(null);
            setLoaneeReferenceNumberDetails(null);
          }}
        >
          <BiChevronLeft size={24} /> back
        </span>
      </div>
      <div className="flex">
        <Input
          defaultName="demandNoticedate"
          register={register}
          name="Date"
          required={false}
          pattern={null}
          errors={errors}
          placeholder="Enter repayment date"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="date"
          classes={`rounded-sm px-3 py-2 text-sm w-fit`}
          onChangeInput={(e) => {
            setSelectedDate(e);

            onChange(e);
            // setRefreshPage((prev) => !prev);
          }}
          defaultValue={null}
          setValue={setValue}
          max={getFormattedDateInputDate(new Date())}
        />

        {refNumber ? (
          <>
            <section>
              <PrintReport
                reportTitle={showFinalNotice ? "Final Notice" : "Demand Notice"}
                buttonName={
                  showFinalNotice ? " Final Notice" : " Demand Notice"
                }
              >
                <section className=" text-[14px] px-6 ">
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold">Ref.No.:{refNumber}</p>
                    <p className="font-semibold">
                      Date :{getFormattedDateDDMMMYYYYDate(selectedDate)}
                    </p>
                  </div>
                  <div className="my-6">
                    To,
                    <p className="font-semibold">
                      {loaneeDetails?.applicantname}
                    </p>
                    <p className="font-semibold uppercase">
                      {loaneeDetails?.applicantrelative}:&nbsp;
                      {loaneeDetails?.applicantrelativename}
                    </p>
                    <p className="font-semibold uppercase">
                      {loaneeDetails?.permanentaddress}
                    </p>
                  </div>

                  <div className="underline font-semibold ">
                    Subject:{" "}
                    {showFinalNotice
                      ? "Final Notice for repayment of loan."
                      : " Demand Notice for repayment of loan."}
                  </div>
                  <div className="mt-6">
                    <p>Dear Sir/Madam, </p>
                    <p className="mt-4">
                      This is to inform you that a sum of
                      <span className="font-semibold">
                        {" "}
                        {formatINRCurrency(TotalOfScheduleI)}
                        &nbsp;
                      </span>
                      has fallen due for repayment against your &nbsp;
                      <span className="font-semibold">
                        {loaneeDetails?.schemename}
                      </span>{" "}
                      loan ID.No:{" "}
                      <span className="font-semibold">
                        {loaneeDetails?.loanee_id}{" "}
                      </span>{" "}
                      account with this Corporation. The detail of the amount
                      payabale as on{" "}
                      <span className="font-semibold">
                        {" "}
                        {getFormattedDateDDMMYYYY(selectedDate)}
                      </span>{" "}
                      by you is given in the schedule below :
                    </p>
                  </div>
                  <div className="flex flex-cols-2 justify-around pt-4 ">
                    <div>
                      <h2 className="underline underline-offset-2 font-semibold">
                        Schedule I
                      </h2>
                      <ul className="list-decimal pt-2">
                        <li>Principal Default</li>
                        <li>Interest due</li>
                        {loaneeDetails?.categoryname === "OBC" ? (
                          <>
                            <li>Penal Interest</li>
                            <li>Interest on Interest</li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                    <div>
                      <h2 className="underline underline-offset-2 font-semibold ">
                        Amount
                      </h2>
                      <ul className="pt-2">
                        <li className="font-semibold">
                          {formatINRCurrency(
                            principleDueWithIntData?.principle_due
                          )}
                        </li>
                        <li className="font-semibold">
                          {formatINRCurrency(
                            principleDueWithIntData?.interest_due_amount
                          )}
                        </li>
                        {loaneeDetails?.categoryname === "OBC" ? (
                          <>
                            <li className="font-semibold">
                              {formatINRCurrency(PenalInterest_Amount)}
                            </li>
                            <li className="font-semibold">
                              {formatINRCurrency(ioiTotalDue)}
                            </li>
                          </>
                        ) : null}
                      </ul>
                      <span className="font-semibold">
                        Total :{" "}
                        <span className="font-semibold">
                          {formatINRCurrency(TotalOfScheduleI)}
                        </span>
                      </span>
                    </div>
                  </div>
                  <p className="pt-4 ml-10 font-semibold">
                    (Rupees{" "}
                    <span className="font-bold">
                      {TotalOfScheduleI_In_Words}
                    </span>
                    only)
                  </p>
                  <div className="flex flex-col-2 justify-around pt-4">
                    <div>
                      <h2 className="underline underline-offset-2 pt-2 font-semibold">
                        Schedule II
                      </h2>
                      <ul className="list-decimal pt-2">
                        <li>Principal Outstanding</li>
                        <li>Interest due</li>

                        {loaneeDetails?.categoryname === "OBC" ? (
                          <>
                            <li>Penal Interest</li>
                            <li>Interest on Interest</li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                    <div>
                      <h2 className="underline underline-offset-2 mt-2 font-semibold">
                        Amount
                      </h2>
                      <ul className="pt-2">
                        <li className="font-semibold">
                          {" "}
                          {formatINRCurrency(
                            principleDueWithIntData?.principle_outstanding_amount
                          )}
                        </li>
                        <li className="font-semibold">
                          {formatINRCurrency(
                            principleDueWithIntData?.interest_due_amount
                          )}
                        </li>
                        {loaneeDetails?.categoryname === "OBC" ? (
                          <>
                            <li className="font-semibold">
                              {formatINRCurrency(PenalInterest_Amount)}
                            </li>
                            <li className="font-semibold">
                              {formatINRCurrency(ioiTotalDue)}
                            </li>
                          </>
                        ) : null}
                      </ul>
                      <span className="font-semibold">
                        {" "}
                        Total :{" "}
                        <span className="font-semibold">
                          {formatINRCurrency(TotalOfScheduleII)}
                        </span>
                      </span>
                    </div>
                  </div>

                  <p className="pt-4 ml-10 font-semibold">
                    (Rupees{" "}
                    <span className="font-bold">
                      {TotalOfScheduleII_In_Words}
                    </span>{" "}
                    only)
                  </p>
                  <p className="mt-6">
                    You are advised to settlle the overdues indicated in the
                    above schedule within 15 days from date of receipt of this
                    notice in the spirit of agreement executed by you and
                    thereby avoid Complication of any kind. In case you fail to
                    repay the aforestated amount within the stipulated period
                    the Corporation may take legal action under the SPDR Act
                    1988/Lok Adalat. <br />
                    Soliciting your kind co-operation
                  </p>

                  <div className="flex flex-row justify-between pt-10">
                    <div>
                      <ul>
                        <li>Name & Signature of the recipients:</li>
                        <li className=" font-semibold  mt-16">
                          {loaneeDetails?.applicantname}
                        </li>

                        <p className="font-semibold uppercase ">
                          {loaneeDetails?.permanentaddress}
                        </p>

                        {selectedGuarantor ? (
                          <>
                            <p className="pt-6">Copy To </p>
                            <p>
                              Guarantor Name:
                              <span className="font-semibold">
                                {selectedGuarantor?.label}
                              </span>
                            </p>
                            <p>
                              Guarantor Address:
                              <span className="font-semibold">
                                {selectedGuarantor?.address}
                              </span>
                            </p>
                          </>
                        ) : null}
                      </ul>
                    </div>
                    <div>
                      <p className="text-right">Yours faithfully,</p>
                      <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
                    </div>
                  </div>
                </section>
              </PrintReport>
            </section>
          </>
        ) : (
          <>
            {selectedDate === undefined ||
            selectedDate === null ||
            selectedDate === "" ? null : (
              <div className="flex items-center gap-4 ">
                <AddButton
                  label="Generate Reference Number"
                  onclick={() => handleGenerate_ReferenceNo()}
                />
              </div>
            )}
          </>
        )}
      </div>

      {selectedDate === undefined ||
      selectedDate === null ||
      selectedDate === "" ? null : (
        <>
          {isLoading ? (
            <>
              <section className="h-screen">
                <SpinnerWithText />
              </section>
            </>
          ) : (
            <>
              {/* ----old---- */}
              {/* <section className="bg-white text-sm my-6 p-6 ">
                <div className="flex justify-between ">
                  <p>
                    Ref. No:
                    <span className="font-semibold">{refNumber || "NA"}</span>
                  </p>
                  <p className="font-semibold">
                    Date :{getFormattedDateDDMMMYYYYDate(selectedDate)}
                  </p>
                </div>
                <div>
                  To,
                  <p className="font-semibold">
                    {loaneeDetails?.applicantname}
                  </p>
                  <p className="font-semibold uppercase">
                    {loaneeDetails?.applicantrelative}:&nbsp;
                    {loaneeDetails?.applicantrelativename}
                  </p>
                  <p className="font-semibold uppercase">
                    {loaneeDetails?.permanentaddress}
                  </p>
                </div>

                <div className="underline font-semibold text-center">
                  Subject:{" "}
                  {showFinalNotice
                    ? "Final Notice for repayment of loan."
                    : " Demand Notice for repayment of loan"}
                </div>
                <div>
                  <p>Dear Sir/Madam, </p>
                  <p>
                    This is to inform you that a sum of&nbsp;
                    <span className="font-semibold">
                      {formatINRCurrency(TotalOfScheduleI)}
                    </span>{" "}
                    has fallen due for repayment against your{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.schemename}
                    </span>{" "}
                    loan ID.No:{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.loanee_id}{" "}
                    </span>{" "}
                    account with this Corporation. The detail of the amount
                    payabale as on{" "}
                    <span className="font-semibold">
                      {getFormattedDateDDMMMYYYYDate(selectedDate)}{" "}
                    </span>{" "}
                    by you is given in the schedule below :
                  </p>
                </div>
                <div className="flex flex-cols-2 justify-around pt-4 ">
                  <div>
                    <h2 className="underline underline-offset-2 font-semibold">
                      Schedule I
                    </h2>
                    <ul className="list-decimal pt-2">
                      <li>Principal Default</li>
                      <li>Interest due</li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li>Penal Interest</li>
                          <li>Interest on Interest</li>
                        </>
                      ) : null}
                    </ul>
                  </div>
                  <div>
                    <h2 className="underline underline-offset-2 font-semibold ">
                      Amount
                    </h2>
                    <ul className="pt-2">
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.principle_due
                        )}
                      </li>
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.interest_due_amount
                        )}
                      </li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li className="font-semibold">
                            {formatINRCurrency(PenalInterest_Amount)}
                          </li>
                          <li className="font-semibold">
                            {formatINRCurrency(ioiTotalDue)}
                          </li>
                        </>
                      ) : null}
                    </ul>
                    <span className="font-semibold">
                      Total :{" "}
                      <span className="font-semibold">
                        {formatINRCurrency(TotalOfScheduleI)}
                      </span>
                    </span>
                  </div>
                </div>
                <p className="pt-4 ml-10 font-semibold">
                  (Rupees{" "}
                  <span className="font-bold">{TotalOfScheduleI_In_Words}</span>
                  only)
                </p>
                <div className="flex flex-col-2 justify-around pt-4">
                  <div>
                    <h2 className="underline underline-offset-2 pt-2 font-semibold">
                      Schedule II
                    </h2>
                    <ul className="list-decimal pt-2">
                      <li>Principal Outstanding</li>
                      <li>Interest due</li>

                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li>Penal Interest</li>
                          <li>Interest on Interest</li>
                        </>
                      ) : null}
                    </ul>
                  </div>
                  <div>
                    <h2 className="underline underline-offset-2 mt-2 font-semibold">
                      Amount
                    </h2>
                    <ul className="pt-2">
                      <li className="font-semibold">
                        {" "}
                        {formatINRCurrency(
                          principleDueWithIntData?.principle_outstanding_amount
                        )}
                      </li>
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.interest_due_amount
                        )}
                      </li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li className="font-semibold">
                            {formatINRCurrency(PenalInterest_Amount)}
                          </li>
                          <li className="font-semibold">
                            {formatINRCurrency(ioiTotalDue)}
                          </li>
                        </>
                      ) : null}
                    </ul>
                    <span className="font-semibold">
                      {" "}
                      Total :{" "}
                      <span className="font-semibold">
                        {formatINRCurrency(TotalOfScheduleII)}
                      </span>
                    </span>
                  </div>
                </div>

                <p className="pt-4 ml-10 font-semibold">
                  (Rupees{" "}
                  <span className="font-bold">
                    {TotalOfScheduleII_In_Words}
                  </span>{" "}
                  only)
                </p>

                <p>
                  You are advised to settlle the overdues indicated in the above
                  schedule within 15 days from date of recipt of this notice in
                  the spirit of agreement executed by you and thereby avoid
                  Complication of any kind. In case you fail to repay the
                  aforesaid amount within the stipulated period, the Corporation
                  may take legal action under the SPDR Act 1988/Lok Adalat.
                  <br />
                  Soliciting your kind co-operation
                </p>

                <div className="flex flex-row justify-between pt-10">
                  <div>
                    <ul>
                      <li>Name & Signature of the recipients:</li>
                      <li className=" font-semibold pt-3">
                        {loaneeDetails?.applicantname}
                      </li>

                      <p className="font-semibold uppercase">
                        {loaneeDetails?.permanentaddress}
                      </p>
                    </ul>
                  </div>
                  <div>
                    <p className="text-right">Yours faithfully,</p>
                    <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
                  </div>
                </div>
              </section> */}
              {/* ----old--- */}

              <section className=" text-[14px] px-6 ">
                <div className="flex justify-between mb-3">
                  <p className="font-semibold">Ref.No.:{refNumber || "NA"}</p>
                  <p className="font-semibold">
                    Date :{getFormattedDateDDMMMYYYYDate(selectedDate)}
                  </p>
                </div>
                <div className="my-6">
                  To,
                  <p className="font-semibold">
                    {loaneeDetails?.applicantname}
                  </p>
                  <p className="font-semibold uppercase">
                    {loaneeDetails?.applicantrelative}:&nbsp;
                    {loaneeDetails?.applicantrelativename}
                  </p>
                  <p className="font-semibold uppercase">
                    {loaneeDetails?.permanentaddress}
                  </p>
                </div>

                <div className="underline font-semibold ">
                  Subject:{" "}
                  {showFinalNotice
                    ? "Final Notice for repayment of loan."
                    : " Demand Notice for repayment of loan."}
                </div>
                <div className="mt-6">
                  <p>Dear Sir/Madam, </p>
                  <p className="mt-4">
                    This is to inform you that a sum of
                    <span className="font-semibold">
                      {" "}
                      {formatINRCurrency(TotalOfScheduleI)}
                      &nbsp;
                    </span>
                    has fallen due for repayment against your &nbsp;
                    <span className="font-semibold">
                      {loaneeDetails?.schemename}
                    </span>{" "}
                    loan ID.No:{" "}
                    <span className="font-semibold">
                      {loaneeDetails?.loanee_id}{" "}
                    </span>{" "}
                    account with this Corporation. The detail of the amount
                    payabale as on{" "}
                    <span className="font-semibold">
                      {" "}
                      {getFormattedDateDDMMMYYYYDate(selectedDate)}
                    </span>{" "}
                    by you is given in the schedule below :
                  </p>
                </div>
                <div className="flex flex-cols-2 justify-around pt-4 ">
                  <div>
                    <h2 className="underline underline-offset-2 font-semibold">
                      Schedule I
                    </h2>
                    <ul className="list-decimal pt-2">
                      <li>Principal Default</li>
                      <li>Interest due</li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li>Penal Interest</li>
                          <li>Interest on Interest</li>
                        </>
                      ) : null}
                    </ul>
                  </div>
                  <div>
                    <h2 className="underline underline-offset-2 font-semibold ">
                      Amount
                    </h2>
                    <ul className="pt-2">
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.principle_due
                        )}
                      </li>
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.interest_due_amount
                        )}
                      </li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li className="font-semibold">
                            {formatINRCurrency(PenalInterest_Amount)}
                          </li>
                          <li className="font-semibold">
                            {formatINRCurrency(ioiTotalDue)}
                          </li>
                        </>
                      ) : null}
                    </ul>
                    <span className="font-semibold">
                      Total :{" "}
                      <span className="font-semibold">
                        {formatINRCurrency(TotalOfScheduleI)}
                      </span>
                    </span>
                  </div>
                </div>
                <p className="pt-4 ml-10 font-semibold">
                  (Rupees{" "}
                  <span className="font-bold">{TotalOfScheduleI_In_Words}</span>
                  only)
                </p>
                <div className="flex flex-col-2 justify-around pt-4">
                  <div>
                    <h2 className="underline underline-offset-2 pt-2 font-semibold">
                      Schedule II
                    </h2>
                    <ul className="list-decimal pt-2">
                      <li>Principal Outstanding</li>
                      <li>Interest due</li>

                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li>Penal Interest</li>
                          <li>Interest on Interest</li>
                        </>
                      ) : null}
                    </ul>
                  </div>
                  <div>
                    <h2 className="underline underline-offset-2 mt-2 font-semibold">
                      Amount
                    </h2>
                    <ul className="pt-2">
                      <li className="font-semibold">
                        {" "}
                        {formatINRCurrency(
                          principleDueWithIntData?.principle_outstanding_amount
                        )}
                      </li>
                      <li className="font-semibold">
                        {formatINRCurrency(
                          principleDueWithIntData?.interest_due_amount
                        )}
                      </li>
                      {loaneeDetails?.categoryname === "OBC" ? (
                        <>
                          <li className="font-semibold">
                            {formatINRCurrency(PenalInterest_Amount)}
                          </li>
                          <li className="font-semibold">
                            {formatINRCurrency(ioiTotalDue)}
                          </li>
                        </>
                      ) : null}
                    </ul>
                    <span className="font-semibold">
                      {" "}
                      Total :{" "}
                      <span className="font-semibold">
                        {formatINRCurrency(TotalOfScheduleII)}
                      </span>
                    </span>
                  </div>
                </div>

                <p className="pt-4 ml-10 font-semibold">
                  (Rupees{" "}
                  <span className="font-bold">
                    {TotalOfScheduleII_In_Words}
                  </span>{" "}
                  only)
                </p>
                <p className="mt-6">
                  You are advised to settlle the overdues indicated in the above
                  schedule within 15 days from date of receipt of this notice in
                  the spirit of agreement executed by you and thereby avoid
                  Complication of any kind. In case you fail to repay the
                  aforestated amount within the stipulated period the
                  Corporation may take legal action under the SPDR Act 1988/Lok
                  Adalat. <br />
                  Soliciting your kind co-operation
                </p>

                <div className="flex flex-row justify-between pt-10">
                  <div>
                    <ul>
                      <li>Name & Signature of the recipients:</li>
                      <li className=" font-semibold  mt-16">
                        {loaneeDetails?.applicantname}
                      </li>

                      <p className="font-semibold uppercase ">
                        {loaneeDetails?.permanentaddress}
                      </p>

                      {selectedGuarantor ? (
                        <>
                          <p className="pt-6">Copy To </p>
                          <p>
                            Guarantor Name:
                            <span className="font-semibold">
                              {selectedGuarantor?.label}
                            </span>
                          </p>
                          <p>
                            Guarantor Address:
                            <span className="font-semibold">
                              {selectedGuarantor?.address}
                            </span>
                          </p>
                        </>
                      ) : null}
                    </ul>
                  </div>
                  <div>
                    <p className="text-right">Yours faithfully,</p>
                    <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}
