import React from "react";
import Dashboard from "../../common/Dashboard";
import DashboardConfig from "../system-admin/Dashboard/DashboardConfig";

export default function DVAdminDashboard() {
  return (
    <Dashboard sidebarType="Deputy General Manager">
      <DashboardConfig />
    </Dashboard>
  );
}
