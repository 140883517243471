import React from "react";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { AiFillLock, AiFillUnlock, AiOutlineEye } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import { MdRemoveRedEye } from "react-icons/md";

export default function LonaeeList({
  repaymentList,
  setLoaneeId,
  setShowDemandNoticeList,
  setLoanNumber,
  setLonaeeDetails,
  setShowDirectDeptNoticeList,
  setShowIndirectDeptNoticeList,
  setShowOTSNoticeList,
}) {
  const handleviewLoaneList = (loaneeDetails) => {
    setLoaneeId(loaneeDetails?.loanee_id);

    setLoanNumber(loaneeDetails?.loan_number);

    setLonaeeDetails(loaneeDetails);

    setShowDemandNoticeList(true);
    setShowDirectDeptNoticeList(true); // for Direct Departmental Notice
    setShowIndirectDeptNoticeList(true); // for Indirect Departmental Notice
    setShowOTSNoticeList(true); // for OTS Notice
  };

  return (
    <div>
      <TableWrapper>
        <thead className="bg-white text-sm sticky top-0 z-0">
          <tr className="bg-slate-200 ">
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Applied
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan status
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {repaymentList?.length > 0 &&
            repaymentList?.map((loaneeDetails, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className=" px-4 whitespace-nowrap text-sm  text-slate-800 font-medium ">
                  <div className="px-3">{loaneeDetails?.loanee_id}</div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-slate-800 font-medium">
                  {loaneeDetails?.applicantname}
                  <div className="text-slate-600 font-normal text-xs flex items-center gap-1">
                    <BsPhone size={12} /> {loaneeDetails?.contactno || "---"}
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {loaneeDetails?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {loaneeDetails?.categoryname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {formatINRCurrency(loaneeDetails?.loanappliedamt)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {loaneeDetails?.loan_status === "Active" ? (
                    <div className="flex items-start gap-1 text-green-700">
                      <AiFillUnlock size={18} />
                      <span className="text-green-700">
                        {loaneeDetails?.loan_status}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-start gap-1 text-slate-600">
                      <AiFillLock size={18} />{" "}
                      <span className="text-primary">
                        {loaneeDetails?.loan_status}
                      </span>
                    </div>
                  )}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm w-24 ">
                  <span
                    className="cursor-pointer text-primary"
                    onClick={() => handleviewLoaneList(loaneeDetails)}
                  >
                    <MdRemoveRedEye size={20} />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
    </div>
  );
}
