import { useEffect, useState } from "react";
import { ROLELIST_URL } from "../../api/api_routing_urls";
import axios from "../../api/axios";

const useGetRoleList = (userName) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const controller = new AbortController();

  const getRoleList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        ROLELIST_URL,
        { params: { username: userName } },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.roleList?.length > 0) {
          let roleDD = [];

          response?.data?.roleList?.map((role) => {
            let roleObj = {
              value: role.roleid,
              label: role.rolename,
            };
            roleDD.push(roleObj);
          });
          setData(roleDD);
        } else {
          setData([]);
        }
      }
      response.status === 202 &&
        setError("No state list found in the system", "error");
    } catch (error) {
      console.error("Error on list", error);
      if (!error?.response) {
        setError("No Server Response");
      } else if (error.response.status === 422) {
        setError("Some of the required inputs were not provided", "error");
      } else {
        setError(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = userName ? true : false;

    if (isMounted) {
      getRoleList();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [userName]);

  return { data, error, loading };
};

export default useGetRoleList;
