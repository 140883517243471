import React from "react";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function DisbursmentDetailListPrint({
  disbursementDetailList,
  selectedCategory,
  selectedDistrictName,
  selectedConstituencyName,
  selectedApplicantGender,
  startDate,
  endDate,
}) {
  return (
    <>
      <section
        className={`text-[11px] bg-slate-50 border
                      grid grid-cols-4 gap-2 p-2 mb-3`}
      >
        <div className="col-span-4 flex justify-between text-[12px] border-b pb-2">
          <div>
            Date:{" "}
            <span className="font-medium ml-1">
              {startDate ? getFormattedDateDDMMMYYYYDate(startDate) : "N/A"}
            </span>
            {" to "}
            <span className="font-medium ml-1">
              {endDate ? getFormattedDateDDMMMYYYYDate(endDate) : "N/A"}
            </span>
          </div>
          <div>
            Total amount :{" "}
            <span className="font-semibold ">
              ₹ {+disbursementDetailList[0]?.total_disbursment_amount}
            </span>
          </div>
        </div>
        <div className="col-span-4 text-slate-800 flex justify-between items-center">
          <div className="flex items-center gap-6">
            <div>
              Category:{" "}
              <span className="font-medium ml-1">
                {selectedCategory ? selectedCategory.label : "N/A"}
              </span>
            </div>
            <div>
              Category:{" "}
              <span className="font-medium ml-1">
                {selectedApplicantGender
                  ? selectedApplicantGender.label
                  : "N/A"}
              </span>
            </div>
            <div>
              District:{" "}
              <span className="font-medium ml-1">
                {selectedDistrictName ? selectedDistrictName?.label : "N/A"}
              </span>
            </div>
            <div>
              Constituency:{" "}
              <span className="font-medium ml-1">
                {selectedConstituencyName
                  ? selectedConstituencyName?.label
                  : "N/A"}
              </span>
            </div>
          </div>

          <div className="justify end">
            No. of borrower:{" "}
            <span className="font-medium ml-1">
              {disbursementDetailList.length || 0}
            </span>
          </div>
        </div>
      </section>
      <table className="min-w-full divide-y divide-slate-200">
        <thead className="bg-slate-200 text-[11px] ">
          <tr>
            <th
              scope="col"
              className="px-2 py-1 text-left font-medium text-slate-900 "
            >
              SI No
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Applicant Name
            </th>
            {/* <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Relative Name
            </th> */}
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Category
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Gender
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Scheme Name
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              District
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Constituency
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600 text-[10px]">
          {disbursementDetailList?.length > 0 ? (
            disbursementDetailList?.map((disbursementList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-2 py-1 whitespace-nowrap ">{+index + 1}</td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.tr_loan_number}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.applicantname}
                </td>
                {/* <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.applicantrelativename}
                </td> */}
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.categoryname}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.applicantgender}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.schemename}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.districtname}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.constituencyname}
                </td>
                <td className="px-2 py-1 whitespace-nowrap ">
                  {disbursementList?.tr_amount}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
