import React from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";
import { SCHEME_CONFIG_URL } from "../../../../api/api_routing_urls";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
export default function SchemeList({
  schemeList,
  setEditSchemeDetails,
  setShowAddForm,
}) {
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const getInterestDetails = async (scheme_id) => {
    try {
      const response = await axiosPrivate.get(
        `${SCHEME_CONFIG_URL}/interestRateDetailsConfig`,
        {
          params: {
            schemeid: scheme_id,
          },
        },
        {
          signal: controller.signal,
        }
      );
      if (response) {
        const intrate = response?.data?.schemeList;
        setEditSchemeDetails((prev) => ({ ...prev, intrate }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onClickEdit = (scheme) => {
    getInterestDetails(scheme?.schemeid);
    setEditSchemeDetails(scheme);
    setShowAddForm(true);
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm ">
        <tr className="font-bold">
          <th scope="col" className="pl-6 py-2 text-left text-slate-900 ">
            Code
          </th>

          <th scope="col" className="px-3 py-2 text-left text-slate-900 ">
            Scheme Name (Short Name)
          </th>
          <th scope="col" className="px-3 py-2 text-left text-slate-900 ">
            Sanction Amount
          </th>
          <th
            scope="col"
            className="px-3 py-2 text-left text-slate-900  w-[20%]"
          >
            Interest Rate
          </th>
          {/* <th
            scope="col"
            className="px-3 py-2 text-left text-slate-900 "
          >
            Start Date
          </th> */}
          {/* <th
            scope="col"
            className="px-3 py-2 text-left text-slate-900 "
          >
            Payment Mode
          </th>

          <th
            scope="col"
            className="px-3 py-2 text-left text-slate-900 "
          >
            Repayment Mode
          </th> */}
          {/* <th
            scope="col"
            className="px-3 py-2 text-left text-slate-900 "
          >
            Repayment Period
          </th> */}

          <th scope="col" className="pr-6 py-2 text-right text-slate-900 ">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className=" divide-y divide-slate-100 text-slate-600">
        {schemeList.length > 0 &&
          schemeList.map((scheme, index) => (
            <tr
              key={index}
              className={`${index % 2 === 0 ? "bg-white" : "bg-slate-100"}`}
            >
              <td className="pl-6 py-2 whitespace-nowrap text-sm">
                {scheme.code}
              </td>

              <td className="px-3 py-2 whitespace-nowrap text-sm">
                {scheme.schemename} ({scheme.schemeshortname})
              </td>
              <td className="px-3 py-2 whitespace-nowrap text-sm">
                {formatINRCurrency(scheme.sanctionamount)}
              </td>
              <td className="py-2 whitespace-nowrap flex flex-wrap">
                <>
                  {scheme?.scheme_category?.map((item, index) => (
                    <span key={index} className="text-xs py-1 px-3">
                      {item?.value === null ? null : (
                        <>
                          {item?.key} :{" "}
                          <span className="text-slate-700 font-semibold">
                            {item?.value}%
                          </span>
                        </>
                      )}
                    </span>
                  ))}
                </>
              </td>

              <td className="pr-6 py-2 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(scheme)}
                    aria-label="Edit Scheme Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
