import React from "react";
import { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { MdOutlineApproval, MdRemoveRedEye } from "react-icons/md";
import Button from "../../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../../theme/lightTheme";
import { SiConvertio } from "react-icons/si";

export const SubMenu = ({
  applicantData,
  onClickEdit,
  onClickAddGuarantor,
  onClickDocumentEdit,
  onClickDeathCase,
  onClickOneTimeSettlement,
  rolename,
  setApplicantDetail,
  setViewApplicantDetail,
  setShowOTSCalcualtion,
  setShowLoaneeDeathCase,
  loaneeDeathCaseDetail,
  setDeathCaseDetail,
  setViewDeathCaseDetail,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => setShowSubMenu(false),
  });

  return (
    <>
      <div className="relative inline-block text-left z" ref={ref}>
        <div className="flex">
          <button type="button" onClick={() => setShowSubMenu(!showSubMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-slate-600 hover:text-slate-800 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>

        {showSubMenu && (
          <div
            className=" absolute right-6 top-0 min-w-max rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-2"
            role="menu"
          >
            <div className="flex items-start justify-start flex-col">
              <>
                {loaneeDeathCaseDetail ? (
                  <>
                    <Button
                      onClick={() => {
                        setDeathCaseDetail(loaneeDeathCaseDetail);
                        setViewDeathCaseDetail(true);
                      }}
                      aria-label="View Sub menu"
                      className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                    >
                      <span className={`${linkTextPrimary} px-0`}>
                        <MdRemoveRedEye size={16} />
                      </span>
                      <span>View Death Case Detail</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setApplicantDetail(applicantData);
                        setViewApplicantDetail(true);
                      }}
                      aria-label="View Sub menu"
                      className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                    >
                      <span className={`${linkTextPrimary} px-0`}>
                        <MdRemoveRedEye size={16} />
                      </span>
                      <span>View Applicant Detail</span>
                    </Button>
                  </>
                )}
              </>

              {loaneeDeathCaseDetail ? (
                <>
                  <Button
                    onClick={() => {
                      setShowLoaneeDeathCase(true);
                      setShowSubMenu(false);
                      onClickDeathCase(loaneeDeathCaseDetail?.loanee_id, false);
                    }}
                    aria-label="Revert To Death Case"
                    className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                  >
                    <span className={`${linkTextPrimary} px-0`}>
                      <SiConvertio size={12} />
                    </span>
                    <span>Revert From Death Case</span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setShowSubMenu(false);
                      setApplicantDetail(applicantData);
                      setShowLoaneeDeathCase(true);
                      onClickDeathCase(applicantData?.loanee_id, true);
                    }}
                    aria-label="Convert To Death Case"
                    className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                  >
                    <span className={`${linkTextPrimary} px-0`}>
                      <SiConvertio size={12} />
                    </span>
                    <span>Convet To Death Case</span>
                  </Button>
                </>
              )}

              {rolename === "Data Entry Operator" ? (
                <>
                  <Button
                    onClick={() => {
                      {
                        onClickAddGuarantor(
                          applicantData?.loanee_id,
                          applicantData?.applicantname
                        );
                      }
                      setShowSubMenu(false);
                    }}
                    aria-label="Add Guarantor Details"
                    className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                    </svg>
                    <span>Add Guarantor</span>
                  </Button>
                </>
              ) : null}
              {applicantData?.is_presanction_revert === true ||
              applicantData?.is_postsanction_revert === true ||
              (applicantData?.pre_forwarded_to === null &&
                rolename === "Data Entry Operator") ? (
                <>
                  <Button
                    onClick={() => {
                      setShowSubMenu(false);
                      onClickEdit(applicantData);
                    }}
                    aria-label="Edit Details"
                    className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span>Edit Applicant Detail</span>
                  </Button>
                </>
              ) : null}

              {applicantData?.is_presanction_approved === true &&
                applicantData?.is_postsanction_approved !== true &&
                rolename === "Data Entry Operator" && (
                  <>
                    <Button
                      onClick={() => {
                        setShowSubMenu(false);
                        onClickDocumentEdit(applicantData);
                      }}
                      aria-label="Edit Details"
                      className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path
                          fillRule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Add Applicant Documents</span>
                    </Button>
                  </>
                )}
              {/* 
                {rolename === "Assistant Manager" &&
                  (applicantData?.isdecreecase === true ? (
                    <Fragment>
                      <Button
                        onClick={() => {
                          setShowSubMenu(false);
                          onClickDecreeCase(
                            applicantData?.loanee_id,
                            false,
                            applicantData?.categoryname
                          );
                        }}
                        aria-label="Set as Decree"
                        className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Revert Decree Case</span>
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Button
                        onClick={() => {
                          setShowSubMenu(false);
                          onClickDecreeCase(
                            applicantData?.loanee_id,
                            true,
                            applicantData?.categoryname
                          );
                        }}
                        aria-label="Set as Decree"
                        className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Set As Decree Case</span>
                      </Button>
                    </Fragment>
                  ))} */}

              {(rolename === "Assistant Manager" ||
                rolename === "General Manager" ||
                rolename === "Deputy General Manager" ||
                rolename === "Managing Director" ||
                rolename === "Super Admin" ||
                rolename === "Data Entry Operator") &&
                (applicantData?.isonetimesettlement === true ? null : (
                  // </> //   </Button> //     <span>Revert form one time settlement</span> //     </svg> //       /> //         clipRule="evenodd" //         d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" //         fillRule="evenodd" //       <path //       <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /> //     > //       fill="currentColor" //       viewBox="0 0 20 20" //       className="h-4 w-4" //       xmlns="http://www.w3.org/2000/svg" //     <svg //   > //     className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`} //     aria-label="Set as Decree" //     }} //       ); //         applicantData?.categoryname //         false, //         applicantData?.loan_number, //       onClickOneTimeSettlement( //       setShowSubMenu(false); //     onClick={() => { //   <Button // <>
                  <>
                    <Button
                      onClick={() => {
                        setShowSubMenu(false);
                        setShowOTSCalcualtion(false);
                        onClickOneTimeSettlement(
                          applicantData?.loan_number,
                          true,
                          applicantData?.categoryname
                        );
                      }}
                      aria-label="Set as Decree"
                      className={`${linkTextPrimary} flex items-center gap-1 py-1  hover:bg-slate-100 w-full`}
                    >
                      <div className="flex items-center gap-1">
                        <MdOutlineApproval size={16} />
                        <span>Convert to one-time settlement </span>
                      </div>
                    </Button>
                  </>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
