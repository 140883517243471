import { useNavigate } from "react-router-dom";
import { back_btn } from "../theme/lightTheme";
import useLogout from "../authentication/hooks/useLogout";
import { LOGOUT_URL } from "../api/api_routing_urls";
import useAuth from "../authentication/hooks/useAuth";
import axios from "../api/axios";
import { useEffect } from "react";

const Unauthorized = () => {
  const navigate = useNavigate();
  const { auth, setAuth, setPersist } = useAuth();

  const logout = useLogout();

  const signOut = async () => {
    await axios.get(LOGOUT_URL, {
      withCredentials: true,
    });
    setPersist(false);

    setAuth({});
    await logout();
    navigate("/");
  };

  useEffect(() => {
    setInterval(() => {
      return () => {
        signOut();
      };
    }, 3000);
  }, []);

  return (
    <section className=" bg-red-50 h-screen  flex justify-center items-center">
      <div className="text-center">
        <h1>Unauthorized</h1>
        <br />
        <p className="text-center">
          You do not have access to the requested page.
        </p>
        <div className="text-center">
          <button className={back_btn} onClick={() => signOut()}>
            Go Back
          </button>
        </div>
      </div>
    </section>
  );
};

export default Unauthorized;
