import React, { useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../../authentication/hooks/useAuth";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../../theme/lightTheme";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import { defaulterDD } from "../../../../utilities/constants/constants";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import PDFImageUploaderAWS from "../../../../reusable-components/inputs/FileUploader/PDFUploaderAWS";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";
import { DEFAULTER_DOCS_URL } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function AddDefaulterDocs({
  setShowAddForm,
  getDefaulterDocsList,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const controller = new AbortController();

  const [selectedDefaulterType, setSelectedDefaulterType] = useState();
  const [particulars, setParticulars] = useState("");

  const [defaulterDoc, setDefaulterDoc] = useState("");

  let isEdit = false;

  const defaultValues = {
    date: !isEdit ? "" : "",
    particulars: !isEdit ? "" : "",
    filterDefaulterList: !isEdit ? "" : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  async function updateApplicantDocument() {
    let file_url = "";
    let file_list = [];

    if (defaulterDoc) {
      // console.log('defaulterDoc if condition')

      file_url = await uploadFile(defaulterDoc);
      file_list.push(
        createFileObj(defaulterDoc?.name, "Defaulter Document", file_url)
      );
      return file_list;
      // console.log('file_list',file_list)
    }
  }

  const onSubmit = async (data) => {
    try {
      let formDataObj = {};

      let defaulterFiles = await updateApplicantDocument();

      // console.log('defaulterFiles',defaulterFiles[0]?.fileurl)

      let response = "";

      formDataObj.defaulter_type = selectedDefaulterType?.value;
      formDataObj.date = data?.date;
      formDataObj.particulars = data?.particulars;

      if (!isEdit) {
        formDataObj.created_by = auth.userid;
        formDataObj.document_url = defaulterFiles[0]?.fileurl;

        response = await axiosPrivate.post(
          `${DEFAULTER_DOCS_URL}/save`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      console.log("response for adding ", response);
      if (isEdit) {
        // data.defaulter_docs_id = editGuarantorDetails?.defaulter_docs_id;
        data.updated_by = auth.userid;

        response = await axiosPrivate.post(
          `${DEFAULTER_DOCS_URL}/update`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          showToast(
            "Defaulter Document has been updated successfully",
            "success"
          );
        } else {
          showToast(
            "Defaulter Document has been added successfully",
            "success"
          );
        }

        getDefaulterDocsList();
        setShowAddForm(false);
      } else {
        if (isEdit) {
          if (
            response.data.updateDefaulterDocumentDetails.includes(
              "unique_defaulter"
            )
          ) {
            showToast(
              "Defaulter Document already exists. Cannot insert duplicate guarantor",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response?.data?.gurrantorDetails.includes("unique_guarantor")) {
            showToast(
              "Defaulter Document already exists. Cannot insert duplicate guarantor",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      // setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);

      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      // setShowAddForm(false);
    }
  };

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 items-center gap-6 pt-4">
            <div className="flex flex-col">
              <label htmlFor="filterDefaulterListType">
                Defaulter Duration
              </label>
              <FilterDropdown
                defaultName="filterDefaulterListType"
                required={null}
                classes={`text-sm w-full rounded-sm`}
                placeholder={"Select Defaulter Duration"}
                data={defaulterDD}
                defaultValue={isEdit ? defaultValues.filterDefaulterList : ""}
                setSelected={setSelectedDefaulterType}
                selected={selectedDefaulterType}
                maxMenuHeight={256}
              />
            </div>

            <div className="pb-1">
              <Input
                defaultName="date"
                register={register}
                name="Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter Date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={` rounded-md text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.date}
                setValue={setValue}
              />
            </div>
          </div>

          <div className="pt-3">
            <TextArea
              defaultName="particulars"
              register={register}
              name="Particulars"
              required={null}
              pattern={null}
              errors={errors}
              placeholder="Write a remarks"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-6 text-sm w-full`}
              onChangeInput={(e) => setParticulars(e)}
              defaultValue={isEdit ? defaultValues.particulars : ""}
              setValue={setValue}
            />
          </div>

          <div className=" pt-6">
            <PDFImageUploaderAWS
              register={register}
              defaultName="defaulterDocument"
              name="Add Defaulter Details Document"
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none  `}
              setError={setError}
              clearError={clearErrors}
              file={defaulterDoc}
              setFile={setDefaulterDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.defaulterDocument : ""}
            />
          </div>

          <div className="flex justify-end gap-3 py-8">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                //   setEditDistrictDetails({});
                //   setValue("districtname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Bank Update Bank"
              className={cancel_button}
              Button
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
