import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import useAuth from "../../../../authentication/hooks/useAuth";
import { ToastContainer } from "react-toastify";
import EmployeeDetailsForm from "./EmployeeDetailsForm";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import { MdPermMedia } from "react-icons/md";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import { BsPersonWorkspace } from "react-icons/bs";
import {
  EMP_DESIGNATION_URL,
  EMP_DETAILS_URL,
} from "../../../../api/api_routing_urls";
import EmployeeDetailsList from "./EmployeeDetailsList";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function EmployeeDetailsConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedEmpDesignation, setSelectedEmpDesignatiom] = useState();
  const [employeeDesignationDD, setEmployeeDesignationDD] = useState();
  const [empDetailsList, setEmpDetailsList] = useState();
  const [editEmpDetails, setEditEmpDetails] = useState();
  const [empDetailsId, setEmpDetailsId] = useState();
  const [showDelete, setShowDelete] = useState(false);

  let isEdit =
    editEmpDetails === undefined || editEmpDetails === null
      ? false
      : Object?.keys(editEmpDetails)?.length > 0
      ? true
      : false;

  const getEmpDesignationDD = async () => {
    try {
      const response = await axiosPrivate.get(EMP_DESIGNATION_URL, {
        signal: controller.signal,
      });

      let employee_DesignationDD = [];

      if (response?.status === 200) {
        response?.data?.empDesigantion_List?.map((empDesignationDetailsObj) => {
          employee_DesignationDD.push({
            label: empDesignationDetailsObj?.emp_designation,
            value: empDesignationDetailsObj?.emp_designation_id,
          });
        });
        setEmployeeDesignationDD(employee_DesignationDD);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEmpDetailsList = async () => {
    try {
      const response = await axiosPrivate.get(EMP_DETAILS_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setEmpDetailsList(response?.data?.empDetails_List);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEmpDetails = async () => {
    try {
      const data = {};
      data.emp_id = empDetailsId;
      const response = await axiosPrivate.post(
        `${EMP_DETAILS_URL}/deleteEmpDetails`,
        data,
        {
          signal: controller.signal,
        }
      );

      response.status === 200 &&
        showToast("Employee Designation deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the Employee Designation is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getEmpDetailsList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getEmpDesignationDD();
    getEmpDetailsList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />
        {showAddForm ? (
          <>
            <EmployeeDetailsForm
              setShowAddForm={setShowAddForm}
              setSelectedEmpDesignatiom={setSelectedEmpDesignatiom}
              selectedEmpDesignation={selectedEmpDesignation}
              employeeDesignationDD={employeeDesignationDD}
              editEmpDetails={editEmpDetails}
              getEmpDetailsList={getEmpDetailsList}
            />
          </>
        ) : (
          <>
            <EmployeeDetailsList
              setShowAddForm={setShowAddForm}
              empDetailsList={empDetailsList}
              setEditEmpDetails={setEditEmpDetails}
              setEmpDetailsId={setEmpDetailsId}
              setShowDelete={setShowDelete}
            />
          </>
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteEmpDetails}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
