import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IconContext } from "react-icons";
import { FaFilePdf, FaTimesCircle } from "react-icons/fa";
import { NEWS_CONFIG_URL } from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import PDFUploader from "../../../reusable-components/inputs/FileUploader/PDFUploader";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import RichTextArea from "../../../reusable-components/richtexteditor/RichTextArea";
import {
  button,
  cancel_button,
  delete_button,
  linkTextDanger,
  linkTextPrimary,
} from "../../../theme/lightTheme";
import { getFormattedDateInputDate } from "../../../utilities/dateFunctions/formatdate";
import { base64toBlob } from "../../../utilities/fileFunctions/pdfFunctions";
import showToast from "../../../utilities/notification/NotificationModal";

const AddNewsForm = ({
  getNewsList,
  editNewsDetails,
  setEditNewsDetails,
  newsTypeList,
  setShowAddForm,
}) => {
  const [selected, setSelected] = useState();
  const [filename, setFilename] = useState("");
  const [newsDoc, setNewsDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();

  const isEdit =
    editNewsDetails !== undefined
      ? Object.keys(editNewsDetails).length > 0
      : false;

  const defaultValues = {
    newstitle: isEdit ? editNewsDetails.newstitle : "",
    newstype: isEdit
      ? newsTypeList.filter(
          (news) => news.value === editNewsDetails.newstype
        )[0]
      : null,
    newsdesc: isEdit ? editNewsDetails.newsdesc : "",
    newsdoc: isEdit ? editNewsDetails.newsdoc : "",
    newsstartdate: isEdit ? editNewsDetails.newsstartdate : "",
    newsenddate: isEdit ? editNewsDetails.newsenddate : "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [showRemoveDoc, setShowRemoveDoc] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);

  const [minEndDate, setMinEndDate] = useState(
    getFormattedDateInputDate(new Date())
  );

  const onChangeStartDate = (e) => {
    if (e !== null) {
      setShowEndDate(true);
      setMinEndDate(getFormattedDateInputDate(e));
      setValue("newsenddate", getFormattedDateInputDate(e));
    } else {
      setShowEndDate(false);
      setMinEndDate(getFormattedDateInputDate(new Date()));
    }
  };

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  useEffect(() => {
    if (isEdit) {
      setMinEndDate(getFormattedDateInputDate(editNewsDetails.newsstartdate));
    }
  }, [isEdit]);

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${editNewsDetails.newstitle} Document`);
    setShowPDFViewer(true);
  };

  const emptyObj = {};
  const setDefaultValueEmpty = () => {
    setEditNewsDetails(emptyObj);
    setShowAddForm(false);
  };

  const onSubmit = async (data) => {
    try {
      let response = {};
      if (typeof data.newsdoc !== "string") {
        data.newsdoc = newsDoc;
      }

      if (isEdit) {
        data.newsid = editNewsDetails.newsid;
        data.updatedby = auth.userid;
        data.newstype = data.newstype.value;
        let updatedData = {
          newsid: data.newsid,
          newstitle: data.newstitle,
          newstype: data.newstype,
          newsdesc: data.newsdesc,
          newsdoc: data.newsdoc,
          newsstartdate: data.newsstartdate,
          newsenddate: data.newsenddate,
          updatedby: data.updatedby,
        };

        response = await axiosPrivate.put(NEWS_CONFIG_URL, updatedData, {
          signal: controller.signal,
        });
      } else {
        data.createdby = auth.userid;
        data.newstype = data.newstype.value;
        let savedData = {
          newstitle: data.newstitle,
          newstype: data.newstype,
          newsdesc: data.newsdesc,
          newsdoc: data.newsdoc,
          newsstartdate: data.newsstartdate,
          newsenddate: data.newsenddate,
          createdby: data.createdby,
        };
        response = await axiosPrivate.post(NEWS_CONFIG_URL, savedData, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast("News & Events Details Successfully Updated", "success");
        } else {
          showToast("News & Events Details Successfully Added", "success");
        }
        getNewsList(true);
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
        return;
      }
      setDefaultValueEmpty();
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <ContainerShadow>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 md:col-span-1">
            <Input
              defaultName="newstitle"
              register={register}
              name="Title"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter News / Events title"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.newstitle}
              setValue={setValue}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <div className="mt-2">
              <Dropdown
                defaultName="newstype"
                register={register}
                labelname="Type"
                required={true}
                pattern={null}
                errors={errors}
                classes={`rounded-lg text-sm w-full z-40`}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={newsTypeList}
                defaultValue={defaultValues.newstype}
                setValue={setValue}
                setSelected={setSelected}
                selected={selected}
                maxMenuHeight={120}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 md:col-span-1">
            <Input
              defaultName="newsstartdate"
              register={register}
              name="News / Event Start Date"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter News / Events start date"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="date"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={onChangeStartDate}
              defaultValue={getFormattedDateInputDate(
                defaultValues.newsstartdate
              )}
              setValue={setValue}
            />
          </div>
          <div
            className={`col-span-2 md:col-span-1 ${
              isEdit ? "block" : showEndDate ? "block" : "hidden"
            }`}
          >
            <Input
              defaultName="newsenddate"
              register={register}
              name="News / Event End Date"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter News / Events end date"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="date"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={getFormattedDateInputDate(
                defaultValues.newsenddate
              )}
              setValue={setValue}
              min={minEndDate}
            />
          </div>
        </div>
        <RichTextArea
          defaultName={"newsdesc"}
          register={register}
          name={"News / Event Description"}
          classes={"w-full"}
          clearError={clearErrors}
          setError={setError}
          setValue={setValue}
          data={defaultValues.newsdesc}
          errors={errors}
          pattern={null}
          required={true}
        />
        {isEdit && editNewsDetails.newsdoc && (
          <Fragment>
            <span className="flex items-center">
              Uploaded Document:
              <Fragment>
                <IconContext.Provider
                  value={{ className: "text-red-700 text-5xl" }}
                >
                  <FaFilePdf />
                </IconContext.Provider>
                <Button
                  className={linkTextPrimary}
                  onClick={() => onClickView(editNewsDetails.newsdoc)}
                >
                  {editNewsDetails.newstitle}.pdf
                </Button>
              </Fragment>
              <Button
                className={linkTextDanger}
                onClick={() => {
                  setShowRemoveDoc(true);
                }}
              >
                <IconContext.Provider
                  value={{ className: "text-red-900 text-xl" }}
                >
                  <FaTimesCircle />
                </IconContext.Provider>
              </Button>
            </span>
          </Fragment>
        )}
        <PDFUploader
          register={register}
          defaultName="newsdoc"
          name={`${
            isEdit && editNewsDetails.newsdoc
              ? "Replace Document"
              : "News / Event Document (if any)"
          }`}
          required={false}
          pattern={null}
          placeholder="News / Event Document"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={newsDoc}
          setBase64={setNewsDoc}
          setFilename={setDocFilename}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.newsdoc : ""}
        />
        <div className="flex items-center justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={`${isEdit ? "Update" : "Submit"}`}
            className={button}
          />
          <Button className={cancel_button} onClick={setDefaultValueEmpty}>
            Cancel
          </Button>
        </div>
      </form>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
      <GenericModal
        open={showRemoveDoc}
        setOpen={setShowRemoveDoc}
        title={`Confirmation Required`}
        isLarge={false}
        isAdd={false}
      >
        <div className="text-justify">
          <p>Are you sure you want to remove the document?</p>
        </div>
        <div className="flex justify-end">
          <Button
            className={`${delete_button} mr-3`}
            onClick={() => {
              setValue("newsdoc", "");
              setEditNewsDetails({ ...editNewsDetails, newsdoc: "" });
              setShowRemoveDoc(false);
            }}
          >
            Yes
          </Button>
          <Button
            className={cancel_button}
            onClick={() => {
              setShowRemoveDoc(false);
            }}
          >
            No
          </Button>
        </div>
      </GenericModal>
    </ContainerShadow>
  );
};

export default AddNewsForm;
