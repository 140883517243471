import React from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";

export const OtsCalculation = ({ otsCalculation }) => {
  return (
    <>
      <section>
        <div className="text-slate-700 text-sm">
          <div className="font-semibold text-slate-900 text-center">
            {" "}
            OTS Calculation
          </div>
          <div className="border bg-slate-50 p-3 ">
            <div>
              <span className="w-36">Loanee Id:</span>
              <span className="font-semibold pl-3">
                {otsCalculation?.loanee_id}
              </span>
            </div>
            <div>
              Loan Number:
              <span className="font-semibold pl-3">
                {otsCalculation?.loan_number}
              </span>
            </div>
            <div>
              OTS Date:{" "}
              <span className="font-semibold pl-3">
                {getFormattedDateDDMMMYYYYDate(otsCalculation?.ots_date)}
              </span>
            </div>
            <div>
              Principal Outstanding :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.outstanding_amt)}
              </span>
            </div>
            <div>
              Interest Due :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.interest_due_amount)}
              </span>
            </div>
            <div>
              Penal Interest :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.penal_int_due)}
              </span>
            </div>
            <div>
              Interest on Interest :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.ioi_int_due)}
              </span>
            </div>

            <div>
              Total Interest :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.total_int_due_amt)}
              </span>
            </div>
            <div>
              Waiver Rate :
              <span className="font-semibold pl-3">
                {otsCalculation?.waiver_rate}
              </span>
            </div>
            <div>
              Waiver Amount :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.waiver_amount)}
              </span>
            </div>
            <div>
              Repayment Due :
              <span className="font-semibold pl-3">
                {formatINRCurrency(otsCalculation?.repayment_due_amount)}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
