// developed by Jesson

import React from "react";
import coverphoto from "../../assets/dummy-image-square.jpg";

import "./PhotosCard.css";

const PhotosCard = ({
  photo_image,
  photo_title,
  photo_description,
  photos_list,
  setSelectedPhotoArray,
  setSelectedPhotoTitle,
}) => {
  const onClickHandler = () => {
    setSelectedPhotoTitle(photo_title);
    setSelectedPhotoArray(photos_list);
  };

  return (
    <>
      <section className="container mx-auto">
        <div className="event">
          <div className="cursor-pointer">
            <img
              src={photo_image || coverphoto}
              alt="Event Image"
              className="h-96 md:w-full"
              onClick={onClickHandler}
            />

            <div className="content pt-2">
              <h3 className="lg:text-xl text-base font-medium">
                {photo_title}
              </h3>

              <div className="rollover">
                <p className="font-body">{photo_description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhotosCard;
