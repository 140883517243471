import React, { useEffect, useRef, Fragment, useState } from "react";
import {
  MdAssignmentTurnedIn,
  MdFilterListAlt,
  MdOutlineFilterAlt,
  MdOutlineSearch,
  MdPrint,
} from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  REPORTS_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import FilterInput from "../../../../reusable-components/search/FilterInput";
import {
  getFormattedDateMMMYYYY,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";
import MonthlyReportList from "./MonthlyReportList";
import ReportCard from "../ReportCard";
import ReactToPrint from "react-to-print";
import { CiCalendar } from "react-icons/ci";
import MonthlyReportListPrint from "./MonthlyReportListPrint";
import { PrintReport } from "../PrintReport/PrintReport";
import { BsDot } from "react-icons/bs";
import { genderDD } from "../../../../utilities/constants/constants";

const MonthlyReportConfig = () => {
  const { auth } = useAuth();
  const [monthlyReportList, setMonthlyReportList] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [editRepaymentDetails, setEditRepaymentDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const pageSize = 10;

  const [categoryList, setCategoryList] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [gramPanchayatList, setGramPanchayatList] = useState([]);
  const [nagarPanchayatList, setNagarPanchayatList] = useState([]);
  const [municipalCorporationList, setMunicipalCorporationList] = useState([]);

  const [startDate, setStartDate] = useState("1900-01-01");
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();

  const [selectedApplicantGender, setSelectedApplicantGender] = useState();

  const [showFilters, setShowFilters] = useState(false);

  const controller = new AbortController();

  const componentRefToPrint = useRef();

  // get searched repayment list
  const getMonthlyReportList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPORTS_CONFIG_URL}/getMonthlyReportConfig`,
        {
          params: {
            specficdate: getFormattedDateInputDate(new Date(startDate)),
            categoryname: selectedCategory?.label || "",
            districtname: selectedDistrictName?.label || "",
            constituencyname: selectedConstituencyName?.label || "",
            applicantgender: selectedApplicantGender?.label || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setMonthlyReportList(response?.data?.monthlyReport);

        const totalNumberOfApplicant =
          response?.data?.monthlyReport[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(Math.ceil(totalNumberOfApplicant / pageSize));
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get category list
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  // get District List
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get constituency list
  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getSearchedResult = () => {
    getMonthlyReportList();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // getRepaymentList();
      getCategoryList();
      // getSchemeList();
      getConstituencyList();
      // getGramPanchayatList();
      getDistrictList();
      // getMunicipalCorporationList();
      // getSubDivisionList();
      // getNagarPanchayatList();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && startDate) {
      getMonthlyReportList();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    selectedCategory?.value,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
    selectedApplicantGender?.value,
  ]);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <>
          <LoadingModal message={"Loading"} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdAssignmentTurnedIn />{" "}
              <span className="pl-1.5">Monthly Progress Report </span>
            </H2withIcon>
            {monthlyReportList[0]?.listcount > 0 && (
              <ReactToPrint
                trigger={() => (
                  <Button className="bg-primary px-3 py-2 text-white flex justify-center items-center gap-1 text-sm md:min-w-36">
                    Print Report
                    <MdPrint />
                  </Button>
                )}
                content={() => componentRefToPrint.current}
                pageStyle="A4"
                documentTitle="Report"
              />
            )}
          </div>
        </>
      )}

      <>
        <section className="flex flex-wrap gap-3 justify-between items-end mb-2">
          <div className="grid grid-cols-2 gap-2 ">
            <FilterInput
              name="Select Month"
              placeholder="Select Month"
              autoComplete="off"
              type="month"
              classes={`text-sm w-full rounded h-8`}
              onChangeInput={setStartDate}
              // defaultValue={getFormattedDateInputDate(startDate)}
            />
            {/* <FilterInput
              name="End Date"
              placeholder="End Date"
              autoComplete="off"
              type="month"
              classes={`text-sm w-full rounded h-8`}
              onChangeInput={setEndDate}
              defaultValue={getFormattedDateInputDate(endDate)}
            /> */}
          </div>
          <div className="flex gap-2">
            <Button
              className={
                "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Apply Filters
              <MdOutlineFilterAlt size={18} />
            </Button>
            <Button
              className={
                "bg-primary text-white text-sm px-3 py-2 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => getSearchedResult()}
            >
              Search
              <MdOutlineSearch size={18} />
            </Button>
          </div>
        </section>
        {showFilters && (
          <section className="grid grid-cols-1  md:grid-cols-4 gap-3 bg-slate-100 border p-3 mt-3">
            <FilterDropdown
              defaultName="filterCategory"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Category..."}
              data={categoryList}
              defaultValue={null}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterGender"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Gender..."}
              data={genderDD}
              defaultValue={null}
              setSelected={setSelectedApplicantGender}
              selected={selectedApplicantGender}
              maxMenuHeight={256}
            />
            {/* <FilterDropdown
              defaultName="filterScheme"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Scheme..."}
              data={schemeList}
              defaultValue={null}
              setSelected={setSelectedSchemeName}
              selected={selectedSchemeName}
              maxMenuHeight={256}
            /> */}
            <FilterDropdown
              defaultName="filterDistrict"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select District..."}
              data={districtList}
              defaultValue={null}
              setSelected={setSelectedDistrictName}
              selected={selectedDistrictName}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterConstituency"
              required={true}
              classes={`text-xs w-full`}
              placeholder={"Select Constituency..."}
              data={constituencyList}
              defaultValue={null}
              setSelected={setSelectedConstituencyName}
              selected={selectedConstituencyName}
              maxMenuHeight={256}
            />
          </section>
        )}
      </>

      <>
        <section
          className="mt-4 text-sm bg-slate-50
          grid grid-cols-1 md:grid-cols-4 gap-3 border p-3"
        >
          <div className=" md:col-span-4 text-slate-800 flex justify-between items-center">
            <div className="flex flex-wrap items-center gap-3 ">
              <div className="flex items-center gap-1">
                <CiCalendar size={19} />
                <span className="font-semibold">
                  {monthlyReportList[0]?.tr_date
                    ? getFormattedDateMMMYYYY(monthlyReportList[0]?.tr_date)
                    : "--  -- , --"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                Category:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.categoryname
                    ? monthlyReportList[0]?.categoryname
                    : "N/A"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                District:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.districtname
                    ? monthlyReportList[0]?.districtname
                    : "N/A"}
                </span>
              </div>

              <BsDot size={20} />

              <div>
                Constituency:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.constituencyname
                    ? monthlyReportList[0]?.constituencyname
                    : "N/A"}
                </span>
              </div>
            </div>
            <div className="justify end">
              No. of Repayments:{" "}
              <span className="font-semibold ml-1">
                {monthlyReportList.length || 0}
              </span>
            </div>
          </div>

          <ReportCard
            title={"Cash"}
            principal={monthlyReportList[0]?.repay_bal_cash_amt}
            interest={monthlyReportList[0]?.repay_int_cash_amt}
          />
          <ReportCard
            title={"Cheque"}
            principal={monthlyReportList[0]?.repay_bal_cheque_amt}
            interest={monthlyReportList[0]?.repay_int_cheque_amt}
          />
          <ReportCard
            title={"Branch"}
            principal={monthlyReportList[0]?.repay_bal_branch_amt}
            interest={monthlyReportList[0]?.repay_int_branch_amt}
          />
          <ReportCard
            title={"Total"}
            principal={
              +monthlyReportList[0]?.repay_bal_cash_amt +
              +monthlyReportList[0]?.repay_bal_cheque_amt +
              +monthlyReportList[0]?.repay_bal_branch_amt
            }
            interest={
              +monthlyReportList[0]?.repay_int_cash_amt +
              +monthlyReportList[0]?.repay_int_cheque_amt +
              +monthlyReportList[0]?.repay_int_branch_amt
            }
          />
        </section>
      </>

      <div className="grid grid-cols-1 mt-6">
        <MonthlyReportList monthlyReportList={monthlyReportList} />
        {monthlyReportList?.length > 0 ? (
          <PrintReport reportTitle={"Monthly Report"}>
            <MonthlyReportListPrint monthlyReportList={monthlyReportList} />
          </PrintReport>
        ) : null}
      </div>
      {monthlyReportList[0]?.listcount > 0 && (
        <>
          <section className="mt-4 p-2 bg-slate-200 text-sm">
            <p>
              Total Repayment Amount:{" "}
              <span className="font-semibold">
                ₹{monthlyReportList[0]?.totalrepaymentamount}
              </span>
            </p>
            <p>
              Total Principle Amount Paid:{" "}
              <span className="font-semibold">
                ₹{monthlyReportList[0]?.tatalprincipleamountpaid}
              </span>
            </p>
            <p>
              Total Interest Amount Paid:{" "}
              <span className="font-semibold">
                ₹{monthlyReportList[0]?.totalinterstamountpaid}
              </span>
            </p>
            <p>
              Total number of borrower:{" "}
              <span className="font-semibold">
                {monthlyReportList[0]?.listcount}
              </span>
            </p>
          </section>
        </>
      )}
    </Dashboard>
  );
};

export default MonthlyReportConfig;
