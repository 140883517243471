import React, { useEffect, useState } from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
// import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import pending_lotty from "../../../../assets/Lottie/Pending.json";
import failed_lotty from "../../../../assets/Lottie/Failed.json";
import success_lotty from "../../../../assets/Lottie/Success.json";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";

const PaymentResponseHandler = () => {
  // const location = useLocation();
  // const { paymentResponse } = location.state || {};

  const [paymentResponse, setPaymentResponse] = useState(null);
  const [lottyOptions, setLottyOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    let dataParam = urlParams.get("data");

    console.log({ dataParam });

    if (dataParam) {
      try {
        //cleaning dataparam
        dataParam = dataParam.replace('"', "");
        // Parse the query string into an object
        const decodedDataParam = decodeURIComponent(dataParam);

        const urlSearchParams = new URLSearchParams(decodedDataParam);
        const responseData = Object.fromEntries(urlSearchParams.entries());

        console.log({ responseData });

        // You can now access the properties directly
        const jsonData = {
          // Add more properties as needed
          order_id: responseData.order_id,
          transaction_validity: responseData.transaction_validity,
          auth_status: responseData.auth_status,
          txn_reference_no: responseData.txn_reference_no,
          transaction_time: responseData.transaction_time,
          transaction_amt: responseData.transaction_amt,
          error_description: responseData.error_description,
        };

        setPaymentResponse(jsonData);

        const defaultOptions = {
          loop: false, // Set loop to true to loop the animation
          autoplay: true, // Set autoplay to true to start the animation when the component mounts
          animationData:
            responseData?.auth_status === "Success"
              ? success_lotty
              : responseData?.auth_status === "Failed"
              ? failed_lotty
              : pending_lotty, // Animation data
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice", // Preserve aspect ratio
          },
        };

        setLottyOptions(defaultOptions);
      } catch (error) {
        // navigate("/");

        console.error("Error parsing query string:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      // navigate("/");
    }
  }, []);

  // if (!paymentResponse) {
  //   return <div>No payment response found</div>;
  // }

  return (
    <>
      <HeroBanner>
        <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
              Payment Response
            </p>
          </div>
        </div>
      </HeroBanner>

      <div className="p-9 border border-slate-200 m-9 min-h-[20rem] flex items-center justify-center">
        {isLoading ? (
          <div>
            <p className="text-center">Fetching payment response...</p>
            <p>
              <SpinnerWithText />
            </p>
          </div>
        ) : (
          <div>
            <strong>
              <h1 className="text-slate-700 text-xl text-center">
                <div>
                  <Lottie options={lottyOptions} height={100} width={100} />
                </div>
                <p className="mt-6 text-center">
                  Online Payment{" "}
                  {paymentResponse?.auth_status === "Success"
                    ? "Successful"
                    : paymentResponse?.auth_status === "Failed"
                    ? "Failed"
                    : "Pending"}
                </p>
              </h1>
              <div className="mt-9">
                <p>
                  <strong>Order ID:</strong> {paymentResponse?.order_id || "NA"}
                </p>
                <p>
                  <strong>Status:</strong>{" "}
                  {paymentResponse?.auth_status || "NA"}
                </p>
                <p>
                  <strong>Transaction Reference No:</strong>{" "}
                  {paymentResponse?.txn_reference_no || "NA"}
                </p>
                <p>
                  <strong>Transaction Amount:</strong>{" "}
                  {formatINRCurrency(paymentResponse?.transaction_amt || 0)}
                </p>
                <p>
                  <strong>Transaction Time:</strong>{" "}
                  {new Date(paymentResponse?.transaction_time).toLocaleString()}
                </p>
                {paymentResponse?.error_description && (
                  <p>
                    <strong>Description:</strong>{" "}
                    {paymentResponse?.error_description || "NA"}
                  </p>
                )}
              </div>
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentResponseHandler;
