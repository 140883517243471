import React, { useState, Fragment } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { MdPrint, MdReceipt } from "react-icons/md";
import ReactToPrint from "react-to-print";
import { CATEGORY_CONFIG_URL } from "../../../../api/api_routing_urls";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

export default function DecreeRepaymentList({
  repaymentList,
  setEditRepaymentDetails,
  setShowAddForm,
  penalRefToPrint,
  getPenalInterestList,
  setViewApplicantInterestDetail,
  setViewPenalInterestDetail,
  getInterestOnInterestList,
  setViewInterestOnInterestDetail,
}) {
  const onClickEdit = (repayment) => {
    setEditRepaymentDetails(repayment);
    setShowAddForm(true);
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr className="bg-slate-200">
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Applicant Name
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Relative Name
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Category
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Amount of Loan applied
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {repaymentList?.length > 0 ? (
          repaymentList?.map((repayment, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.applicantname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.applicantrelativename}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.categoryname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.loanappliedamt}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm ">
                <div className="flex justify-evenly gap-2">
                  {repayment?.isprincipleduepaidoff !== true ? (
                    <Button
                      onClick={() => onClickEdit(repayment)}
                      aria-label="Add Repayment"
                      className={linkTextPrimary}
                    >
                      Add Repayment
                    </Button>
                  ) : (
                    <Fragment>
                      <div className="flex gap-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-green-700 "
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Principle Paid off
                      </div>
                    </Fragment>
                  )}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <Fragment>
            <tr className="m-auto text-center">
              <td colSpan={9} className="py-3">
                No List Found
              </td>
            </tr>
          </Fragment>
        )}
      </tbody>
    </TableWrapper>
  );
}

const SubMenu = ({
  getPenalInterestList,
  setViewApplicantInterestDetail,
  setViewPenalInterestDetail,
  getInterestOnInterestList,
  setViewInterestOnInterestDetail,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showPrintPenal, setShowPrintPenal] = useState(false);
  const [showPrintInterest, setShowPrintInterest] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => setShowSubMenu(false),
  });

  return (
    <Fragment>
      <div className="relative inline-block text-left" ref={ref}>
        <div className="flex">
          <button type="button" onClick={() => setShowSubMenu(!showSubMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-slate-600 hover:text-slate-800 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>

        {showSubMenu && (
          <div
            className="z-60  absolute -right-3 bottom-4 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-2"
            role="menu"
          >
            <div className="flex items-start justify-start flex-col">
              {showPrintPenal ? (
                <Button onClick={setViewApplicantInterestDetail(true)}>
                  View Penal Interest
                </Button>
              ) : (
                // <ReactToPrint
                //   trigger={() => (
                //     <span
                //       className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                //     >
                //       <MdPrint />
                //       Penal Interest
                //     </span>
                //   )}
                //   content={() => penalRefToPrint?.current}
                //   pageStyle="A4"
                //   documentTitle="Report"
                // />
                <Button
                  className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                  onClick={() => {
                    getPenalInterestList();
                    setViewPenalInterestDetail(true);
                    setViewApplicantInterestDetail(true);
                  }}
                >
                  View Penal Interest
                </Button>
              )}
              {showPrintPenal ? (
                <Button onClick={setViewApplicantInterestDetail(true)}>
                  View Interest on Interest
                </Button>
              ) : (
                <Button
                  className={`${linkTextPrimary} flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                  onClick={() => {
                    getInterestOnInterestList();
                    setViewInterestOnInterestDetail(true);
                    setViewApplicantInterestDetail(true);
                  }}
                >
                  View Interst on Interest
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
