import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { PHOTO_GALLERY_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import ImageUploader from "../../../../reusable-components/inputs/FileUploader/ImageUploader";
import NewImageUploader from "../../../../reusable-components/inputs/FileUploader/NewImageUploader";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";

export default function AddPhotoForm({
  getPhotoGalleryList,
  editPhotoDetails,
  setEditPhotoDetails,
  setShowAddForm,
}) {
  const [photoDoc, setPhotoDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");

  const [fileUploadError, setFileUploadError] = useState(false);

  const [passphoto, setPassphoto] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editPhotoDetails).length > 0;

  const defaultValues = {
    mediacaption: !isEdit ? "" : editPhotoDetails?.mediacaption,
    mediatype: "Broachers",
    mediafile: !isEdit ? "" : editPhotoDetails?.mediafile,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  console.error(errors);

  useEffect(() => {
    setPhotoDoc(editPhotoDetails.mediafile);
  }, [isEdit]);

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  const onSubmit = async (data) => {
    try {
      if (passphoto) {
        data.mediafile = await uploadFile(passphoto);
      } else {
        data.mediatype = defaultValues?.mediatype;
      }

      let response = "";
      if (typeof data.mediafile !== "string") {
        data.mediafile = photoDoc;
      }
      if (!isEdit) {
        data.createdby = auth.userid;
        data.mediatype = defaultValues.mediatype;
        response = await axiosPrivate.post(PHOTO_GALLERY_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.mediatype = defaultValues.mediatype;
        data.mediaid = editPhotoDetails?.mediaid;
        response = await axiosPrivate.put(PHOTO_GALLERY_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditPhotoDetails({});
          showToast("Brochure has been updated successfully", "success");
        } else {
          showToast("Brochure has been added successfully", "success");
        }
        getPhotoGalleryList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <img src={photoDoc} className={"w-48 h-auto items-center"} />
        </div>
        {/* <ImageUploader
          register={register}
          defaultName="mediafile"
          name={`${
            isEdit && editPhotoDetails.mediafile
              ? "Replace Brochure"
              : "Brochure"
          }`}
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Brochure"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={photoDoc}
          setBase64={setPhotoDoc}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.mediafile : ""}
        /> */}

        <NewImageUploader
          register={register}
          defaultName="mediafile"
          name="Broachers"
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Broachers"
          type="file"
          errors={errors}
          classes={`rounded px-3 py-2 text-sm w-full `}
          setError={setError}
          clearError={clearErrors}
          // file={passphoto}
          // setPhotoBase64={setPassphoto}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.mediafile : ""}
          setFile={setPassphoto}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
        />
        <Input
          defaultName="mediacaption"
          register={register}
          name="Caption"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter caption"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.mediacaption}
          setValue={setValue}
        />

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditPhotoDetails({});
              setValue("categoryname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Broacher Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}
