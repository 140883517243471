import React from "react";
import SubMenu from "./SubMenu";
import { Link, useLocation } from "react-router-dom";
import { MdNewReleases } from "react-icons/md";

export default function NavLinks({ showTransparentNav, item, index }) {
  const location = useLocation();
  const activeText = !showTransparentNav ? "text-primary" : "text-ternary";
  const checkActive = () => {
    if (location.pathname === item.url) {
      return activeText;
    } else if (location.pathname.includes("media") && item.url === "#media") {
      return activeText;
    } else if (location.pathname.includes("media") && item.url === "#media") {
      return activeText;
    } else {
      return showTransparentNav ? "text-white" : "text-secondary";
    }
  };

  return (
    <li
      className={`nav-item ${
        item.hasSubMenu ? "group inline-block relative" : ""
      }`}
      key={index}
      onClick={() => window.scrollTo(0, 0)}
    >
      {item.url.includes("http") ? (
        <a
          className={`px-2 py-2 flex items-center text-xs md:text-xxs lg:text-xs  font-bold leading-snug bg-transparent hover:border-b border-ternary text-decoration-none focus:bg-ternary active:bg-ternary ${checkActive()}`}
          // className="px-3 py-2 flex items-center text-xs md:text-xxs lg:text-xs font-bold leading-snug text-secondary hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none focus:bg-ternary active:bg-ternary"
          href={item.url}
          target="_blank"
        >
          {location.pathname === item.url ? item.active_icon : item.normal_icon}
          <span className="text-xs md:text-xxs lg:text-xs">{item.navlink}</span>
          <div className="px-1">
            <span className="text-red-600 animate-ping transform delay-1000 absolute">
              <MdNewReleases size={16} />
            </span>
            <span className="text-red-600 ">
              <MdNewReleases size={16} />
            </span>
          </div>
        </a>
      ) : (
        <Link to={`${item.url}`}>
          <div
            className={`px-2 py-2 flex items-center text-xs md:text-xxs lg:text-xs  font-bold leading-snug bg-transparent hover:border-b border-ternary text-decoration-none focus:bg-ternary active:bg-ternary ${checkActive()}`}
          >
            {location.pathname === item.url
              ? item.active_icon
              : item.normal_icon}
            <span className="text-xs md:text-xxs lg:text-xs ml-1">
              {item.navlink}
            </span>
          </div>
        </Link>
      )}

      {item.hasSubMenu && <SubMenu key={index} items={item.subMenu} />}
    </li>
  );
}
