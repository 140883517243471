import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import H1 from "../../../reusable-components/headings/H1";

export default function NamesAndDutiesPage() {
  const navigate = useNavigate();

  let employeeDetails = [
    {
      name: "Shri, Janga Bdr. Subba",
      designation: "Chairman",
      duties: "Overall supervision as Board's Chairman",
    },
    {
      name: "Shri, Ongda Tshering Bhutia, IRS",
      designation: "Managing Director",
      duties: "Over all in-charge of the Corporation",
    },
    {
      name: "Shri, Kishore Shrestha ",
      designation: "General Manager",
      duties: "In-charge of Administration, Projects, Finance & Accounts.",
    },
    {
      name: "Mrs, Indira Pillai  ",
      designation: "Additional L.O.",
      duties: `In-charge of all Legal Matters pertaining 
      to the Corporation and designated State 
      Public Information Officer of SABCCO.  `,
    },
    {
      name: "Shri, Arun Gurung  ",
      designation: "DY. General Manager-I",
      duties: `Incharge of Administration and Projects 
      related to Other Backward Classes and 
      Persons with Disability.
       `,
    },
    {
      name: "Shri, Durga Bdr. Manger ",
      designation: " DY. General Manager II",
      duties: `Incharge of Projects related to 
      Scheduled Castes and officiating Asstt. 
      Public Information Officer of SABCCO.
       `,
    },
    {
      name: "Shri, Binod Ghatani",
      designation: " DY. General Manager II",
      duties: `Incharge of Projects related to 
      Scheduled Tribes.
       `,
    },
    {
      name: "Mrs. Purnima Ghimirey",
      designation: " P.S to MD",
      duties: `P. S. function to MD.`,
    },
    {
      name: "Mrs, Rinzing Ongmu Bhutia",
      designation: "Assistant Manager -I",
      duties: `Incharge of Loan Management Software`,
    },
    {
      name: "Mrs. Meera Dural",
      designation: "Assistant Manager -II",
      duties: `Finance Division`,
    },
    {
      name: "Ms.Phup Lhamu Bhutia",
      designation: "Jr. Manager-I",
      duties: `Finance Division`,
    },
    {
      name: "Mrs. Shanti Maya Subba",
      designation: "Jr. Manager-II",
      duties: `Finance Division`,
    },
    {
      name: "Mrs. Pempa Lhamu Tamang",
      designation: "Jr. Manager-III",
      duties: `Finance Division`,
    },
    {
      name: "Mr.Tenzing Dorjee Lachenpa",
      designation: "Jr. Manager-IV",
      duties: `Finance Division`,
    },
    {
      name: "Mrs. Kabita Sherpa",
      designation: "Sr. O.A-I",
      duties: `Custodian of Collateral Security 
      Documents
      `,
    },
    {
      name: "Mrs. Sanju Rai",
      designation: "Sr. O.A-II",
      duties: `Project (Scheduled Tribes)`,
    },
    {
      name: "Mrs. Chum Paden ",
      designation: "Sr. O.A-III",
      duties: `Project (Other Backward Classes)`,
    },
    {
      name: " Mrs. Tashi Ongmu Bhutia ",
      designation: "Sr. O.A-IV",
      duties: `Project (Scheduled Tribes)`,
    },
    {
      name: "Mrs. Tirtha Maya Tamang",
      designation: "O.A-I",
      duties: `Project (Scheduled Castes)`,
    },
    {
      name: "Mr. Yapching Bhutia",
      designation: "O.A-II",
      duties: `Project (Other Backward Classes)`,
    },
    {
      name: "Mr. Hem Bdr. Lamichaney",
      designation: "Driver",
      duties: `Driving`,
    },
    {
      name: "Mr. Prem Kr. Pradhan",
      designation: "Jr. Driver",
      duties: `Driving`,
    },
    {
      name: "Mr. Ney Tsh. Lepcha",
      designation: "Jr. Driver",
      duties: `Driving`,
    },
    {
      name: "Mr. Naresh Citury",
      designation: "Peon ",
      duties: `Office attendant`,
    },
    {
      name: "Mr. Samphey Bhutia",
      designation: "Peon ",
      duties: `Office attendant`,
    },
    {
      name: "Smt. Rekha Devi Ram",
      designation: "Safaikarmachari/ peon",
      duties: `Office attendant`,
    },
    {
      name: "Mrs. Krishna Manger",
      designation: "Jr. Safaikarmachari/ Peon",
      duties: `Office attendant`,
    },
    {
      name: "Mr. Mani Kr. Chettri",
      designation: "Jr.Driver",
      duties: `Driving`,
    },
    {
      name: "Mr. Dawa Lepcha",
      designation: "Jr.Driver",
      duties: `Driving`,
    },
  ];
  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1> Name and duties of officers and Employees</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className="lg:mx-20 lg:my-10">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 my-4 lg:mx-0 lg:my-0">
          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2">
            Power and duties of its officers and employees of SABCCO is as
            under:-
          </p>
          <section className="my-10">
            <div className=" border border-slate-200 overflow-x-auto">
              <table className=" divide-y divide-slate-200">
                <thead className="bg-white text-sm ">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      S.No.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Name of Officers and Staff
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Designation
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Power and Duties
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-slate-200 text-slate-800 overflow-hidden  ">
                  {employeeDetails.length > 0 &&
                    employeeDetails.map((employeeDetailsObj, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {employeeDetailsObj.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {employeeDetailsObj.designation}
                        </td>
                        <td className=" px-6 py-4 whitespace-nowrap lg:whitespace-normal text-sm">
                          {employeeDetailsObj.duties}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
