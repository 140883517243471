import React, { useState } from "react";
import Navbar from "./Navbar";
import StickyNavbar from "./StickyNavbar";

export default function PublicNavbar() {
  const [showTransparentNav, setShowTransparentNav] = useState(true);
  const changeNavbar = () => {
    if (window.scrollY >= 30) {
      setShowTransparentNav(false);
    } else {
      setShowTransparentNav(true);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <>
      {/* <NavHeader /> */}

      <Navbar showTransparentNav={showTransparentNav} />
      {/* <NavHeader /> */}
      {!showTransparentNav && (
        <StickyNavbar showTransparentNav={showTransparentNav} />
      )}
    </>
  );
}
