/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import SystemAdminDashboard from "./area/system-admin/SystemAdminDashboard";
import Login from "./login/Login";
import ROLES from "./api/roles_list";
import Unauthorized from "./common/Unathorized";
import PersistLogin from "./authentication/components/PersistLogin";
import RequireAuth from "./authentication/components/RequireAuth";
import Missing from "./common/Missing";
import { PublicLayout } from "./area/public/PublicLayout";
import Home from "./area/public/Pages/Home";
import AboutUs from "./area/system-admin/contentManagement/aboutUs/AboutUs";
import CategoryConfig from "./area/system-admin/MasterConfiguration/categoryConfig/CategoryConfig";
import SchemesConfig from "./area/system-admin/contentManagement/schemes/SchemesConfig";
import AwardsConfig from "./area/system-admin/contentManagement/awards/AwardsConfig";
import PublicationsConfig from "./area/system-admin/contentManagement/publications/PublicationsConfig";
import NewsConfig from "./area/system-admin/NewsEvents/NewsConfig";
import PhotoGalleryConfig from "./area/system-admin/contentManagement/photoGallery/PhotoGalleryConfig";
import BroacherConfig from "./area/system-admin/contentManagement/broachersGallery/BroacherConfig";
import FAQConfig from "./area/system-admin/contentManagement/faq/FAQConfig";
import About from "./area/public/Pages/About";
import SchemeList from "./area/public/Pages/SchemeList";
import SchemeDetails from "./area/public/schemes/SchemeDetails";
import AwardsAppreciations from "./area/public/Pages/AwardsAppreciations";
import Publications from "./area/public/Pages/Publications";
import Photo_Gallery from "./area/public/Pages/Photo_Gallery";
import News_Events from "./area/public/Pages/News_Events";
import Broachers from "./area/public/Pages/Broachers";
import Feedback from "./area/public/Pages/Feedback";
import DistrictConfig from "./area/system-admin/MasterConfiguration/districtConfig/DistrictConfig";
import CashCounterConfig from "./area/system-admin/MasterConfiguration/cashcounter_Config/CashCounterConfig";
import ConstituencyConfig from "./area/system-admin/MasterConfiguration/constituencyConfig/ConstituencyConfig";
import SubDivisionConfig from "./area/system-admin/MasterConfiguration/subDivisionConfig/SubDivisionConfig";
import GramPanchayatConfig from "./area/system-admin/MasterConfiguration/gramPahchayatConfig/GramPahchayatConfig";
import SchemeConfig from "./area/system-admin/MasterConfiguration/schemeConfig/SchemeConfig";
import ApplicantDetailsConfig from "./area/system-admin/Application/applicantDetails/ApplicantDetailsConfig";
import UserManagementConfig from "./area/system-admin/UserManagement/UserManagementConfig";
import EmployeeManagementConfig from "./area/system-admin/EmployeeManagement/EmployeeManagementConfig";
import NagarPanchayatConfig from "./area/system-admin/MasterConfiguration/nagarPanchayatConfig/NagarPanchayatConfig";
import MunicipalCorporationConfig from "./area/system-admin/MasterConfiguration/municipalCorporationConfig/MunicipalCorporationConfig";
import DEOAdminDashboard from "./area/data-entry-operator/DEOAdminDashboard";
import DVAdminDashboard from "./area/document-verifier/DVAdminDashboard";
import GMAdminDashboard from "./area/general-manager/GMAdminDashboard";
import MDAdminDashboard from "./area/managing-director/MDAdminDashboard";
import LegalAdminDashboard from "./area/legal-role/LegalAdminDashboard";
import AccountsAdminDashboard from "./area/accounts-role/AccountsAdminDashboard";
import RepaymentConfig from "./area/system-admin/LoanEntry/Repayment/RepaymentConfig";
import DecreeRepaymentConfig from "./area/system-admin/LoanEntry/DecreeCaseRepayment/DecreeRepaymentConfig";
import RepaymentHistoryConfig from "./area/system-admin/LoanEntry/RepaymentHistory/RepaymentHistoryConfig";
import SanctionDetailConfig from "./area/system-admin/ReportsList/SanctionDetail/SanctionDetailConfig";
import DisbursmentDetailConfig from "./area/system-admin/ReportsList/DisbursementDetail/DisbursmentDetailConfig";
import RecordKeeperDashboard from "./area/record-keeper/RecordKeeperDashboard";
import CashCounterDashboard from "./area/cash-counter/CashCounterDashboard";
import AssistantManagerDashboard from "./area/assistant-manager/AssistantManagerDashboard";
import DefaulterDetailConfig from "./area/system-admin/ReportsList/DefaulterDetail/DefaulterDetailConfig";
import OneTimeSettlementRepaymentConfig from "./area/system-admin/LoanEntry/OneTimeSettlementRepayment/OneTimeSettlementRepaymentConfig";
// import RecoveryPercentageConfig from "./area/system-admin/ReportsList/RecoveryPercentage/RecoveryPercentageConfig";
import RecoveryPercentageConfig from "./area/system-admin/ReportsList/RecoveryPercentageReport/RecoveryConfig";
import MonthlyReportConfig from "./area/system-admin/ReportsList/MonthlyReport/MonthlyReportConfig";
import ContentAdminDashboard from "./area/content-admin/ContentAdminDashboard";
import FeedbackConfig from "./area/system-admin/Feedback/FeedbackConfig";
import AuditTrailConfig from "./area/system-admin/AuditTrail/AuditTrailConfig";
import PasswordConfig from "./area/system-admin/ChangePassword/PasswordConfig";
import LedgerConfig from "./area/system-admin/ReportsList/Ledger/LedgerConfig";
import DailyReportConfig from "./area/system-admin/ReportsList/DailyReport/DailyReportConfig";

import BankConfig from "./area/system-admin/MasterConfiguration/bankConfig/BankConfig";
import BankBranchConfig from "./area/system-admin/MasterConfiguration/bankbranch/BankBranchConfig";
import DeathCaseConfig from "./area/system-admin/Application/death-case/DeathCaseConfig";
import LonaeeListConfig from "./area/system-admin/DemandNoticeV2/LoaneeListConfig";
import AreaConfig from "./area/system-admin/MasterConfiguration/areaConfig/AreaConfig";
import DesignationConifig from "./area/system-admin/MasterConfiguration/designationConfig/DesignationConifig";
import EmployeesList from "./area/public/Pages/EmployeesList";
import OurScheme from "./area/public/Pages/OurScheme/OurScheme";
import MediaPhotoGallery from "./area/public/Pages/MediaPhotoGallery";
import MediaBrochures from "./area/public/Pages/MediaBrochures";
import CashCounter_Config from "./area/system-admin/CashCounter/CashCounter_Config";
import YearlyCompiledReportConfig from "./area/system-admin/ReportsList/YearlyCompiledReport/YearlyCompiledReportConfig";
import YearlyWiseReportConfig from "./area/system-admin/ReportsList/YearlyWiseReport/YearlyWiseReportConfig";
import DirectDeptNoticeConfig from "./area/system-admin/Notice/DirectDepartmentalNotice/DirectDeptNoticeConfig";
import IndirectDeptNoticeConfig from "./area/system-admin/Notice/IndirectDepartmentalNotice/IndirectDeptNoticeConfig";
import RecoveryConfig from "./area/system-admin/ReportsList/RecoveryPercentageReport/RecoveryConfig";
import { OtsCalculation } from "./area/system-admin/Application/applicant-components/OtsCalculation";
import OtsNoticeConfig from "./area/system-admin/Notice/OTSNotice/OtsNoticeConfig";
import EmployeeDesignationConfig from "./area/system-admin/EmployeeList_ContentAdmin/EmployeeDesignation/EmployeeDesignationConfig";
import EmployeeDetailsConfig from "./area/system-admin/EmployeeList_ContentAdmin/EmployeeDetails/EmployeeDetailsConfig";
import RTI from "./area/public/Pages/RTI";
import Rti_v2 from "./area/public/Pages/rti_v2.component";
import OrganizationAndFunctionPage from "./area/public/Pages/organizationAndFunctionPage.component";
import AM_II_Dashboard from "./area/A.M-II/AM-II_Dashboard";
import Sr_OA_II_Dashboard from "./area/Sr.O.A-II/Sr.O.A-II_Dashboard";
import NamesAndDutiesPage from "./area/public/Pages/nameAndDuties.component";
import ProcedureDecisionMakingpage from "./area/public/Pages/procedureDecisionMakingPage.component";

import PaymentUAT from "./area/public/Pages/paymentGateway/paymentUAT.component";
import ContactUs from "./area/public/Pages/contactUs.component";
import TermsAndCondition from "./area/public/Pages/termsAndCondition.component";
import PrivacyPolicy from "./area/public/Pages/privacyPolicy.component";
import RefundCancellationPolicy from "./area/public/Pages/refundCancellationPolicy.component";
import RepaymentLogin from "./area/public/Pages/Online-payment/repaymentLogin.component";

import LoaneeRepaymentDetails from "./area/public/Pages/Online-payment/loaneeRepaymentDetails.component";
import VerifyOtpPage from "./area/public/Pages/Online-payment/verifyOtpPage.componenet";
import PersistRepaymentLoaneDetails from "./authentication/components/PersistRepaymentLoaneDetails";
import RTIMonthlyRemunerationPage from "./area/public/Pages/rtiMonthlyRemuneration.component";
import BoardOfDirectorsPage from "./area/public/Pages/boardOfDirectorsPage.component";
import PublicInformationOfficerDetailsPage from "./area/public/Pages/publicInformationOfficerDetailsPage";
import MemorandumOfArticlesPage from "./area/public/Pages/memorandumOfArticlesPage";
import ManagementForConsultationPage from "./area/public/Pages/managementForConsultationPage";
import AnnualReportPage from "./area/public/Pages/AnnualReportPage";
import NocApplicationConfig from "./area/system-admin/Application/noc-application/NocApplicationConfig";
import ConfigDefaulterDocs from "./area/system-admin/ReportsList/DefaulterDetail/configDefaulterDocs.component";
import NOCApplicationConfig from "./area/public/noc-application/NOCApplicationConfig";
import NOCApplication from "./area/public/noc-application/NOCApplication";
import Cashier_Dashboard from "./area/Cashier/Cashier_Dashboard";
import PaymentResponseHandler from "./area/public/Pages/paymentGateway/PaymentResponseHandler";
import LaunchPage from "./area/launch-page/launchPage.component";
import LaunchPageConfetti from "./area/launch-page/launchPageConfetti.component";
import ViewNOCApplication from "./area/public/noc-application/ViewNocApplication";
import ApplicantLogin from "./area/public/noc-application/applicantLogin.component";
import NocApplicantVerifyOtpPage from "./area/public/noc-application/NocApplicantVerifyOtpPage.componenet";
import Noc_online_payment from "./area/public/noc-application/noc_online_payment.component";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      {/* Public Routes */}
      <Route exact path="/launch/go" element={<LaunchPage />} />
      <Route exact path="/launch/go-to" element={<LaunchPageConfetti />} />

      <Route element={<PublicLayout />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/schemes/:categoryid" element={<SchemeList />} />
        <Route exact path="/award_s" element={<AwardsAppreciations />} />
        {/* <Route exact path="/media/photo-gallery" element={<Photo_Gallery />} /> */}
        {/* <Route exact path="/media/broacher_s" element={<Broachers />} /> */}
        <Route exact path="/new_s" element={<News_Events />} />
        <Route exact path="/feed-back" element={<Feedback />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        {/* Jesson Changes Start*/}
        <Route exact path="/sabcco-employees" element={<EmployeesList />} />
        <Route exact path="/sabcco-offered-schemes" element={<OurScheme />} />
        <Route
          exact
          path="/media/photo-gallery"
          element={<MediaPhotoGallery />}
        />
        <Route
          exact
          path="/media/brochures-and-pamphlets"
          element={<MediaBrochures />}
        />

        {/* Jesson Changes End*/}

        <Route
          exact
          path="/schemes/schemedetails/:schemeid"
          element={<SchemeDetails />}
        />
        <Route exact path="/publication_s" element={<Publications />} />

        <Route exact path="/payment/uat-env" element={<PaymentUAT />} />

        {/* <Route
          path="/payment-response/:orderId"
          component={PaymentResponseHandler}
        /> */}

        <Route
          exact
          path="/transaction-response"
          element={<PaymentResponseHandler />}
        />

        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route
          exact
          path="/terms-and-condition"
          element={<TermsAndCondition />}
        />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/refund-cancellation-policy"
          element={<RefundCancellationPolicy />}
        />
        <Route
          exact
          path="/online-repayment-login"
          element={<RepaymentLogin />}
        />

        <Route exact path="/verify-login-otp" element={<VerifyOtpPage />} />
        <Route
          exact
          path="/noc-applicant-veriffy-otp"
          element={<NocApplicantVerifyOtpPage />}
        />
        <Route
          exact
          path="/noc-online-payment"
          element={<Noc_online_payment />}
        />
        {/* <Route exact path="/rti" element={<RTI />} /> */}
        <Route exact path="/rti" element={<Rti_v2 />} />

        <Route
          exact
          path="/noc-application"
          element={<NOCApplicationConfig />}
        />
        <Route
          exact
          path="/noc-application-form"
          element={<NOCApplication />}
        />
        <Route exact path="/noc-applicant-login" element={<ApplicantLogin />} />
        <Route
          exact
          path="/view-noc-application/:uniqueSerialNumber"
          element={<ViewNOCApplication />}
        />

        <Route
          exact
          path="/rti/organization-and-function"
          element={<OrganizationAndFunctionPage />}
        />
        <Route
          exact
          path="/rti/names-and-duties"
          element={<NamesAndDutiesPage />}
        />
        <Route
          exact
          path="/rti/montly-remuneration"
          element={<RTIMonthlyRemunerationPage />}
        />
        <Route
          exact
          path="/rti/procedure-decision-making"
          element={<ProcedureDecisionMakingpage />}
        />
        <Route
          exact
          path="/rti/board-of-directors"
          element={<BoardOfDirectorsPage />}
        />
        <Route
          exact
          path="/rti/details-of-public-info-officers"
          element={<PublicInformationOfficerDetailsPage />}
        />
        <Route
          exact
          path="/rti/memorandum-of-articles"
          element={<MemorandumOfArticlesPage />}
        />
        <Route
          exact
          path="/rti/management-for-consultation"
          element={<ManagementForConsultationPage />}
        />
        <Route exact path="/rti/annual-report" element={<AnnualReportPage />} />
      </Route>

      <Route path="unauthorized" element={<Unauthorized />} />

      <Route element={<PersistLogin />}>
        <Route exact path="/login" element={<Login />} />
        <Route element={<RequireAuth allowedRoles={[ROLES["Super Admin"]]} />}>
          <Route
            exact
            path="/sysadmin/dashboard"
            element={<SystemAdminDashboard />}
          />
          <Route exact path="/sysadmin/about_us" element={<AboutUs />} />
          <Route
            exact
            path="/sysadmin/master-category"
            element={<CategoryConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-district"
            element={<DistrictConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-constituency"
            element={<ConstituencyConfig />}
          />

          <Route exact path="/sysadmin/master-area" element={<AreaConfig />} />
          <Route
            exact
            path="/sysadmin/master-subDivision"
            element={<SubDivisionConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-gramPanchayat"
            element={<GramPanchayatConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-nagarPanchayat"
            element={<NagarPanchayatConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-municipalCorporation"
            element={<MunicipalCorporationConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-scheme"
            element={<SchemeConfig />}
          />
          <Route exact path="/sysadmin/master-bank" element={<BankConfig />} />

          <Route
            exact
            path="/sysadmin/master-bankbranch"
            element={<BankBranchConfig />}
          />
          <Route
            exact
            path="/sysadmin/master-designation"
            element={<DesignationConifig />}
          />
          <Route
            exact
            path="/sysadmin/master-cash-counter"
            element={<CashCounterConfig />}
          />
          <Route
            exact
            path="/sysadmin/awards_list"
            element={<AwardsConfig />}
          />
          <Route
            exact
            path="/sysadmin/publication"
            element={<PublicationsConfig />}
          />
          <Route exact path="/sysadmin/news" element={<NewsConfig />} />
          <Route
            exact
            path="/sysadmin/media-photo"
            element={<PhotoGalleryConfig />}
          />
          <Route
            exact
            path="/sysadmin/media-broacher"
            element={<BroacherConfig />}
          />

          <Route exact path="/sysadmin/faq" element={<FAQConfig />} />
          <Route
            exact
            path="/sysadmin/feed_back"
            element={<FeedbackConfig />}
          />

          <Route exact path="/sysadmin/schemes" element={<SchemesConfig />} />
          <Route
            exact
            path="/sysadmin/auditTrail"
            element={<AuditTrailConfig />}
          />

          <Route
            exact
            path="/sysadmin/application-details"
            element={<ApplicantDetailsConfig />}
          />
          <Route
            exact
            path="/sysadmin/application-deathcasedetails"
            element={<DeathCaseConfig />}
          />

          <Route
            exact
            path="/sysadmin/loanEntry-repayment"
            element={<RepaymentConfig />}
          />
          <Route
            exact
            path="/sysadmin/loanEntry-repaymentHistory"
            element={<RepaymentHistoryConfig />}
          />

          <Route
            exact
            path="/sysadmin/reports-sanctionDetails"
            element={<SanctionDetailConfig />}
          />
          <Route
            exact
            path="/sysadmin/reports-disbursmentDetails"
            element={<DisbursmentDetailConfig />}
          />
          <Route
            exact
            path="/sysadmin/defaulter-defaulterList"
            element={<DefaulterDetailConfig />}
          />
          <Route
            exact
            path="/sysadmin/defaulter-uploadDefaulterDetails"
            element={<ConfigDefaulterDocs />}
          />
          <Route
            exact
            path="/sysadmin/reports-recoveryPercentage"
            element={<RecoveryPercentageConfig />}
          />

          <Route
            exact
            path="/sysadmin/reports-fiscalYear-recoveryPercentage"
            element={<RecoveryConfig />}
          />
          <Route
            exact
            path="/sysadmin/reports-monthlyReport"
            element={<MonthlyReportConfig />}
          />
          <Route
            exact
            path="/sysadmin/reports-ledger"
            element={<LedgerConfig />}
          />
          <Route
            exact
            path="/sysadmin/reports-repaymentLedger"
            element={<RepaymentConfig />}
          />
          {/* <Route
            exact
            path="/sysadmin/reports-penal"
            element={<PenalInterestConfig />}
          /> */}

          <Route
            exact
            path="/sysadmin/reports-dailyReport"
            element={<DailyReportConfig />}
          />

          <Route
            exact
            path="/sysadmin/user-management"
            element={<UserManagementConfig />}
          />
          <Route
            exact
            path="/sysadmin/employee-management"
            element={<EmployeeManagementConfig />}
          />
          {/* {Demand Notice} */}
          <Route
            exact
            path="/sysadmin/demand_notice"
            element={<LonaeeListConfig />}
          />

          {/* {Direct Departmental  Notice} */}
          <Route
            exact
            path="/sysadmin/direct-dept-notice_notice"
            element={<DirectDeptNoticeConfig />}
          />
          <Route
            exact
            path="/sysadmin/indirect1-dept-notice"
            element={<IndirectDeptNoticeConfig />}
          />
          <Route
            exact
            path="/sysadmin/ots-notice"
            element={<OtsNoticeConfig />}
          />

          <Route
            exact
            path="/sysadmin/cash_counter"
            element={<CashCounter_Config />}
          />
          <Route
            exact
            path="/sysadmin/change-password"
            element={<PasswordConfig />}
          />
          <Route
            exact
            path="/sysadmin/reports-yearly-compiled"
            element={<YearlyCompiledReportConfig />}
          />
        </Route>

        {
          //#region Data Entry Operator
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES["Data Entry Operator"]]} />
            }
          >
            <Route
              exact
              path="/deoadmin/dashboard"
              element={<DEOAdminDashboard />}
            />

            <Route
              exact
              path="/deoadmin/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/deoadmin/application-deathcasedetails"
              element={<DeathCaseConfig />}
            />
            <Route
              exact
              path="/deoadmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/deoadmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/deoadmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/deoadmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />

            {/* <Route
              exact
              path="/deoadmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}
            <Route
              exact
              path="/deoadmin/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />
            <Route
              exact
              path="/deoadmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/deoadmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/deoadmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/deoadmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/deoadmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />

            <Route
              exact
              path="/deoadmin/master-bank"
              element={<BankConfig />}
            />
            <Route
              exact
              path="/deoadmin/master-bankbranch"
              element={<BankBranchConfig />}
            />
            <Route
              exact
              path="/deoadmin/master-area"
              element={<AreaConfig />}
            />

            <Route
              exact
              path="/deoadmin/master-constituency"
              element={<ConstituencyConfig />}
            />

            <Route
              exact
              path="/deoadmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/deoadmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            {/* {Demand Notice} */}
            <Route
              exact
              path="/deoadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/deoadmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/deoadmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/deoadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/deoadmin/application-noc"
              element={<NocApplicationConfig />}
            />

            <Route
              exact
              path="/deoadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Document Verifier / Deputy General Manager
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES["Deputy General Manager"]]} />
            }
          >
            <Route
              exact
              path="/dvadmin/dashboard"
              element={<DVAdminDashboard />}
            />

            <Route
              exact
              path="/dvadmin/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/dvadmin/application-deathcasedetails"
              element={<DeathCaseConfig />}
            />
            <Route
              exact
              path="/dvadmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/dvadmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/dvadmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/dvadmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            <Route
              exact
              path="/dvadmin/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />
            {/* <Route
              exact
              path="/dvadmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}
            <Route
              exact
              path="/dvadmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/dvadmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/dvadmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/dvadmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/dvadmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/dvadmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />

            <Route
              exact
              path="/dvadmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            {/* {Demand Notice} */}
            <Route
              exact
              path="/dvadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/dvadmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/dvadmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/dvadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/dvadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region General Manager
          <Route
            element={<RequireAuth allowedRoles={[ROLES["General Manager"]]} />}
          >
            {/* {Demand Notice} */}
            <Route
              exact
              path="/gmadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/gmadmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/gmadmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/gmadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/gmadmin/dashboard"
              element={<GMAdminDashboard />}
            />
            {/* <Route
              exact
              path="/gmadmin/application-details"
              element={<DeathCaseConfig />}
            /> */}

            <Route
              exact
              path="/gmadmin/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/gmadmin/application-deathcasedetails"
              element={<DeathCaseConfig />}
            />

            <Route
              exact
              path="/gmadmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/gmadmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/gmadmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            {/* <Route
              exact
              path="/gmadmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}
            <Route
              exact
              path="/gmadmin/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/gmadmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/gmadmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/gmadmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/gmadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Managing Director
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES["Managing Director"]]} />
            }
          >
            {/* {Demand Notice} */}
            <Route
              exact
              path="/mdadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/mdadmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/mdadmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/mdadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/mdadmin/dashboard"
              element={<MDAdminDashboard />}
            />
            <Route
              exact
              path="/mdadmin/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/mdadmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/mdadmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/mdadmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/mdadmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            {/* <Route
              exact
              path="/mdadmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}
            <Route
              exact
              path="/mdadmin/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />
            <Route
              exact
              path="/mdadmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/mdadmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/mdadmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/mdadmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/mdadmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/mdadmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />

            <Route
              exact
              path="/mdadmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/mdadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Legal
          <Route element={<RequireAuth allowedRoles={[ROLES["Legal"]]} />}>
            {/* {Demand Notice} */}
            <Route
              exact
              path="/legaladmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/legaladmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/legaladmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/legaladmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/legaladmin/dashboard"
              element={<LegalAdminDashboard />}
            />
            <Route
              exact
              path="/legaladmin/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/legaladmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/legaladmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/legaladmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/legaladmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/legaladmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/legaladmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/legaladmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Record Keeper
          <Route
            element={<RequireAuth allowedRoles={[ROLES["Record Keeper"]]} />}
          >
            <Route
              exact
              path="/recordkeeper/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/recordkeeper/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />

            <Route
              exact
              path="/recordkeeper/dashboard"
              element={<RecordKeeperDashboard />}
            />
            <Route
              exact
              path="/recordkeeper/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/recordkeeper/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/recordkeeper/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/recordkeeper/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/recordkeeper/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/recordkeeper/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/recordkeeper/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/recordkeeper/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Accounts
          <Route
            element={<RequireAuth allowedRoles={[ROLES["DGM Accounts"]]} />}
          >
            <Route
              exact
              path="/dgmaccountsadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/dgmaccountsadmin/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/dashboard"
              element={<AccountsAdminDashboard />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/application-details"
              element={<ApplicantDetailsConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            {/* <Route
              exact
              path="/dgmaccountsadmin/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}

            <Route
              exact
              path="/dgmaccountsadmin/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/dgmaccountsadmin/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/dgmaccountsadmin/reports-dailyReport"
              element={<DailyReportConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/dgmaccountsadmin/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/dgmaccountsadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region A.M-II
          <Route element={<RequireAuth allowedRoles={[ROLES["A.M -II"]]} />}>
            <Route
              exact
              path="/am-ii/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/am-ii/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/am-ii/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/am-ii/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/am-ii/dashboard"
              element={<AM_II_Dashboard />}
            />
            <Route
              exact
              path="/am-ii/application-details"
              element={<ApplicantDetailsConfig />}
            />

            <Route
              exact
              path="/am-ii/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/am-ii/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/am-ii/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/am-ii/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            {/* <Route
              exact
              path="/am-ii/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}

            <Route
              exact
              path="/am-ii/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />

            <Route
              exact
              path="/am-ii/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/am-ii/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/am-ii/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/am-ii/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/am-ii/reports-dailyReport"
              element={<DailyReportConfig />}
            />

            <Route
              exact
              path="/am-ii/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/am-ii/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/am-ii/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
        {
          //#region Sr_OA_II_AdminSidebar
          <Route element={<RequireAuth allowedRoles={[ROLES["Sr.O.A-II"]]} />}>
            <Route
              exact
              path="/sr-oa-ii/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/sr-oa-ii/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/dashboard"
              element={<Sr_OA_II_Dashboard />}
            />
            <Route
              exact
              path="/sr-oa-ii/application-details"
              element={<ApplicantDetailsConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            {/* <Route
              exact
              path="/sr-oa-ii/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}

            <Route
              exact
              path="/sr-oa-ii/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/sr-oa-ii/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/sr-oa-ii/reports-dailyReport"
              element={<DailyReportConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/sr-oa-ii/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/sr-oa-ii/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>

          //#endregion
        }
        {
          //#region Cashier Sidebar
          <Route element={<RequireAuth allowedRoles={[ROLES["Cashier"]]} />}>
            <Route
              exact
              path="/cashier/dashboard"
              element={<Cashier_Dashboard />}
            />

            <Route
              exact
              path="/cashier/loanEntry-repayment"
              element={<RepaymentConfig />}
            />

            <Route
              exact
              path="/cashier/loanEntry-oneTimeSettlement"
              element={<OneTimeSettlementRepaymentConfig />}
            />

            <Route
              exact
              path="/cashier/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/cashier/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/cashier/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            {/* <Route
              exact
              path="/cashier/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            /> */}

            <Route
              exact
              path="/cashier/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />

            <Route
              exact
              path="/cashier/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/cashier/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/cashier/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/cashier/reports-penal"
              element={<PenalInterestConfig />}
            /> */}
            <Route
              exact
              path="/cashier/reports-dailyReport"
              element={<DailyReportConfig />}
            />

            <Route
              exact
              path="/cashier/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/cashier/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />
            <Route
              exact
              path="/cashier/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
          </Route>

          //#endregion
        }

        {
          //#region Assistant Manager
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES["Assistant Manager"]]} />
            }
          >
            <Route
              exact
              path="/assistantmanager/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/assistantmanager/indirect1-dept-notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/assistantmanager/direct-dept-notice_notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/assistantmanager/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/assistantmanager/dashboard"
              element={<AssistantManagerDashboard />}
            />
            <Route
              exact
              path="/assistantmanager/application-details"
              element={<ApplicantDetailsConfig />}
            />
            <Route
              exact
              path="/assistantmanager/application-deathcasedetails"
              element={<DeathCaseConfig />}
            />
            <Route
              exact
              path="/assistantmanager/loanEntry-repayment"
              element={<RepaymentConfig />}
            />
            <Route
              exact
              path="/assistantmanager/loanEntry-decreeRepayment"
              element={<DecreeRepaymentConfig />}
            />
            <Route
              exact
              path="/assistantmanager/loanEntry-oneTimeSettlement"
              element={<OneTimeSettlementRepaymentConfig />}
            />
            <Route
              exact
              path="/assistantmanager/loanEntry-repaymentHistory"
              element={<RepaymentHistoryConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-sanctionDetails"
              element={<SanctionDetailConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-disbursmentDetails"
              element={<DisbursmentDetailConfig />}
            />
            <Route
              exact
              path="/assistantmanager/defaulter-defaulterList"
              element={<DefaulterDetailConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-recoveryPercentage"
              element={<RecoveryPercentageConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-fiscalYear-recoveryPercentage"
              element={<RecoveryConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-monthlyReport"
              element={<MonthlyReportConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-ledger"
              element={<LedgerConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-repaymentLedger"
              element={<RepaymentConfig />}
            />
            {/* <Route
              exact
              path="/assistantmanager/reports-penal"
              element={<PenalInterestConfig />}
            /> */}

            <Route
              exact
              path="/assistantmanager/reports-dailyReport"
              element={<DailyReportConfig />}
            />
            <Route
              exact
              path="/assistantmanager/change-password"
              element={<PasswordConfig />}
            />

            <Route
              exact
              path="/assistantmanager/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />
            <Route
              exact
              path="/assistantmanager/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/assistantmanager/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }

        {
          //#region Content Admin
          <Route
            element={<RequireAuth allowedRoles={[ROLES["Content Admin"]]} />}
          >
            <Route
              exact
              path="/contentadmin/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/contentadmin/indirect1-dept-notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/contentadmin/direct-dept-notice_notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/contentadmin/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/contentadmin/dashboard"
              element={<ContentAdminDashboard />}
            />
            <Route exact path="/contentadmin/about_us" element={<AboutUs />} />
            <Route
              exact
              path="/contentadmin/awards_list"
              element={<AwardsConfig />}
            />
            <Route
              exact
              path="/contentadmin/change-password"
              element={<PasswordConfig />}
            />
            <Route
              exact
              path="/contentadmin/media-photo"
              element={<PhotoGalleryConfig />}
            />

            <Route
              exact
              path="/contentadmin/media-broacher"
              element={<BroacherConfig />}
            />
            <Route
              exact
              path="/contentadmin/employee-designation"
              element={<EmployeeDesignationConfig />}
            />
            <Route
              exact
              path="/contentadmin/employee-list"
              element={<EmployeeDetailsConfig />}
            />

            <Route
              exact
              path="/contentadmin/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }

        {
          //#region CashCounter Dashboard
          <Route
            element={<RequireAuth allowedRoles={[ROLES["Cash Counter"]]} />}
          >
            {/* {Demand Notice} */}
            <Route
              exact
              path="/cashcounter/demand_notice"
              element={<LonaeeListConfig />}
            />

            {/* {Direct Departmental  Notice} */}
            <Route
              exact
              path="/cashcounter/direct-dept-notice_notice"
              element={<DirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/cashcounter/indirect1-dept-notice"
              element={<IndirectDeptNoticeConfig />}
            />
            <Route
              exact
              path="/cashcounter/ots-notice"
              element={<OtsNoticeConfig />}
            />

            <Route
              exact
              path="/cashcounter/dashboard"
              element={<CashCounterDashboard />}
            />
            <Route
              exact
              path="/cashcounter/master-cash-counter"
              element={<CashCounterConfig />}
            />

            <Route
              exact
              path="/cashcounter/cash_counter"
              element={<CashCounter_Config />}
            />

            <Route
              exact
              path="/cashcounter/reports-yearly-compiled"
              element={<YearlyCompiledReportConfig />}
            />

            <Route
              exact
              path="/cashcounter/reports-year-wise"
              element={<YearlyWiseReportConfig />}
            />

            <Route
              exact
              path="/cashcounter/defaulter-uploadDefaulterDetails"
              element={<ConfigDefaulterDocs />}
            />
          </Route>
          //#endregion
        }
      </Route>

      {/* Routes requiring Authentication*/}
      <Route element={<PersistRepaymentLoaneDetails />}>
        <Route
          exact
          path="/online-repayment-details"
          element={<LoaneeRepaymentDetails />}
        />
      </Route>

      {/* <Route
        exact
        path="/transaction-response"
        element={<PaymentResponseHandler />}
      /> */}

      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
