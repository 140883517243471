import React from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

export default function ConstituencyList({
  constituencyList,
  setEditConstituencyDetails,
  setShowAddForm,
}) {
  const onClickEdit = (constituency) => {
    setEditConstituencyDetails(constituency);
    setShowAddForm(true);
  };
  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            Constituency Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            District Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {constituencyList?.length > 0 &&
          constituencyList.map((constituency, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {constituency.constituencyname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {constituency.districtname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(constituency)}
                    aria-label="Edit Constituency Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
