import { Menu } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import { FiEye, FiSettings } from "react-icons/fi";
import { Transition } from "react-transition-group";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";

export default function BankBranchList({
  bankBranchList,
  setEditBankBranchDetails,
  setShowAddForm,
  setShowDelete,
  setBranchId,
}) {
 
  const handleDelete = (id) => {
    
    setBranchId(id);
    setShowDelete(true);
  };
  const handleEdit = (details) => {
   
    setEditBankBranchDetails(details);
    setShowAddForm(true);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-sm ">
          <tr className="bg-stone-100">
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              Bank Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              Branch Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              IFSC Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {/* <tr className="px-6 py-4 whitespace-nowrap text-sm">
            
          </tr> */}
          {bankBranchList?.length > 0 &&
            bankBranchList?.map((bankObj, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? `bg-white` : `bg-stone-100`} `}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {bankObj.bankname}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {bankObj.bankbranch}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {bankObj.bankifsccode}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(bankObj)}
                      aria-label="Edit Bank Details"
                      className={linkTextPrimary}
                    >
                      Edit
                    </Button>
                  </div>
                </td> */}
                <td className="px-4 py-4 whitespace-nowrap text-sm relative">
                  {
                    <div className="flex justify-end">
                      <Menu as="div" className="block text-left">
                        <div>
                          <Menu.Button className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                            <div className="flex items-center gap-3">
                              <FiSettings size={16} />
                              <p>Manage</p>
                            </div>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() => handleEdit(bankObj)}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                  >
                                    <div className="flex items-center gap-3 text-black hover:text-primary">
                                      <FiEye size={16} />
                                      <p className="">View/Edit</p>
                                    </div>
                                  </div>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() =>
                                      handleDelete(bankObj?.bankbranchid)
                                    }
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                  >
                                    <div className="flex items-center gap-3 text-black hover:text-primary">
                                      <FiEye size={16} />
                                      <p className="">Delete</p>
                                    </div>
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  }
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
    </>
  );
}
