/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import { useForm } from "react-hook-form";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import Captcha from "../../../utilities/captcah/Captcha";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import showToast from "../../../utilities/notification/NotificationModal";
import {
  FEEDBACK_URL,
  PUBLIC_NOC_APPLICATION,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import {
  button,
  disabled_button,
  linkTextPrimary,
} from "../../../theme/lightTheme";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import sabccoLogo from "../../../assets/SABCCO_128x128.png";
import { getFormattedDateDDMMYYYY } from "../../../utilities/dateFunctions/formatdate";
import { PrintNOCApplication } from "../../system-admin/ReportsList/PrintReport/PrintNOCApplication";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const ViewNOCApplication = () => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const [viewApplicationDetail, setViewApplicationDetail] = useState(false);

  const [nocApplicationDetail, setnocApplicationDetail] = useState();

  const { uniqueSerialNumber } = useParams();

  const getNocApplicationDetail = async () => {
    console.log("getNocApplicationDetail");
    try {
      let response = await axios.get(
        `${PUBLIC_NOC_APPLICATION}/get`,
        { params: { noc_serial_no: uniqueSerialNumber } },
        {
          signal: controller.signal,
        }
      );

      console.log("response getNocApplicationDetail", response);

      if (response?.status === 200 && response?.data?.nocApplication) {
        setnocApplicationDetail(response?.data?.nocApplication);
        setViewApplicationDetail(true);

        window.scroll({ top: 100 });
      } else {
      }
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (uniqueSerialNumber && isMounted) {
      getNocApplicationDetail();
    }

    return () => {
      isMounted = false;
    };
  }, [uniqueSerialNumber]);

  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>No Objection Certificate Application</H1>
          </div>
        </div>
      </HeroBanner>
      {viewApplicationDetail ? (
        <>
          <section className="container mx-auto max-w-7xl my-9 px-3">
            <p
              className={`text-center text-sm font-semibold p-3 my-6 border rounded-sm
            ${
              nocApplicationDetail.isverified
                ? " border-green-800  text-green-900 bg-green-100"
                : " border-yellow-800  text-yellow-900  bg-yellow-100"
            }
            `}
            >
              Your application has
              {nocApplicationDetail.isverified
                ? " been verified. Your may print/download your application."
                : " not been verified yet."}
            </p>

            {/* <div className="mb-6">
              <div className="w-full flex justify-center md:justify-center">
                <Button
                  className={`w-full md:w-auto md:max-w-xs rounded-sm flex justify-center items-center transition-all duration-300 ease-in-out px-6 py-3 text-base border border-slate-200 bg-primary text-white focus:outline-none focus:ring-0`}
                  onClick={() => navigate("/noc-applicant-login")}
                >
                  <span>Make Payment</span>
                </Button>
              </div>
            </div> */}
            <div className="bg-white py-9 border">
              <NOCApplicationForm nocApplicationDetail={nocApplicationDetail} />
            </div>
            <div className=" md:flex justify-between items-center mt-9">
              <Button onClick={() => navigate("/noc-application")}>
                <p className={` texMaket-center ${linkTextPrimary} mb-9`}>
                  Return to NOC Application
                </p>
              </Button>
              {nocApplicationDetail.isverified ? (
                <>
                  <PrintNOCApplication>
                    <NOCApplicationForm
                      nocApplicationDetail={nocApplicationDetail}
                    />
                  </PrintNOCApplication>
                </>
              ) : null}
            </div>
          </section>{" "}
        </>
      ) : (
        <section>
          <div className="container mx-auto max-w-7xl my-9">
            <div className="border text-center bg-yellow-100 p-9">
              <p className="bg-white text-2xl p-3 my-6 border border-yellow-800 rounded-sm text-yellow-900 flex-col flex items-center justify-center">
                <MdOutlineDoNotDisturbAlt size={56} />
                <p>
                  NOC Application with Serial No:
                  <span className="font-bold"> {uniqueSerialNumber} </span>
                  not found.
                </p>
              </p>{" "}
              <div className=" text-white mt-[3rem] cursor-pointer">
                <NavLink end to="/noc-application">
                  <Button className={button}>
                    <span className="">Check another NOC Application</span>
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ViewNOCApplication;

export const NOCApplicationForm = ({ nocApplicationDetail }) => {
  return (
    <>
      <div className=" text-[14px] px-9 text-justify">
        <div className="w-full">
          <div className="flex gap-3 items-center my-6 w-fit mx-auto">
            <img className="h-16 w-16" src={sabccoLogo} />
            <div>
              <p className="text-center text-[16px] font-semibold">
                Sikkim SC / ST / OBC Development Corporation.Ltd. (SABCCO)
              </p>
              <p className="text-[14px]  -mt-1">
                Sonam Tshering Marg, Gangtok - 737101
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {nocApplicationDetail.noc_serial_no ? (
            <>
              <p className="font-semibold">
                Serial No.: {nocApplicationDetail.noc_serial_no}
              </p>
            </>
          ) : null}

          {nocApplicationDetail.createdat ? (
            <>
              <p className="font-semibold">
                Date: {getFormattedDateDDMMYYYY(nocApplicationDetail.createdat)}
              </p>
            </>
          ) : null}
        </div>

        <div className="mb-9">
          <p className="font-semibold text-center mb-6">
            TO WHOM IT MAY CONCERN
          </p>
          <p>
            This is to certify that Shri/Smt/Kumari
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_name}{" "}
            </span>
            S/o, D/o, W/o
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_relative_name}{" "}
            </span>
            resident of
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_resident}{" "}
            </span>
            does not have any loan till date or outstanding as reflected in the
            books. Hence this Corporation does not have any objection for grant
            of Job/Regularisation in
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_department_name}{" "}
            </span>
            Department in respect of the applicant.
          </p>

          <p className="pt-3">
            This certificate is issued without prejudice to the Corporation's
            right to recover the loan amount if it is found at any later point
            of time that he/she owes any amount to this Corporation.
          </p>

          <p className="pt-3">
            This certificate is valid for a period of 6 (Six) months from the
            date of issue.
          </p>
        </div>

        <div className="flex flex-row justify-between pt-6">
          <p className="font-semibold">{formatINRCurrency(150)}/-</p>
          <div>
            <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
          </div>
        </div>
      </div>
    </>
  );
};
