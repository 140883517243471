import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import {
  AREA_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  LOANEE_DETAILS_CONFIG,
} from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import {
  guarantorTypeDD,
  guarantorRelationshipDD,
} from "../../../../utilities/constants/constants";
import {
  reContact,
  reEmail,
} from "../../../../utilities/constants/regularexpressions";
import CapturePhoto from "../../../../reusable-components/inputs/FileUploader/CapturePhoto";
import InputCheckbox from "../../../../reusable-components/inputs/InputCheckBox/InputCheckbox";
import PDFImageUploaderAWS from "../../../../reusable-components/inputs/FileUploader/PDFUploaderAWS";
import { getCurrentDate } from "../../../../utilities/dateFunctions/getdate";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";

export default function AddGuarantorForm({
  loaneeId,
  editGuarantorDetails,
  setEditGuarantorDetails,
  setShowAddForm,
  getApplicantGuarantorDetailList,
  getSearchedApplicantList,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editGuarantorDetails).length > 0;

  const [passphoto, setPassphoto] = useState("");

  const [selectedGuarantorType, setSelectedGuarantorType] = useState();
  const [selectedGuarantorRelationship, setSelectedGuarantorRelationship] =
    useState();

  // const [sikkimSubjectChecked, setSikkimSubjectChecked] = useState(false);
  const [suretyParchaChecked, setSuretyParchaChecked] = useState(false);
  // const [salaryDocChecked, setSalaryDocChecked] = useState(false);
  const [salaryCertificateChecked, setSalaryCertificateChecked] =
    useState(false);
  // const [incomeCertificateChecked, setIncomeCertificateChecked] =useState(false);
  const [departmentalUndertakingChecked, setDepartmentalUndertakingChecked] =
    useState(false);

  const [adharCardChecked, setAdharCardChecked] = useState(false);
  // const [voterCardChecked, setVoterCardChecked] = useState(false);
  const [deductionLetterChecked, setDeductionLetterChecked] = useState(false);

  // const [sikkimSubjectDoc, setSikkimSubjectDoc] = useState("");
  const [suretyParchaDoc, setSuretyParchaDoc] = useState("");

  const [salaryDoc, setSalaryDoc] = useState("");
  // const [incomeCertDoc, setIncomeCertDoc] = useState("");
  const [departmentalUndertakingDoc, setDepartmentalUndertakingDoc] =
    useState("");
  const [adharCardDoc, setAdharCardDoc] = useState("");
  // const [voterCardDoc, setVoterCardDoc] = useState("");
  const [deductionLetterDoc, setDeductionLetterDoc] = useState("");

  const [fileUploadError, setFileUploadError] = useState(false);
  const [userPassPhoto, setUserPassPhoto] = useState();

  const [selectedArea, setSelectedArea] = useState("");

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [selected_LoaneeArea, setSelected_LoaneeArea] = useState();
  const [areaListDistrictWise, setAreaListDistrictWise] = useState([]);
  const [loaneeArea_List, setLoaneeArea_List] = useState([]);

  const defaultValues = {
    guarantorimage: !isEdit ? "" : editGuarantorDetails?.guarantorimage,
    guarantorname: !isEdit ? "" : editGuarantorDetails?.guarantorname,
    guarantoraddress: !isEdit ? "" : editGuarantorDetails?.guarantoraddress,
    guarantorcontactno: !isEdit ? "" : editGuarantorDetails?.guarantorcontactno,
    guarantoremail: !isEdit ? "" : editGuarantorDetails?.guarantoremail,
    khatiannumber: !isEdit ? "" : editGuarantorDetails?.khatiannumber,
    khasranumber: !isEdit ? "" : editGuarantorDetails?.khasranumber,

    area: !isEdit
      ? ""
      : {
          label: editGuarantorDetails?.area,
          value: editGuarantorDetails?.area,
        },

    // area: !isEdit ? "" : editGuarantorDetails?.area?.value,
    regundername: !isEdit ? "" : editGuarantorDetails?.regundername,
    situatedunderblock: !isEdit ? "" : editGuarantorDetails?.situatedunderblock,
    alternativecontactno: !isEdit
      ? ""
      : editGuarantorDetails?.alternativecontactno,
    guarantortype: !isEdit
      ? ""
      : {
          value: editGuarantorDetails?.guarantortype,
          label: editGuarantorDetails?.guarantortype,
        },
    guarantorrelationship: !isEdit
      ? ""
      : {
          value: editGuarantorDetails?.guarantorrelationship,
          label: editGuarantorDetails?.guarantorrelationship,
        },
    departmantname: !isEdit ? "" : editGuarantorDetails?.departmantname,
    grosssalary: !isEdit ? "" : editGuarantorDetails?.grosssalary,
    netsalary: !isEdit ? "" : editGuarantorDetails?.netsalary,
    retirementdate: !isEdit ? "" : editGuarantorDetails?.retirementdate,
    undertaking: !isEdit ? "" : editGuarantorDetails?.undertaking,
    salarydeductionamount: !isEdit
      ? ""
      : editGuarantorDetails?.salarydeductionamount,
    deductiondate: !isEdit ? "" : editGuarantorDetails?.deductiondate,

    districtid: !isEdit ? "" : selectedDistrict,
    area_id: !isEdit ? "" : selected_LoaneeArea,
    guarantor_designation: !isEdit
      ? ""
      : editGuarantorDetails?.guarantor_designation,
  };

  async function guarnatorDocChecklistHandler() {
    let fileschecklist = [];

    fileschecklist.push(
      createFileChecklistObj("Surety Parcha", suretyParchaChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Salary Certificate", salaryCertificateChecked)
    );
    fileschecklist.push(
      createFileChecklistObj(
        "Departmental Undertaking",
        departmentalUndertakingChecked
      )
    );
    fileschecklist.push(createFileChecklistObj("Adhar Card", adharCardChecked));
    fileschecklist.push(
      createFileChecklistObj("Deduction Letter", deductionLetterChecked)
    );

    return fileschecklist;
  }

  function checkListHandler() {
    if (isEdit) {
      editGuarantorDetails?.guarantorchecklist?.map((checkListObj) => {
        if (checkListObj?.file === "Surety Parcha") {
          setSuretyParchaChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Salary Certificate") {
          setSalaryCertificateChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Departmental Undertaking") {
          setDepartmentalUndertakingChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Adhar Card") {
          setAdharCardChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Deduction Letter") {
          setDeductionLetterChecked(checkListObj?.check);
        }
      });
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const getDistrictList = async (district_id) => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];

          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);

            if (+districtObj.districtid === +district_id) {
              setSelectedDistrict({
                value: districtObj.districtid,
                label: districtObj.districtname,
              });
            }
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error("get district", error);
    }
  };

  const getAreaList = async (area_id) => {
    try {
      const response = await axiosPrivate.get(AREA_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.areaList?.length > 0) {
          let areaDD = [];
          response?.data?.areaList?.map((areaObj) => {
            let areaObjDD = {
              value: areaObj.area_id,
              label: areaObj.area_name,
              districtid: areaObj.districtid,
            };
            areaDD.push(areaObjDD);

            if (+areaObj.area_id === +area_id) {
              setSelected_LoaneeArea({
                value: areaObj.area_id,
                label: areaObj.area_name,
                districtid: areaObj.districtid,
              });
            }
          });
          setLoaneeArea_List(areaDD);
        } else {
          setLoaneeArea_List([]);
        }
      }
      response.status === 202 &&
        showToast("No  Area List found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  const createFileChecklistObj = (file, check) => {
    let fileObj = {
      file: file,
      check: check,
    };
    return fileObj;
  };

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  async function updateGuarantorDocument() {
    let file_url = "";
    let file_list = [];

    if (suretyParchaDoc) {
      file_url = await uploadFile(suretyParchaDoc);
      file_list.push(
        createFileObj(suretyParchaDoc?.name, "Surety's Parcha", file_url)
      );
    }

    if (salaryDoc) {
      file_url = await uploadFile(salaryDoc);
      file_list.push(
        createFileObj(salaryDoc?.name, "Salary Certificate", file_url)
      );
    }

    if (departmentalUndertakingDoc) {
      file_url = await uploadFile(departmentalUndertakingDoc);
      file_list.push(
        createFileObj(
          departmentalUndertakingDoc?.name,
          "Departmental Undertaking",
          file_url
        )
      );
    }
    if (adharCardDoc) {
      file_url = await uploadFile(adharCardDoc);
      file_list.push(createFileObj(adharCardDoc?.name, "Adhar Card", file_url));
    }
    if (deductionLetterDoc) {
      file_url = await uploadFile(deductionLetterDoc);
      file_list.push(
        createFileObj(deductionLetterDoc?.name, "Deduction Letter", file_url)
      );
    }

    return file_list;
  }

  const onSubmit = async (data) => {
    try {
      let formDataObj = {};

      let gurantorFiles = await updateGuarantorDocument();
      let gurantorFilesChecklist = await guarnatorDocChecklistHandler();

      let response = "";
      data.guarantortype = data.guarantortype?.value;
      data.guarantorrelationship = data.guarantorrelationship?.value;

      if (userPassPhoto) {
        data.guarantorimage = await uploadFile(userPassPhoto);
      } else {
        data.guarantorimage = editGuarantorDetails?.guarantorimage || null;
      }

      formDataObj.guarantorname = data?.guarantorname;
      formDataObj.guarantoraddress = data?.guarantoraddress;
      formDataObj.guarantortype = data?.guarantortype;
      formDataObj.guarantorcontactno = data?.guarantorcontactno || null;
      formDataObj.alternativecontactno = data?.alternativecontactno || null;
      formDataObj.guarantoremail = data?.guarantoremail;
      formDataObj.guarantorfiles = JSON.stringify(gurantorFiles);
      formDataObj.guarantorchecklist = JSON.stringify(gurantorFilesChecklist);
      formDataObj.guarantorrelationship = data?.guarantorrelationship;
      formDataObj.khatiannumber = data?.khatiannumber;
      formDataObj.khasranumber = data?.khasranumber;
      formDataObj.area = data?.area?.value || "";
      formDataObj.regundername = data?.regundername;
      formDataObj.situatedunderblock = data?.situatedunderblock;
      formDataObj.departmantname = data?.departmantname;
      formDataObj.grosssalary = data?.grosssalary;
      formDataObj.netsalary = data?.netsalary;
      formDataObj.retirementdate = data?.retirementdate;
      formDataObj.undertaking = data?.undertaking;
      formDataObj.salarydeductionamount = data?.salarydeductionamount;
      formDataObj.deductiondate = data?.deductiondate;
      formDataObj.districtid = data?.districtid?.value;
      formDataObj.area_id = data?.area_id?.value;
      formDataObj.guarantor_designation = data?.guarantordesignation;

      if (!isEdit && loaneeId) {
        console.log("saveGuarantor");
        formDataObj.guarantorimage = data?.guarantorimage;
        formDataObj.loanee_id = loaneeId;
        formDataObj.createdby = auth.userid;

        response = await axiosPrivate.post(
          `${LOANEE_DETAILS_CONFIG}/saveGuarantor`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );

        console.log("response", response);
      }
      if (isEdit) {
        data.guarantorid = editGuarantorDetails?.guarantorid;
        data.updatedby = auth.userid;

        formDataObj.guarantorid = data?.guarantorid;
        formDataObj.guarantorimage = data?.guarantorimage;
        formDataObj.updatedby = data?.updatedby;

        response = await axiosPrivate.post(
          `${LOANEE_DETAILS_CONFIG}/updateGuarantor`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditGuarantorDetails({});
          showToast("Guarantor has been updated successfully", "success");
        } else {
          showToast("Guarantor has been added successfully", "success");
        }
        getSearchedApplicantList();
        getApplicantGuarantorDetailList();
      } else {
        if (isEdit) {
          if (
            response.data.updateGurrantorDetails.includes("unique_guarantor")
          ) {
            showToast(
              "Guarantor already exists. Cannot insert duplicate guarantor",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response?.data?.gurrantorDetails.includes("unique_guarantor")) {
            showToast(
              "Guarantor already exists. Cannot insert duplicate guarantor",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);

      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setShowAddForm(false);
    }
  };

  function areaHandler() {
    let filteredAreaList = loaneeArea_List?.filter(
      (areaObj) => +areaObj?.districtid === +selectedDistrict?.value
    );

    setAreaListDistrictWise(filteredAreaList);
  }

  useMemo(() => areaHandler(), [selectedDistrict]);
  useEffect(() => {
    if (isEdit) {
      setPassphoto(editGuarantorDetails?.guarantorimage);
    } else {
      setPassphoto("");
    }
  }, [editGuarantorDetails?.guarantorimage]);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isEdit) {

  //   }
  //   checkListHandler();

  //   getDistrictList(0);
  //   getAreaList(0);
  // }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      checkListHandler();

      if (isEdit) {
        getAreaList(editGuarantorDetails?.area_id);
        getDistrictList(editGuarantorDetails?.districtid);
      }
      if (!isEdit) {
        getDistrictList(0);
        getAreaList(0);
      }
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const areaDD = [
    { value: "Rural", label: "Rural" },
    { value: "Urban", label: "Urban" },
  ];

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="bg-white p-6 border"
      >
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-x-3 items-start">
          <div className="col-span-1 mb-6 ">
            <CapturePhoto
              register={register}
              defaultName="guarantorimage"
              name="Passphoto"
              // required={isEdit ? false : true}
              required={false}
              pattern={null}
              placeholder="Guarantor Image"
              type="file"
              errors={errors}
              classes={`rounded px-3 py-2 text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              file={passphoto}
              setPhotoBase64={setPassphoto}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.guarantorimage : ""}
              setFile={setUserPassPhoto}
              fileUploadError={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
          </div>

          <div className=" lg:col-span-3 col-span-1 grid grid-cols-1 lg:grid-cols-3 lg:gap-x-3">
            <Input
              defaultName="guarantorname"
              register={register}
              name="Guarantor Name"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter guarantor name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.guarantorname}
              setValue={setValue}
            />
            <Input
              defaultName="guarantorcontactno"
              register={register}
              name="Guarantor Contact No"
              required={false}
              pattern={reContact}
              errors={errors}
              placeholder="Enter guarantor contact no"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.guarantorcontactno}
              setValue={setValue}
            />
            <Input
              defaultName="alternativecontactno"
              register={register}
              name="Alternative Contact No"
              required={false}
              pattern={reContact}
              errors={errors}
              placeholder="Enter alternative contact no"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.alternativecontactno}
              setValue={setValue}
            />
            <Input
              defaultName="guarantoremail"
              register={register}
              name="Guarantor Email"
              required={false}
              pattern={reEmail}
              errors={errors}
              placeholder="Enter guarantor"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.guarantoremail}
              setValue={setValue}
            />

            <Dropdown
              defaultName="guarantortype"
              register={register}
              labelname="Guarantor Type"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={guarantorTypeDD}
              defaultValue={defaultValues.guarantortype}
              setValue={setValue}
              setSelected={setSelectedGuarantorType}
              selected={selectedGuarantorType}
              maxMenuHeight={256}
            />
            <Dropdown
              defaultName="guarantorrelationship"
              register={register}
              labelname="Guarantor Relationship"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={guarantorRelationshipDD}
              defaultValue={defaultValues.guarantorrelationship}
              setValue={setValue}
              setSelected={setSelectedGuarantorRelationship}
              selected={selectedGuarantorRelationship}
              maxMenuHeight={256}
            />

            <div className="lg:col-span-2">
              <TextArea
                defaultName="guarantoraddress"
                register={register}
                name="Guarantor address"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter guarantor address"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                onChangeInput={null}
                defaultValue={defaultValues.guarantoraddress}
                setValue={setValue}
              />
            </div>
            <Input
              defaultName="guarantordesignation"
              register={register}
              name="Guarantor Designation"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Add Guarantor Designation"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.guarantor_designation}
              setValue={setValue}
            />

            <Dropdown
              defaultName="area"
              register={register}
              labelname="Area Type"
              required={false}
              errors={errors}
              classes={`rounded-lg text-sm w-full z-40`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={areaDD}
              defaultValue={defaultValues.area}
              setValue={setValue}
              setSelected={setSelectedArea}
              selected={selectedArea}
              maxMenuHeight={120}
              // isDisabled={isEdit ? true : false}
            />

            <Dropdown
              defaultName="districtid"
              register={register}
              labelname="District"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={districtList}
              defaultValue={defaultValues.districtid}
              setValue={setValue}
              setSelected={setSelectedDistrict}
              selected={selectedDistrict}
              maxMenuHeight={120}
            />

            <Dropdown
              defaultName="area_id"
              register={register}
              labelname="Area"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={areaListDistrictWise || loaneeArea_List}
              defaultValue={defaultValues.area_id}
              setValue={setValue}
              setSelected={setSelected_LoaneeArea}
              selected={selected_LoaneeArea}
              maxMenuHeight={256}
            />

            {(selectedGuarantorType?.label === "Parcha Holders" ||
              selectedGuarantorType?.label ===
                "Salaried with Parcha Holders") && (
              <>
                <Input
                  defaultName="khatiannumber"
                  register={register}
                  name="Khatian Number"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter khatian number"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.khatiannumber}
                  setValue={setValue}
                />
                <Input
                  defaultName="khasranumber"
                  register={register}
                  name="Khasra Number"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter khasra number"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.khasranumber}
                  setValue={setValue}
                />

                <Input
                  defaultName="regundername"
                  register={register}
                  name="Registered Under Name"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter registered under name"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.regundername}
                  setValue={setValue}
                />
                <Input
                  defaultName="situatedunderblock"
                  register={register}
                  name="Situated Under Block"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter situated under block"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.situatedunderblock}
                  setValue={setValue}
                />
              </>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4  gap-x-3 items-start">
          {(selectedGuarantorType?.label === "Salaried with Parcha Holders" ||
            selectedGuarantorType?.label?.includes("Employee")) && (
            <>
              <Input
                defaultName="departmantname"
                register={register}
                name="Departmant Name"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter departmant name"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.departmantname}
                setValue={setValue}
              />
              <Input
                defaultName="grosssalary"
                register={register}
                name="Gross Salary"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter gross salary"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.grosssalary}
                setValue={setValue}
              />
              <Input
                defaultName="netsalary"
                register={register}
                name="Net Salary"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter net salary"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.netsalary}
                setValue={setValue}
              />
              <Input
                defaultName="retirementdate"
                register={register}
                name="Retirement Date"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter retirement date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.retirementdate}
                setValue={setValue}
                min={getCurrentDate()}
              />

              <Input
                defaultName="salarydeductionamount"
                register={register}
                name="Deduction Amount"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter deduction amount"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.salarydeductionamount}
                setValue={setValue}
              />

              <Input
                defaultName="deductiondate"
                register={register}
                name="Deduction Date"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter deduction date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.deductiondate}
                setValue={setValue}
              />
            </>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4  gap-x-3 items-start">
          <div className="lg:col-span-2">
            <TextArea
              defaultName="undertaking"
              register={register}
              name="Undertaking"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter undertaking"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
              onChangeInput={null}
              defaultValue={defaultValues.undertaking}
              setValue={setValue}
            />
          </div>
        </div>
        <div className="mt-4 pt-4 border-t ">
          <div className="grid grid-cols-1  lg:grid-cols-2 gap-x-3">
            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="sikkimsubject"
              name={`${
                isEdit && editGuarantorDetails?.sikkimsubject
                  ? "Replace Copy of Sikkim Subject Document / Image"
                  : "Attested Copy of Sikkim Subject Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={sikkimSubjectDoc}
              setFile={setSikkimSubjectDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            /> */}

            <PDFImageUploaderAWS
              register={register}
              defaultName="suretyparcha"
              name={`${
                isEdit && editGuarantorDetails?.suretyparcha
                  ? "Replace Surety's Parcha / Image"
                  : "Surety's Parcha Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={suretyParchaDoc}
              setFile={setSuretyParchaDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.suretyparcha : ""}
            />

            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="salarycertificate"
              name={`${
                isEdit && editGuarantorDetails?.salarycertificate
                  ? "Replace Surety's Parcha/ Salary Document / Image"
                  : "Surety's Parcha/ Salary Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDoc}
              setFile={setSalaryDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.salarycertificate : ""}
            /> */}

            <PDFImageUploaderAWS
              register={register}
              defaultName="salarycertificate"
              name={`${
                isEdit && editGuarantorDetails?.salarycertificate
                  ? "Replace Salary Certificate Document / Image"
                  : "Salary Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDoc}
              setFile={setSalaryDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.salarycertificate : ""}
            />

            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="incomecertificate"
              name={`${
                isEdit && editGuarantorDetails?.incomecertificate
                  ? "Replace Income Certificate Document / Image"
                  : "Income Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={incomeCertDoc}
              setFile={setIncomeCertDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            /> */}

            <PDFImageUploaderAWS
              register={register}
              defaultName="departmentalUndertaking"
              name={`${
                isEdit && editGuarantorDetails?.departmentalUndertaking
                  ? "Replace Departmental Undertaking Document / Image"
                  : "Departmental Undertaking Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={departmentalUndertakingDoc}
              setFile={setDepartmentalUndertakingDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={
                isEdit ? defaultValues.departmentalUndertaking : ""
              }
            />

            <PDFImageUploaderAWS
              register={register}
              defaultName="adharcard"
              name={`${
                isEdit && editGuarantorDetails?.adharcard
                  ? "Replace Adhar Card Document / Image"
                  : "Adhar Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={adharCardDoc}
              setFile={setAdharCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.adharcard : ""}
            />
            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="votercard"
              name={`${
                isEdit && editGuarantorDetails?.votercard
                  ? "Replace Voter Card Document / Image"
                  : "Voter Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={voterCardDoc}
              setFile={setVoterCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            /> */}
            <PDFImageUploaderAWS
              register={register}
              defaultName="deductionletter"
              name={`${
                isEdit && editGuarantorDetails?.deductionletter
                  ? "Replace Deduction Letter Document / Image"
                  : "Deduction Letter Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={deductionLetterDoc}
              setFile={setDeductionLetterDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.deductionletter : ""}
            />
          </div>
        </div>

        <div className="mt-4 pt-4 border-t ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3">
            {/* <InputCheckbox
              defaultName="sikkimsubjectchecked"
              register={register}
              name="Attested Copy of Sikkim Subject"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSikkimSubjectChecked}
              defaultValue={sikkimSubjectChecked}
            /> */}
            <InputCheckbox
              defaultName="suretyParchaChecked"
              register={register}
              name="Surety's Parcha"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSuretyParchaChecked}
              defaultValue={suretyParchaChecked}
            />
            <InputCheckbox
              defaultName="salarycertificatechecked"
              register={register}
              name="Salary Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSalaryCertificateChecked}
              defaultValue={salaryCertificateChecked}
            />
            {/* <InputCheckbox
              defaultName="incomecertificatechecked"
              register={register}
              name="Income Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setIncomeCertificateChecked}
              defaultValue={incomeCertificateChecked}
            /> */}
            <InputCheckbox
              defaultName="departmentalUndertakingChecked"
              register={register}
              name="Departmental Undertaking Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setDepartmentalUndertakingChecked}
              defaultValue={departmentalUndertakingChecked}
            />
            <InputCheckbox
              defaultName="adharchecked"
              register={register}
              name="Adhar Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setAdharCardChecked}
              defaultValue={adharCardChecked}
            />

            {/* <InputCheckbox
              defaultName="votercardchecked"
              register={register}
              name="Voter Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setVoterCardChecked}
              defaultValue={voterCardChecked}
            /> */}
            <InputCheckbox
              defaultName="votercardchecked"
              register={register}
              name="Deduction letter Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setDeductionLetterChecked}
              defaultValue={deductionLetterChecked}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditGuarantorDetails({});
              setValue("guarantor", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Guarantor Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}
