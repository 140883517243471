import React from "react";

const FiscalYearDropDown = ({
  classes,
  placeholder,
  data,
  defaultValue,
  setSelected,
}) => {
  const onChangeHandler = (data) => {
    try {
      setSelected(data.target.value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <select
        onChange={(e) => onChangeHandler(e)}
        className={classes}
        defaultValue={defaultValue}
      >
        <option value="">{placeholder}</option>
        {data.map((year) => (
          <option key={year} value={`${year + 1}-04-01`}>
            {year}-{year + 1}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FiscalYearDropDown;
