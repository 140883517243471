/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { IoIosLink } from "react-icons/io";
import sabccologo from "../../assets/SABCCO Logo.png";

import AnimatedDiv from "./animatedBg.component";

const LaunchPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleKeyPress(event) {
    if (event.key === "Enter") {
      await document.getElementById("launchLink");
      setLoading(true);

      setTimeout(() => {
        //   console.log("This text will be displayed after 1 second.");
        navigate("/launch/go-to");
      }, 1000);
    }
  }

  useEffect(() => {
    let isMounted = true;
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      isMounted = false;
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <section className="relative flex flex-col justify-center items-center h-auto w-full ">
      <div className="w-full ">
        <AnimatedDiv />
      </div>
      <div>
        <div className="flex flex-col justify-center items-center mb-[3rem]">
          <p className="text-3xl md:text-5xl lg:text-8xl font-bold text-primary leading-relaxed animate-pulse lg:ml-[2rem] pt-6">
            New Portal Launch!
          </p>
        </div>

        <div className="flex justify-center gap-x-3 items-center mb-[1rem]">
          <div className="flex justify-center w-auto h-[40vh] ">
            <img src={sabccologo} />
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mb-[3rem] xl:pt-10">
          <p className="text-center md:text-2xl lg:text-5xl font-bold text-primary md:leading-relaxed border-b-2 border-slate-600 border-spacing-x-3 mb-3 mx-3 md:px-0 mt-10 md:mt-0 ">
            Sikkim SC,ST& OBC Development Corporation Limited
          </p>
          <p className="text-xl lg:text-4xl  tracking-[0.5rem] lg:tracking-[1rem]  font-bold">
            SABCCO
          </p>
        </div>

        <div className="h-32 pt-[2rem]">
          <div
            className="w-[90%] md:w-[60%] lg:w-[40%] mx-auto text-center md:text-2xl bg-primary cursor-pointer border
         border-primary hover:bg-primary bg-opacity-95 hover:bg-opacity-100 text-white transition-all 
         ease-in-out duration-300 rounded-2xl py-2 md:px-16 md:py-8 uppercase  "
          >
            {loading ? (
              <div className="flex space-x-3 justify-center items-center h-6">
                <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-3 w-3 bg-white rounded-full animate-bounce"></div>
              </div>
            ) : (
              <Link to="/launch/go-to">
                <div className="flex space-x-3 justify-center items-center h-6 text-3xl ">
                  Launch
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LaunchPage;
