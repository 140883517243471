const LOGIN_URL = "/login";
const LOGOUT_URL = "/logout";
const REPAYMENT_LOGOUT_URL = "/repaymentLogout";

const REPAYMENT_lOGIN_URL = "/repaymentLogin";
const MANAGE_OTP_DETAILS_URL = "/manageOtp";

const ABOUT_US_CONFIG_URL = "/sysAdmin/aboutUs";
const CATEGORY_CONFIG_URL = "/sysAdmin/categoryConfig";
const DISTRICT_CONFIG_URL = "/sysAdmin/districtConfig";

const BANK_CONFIG_URL = "/sysAdmin/bankConfig";
const BANKBRANCH_CONFIG_URL = "/sysAdmin/bankBranchConfig";
const SCHEME_CONFIG_URL = "/sysAdmin/schemeConfig";
const CONSTITUENCY_CONFIG_URL = "/sysAdmin/constituencyConfig";
const SUBDIVISION_CONFIG_URL = "/sysAdmin/subDivisionConfig";
const GRAMPANCHAYAT_CONFIG_URL = "/sysAdmin/gramPanchayatConfig";
const SCHEMES_CONFIG_URL = "/sysAdmin/schemesConfig";
const INTEREST_CONFIG_URL = "/sysAdmin/interestRateDetailsConfig";
const AWARDS_CONFIG_URL = "/sysAdmin/awardsConfig";
const PUBLICATIONS_CONFIG_URL = "/sysAdmin/publicationConfig";
const NEWS_CONFIG_URL = "/sysAdmin/newsConfig";
const PHOTO_GALLERY_CONFIG_URL = "/sysAdmin/photoGalleryConfig";
const BROACHER_CONFIG_URL = "/sysAdmin/broacherConfig";
const FAQ_CONFIG_URL = "/sysAdmin/faqConfig";
const APPLICANT_PERSONAL_DETAILS_CONFIG_URL =
  "/sysAdmin/applicantPersonalDetailsConfig";
const USER_MANAGEMENT_CONFIG_URL = "/sysAdmin/userManagementConfig";
const NAGAR_PANCHAYAT_CONFIG_URL = "/sysAdmin/nagarPanchayatConfig";
const MUNICIPAL_CORPORATION_CONFIG_URL = "/sysAdmin/municipalCorporationConfig";
const EMPLOYEE_MANAGEMENT_CONFIG_URL = "/sysAdmin/employeeManagementConfig";
const REPAYMENT_CONFIG_URL = "/sysAdmin/repaymentConfig";

const REPORTS_CONFIG_URL = "/sysAdmin/reportsConfig";
const FEEDBACK_CONFIG_URL = "/sysAdmin/feedbackConfig";
const AUDIT_TRAIL_CONFIG_URL = "/sysAdmin/auditTrailConfig";
const APPLICANT_REMARK_CONFIG_URL = "/sysAdmin/applicantRemarkConfig";
const CHANGE_PASSWORD = "/sysAdmin/changePassword";
const GET_LEDGER_DETAILS = "/sysAdmin/getLedgerDetails";
const LEDGER_CONFIG_URL = "/sysAdmin/ledgerConfig";
const GET_INTEREST_LEDGER_DETAILS = "/sysAdmin/getInterestLedgerDetails";
const GET_PENAL_INTEREST_DETAILS = "/sysAdmin/getPenalInterestDetails";
const GET_RECEIPT_REPAYMENT_DETAILS = "/sysAdmin/getReceiptRepaymentDetails";
const GET_CHEQUE_RECEIPT_LIST_DETAILS = "/sysAdmin/getChequeReceiptListDetails";

const GET_INTEREST_ON_INTEREST_DETAILS =
  "/sysAdmin/getInterestOnInterstDetails";

const GET_DASHBOARD_REPORT_URL = "/sysAdmin/getDashboardReport";

//Category wise Dashboard Details
const GET_DASHBOARD_DETAILS_URL = "/sysAdmin/getDashboardDetails";
const LOANEE_DETAILS_CONFIG = "/sysAdmin/loaneeDetailsConfig";
const OTS_CONFIG_URL = "/sysAdmin/oneTimeSettlementConfig";
const SEND_MAIL_URL = "/sendmail";

const NOTICE_URL = "/sysAdmin/noticeConfig";
const AREA_CONFIG_URL = "sysAdmin/areaConfig";
const DESIGNATION_CONFIG_URL = "sysAdmin/designationConfig";
const MST_CASHCOUNTER_CONFIG_URL = "sysAdmin/cashCounterConfig";
const MEDIA_CONFIG_URL = "sysAdmin/mediaConfig";
const MEDIA_ITEMS_CONFIG_URL = "sysAdmin/mediaItemsConfig";
const CASHCOUNTER_CONFIG_URL = "sysAdmin/cashCounter_Config";
const YEARLY_COMPILED_REPORT_URL = "sysAdmin/yearlyCompiledReport";
const NOTIFICATION_CONFIG_URL = "sysAdmin/notificationConfig";
const YEARLY_WISE_REPORT_URL = "sysAdmin/yearlyWiseReport";

//#region Public URLs

const ABOUT_US_PUBLIC_URL = "/aboutus";
const SCHEMES_LIST_PUBLIC_URL = "/schemeslist";
const NOTICE_BOARD_LIST_URL = "/noticeboard";
const PHOTOGALLERY_LIST_URL = "/photogallery";
const AWARDS_LIST_URL = "/awards";
const FAQs_LIST_URL = "/faqs";
const NEWs_EVENTs_LIST_URL = "/newsEvents";
const FEEDBACK_URL = "/feedback";
const AWS_ACCESS_URL = "/getAWSAccess";
const RAS_KEY_URL = "/getRsaPublicKey";
const CAPTCHA_URL = "/getCaptcha";
const ROLELIST_URL = "/rolelist";
const EMPLOYEE_DETAIL_URL = "/employeelist";

const CREATE_ORDER_API = "/payment";
const PUBLIC_REPAYMENT_CONFIG_URL = "/getPrincipleDueWithInterestUntilDate";

const PUBLIC_NOC_APPLICATION = "/public-noc-application";

//#endregion

const LOANEE_DEATH_CASE = "/sysAdmin/loaneeDeathCaseCongfig";
const NOC_APPLICATION_LIST_URL = "/sysAdmin/nocApplicationListCongfig";
const VERIFY_NOC_APPLICATION_URL = "/sysAdmin/verifyNocApplicationCongfig";

const EMP_DESIGNATION_URL = "/sysAdmin/empDesigantionConfig";
const EMP_DETAILS_URL = "/sysAdmin/empDetailsConfig";

const DEFAULTER_DOCS_URL = "/sysAdmin/defaulterDocs";

module.exports = {
  LOGIN_URL,
  LOGOUT_URL,
  ABOUT_US_CONFIG_URL,
  CATEGORY_CONFIG_URL,
  SCHEMES_CONFIG_URL,
  AWARDS_CONFIG_URL,
  PUBLICATIONS_CONFIG_URL,
  NEWS_CONFIG_URL,
  PHOTO_GALLERY_CONFIG_URL,
  BROACHER_CONFIG_URL,
  FAQ_CONFIG_URL,
  SEND_MAIL_URL,
  ABOUT_US_PUBLIC_URL,
  SCHEMES_LIST_PUBLIC_URL,
  NOTICE_BOARD_LIST_URL,
  PHOTOGALLERY_LIST_URL,
  AWARDS_LIST_URL,
  FAQs_LIST_URL,
  NEWs_EVENTs_LIST_URL,
  FEEDBACK_URL,
  DISTRICT_CONFIG_URL,

  BANK_CONFIG_URL,
  BANKBRANCH_CONFIG_URL,

  CONSTITUENCY_CONFIG_URL,
  SUBDIVISION_CONFIG_URL,
  GRAMPANCHAYAT_CONFIG_URL,
  SCHEME_CONFIG_URL,
  INTEREST_CONFIG_URL,
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  USER_MANAGEMENT_CONFIG_URL,
  AWS_ACCESS_URL,
  NAGAR_PANCHAYAT_CONFIG_URL,
  MUNICIPAL_CORPORATION_CONFIG_URL,
  EMPLOYEE_MANAGEMENT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
  REPORTS_CONFIG_URL,
  FEEDBACK_CONFIG_URL,
  RAS_KEY_URL,
  CAPTCHA_URL,
  AUDIT_TRAIL_CONFIG_URL,
  ROLELIST_URL,
  APPLICANT_REMARK_CONFIG_URL,
  CHANGE_PASSWORD,
  GET_LEDGER_DETAILS,
  GET_INTEREST_LEDGER_DETAILS,
  GET_PENAL_INTEREST_DETAILS,
  GET_INTEREST_ON_INTEREST_DETAILS,
  LOANEE_DETAILS_CONFIG,
  LEDGER_CONFIG_URL,
  GET_DASHBOARD_REPORT_URL,
  OTS_CONFIG_URL,

  LOANEE_DEATH_CASE,
  NOTICE_URL,
  AREA_CONFIG_URL,

  DESIGNATION_CONFIG_URL,
  MEDIA_CONFIG_URL,
  MEDIA_ITEMS_CONFIG_URL,
  MST_CASHCOUNTER_CONFIG_URL,
  EMPLOYEE_DETAIL_URL,

  CASHCOUNTER_CONFIG_URL,
  GET_DASHBOARD_DETAILS_URL,
  YEARLY_COMPILED_REPORT_URL,
  NOTIFICATION_CONFIG_URL,
  YEARLY_WISE_REPORT_URL,
  EMP_DESIGNATION_URL,
  EMP_DETAILS_URL,

  CREATE_ORDER_API,
  REPAYMENT_lOGIN_URL,
  MANAGE_OTP_DETAILS_URL,

  PUBLIC_REPAYMENT_CONFIG_URL,
  REPAYMENT_LOGOUT_URL,

  DEFAULTER_DOCS_URL,

  GET_RECEIPT_REPAYMENT_DETAILS,
  GET_CHEQUE_RECEIPT_LIST_DETAILS,

  PUBLIC_NOC_APPLICATION,
  NOC_APPLICATION_LIST_URL,
  VERIFY_NOC_APPLICATION_URL,
};
