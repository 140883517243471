import React from "react";
import { NavLink } from "react-router-dom";
import SidebarLinkGroup from "../../common/SidebarLinkGroup";
import { MdEditNote, MdOutlineDashboard } from "react-icons/md";
import SidebarIcons from "../../common/SidebarIcons";

import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineLineChart } from "react-icons/ai";
import { HiOutlineCog } from "react-icons/hi";
import { BiAddToQueue } from "react-icons/bi";
import Badge from "../../reusable-components/badge/Badge";
import useNotificationHook from "../../authentication/hooks/useNotificationHook";
import ReportSidebar from "../common/ReportSidebar";
import { FaRegAddressCard } from "react-icons/fa6";

export default function DEOAdminSidebar({
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
}) {
  const { notificationDetail } = useNotificationHook();

  const total_count = notificationDetail?.notificationDetails?.total_count || 0;

  return (
    <ul className="mt-3">
      {/* Dashboard */}

      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/deoadmin/dashboard" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/deoadmin/dashboard"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/deoadmin/dashboard"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/deoadmin/dashboard"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineDashboard size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Dashboard
            </span>
          </div>
        </NavLink>
      </li>

      <SidebarLinkGroup activecondition={pathname.includes("master-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block truncate transition duration-150 ${
                  pathname.includes("master-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("master-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <HiOutlineCog size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Master Config
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/master-bank"
                      className={`block 
                       ${
                         pathname.includes("-bank")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Bank
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/master-bankbranch"
                      className={`block 
                       ${
                         pathname.includes("branch")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Bank Branch
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/master-area"
                      className={`block 
                       ${
                         pathname.includes("area")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Area
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/master-constituency"
                      className={`block 
                       ${
                         pathname.includes("constituency")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Constituency
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Application */}
      <SidebarLinkGroup activecondition={pathname.includes("application-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("application-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between relative">
                  <div className="flex items-center ">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("application-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <IoDocumentTextOutline size={24} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Application
                    </span>
                    <div>
                      {+total_count && !open > +0 ? (
                        <Badge>{total_count}</Badge>
                      ) : null}
                    </div>
                  </div>
                  {/* Icon */}
                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/application-details"
                      className={`block  relative
                       ${
                         pathname.includes("-details")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Applicant Details
                      </span>

                      {+total_count > +0 ? <Badge>{total_count}</Badge> : null}
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/application-deathcasedetails"
                      className={`block 
                       ${
                         pathname.includes("deathcasedetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Death Case Details
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/application-noc"
                      className={`block 
                       ${
                         pathname.includes("application-noc")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        NOC application
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      <SidebarLinkGroup activecondition={pathname.includes("defaulter-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("defaulter-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <FaRegAddressCard size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Defaulter Details
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/defaulter-defaulterList"
                      className={`block 
                       ${
                         pathname.includes("defaulter-defaulterList")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter List
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/defaulter-uploadDefaulterDetails"
                      className={`block 
                       ${
                         pathname.includes("defaulter-uploadDefaulterDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      {/* ReportList */}
      <ReportSidebar
        pathname={pathname}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
        accessRole={"deoadmin"}
      />

      <SidebarLinkGroup activecondition={pathname.includes("notice")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("notice")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("notice")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Notice
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/demand_notice"
                      className={`block 
                       ${
                         pathname.includes("demand_notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Demand Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/direct-dept-notice_notice"
                      className={`block 
                       ${
                         pathname.includes("direct-")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Direct Departmental Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/indirect1-dept-notice"
                      className={`block 
                       ${
                         pathname.includes("indirect1-dept-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Indirect Departmental Notice
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/deoadmin/ots-notice"
                      className={`block 
                       ${
                         pathname.includes("ots-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        OTS Notice
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>
    </ul>
  );
}
