import React from "react";
import { NavLink } from "react-router-dom";
// import SidebarLinkGroup from "../common/SidebarLinkGroup";
import {
  MdDocumentScanner,
  MdEditNote,
  MdOutlineDashboard,
  MdOutlinePermMedia,
} from "react-icons/md";
// import SidebarIcons from "../common/SidebarIcons";
import { FaAward, FaInfoCircle, FaTachometerAlt } from "react-icons/fa";
import SidebarIcons from "../../common/SidebarIcons";
import SidebarLinkGroup from "../../common/SidebarLinkGroup";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CgAwards } from "react-icons/cg";
import { IoPeopleSharp } from "react-icons/io5";
import { FaRegAddressCard } from "react-icons/fa6";

export default function ContentAdminSidebar({
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
}) {
  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/contentadmin/dashboard" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/contentadmin/dashboard"
          className={`block  truncate transition duration-150 ${
            pathname === "/contentadmin/dashboard"
              ? "text-slate-50 hover:text-slate-100"
              : "text-slate-100"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/contentadmin/dashboard"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <MdOutlineDashboard size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Dashboard
            </span>
          </div>
        </NavLink>
      </li>

      {/* About Us */}
      <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/contentadmin/about_us" && "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/contentadmin/about_us"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/contentadmin/about_us" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/contentadmin/about_us"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <AiOutlineInfoCircle size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              About Us
            </span>
          </div>
        </NavLink>
      </li>

      {/* Awards and appreciation */}
      {/* <li
        className={`p-2 rounded-sm mb-0.5 last:mb-0 ${
          pathname === "/contentadmin/awards_list" &&
          "bg-slate-100 bg-opacity-10"
        }`}
      >
        <NavLink
          end
          to="/contentadmin/awards_list"
          className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
            pathname === "/contentadmin/awards_list" && "hover:text-slate-200"
          }`}
        >
          <div className="flex items-center">
            <SidebarIcons
              className={`shrink-0 ${
                pathname === "/contentadmin/awards_list"
                  ? "text-slate-100"
                  : "text-slate-300"
              } font-medium`}
              size={sidebarExpanded ? 28 : 25}
            >
              <CgAwards size={24} />
            </SidebarIcons>
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              Awards &amp; Appreciation
            </span>
          </div>
        </NavLink>
      </li> */}

      {/* Media Gallary */}
      <SidebarLinkGroup activecondition={pathname.includes("media-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("media-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdOutlinePermMedia size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Media Gallery
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/media-photo"
                      className={`block 
                       ${
                         pathname.includes("photo")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Photo Gallery
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className="mb-1 last:mb-0">
                    <NavLink
                      end
                      to="/contentadmin/media-video"
                      className={`block 
                       ${
                         pathname.includes("video")
                           ? "text-white"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Video Gallery
                      </span>
                    </NavLink>
                  </li> */}
                  <li className="mb-1 last:mb-0">
                    <NavLink
                      end
                      to="/contentadmin/media-broacher"
                      className={`block 
                       ${
                         pathname.includes("broacher")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Brochures &amp; Pamphlets
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Notices  */}
      {/* <SidebarLinkGroup activecondition={pathname.includes("notice")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("notice")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("notice")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <MdEditNote />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Notice
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/demand_notice"
                      className={`block 
                       ${
                         pathname.includes("demand_notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Demand Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/direct-dept-notice_notice"
                      className={`block 
                       ${
                         pathname.includes("direct-")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Direct Departmental Notice
                      </span>
                    </NavLink>
                  </li>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/indirect1-dept-notice"
                      className={`block 
                       ${
                         pathname.includes("indirect1-dept-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Indirect Departmental Notice
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/ots-notice"
                      className={`block 
                       ${
                         pathname.includes("ots-notice")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        OTS Notice
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup> */}

      {/* {Employee} */}
      <SidebarLinkGroup activecondition={pathname.includes("employee")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("employee")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("employee")
                          ? "text-slate-100"
                          : "text-slate-400"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <IoPeopleSharp size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Employee
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/employee-designation"
                      className={`block 
                       ${
                         pathname.includes("-designation")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Employee Designation
                      </span>
                    </NavLink>
                  </li>
                  <li className="mb-1 last:mb-0">
                    <NavLink
                      end
                      to="/contentadmin/employee-list"
                      className={`block 
                       ${
                         pathname.includes("-list")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Employee List
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}

      <SidebarLinkGroup activecondition={pathname.includes("defaulter-")}>
        {(handleClick, open) => {
          return (
            <React.Fragment>
              <a
                href="#0"
                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                  pathname.includes("defaulter-")
                    ? "text-slate-50 hover:text-slate-100"
                    : "text-slate-300"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarIcons
                      className={`shrink-0 ${
                        pathname.includes("media-")
                          ? "text-slate-100"
                          : "text-slate-300"
                      } font-medium`}
                      size={sidebarExpanded ? 28 : 25}
                    >
                      <FaRegAddressCard size={20} />
                    </SidebarIcons>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Defaulter Details
                    </span>
                  </div>

                  <div className="flex shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                        open && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/defaulter-defaulterList"
                      className={`block 
                       ${
                         pathname.includes("defaulter-defaulterList")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-300 hover:text-slate-100"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter List
                      </span>
                    </NavLink>
                  </li>

                  <li className={`mb-1.5 last:mb-0`}>
                    <NavLink
                      end
                      to="/contentadmin/defaulter-uploadDefaulterDetails"
                      className={`block 
                       ${
                         pathname.includes("defaulter-uploadDefaulterDetails")
                           ? "text-slate-50 hover:text-slate-100"
                           : "text-slate-400 hover:text-slate-300"
                       } transition duration-150 truncate`}
                    >
                      <span className="text-sm font-normal lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Defaulter Details
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>

      {/* Defalter Details */}
    </ul>
  );
}
