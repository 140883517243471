import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import MobileSubMenu from "./MobileSubMenu";

export default function MobileNavLinks({ item, index, setIsActive }) {
  const dropdownSubmenuRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isSubMenuActive, setIsSubMenuActive] = useState(false);

  const closeMenu = () => {
    setIsSubMenuActive(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <li className={`nav-item ${item.hasSubMenu ? "" : ""}`} key={index}>
      {item.url.includes("http") ? (
        <a href={item.url} target="_blank">
          <div
            className="px-3 py-2 flex items-center text-xs md:text-xxs lg:text-xs uppercase font-bold leading-snug text-secondary hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none
        focus:bg-ternary active:bg-ternary"
          >
            {/* <IconContext.Provider value={{ className: "text-lg" }}>
              {item.normal_icon}
            </IconContext.Provider> */}
            <span className="ml-1 pt-1 text-sm">{item.navlink}</span>
          </div>
        </a>
      ) : !item.hasSubMenu ? (
        <Link to={`${item.url}`}>
          <div
            className="px-3 py-2 flex items-center text-xs md:text-xxs lg:text-xs uppercase font-bold leading-snug text-secondary hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none
        focus:bg-ternary active:bg-ternary"
            onClick={() => {
              setIsActive(false);
            }}
          >
            {/* <IconContext.Provider value={{ className: "text-lg" }}>
              {item.normal_icon}
            </IconContext.Provider> */}
            <span className="ml-1 pt-1 text-sm">{item.navlink}</span>
          </div>
        </Link>
      ) : (
        <div
          style={{
            textDecoration: "none",
            color: "#333333",
            padding: "10px 15px",
            display: "block",
          }}
          className="cursor-pointer"
        >
          <div
            className="px-3 py-2 flex justify-between items-center text-xs md:text-xxs lg:text-xs uppercase font-bold leading-snug text-secondary hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none
        focus:bg-ternary active:bg-ternary"
            onClick={() => setIsSubMenuActive(true)}
          >
            <div className="flex flex-row items-center">
              {/* <IconContext.Provider value={{ className: "text-lg" }}>
                {item.normal_icon}
              </IconContext.Provider> */}
              <span className="ml-1 pt-1 text-sm">{item.navlink}</span>
            </div>
            <div>
              {/* <IconContext.Provider value={{ className: "text-lg" }}>
                <FiChevronDown />
              </IconContext.Provider> */}
            </div>
          </div>
          <nav
            ref={dropdownSubmenuRef}
            className={`subMenu ${
              isSubMenuActive ? "active" : "inactive"
            } text-left`}
          >
            <ul ref={wrapperRef} className="bg-white">
              {item.subMenu.map((item, index) => (
                <MobileSubMenu
                  item={item}
                  index={index}
                  key={index}
                  setIsSubMenuActive={setIsSubMenuActive}
                  setIsActive={setIsActive}
                />
              ))}
            </ul>
          </nav>
        </div>
      )}
    </li>
  );
}
