import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { colorsList } from "../../../utilities/constants/colors";

export default function LineChartReport({ dataObj, title, ...rest }) {
  //   no_approved: "7601";
  //   no_disbursed: "7599";
  //   no_pending: "11";
  //   no_revert: "2";

  const [lineChartWidth, setLineChartWidth] = useState();

  const data = dataObj;
  useEffect(() => {
    if (+window?.innerWidth > 768) {
      setLineChartWidth(+window?.innerWidth / 8 || 100);
    } else {
      setLineChartWidth(+window?.innerWidth / 2.5 || 100);
    }
  }, [+window.innerWidth]);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={lineChartWidth}
          height={lineChartWidth}
          data={data}
          margin={{
            top: 19,
            right: 9,
            left: 9,
            bottom: 19,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            height={90}
            tick={<CustomizedAxisTick />}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis tick={<CustomizedYAxisTick />} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="expect"
            stroke={colorsList[7]}
            // label={<CustomizedLabel stroke={colorsList[7]} />}
          />
          <Line
            type="monotone"
            dataKey="actual"
            stroke={colorsList[6]}
            // label={<CustomizedLabel dy={1} stroke={colorsList[6]} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

const CustomizedLabel = (props) => {
  const { x, y, stroke, value, dy } = props;

  return (
    <text
      x={x}
      y={y}
      dy={dy ? dy : 32}
      fill={stroke}
      fontSize={12}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {payload.value}
      </text>
    </g>
  );
};
const CustomizedYAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-6} y={0} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};
