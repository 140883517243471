import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { REPAYMENT_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import { rePaymentFormModeDD } from "../../../../utilities/constants/constants";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";

const AddDecreeRepaymentForm = ({
  getRepaymentList,
  editRepaymentDetails,
  setEditRepaymentDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [selectedRepaymentMode, setSelectedRepaymentMode] = useState();
  const [principleDueWithInterest, setPrincipleDueWithInterest] = useState();
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [principlePaidAmount, setPrinciplePaidAmount] = useState(0);
  const [interestPaidAmount, setInterestPaidAmount] = useState(0);
  const [interestDueAmount, setInterestDueAmount] = useState(0);
  const [principleDueAmount, setPrincipleDueAmount] = useState(0);
  const [currentDateFromServer, setCurrentDateFromServer] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: principleDueWithInterest,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const getCurrentDateFromServer = async () => {
    try {
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getCurrentDateFromServer`,
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setCurrentDateFromServer(
          getFormattedDateInputDate(response?.data?.currentDateFromServer)
        );
        getPrincipleDueWithInterestUntilDate(
          response?.data?.currentDateFromServer
        );
      }

      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getPrincipleDueWithInterestUntilDate = async (specfic_date) => {
    try {
      if (specfic_date) {
        const response = await axiosPrivate.get(
          `${REPAYMENT_CONFIG_URL}/decreePrincipleDueWithInterestUntilDate`,
          {
            params: {
              personaldetailsid: editRepaymentDetails?.personaldetailsid,
              specficdate: specfic_date,
            },
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status === 200) {
          setPrincipleDueWithInterest(
            response?.data?.principleDueWithInterestData
          );
          setTotalDueAmount(
            parseInt(
              response?.data?.principleDueWithInterestData?.interestdueamount
            ) +
              parseInt(
                response?.data?.principleDueWithInterestData?.principledue
              )
          );
        }

        response.status === 202 &&
          showToast("No state list found in the system", "error");
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const repaymentAmountChangeHandler = (amount) => {
    if (amount !== null && amount !== undefined && amount !== "") {
      let repayAmount = parseInt(amount);
      let interestDueAmount = parseInt(
        principleDueWithInterest?.interestdueamount
      );
      let principleDueAmount = parseInt(
        principleDueWithInterest?.principleoutstandingamount
      );
      if (repayAmount > interestDueAmount) {
        setInterestPaidAmount(() => interestDueAmount);
        setPrinciplePaidAmount(() => repayAmount - interestDueAmount);
        setPrincipleDueAmount(
          () => principleDueAmount - (repayAmount - interestDueAmount)
        );
        setInterestDueAmount(0);
      }
      if (repayAmount <= interestDueAmount) {
        setInterestPaidAmount(() => repayAmount);
        setPrinciplePaidAmount(0);
        setInterestDueAmount(() => interestDueAmount - repayAmount);
        setPrincipleDueAmount(principleDueAmount);
      }
    } else {
      setPrinciplePaidAmount(0);
      setInterestPaidAmount(0);
      setInterestDueAmount(0);
    }
  };

  const onSubmit = async (data) => {
    try {
      let response = "";
      let formDataObj = {};
      let lastRepaymentDate = getFormattedDateInputDate(
        principleDueWithInterest?.lastrepaymentdate
      );
      const monthlyInstallmentDueAmount =
        parseInt(principleDueWithInterest?.principledue) -
        parseInt(principlePaidAmount);

      formDataObj.repaymentamount = parseInt(data?.repaymentamount);
      formDataObj.repaymentdate =
        lastRepaymentDate <= data?.repaymentdate
          ? data?.repaymentdate
          : lastRepaymentDate;
      formDataObj.personaldetailsid = editRepaymentDetails?.personaldetailsid;
      formDataObj.interestdueamount = interestDueAmount;
      formDataObj.principleoutstandingamount = principleDueAmount;
      formDataObj.principleamountpaid = principlePaidAmount;
      formDataObj.interestamountpaid = interestPaidAmount;
      formDataObj.monthlyinstallmentdueamount = parseInt(
        monthlyInstallmentDueAmount
      );
      formDataObj.paymentmode = data?.paymentmode?.value;
      formDataObj.chequeno = data?.chequeno || null;
      formDataObj.transectionid = data?.transectionid || null;
      formDataObj.remarks = data?.remarks || null;
      formDataObj.createdby = auth.userid;

      response = await axiosPrivate.post(
        `${REPAYMENT_CONFIG_URL}/saveDecreeRepaymentDetailsConfig`,
        formDataObj,
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Repayment detail has been added successfully", "success");
        getRepaymentList();
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getCurrentDateFromServer();
  }, []);

  return (
    <section>
      {" "}
      <section className="grid grid-cols-3 gap-x-3 gap-y-2">
        <LabelWithDetail
          label="Principle Outstanding"
          value={formatINRCurrency(
            principleDueWithInterest?.principleoutstandingamount
          )}
        />
        <LabelWithDetail
          label="Principle Due"
          value={formatINRCurrency(principleDueWithInterest?.principledue)}
        />
        <LabelWithDetail
          label="Interest Due"
          value={formatINRCurrency(principleDueWithInterest?.interestdueamount)}
        />
        <LabelWithDetail label="Total Due Amount " value={totalDueAmount} />
        <LabelWithDetail
          label="Interest Calculation Since"
          value={getFormattedDateDDMMMYYYYDate(
            principleDueWithInterest?.lastrepaymentdate
          )}
        />
        <LabelWithDetail
          label="Calculation For Days"
          value={principleDueWithInterest?.calculationfordays}
        />
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-x-3 ">
          <fieldset className="col-span-3  p-2 bg-slate-100 grid grid-cols-3 gap-x-3 mt-4">
            <legend className="px-2 bg-slate-600 rounded-sm text-white text-sm p-1">
              Payment
            </legend>
            <Input
              defaultName="repaymentamount"
              register={register}
              name="Repayment Amount"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter repayment amount"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={repaymentAmountChangeHandler}
              defaultValue={principleDueWithInterest?.repaymentamount}
              setValue={setValue}
            />

            <Dropdown
              defaultName="paymentmode"
              register={register}
              labelname="Payment Mode"
              required={true}
              pattern={null}
              errors={errors}
              classes={`rounded-lg text-sm w-full`}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={rePaymentFormModeDD}
              defaultValue={principleDueWithInterest?.paymentmode}
              setValue={setValue}
              setSelected={setSelectedRepaymentMode}
              selected={selectedRepaymentMode}
              maxMenuHeight={120}
            />
            <Input
              defaultName="repaymentdate"
              register={register}
              name="Repayment Date"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter repayment date"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="date"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={(e) => {
                if (
                  getFormattedDateInputDate(e) >=
                  getFormattedDateInputDate(
                    principleDueWithInterest?.lastrepaymentdate
                  )
                ) {
                  clearErrors("repaymentdate");
                  clearErrors(`repaymentdate_onChange`);
                  getPrincipleDueWithInterestUntilDate(e);
                } else {
                  setError("repaymentdate", {
                    type: "required",
                    message: `Repayment date is invalid`,
                  });
                }
              }}
              defaultValue={currentDateFromServer}
              setValue={setValue}
              min={getFormattedDateInputDate(
                principleDueWithInterest?.lastrepaymentdate
              )}
              max={getFormattedDateInputDate(
                principleDueWithInterest?.currentdate
              )}
            />
          </fieldset>
          <fieldset className="col-span-3 p-2 border-t grid grid-cols-3 gap-x-3 mt-4">
            <legend className="px-2 bg-slate-600 rounded-sm text-white text-sm p-1">
              Adjustment
            </legend>
            <Input
              defaultName="principleamountpaid"
              register={register}
              name="Principle Amount Paid"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter principle amount paid"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="numeric"
              classes={`rounded-lg px-3 py-2 text-sm w-full cursor-not-allowed`}
              onChangeInput={null}
              defaultValue={principlePaidAmount}
              setValue={setValue}
              disabled={true}
            />
            <Input
              defaultName="interestamountpaid"
              register={register}
              name="Interest Amount Paid"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter interest amount paid"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="numeric"
              classes={`rounded-lg px-3 py-2 text-sm w-full cursor-not-allowed`}
              onChangeInput={null}
              defaultValue={interestPaidAmount}
              setValue={setValue}
              disabled={true}
            />
          </fieldset>
          <fieldset className="col-span-3 p-2 border-t grid grid-cols-3 gap-x-3 mt-1">
            <legend className="px-2 bg-slate-600 rounded-sm text-white text-sm p-1">
              Other details
            </legend>
            <TextArea
              defaultName="remarks"
              register={register}
              name="Remarks"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter remarks"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
              onChangeInput={null}
              defaultValue={principleDueWithInterest?.remarks}
              setValue={setValue}
            />
            <Input
              defaultName="chequeno"
              register={register}
              name="Cheque No"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter cheque no"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="numeric"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={principleDueWithInterest?.chequeno}
              setValue={setValue}
            />
            <Input
              defaultName="transectionid"
              register={register}
              name="Transaction Id"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter transaction id"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={principleDueWithInterest?.transectionid}
              setValue={setValue}
            />
          </fieldset>
        </div>
        <div className="flex justify-between mt-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Submit"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditRepaymentDetails({});
              setValue("districtname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel District Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddDecreeRepaymentForm;

const LabelWithDetail = ({ label, value }) => {
  return (
    <Fragment>
      <section>
        <label className="p-1 font">{label}</label>
        <p className="font-medium py-1.5 px-2 border bg-slate-50 rounded">
          {value}
        </p>
      </section>
    </Fragment>
  );
};
