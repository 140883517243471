const ROLES = {
  "Super Admin": 1,
  "Content Admin": 2,
  "Data Entry Operator": 3,
  "Deputy General Manager": 4,
  "General Manager": 5,
  "Managing Director": 6,
  Legal: 7,
  Accounts: 8,
  "Record Keeper": 9,
  "Assistant Manager": 10,
  "DGM Accounts": 11,
  "A.M -II": 12,
  "Sr.O.A-II": 13,
  Cashier: 14,
};

module.exports = ROLES;
