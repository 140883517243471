/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { REPAYMENT_CONFIG_URL } from "../../../../../api/api_routing_urls";
import useAuth from "../../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../../reusable-components/inputs/Dropdowns/Dropdown";
import TextArea from "../../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../../theme/lightTheme";
import { rePaymentFormModeDD } from "../../../../../utilities/constants/constants";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../../utilities/notification/NotificationModal";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";
import GenericModal from "../../../../../reusable-components/modals/GenericModal";
import Receipt_In_Add_Repayment from "../../../ReportsList/Ledger/Receipt_In_Add_Repayment";
const AddIOIForm = ({
  addRepaymentDetails,
  principleDueWithIntData,
  repaymentDate,
  currentDateFromServer,
  setAddIOI,
  getInitialLoadCalculation,
  setRefreshPage,
  totalDueAmount,
  penalTotalDue,
  ioiTotalDue,
  loaneeTotalRepaymentAmt,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [selectedRepaymentMode, setSelectedRepaymentMode] = useState();
  const [specficDate, setSpecficDate] = useState();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [specficChequeDate, setSpecficChequeDate] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: principleDueWithIntData,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const onSubmit = async () => {
    try {
      const data = formData;
      const response = await axiosPrivate.post(
        `${REPAYMENT_CONFIG_URL}/repaymentIOInterestLedger`,
        {
          loan_number: addRepaymentDetails?.loan_number,
          paymentmode: data?.paymentmode?.value,
          repayment_date:
            data?.repaymentdate || specficDate || currentDateFromServer,
          interest_paid: data.repaymentamount,
          chequeno: data?.chequeno,
          transectionid: data?.transectionid,
          particulars: data?.particulars,
          createdby: auth.userid,
          interest_debit: +ioiTotalDue,
          cheque_date: formData?.chequedate,
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Repayment detail has been added successfully", "success");
        getInitialLoadCalculation(repaymentDate);
        reset();
        setAddIOI(false);
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setRefreshPage((prev) => !prev);
    }
  };

  const handleFormSubmit = (data) => {
    if (+data.repaymentamount <= +ioiTotalDue) {
      setFormData(data); // Store form data
      setShowModal(true); // Open modal
    } else {
      showToast(
        "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
        "error"
      );
    }
  };

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="grid grid-cols-3 gap-x-3 ">
            <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2">
              <legend className="px-2 ml-3 bg-slate-700 rounded-sm text-white text-sm p-1">
                Interst on Interest Repayment
              </legend>
              <Input
                defaultName="repaymentdate"
                register={register}
                name="Repayment Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter repayment date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={(e) => {
                  if (
                    getFormattedDateInputDate(e) >=
                    getFormattedDateInputDate(
                      principleDueWithIntData?.last_repaymentdate
                    )
                  ) {
                    clearErrors("repaymentdate");
                    clearErrors(`repaymentdate_onChange`);
                    setSpecficDate(e);
                  } else {
                    setError("repaymentdate", {
                      type: "required",
                      message: `Repayment date is invalid`,
                    });
                  }
                }}
                defaultValue={repaymentDate || currentDateFromServer}
                setValue={setValue}
                min={getFormattedDateInputDate(
                  principleDueWithIntData?.last_repaymentdate
                )}
                max={getFormattedDateInputDate(
                  principleDueWithIntData?.currentdate
                )}
              />
              <Input
                defaultName="repaymentamount"
                register={register}
                name="Repayment Amount"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter repayment amount"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={(e) => {
                  // for form validation
                  console.table(e);
                }}
                defaultValue={0}
                setValue={setValue}
                max={ioiTotalDue}
                min={0}
              />

              <Dropdown
                defaultName="paymentmode"
                register={register}
                labelname="Payment Mode"
                required={true}
                pattern={null}
                errors={errors}
                classes={`rounded-sm text-sm w-full`}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={rePaymentFormModeDD}
                defaultValue={principleDueWithIntData?.paymentmode}
                setValue={setValue}
                setSelected={setSelectedRepaymentMode}
                selected={selectedRepaymentMode}
                maxMenuHeight={120}
              />
            </fieldset>
            {/* <fieldset className="col-span-3 px-2 grid grid-cols-3 gap-x-3 text-sm my-3 text-slate-800 ">
              <legend className="text-slate-700 text-sm underline underline-offset-2">
                Adjustment
              </legend>
              <AddPenalForm
                label="Principle Amount Pay"
                value={`₹ ${principlePaidAmount}`}
              />
              <AddPenalForm
                label="Interest Amount Pay"
                value={`₹ ${interestPaidAmount}`}
              />
            </fieldset> */}
            <fieldset className="col-span-3 px-2 grid grid-cols-1 md:grid-cols-3 gap-x-3 text-sm my-3 ">
              <legend className="text-slate-700  text-sm  underline underline-offset-2">
                Other details
              </legend>
              <TextArea
                defaultName="particulars"
                register={register}
                name="Particulars"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter particulars"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.particulars}
                setValue={setValue}
              />
              <Input
                defaultName="chequeno"
                register={register}
                name="Cheque No"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque no"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="numeric"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.chequeno}
                setValue={setValue}
              />
              <Input
                defaultName="chequedate"
                register={register}
                name="Cheque Date"
                required={null}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={(e) => {
                  {
                    setSpecficChequeDate(e);
                  }
                }}
                defaultValue={currentDateFromServer}
                setValue={setValue}
                // min={getFormattedDateInputDate(
                //   principleDueWithIntData?.last_repaymentdate
                // )}
                // max={getFormattedDateInputDate(
                //   principleDueWithIntData?.currentdate
                // )}
              />

              {/* <Input
                defaultName="transectionid"
                register={register}
                name="Transection Id"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter transection id"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={principleDueWithIntData?.transectionid}
                setValue={setValue}
              /> */}
            </fieldset>
          </div>
          <div className="flex justify-between mt-6 px-2">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={"Submit"}
              className={button}
            />
            <Button
              onClick={() => {
                setAddIOI(false);
              }}
              aria-label="Cancel District Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>

      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        title={"Receipt Repayment"}
        isLarge={true}
        isAdd={false}
      >
        <Receipt_In_Add_Repayment
          principleDueWithIntData={principleDueWithIntData}
          addRepaymentDetails={addRepaymentDetails}
          totalDueAmount={totalDueAmount}
          formData={formData}
          penalTotalDue={penalTotalDue}
          ioiTotalDue={ioiTotalDue}
          loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
        />

        <div className="flex justify-end mt-6 gap-x-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Confirm"}
            className={button}
            onClick={onSubmit}
          />
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            aria-label="Cancel Modal Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </GenericModal>
    </>
  );
};
export default AddIOIForm;
