import React, { Fragment } from "react";
import logo from "../../assets/SABCCO_128x128_white.png";
import { Link } from "react-router-dom";
// import SikkimLogo from "../../assets/footericons/Sikkim_grey_350.png";
// import RoadIcon from "../../assets/footericons/RoadeElement.png";
// import DirectionIcon from "../../assets/footericons/Direction element.png";
// import GOIclerances from "../../assets/Documents/GOIClearances.pdf";
// import FooterImg1 from "../../assets/footericons/darkBGLogo/data-gov-logo.png";
// import FooterImg2 from "../../assets/footericons/darkBGLogo/digital-india-logo.png";
// import FooterImg3 from "../../assets/footericons/darkBGLogo/india-gov-logo.png";
// import FooterImg4 from "../../assets/footericons/darkBGLogo/logo-small.png";
// import FooterImg5 from "../../assets/footericons/darkBGLogo/Meity_logo.png";
// import FooterImg6 from "../../assets/footericons/darkBGLogo/pm-india-logo.png";

export default function PublicFooter() {
  return (
    // <div className="bg-secondary">
    //   <div
    //     className="w-full"
    //     style={{
    //       // backgroundImage: `url(${RoadIcon})`,
    //       backgroundRepeat: `no-repeat`,
    //       backgroundSize: `contain`,
    //     }}
    //   >
    //     <div className="container mx-auto py-10 text-center md:text-left">
    //       <div className="flex flex-col md:flex-row items-center justify-center">
    //         {/* <img src={SikkimLogo} className="h-28" /> */}
    //         <div className="pl-6 text-white ">
    //           <span className="text-3xl font-bold ">SAB</span>
    //           <span className="text-3xl font-bold border-b-4 border-ternary">
    //             CCO
    //           </span>
    //           <span className="text-2xl font-medium">&nbsp;Portal</span>
    //           <p className="mt-4 text-slate-500 font-medium">
    //             Empowering citizen for{" "}
    //             <span className="text-ternary font-medium">Businesses </span>
    //             of tomorrow
    //           </p>
    //         </div>
    //         {/* <img src={DirectionIcon} className="h-16" /> */}
    //       </div>
    //       <div className="grid grid-cols-5 gap-4 mt-16">
    //         <div className="col-span-5 md:col-span-2 lg:col-span-1 flex justify-center">
    //           <div>
    //             <h1 className="text-blue-300 font-bold">Important Links</h1>
    //             <div className="flex flex-col mt-3">
    //               <FooterLink linktext="Public Grievance" link="/grievance" />
    //               <FooterLink linktext="Public Feedback" link="/feedback" />
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-span-5 md:col-span-2 lg:col-span-1 flex justify-center">
    //           <div>
    //             <h1 className="text-blue-300 font-bold">Policies</h1>
    //             <div className="flex flex-col mt-3">
    //               <FooterLink linktext="Terms and Conditions" link="" />
    //               <FooterLink linktext="Disclaimer" link="" />
    //               <FooterLink linktext="Privacy Policy" link="" />
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-span-5 md:col-span-2 lg:col-span-1 flex justify-center">
    //           <div>
    //             <h1 className="text-blue-300 font-bold">Useful Links</h1>
    //             <div className="flex flex-col mt-3">
    //               <FooterLink
    //                 linktext="Government of India Links"
    //                 link="https://www.india.gov.in/"
    //                 target="_blank"
    //               />
    //               <FooterLink
    //                 linktext="Sikkim State Portal"
    //                 link="https://sikkim.gov.in/"
    //                 target="_blank"
    //               />
    //               {/* <FooterLink
    //                 linktext="GOI Clearances / Reistration / Licences"
    //                 link={GOIclerances}
    //                 target="_blank"
    //               /> */}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-span-5 md:col-span-2 lg:col-span-1 flex justify-center">
    //           <div>
    //             <h1 className="text-blue-300 font-bold">FAQs</h1>
    //             <div className="flex flex-col mt-3">
    //               <FooterLink linktext="EODB FAQs" link="" />
    //               <FooterLink linktext="Setting up Business" link="" />
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-span-5 md:col-span-2 lg:col-span-1 flex justify-center">
    //           <div>
    //             <h1 className="text-blue-300 font-bold">Help Desk</h1>
    //             <div className="flex flex-col mt-3">
    //               <FooterLink linktext="Helpline no" link="" />
    //               <FooterLink linktext="E-mail" link="" />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* <div className="py-2" style={{ background: "black" }}>
    //       <div className="grid grid-cols-6 px-4 py-1">
    //         <div className="col-span-2 md:col-span-1 flex justify-center border-r border-slate-200">
    //           <a href="https://data.gov.in" target={"_blank"}>
    //             <img src={FooterImg1} />
    //           </a>
    //         </div>
    //         <div className="col-span-2 md:col-span-1 flex justify-center border-r border-slate-200">
    //           <a href="https://www.digitalindia.gov.in/" target={"_blank"}>
    //             <img src={FooterImg2} />
    //           </a>
    //         </div>
    //         <div className="col-span-2 md:col-span-1 flex justify-center md:border-r border-slate-200">
    //           <a href="https://www.india.gov.in/" target="_blank">
    //             <img src={FooterImg3} />
    //           </a>
    //         </div>
    //         <div className="col-span-2 md:col-span-1 mt-3 md:mt-0 flex justify-center border-r border-slate-200">
    //           <a href="https://sikkim.gov.in" target="_blank">
    //             <img src={FooterImg4} />
    //           </a>
    //         </div>
    //         <div className="col-span-2 md:col-span-1 mt-3 md:mt-0 flex justify-center border-r border-slate-200">
    //           <a href="https://www.meity.gov.in/" target="_blank">
    //             <img src={FooterImg5} />
    //           </a>
    //         </div>
    //         <div className="col-span-2 md:col-span-1 mt-3 md:mt-0 flex justify-center">
    //           <a href="https://www.pmindia.gov.in/en/" target="_blank">
    //             <img src={FooterImg6} />
    //           </a>
    //         </div>
    //       </div>
    //     </div> */}
    //   </div>
    // </div>
    <Fragment>
      <footer className="text-center lg:text-left bg-primary text-white ">
        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col justify-center items-center md:items-start">
              <img src={logo} className="h-16 w-16 mb-4" />
              <div>
                <h5 className="font-medium">SABCCO</h5>
                <p className=" text-xs text-center md:text-left">
                  Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                  Classes Development Corporation Ltd.
                </p>
              </div>
            </div>

            <div className="lg:col-start-3 ">
              <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                Useful links
              </h6>

              <p className=" text-center md:text-left">
                <a
                  href={`${process.env.REACT_APP_ENDPOINT_URL_ONLINE}/about-us`}
                >
                  About Us
                </a>
              </p>
              <p className="text-center md:text-left">
                <a
                  href={`${process.env.REACT_APP_ENDPOINT_URL_ONLINE}/feed-back`}
                >
                  Feedback
                </a>
              </p>
              <p className="text-center md:text-left">
                <Link to={"/terms-and-condition"}>Terms and Condition</Link>
              </p>
              <p className="text-center md:text-left">
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </p>
              <p className="text-center md:text-left">
                <Link to={"/refund-cancellation-policy"}>
                  Refund Cancellation Policy
                </Link>
              </p>
            </div>
            <div className="">
              <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                Contact
              </h6>
              <p className="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="home"
                  className="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                  ></path>
                </svg>
                Gangtok, Sikkim
              </p>
              <p className="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  className="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
                info[at]sabcco[dot]com
              </p>
            </div>
          </div>
        </div>
        <div className="text-center p-1.5 border-t border-slate-800 bg-primary text-xs ">
          <span>© 2021 Copyright: </span>
          <a
            className="text-white font-medium"
            href={process.env.REACT_APP_ENDPOINT_URL_ONLINE}
          >
            SABCCO
          </a>
        </div>
      </footer>
    </Fragment>
  );
}

const FooterLink = ({ linktext, link, target }) => {
  return (
    <a href={link} target={target} className="text-white mb-1 cursor-pointer">
      {linktext}
    </a>
  );
};
