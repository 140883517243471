import React from "react";
import { useState, useEffect } from "react";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../../utilities/dateFunctions/formatdate";
import LabelWithDetailPrint from "./LabelWithDetailPrint";

export default function InterestLedgerListPrint({
  interestLedgerList,
  addRepaymentDetails,
  principleDueWithIntData,
  totalDueAmount,
  penalTotalDue,
  ioiTotalDue,
}) {
  const [list, setList] = useState(interestLedgerList);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setList(interestLedgerList);
    }
    return () => {
      isMounted = false;
    };
  }, [interestLedgerList]);

  return (
    <>
      <section className="border px-3 py-2 text-[10px] my-3 grid grid-cols-3 bg-slate-100 relative">
        <LabelWithDetailPrint
          label={"Name"}
          value={addRepaymentDetails?.applicantname}
        />
        <LabelWithDetailPrint
          label={"Loan ID"}
          value={addRepaymentDetails?.loanee_id}
        />
        <LabelWithDetailPrint
          label={"Loan No"}
          value={addRepaymentDetails?.loan_number}
        />
        <LabelWithDetailPrint
          label={"Category"}
          value={addRepaymentDetails?.categoryname}
        />
        <LabelWithDetailPrint
          label={"Amount"}
          value={formatINRCurrency(addRepaymentDetails?.loanappliedamt)}
        />

        <LabelWithDetailPrint
          label="Int Rate (Scheme)"
          value={`${+principleDueWithIntData?.int_rate}  (${
            addRepaymentDetails?.loan_scheme || "---"
          }) `}
        />
        <LabelWithDetailPrint
          label="Calculation Start Date"
          value={
            getFormattedDateDDMMMYYYYDate(
              principleDueWithIntData?.last_repaymentdate
            ) || "---"
          }
        />
        <LabelWithDetailPrint
          label="No of Days"
          value={principleDueWithIntData?.calculationfordays}
        />

        <LabelWithDetailPrint
          label="Principle Outstanding"
          value={formatINRCurrency(
            principleDueWithIntData?.principle_outstanding_amount
          )}
        />
        <LabelWithDetailPrint
          label="Principle Due"
          value={formatINRCurrency(principleDueWithIntData?.principle_due)}
        />
        <LabelWithDetailPrint
          label="Interest Due"
          value={formatINRCurrency(
            principleDueWithIntData?.interest_due_amount
          )}
        />

        <LabelWithDetailPrint
          label="Total Due Amount"
          value={formatINRCurrency(totalDueAmount)}
        />

        {addRepaymentDetails?.categoryname === "OBC" && (
          <>
            <LabelWithDetailPrint
              label="Penal Due"
              value={formatINRCurrency(penalTotalDue)}
              noRecord={+penalTotalDue === -1 ? true : false}
              isApprox={true}
            />
            <LabelWithDetailPrint
              label="IOI Due"
              value={formatINRCurrency(ioiTotalDue)}
              noRecord={+ioiTotalDue === -1 ? true : false}
              isApprox={true}
            />
          </>
        )}
      </section>

      <table className="min-w-full divide-y divide-slate-200">
        <thead className="bg-slate-200 text-[11px] ">
          <tr>
            <th
              scope="col"
              className="px-3 py-2  text-left font-medium text-slate-900 "
            >
              TR No
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              Interest
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              Int Paid
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              PI
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              PI Paid
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              IOI
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              IOI Paid
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 "
            >
              Particulars
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900 w-[10%]"
            >
              Mode
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-800 text-[10px]">
          {list?.length > 0 ? (
            list?.map((intLedger, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-1 whitespace-nowrap">
                  {intLedger?.interest_ledger_id}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(intLedger?.tr_date)}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.int_debit || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.int_credit || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.int_pidebit || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.int_picredit || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.interest_on_interest || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.interest_on_interest_pay || 0}
                </td>
                <td className="px-3  whitespace-nowrap">
                  {intLedger?.tr_particulars || "---"}
                </td>
                <td className="px-3  whitespace-nowrap ">
                  {intLedger?.pay_mode || "---"}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
