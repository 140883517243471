import React, { Fragment, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BiTrash } from "react-icons/bi";
import { FaFilePdf } from "react-icons/fa";
import { MdOutlineEditNote } from "react-icons/md";
import Button from "../../../../reusable-components/buttons/Button";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  button,
  cancel_button,
  linkTextDanger,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";

export default function AwardsList({
  awardsList,
  setEditAwardsDetails,
  setShowAddForm,
  setPublicationId,
  setShowDelete,
}) {
  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const onClickEdit = (awards) => {
    setEditAwardsDetails(awards);
    setShowAddForm(true);
  };

  const onClickDelete = (publication_id) => {
    setPublicationId(publication_id);
    setShowDelete(true);
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${title} Document`);
    setShowPDFViewer(true);
  };

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr className="bg-slate-200">
            <th
              scope="col"
              className="px-6 py-3 font-medium text-slate-900  tracking-wider text-left"
            >
              Award Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center font-medium text-slate-900  tracking-wider"
            >
              Award Document / Image
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right font-medium text-slate-900  tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {awardsList?.length > 0 &&
            awardsList.map((awards, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex flex-col justify-center items-left">
                    <span className="font-medium">
                      {awards.publicationtitle}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm flex justify-center items-center">
                  {awards.publicationdoc.includes("image") ? (
                    <img
                      src={awards.publicationdoc}
                      className={"w-40 max-h-20 object-cover"}
                    />
                  ) : (
                    <Fragment>
                      <IconContext.Provider
                        value={{ className: "text-red-700 text-5xl" }}
                      >
                        <FaFilePdf />
                      </IconContext.Provider>
                      <Button
                        className={linkTextPrimary}
                        onClick={() =>
                          onClickView(
                            awards.publicationdoc,
                            awards.publicationtitle
                          )
                        }
                      >
                        View
                      </Button>
                    </Fragment>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {/* <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(awards)}
                      aria-label="Edit Awards Details"
                      className={linkTextPrimary}
                    >
                      Edit
                    </Button>
                  </div> */}
                  <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(awards)}
                      aria-label="Edit Awards Details"
                      className={linkTextPrimary}
                    >
                      <MdOutlineEditNote size={24} />
                    </Button>
                    <Button
                      onClick={() => onClickDelete(awards?.publicationid)}
                      aria-label="Delete User Details"
                      className={linkTextDanger}
                    >
                      <BiTrash size={20} />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </Fragment>
  );
}
