import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function MemorandumOfArticlesPage() {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Memorandum of Articles and Association</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <p className="font-semibold  text-center pb-4 text-base md:text-xl underline underline-offset-2">
          Memorandum of Articles and Association
        </p>

        <p>
          SABCCO has been established under the Sikkim Companies Act 1961 as a
          State Govt. undertaking and functions as a Corporation. It has its own
          Memorandum of Articles which governs the Objective and Organisation
          structure while its Articles of Association highlights the rules and
          procedure thereof to run the Corporation. In so far as employees and
          other service conditions are concerned, SABCCO follows the guidelines
          and rules governing the State Government employees with respect to
          their remuneration and other service matters and also anything decided
          and approved by the BOARD over and above those existing.
        </p>
      </section>
    </>
  );
}
