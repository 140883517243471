/* eslint-disable no-unused-vars */
import React from "react";

import "./animatedBg.styles.css";

const AnimatedBg = () => {
  return (
    <section>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </section>
  );
};

export default AnimatedBg;
