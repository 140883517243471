import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  SCHEME_CONFIG_URL,
  CATEGORY_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";
import {
  paymentModeDD,
  moratiumModeDD,
  repaymentModeDD,
} from "../../../../utilities/constants/constants";
import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";

const AddSchemeForm = ({
  getSchemeList,
  editSchemeDetails,
  setEditSchemeDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [formDataObj, setFormDataObj] = useState({});
  const isEdit = Object.keys(editSchemeDetails)?.length > 0;
  const [categoryList, setCategoryList] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const [selectedMoratiumMode, setSelectedMoratiumMode] = useState(null);
  const [selectedRepaymentMode, setSelectedRepaymentMode] = useState(null);
  const defaultValues = {
    schemeshortname: !isEdit ? "" : editSchemeDetails?.schemeshortname,
    schemename: !isEdit ? "" : editSchemeDetails?.schemename,
    sanctionamount: !isEdit ? "" : editSchemeDetails?.sanctionamount,
    startdate: !isEdit
      ? ""
      : getFormattedDateInputDate(editSchemeDetails?.startdate),
    enddate: !isEdit
      ? ""
      : getFormattedDateInputDate(editSchemeDetails?.enddate),
    startrange: !isEdit ? "" : editSchemeDetails?.startrange,
    endrange: !isEdit ? "" : editSchemeDetails?.endrange,
    moratiumperiod: !isEdit ? "" : editSchemeDetails?.moratiumperiod,
    repaymentperiod: !isEdit ? "" : editSchemeDetails?.repaymentperiod,
    paymentmode: !isEdit
      ? ""
      : {
          value: editSchemeDetails?.paymentmode,
          label: editSchemeDetails?.paymentmode,
        },
    moratiummode: !isEdit
      ? ""
      : {
          value: editSchemeDetails?.moratiummode,
          label: editSchemeDetails?.moratiummode,
        },
    repaymentmode: !isEdit
      ? ""
      : {
          value: editSchemeDetails?.repaymentmode,
          label: editSchemeDetails?.repaymentmode,
        },
  };

  console.log({ editSchemeDetails });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];
          for (let i = 0; i < response?.data?.categoryList?.length; i++) {
            let categoryObj = {
              value: response.data.categoryList[i].categoryid,
              label: response.data.categoryList[i].categoryname,
            };
            categoryDD.push(categoryObj);
          }
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getInterestWihtCategoryName = (category_name) => {
    let interestRate = 0;

    console.log({ category_name });

    interestRate =
      editSchemeDetails?.scheme_category?.filter(
        (obj) => obj?.key === category_name
      )[0]?.value || 0;

    console.log({ interestRate });

    return interestRate;
  };

  const onSubmit = async (data) => {
    let intrate = [];

    for (let i = 0; i <= Object.keys(data).length; i++) {
      if (Object.keys(data)[i]?.toString()?.includes("intrate")) {
        let dataObj = Object.keys(data)[i].toString()?.split("_");

        if (parseInt(Object.values(data)[i]) > 0) {
          let intrateObj = {
            interestrate: parseInt(Object.values(data)[i]),
            categoryid: parseInt(dataObj[1]),
          };
          intrate.push(intrateObj);
        }
      }
    }
    data.categoryid = 1;
    data.interestrate = intrate;

    try {
      let response = "";
      if (!isEdit) {
        formDataObj.schemeshortname = data?.schemeshortname;
        formDataObj.schemename = data?.schemename;
        formDataObj.sanctionamount = data?.sanctionamount;
        formDataObj.startdate = data?.startdate;
        formDataObj.paymentperiod = data?.paymentperiod;
        formDataObj.paymentmode = data?.paymentmode?.value;
        formDataObj.moratiumperiod = data?.moratiumperiod;
        formDataObj.moratiummode = data?.moratiummode?.value;
        formDataObj.repaymentperiod = data?.repaymentperiod;
        formDataObj.repaymentmode = data?.repaymentmode?.value;
        formDataObj.startrange = data?.startrange;
        formDataObj.endrange = data?.endrange;
        formDataObj.createdby = auth.userid;
        formDataObj.interestrate = JSON.stringify(data?.interestrate);
        formDataObj.enddate = data?.enddate;

        response = await axiosPrivate.post(SCHEME_CONFIG_URL, formDataObj, {
          signal: controller.signal,
        });
      } else {
        formDataObj.schemeid = editSchemeDetails?.schemeid;
        formDataObj.schemeshortname = data?.schemeshortname;
        formDataObj.schemename = data?.schemename;
        formDataObj.sanctionamount = data?.sanctionamount;
        formDataObj.startdate = data?.startdate;
        formDataObj.paymentmode = data?.paymentmode?.value;
        formDataObj.moratiumperiod = data?.moratiumperiod;
        formDataObj.moratiummode = data?.moratiummode?.value;
        formDataObj.repaymentperiod = data?.repaymentperiod;
        formDataObj.repaymentmode = data?.repaymentmode?.value;
        formDataObj.startrange = data?.startrange;
        formDataObj.endrange = data?.endrange;
        formDataObj.updatedby = auth.userid;
        formDataObj.interestrate = JSON.stringify(data?.interestrate);
        formDataObj.enddate = data?.enddate;

        response = await axiosPrivate.put(SCHEME_CONFIG_URL, formDataObj, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditSchemeDetails({});
          showToast("Scheme has been updated successfully", "success");
        } else {
          showToast("Scheme has been added successfully", "success");
        }
        getSchemeList();
      } else {
        if (isEdit) {
          if (response.data.updateSchemeDetails.includes("unique_scheme")) {
            showToast(
              "Scheme already exists. Cannot insert duplicate scheme",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveSchemeDetails.includes("unique_scheme")) {
            showToast(
              "Scheme already exists. Cannot insert duplicate scheme",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <section>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-x-3">
          <Input
            defaultName="schemeshortname"
            register={register}
            name="Scheme Short Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter scheme short name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.schemeshortname}
            setValue={setValue}
          />
          <Input
            defaultName="schemename"
            register={register}
            name="Scheme Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter scheme name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.schemename}
            setValue={setValue}
          />
          <Input
            defaultName="sanctionamount"
            register={register}
            name="Sanction Amount"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter sanction amount name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.sanctionamount}
            setValue={setValue}
          />
          <Input
            defaultName="startdate"
            register={register}
            name="Start Date"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter start date"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="date"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.startdate}
            setValue={setValue}
          />

          <Input
            defaultName="enddate"
            register={register}
            name="End Date"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter end date"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="date"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.enddate}
            setValue={setValue}
          />
          <Input
            defaultName="startrange"
            register={register}
            name="Start Range"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter start range"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.startrange}
            setValue={setValue}
          />
          <Input
            defaultName="endrange"
            register={register}
            name="End Range"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter end range"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.endrange}
            setValue={setValue}
          />
          {/* <Input
            defaultName="paymentperiod"
            register={register}
            name="Payment period"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter payment period"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.paymentperiod}
            setValue={setValue}
          /> */}
          <Dropdown
            defaultName="paymentmode"
            register={register}
            labelname="Payment Mode"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-lg text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={paymentModeDD}
            defaultValue={defaultValues.paymentmode}
            setValue={setValue}
            setSelected={setSelectedPaymentMode}
            selected={selectedPaymentMode}
            maxMenuHeight={120}
          />
          {/* <Input
            defaultName="moratiumperiod"
            register={register}
            name="Moratorium period"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter Moratorium period"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.moratiumperiod}
            setValue={setValue}
          />
          <Dropdown
            defaultName="moratiummode"
            register={register}
            labelname="Moratorium Mode"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-lg text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={moratiumModeDD}
            defaultValue={defaultValues.moratiummode}
            setValue={setValue}
            setSelected={setSelectedMoratiumMode}
            selected={selectedMoratiumMode}
            maxMenuHeight={120}
          /> */}
          <Input
            defaultName="repaymentperiod"
            register={register}
            name="Repayment period"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter repayment period"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.repaymentperiod}
            setValue={setValue}
          />
          <Dropdown
            defaultName="repaymentmode"
            register={register}
            labelname="Repayment Mode"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-lg text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={repaymentModeDD}
            defaultValue={defaultValues.repaymentmode}
            setValue={setValue}
            setSelected={setSelectedRepaymentMode}
            selected={selectedRepaymentMode}
            maxMenuHeight={120}
          />
          <Fragment>
            {categoryList?.map((category) => {
              return (
                <div key={category.value}>
                  <Input
                    defaultName={`intrate_${category?.value}`}
                    register={register}
                    name={category?.label}
                    required={false}
                    pattern={null}
                    errors={errors}
                    placeholder="Enter interest rate"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="number"
                    classes={`rounded-lg px-3 py-2 text-sm w-full`}
                    onChangeInput={null}
                    defaultValue={
                      isEdit ? getInterestWihtCategoryName(category?.label) : 0
                    }
                    setValue={setValue}
                  />
                </div>
              );
            })}
          </Fragment>
        </div>
        <div className="flex justify-between mt-4">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditSchemeDetails({});
              setValue("schemename", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Scheme Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddSchemeForm;
