import React from "react";
import { Link } from "react-router-dom";

export default function MobileSubMenu({
  item,
  index,
  setIsSubMenuActive,
  setIsActive,
}) {
  return (
    <ul className="bg-white">
      <li className={`nav-item`} key={index}>
        {item.url.includes("http") ? (
          <a
            className="bg-white hover:bg-secondary py-2 px-4 block whitespace-no-wrap text-sm text-black hover:text-white"
            href={item.url}
            target="_blank"
          >
            {item.title}
          </a>
        ) : (
          <Link to={`${item.url}`}>
            <div
              className="bg-white hover:bg-secondary py-2 px-4 block whitespace-no-wrap text-sm text-black hover:text-white"
              onClick={() => {
                setIsSubMenuActive(false);
                setIsActive(false);
                window.scrollTo(0, 0);
              }}
            >
              {item.title}
            </div>
          </Link>
        )}
      </li>
    </ul>
  );
}
