import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RiRefreshLine } from "react-icons/ri";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import H4 from "../../../reusable-components/headings/H4";

export default function PieChartReport({ dataObj, title, ...rest }) {
  //   no_approved: "7601";
  //   no_disbursed: "7599";
  //   no_pending: "11";
  //   no_revert: "2";

  const [pieChartWidth, setWindowWidth] = useState();

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    if (+window?.innerWidth > 768) {
      setWindowWidth(+window?.innerWidth / 8 || 100);
    } else {
      setWindowWidth(+window?.innerWidth / 2.5 || 100);
    }
  }, [+window.innerWidth]);

  return (
    <>
      <section className="bg-slate-50 w-full border rounded-sm text-slate-700 p-3">
        <div className="text-center">
          <H4>{title}</H4>
        </div>

        <div className="grid grid-cols-2 gap-3 items-center">
          <div className="text-xs w-fit">
            <ResponsiveContainer width={pieChartWidth} height={pieChartWidth}>
              <PieChart>
                <Pie
                  data={dataObj}
                  cx="45%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  // outerRadius={
                  //   50
                  //   // +windowWidth < 150 ? 70 : +windowWidth < 300 ? 90 : null
                  // }
                  fill="#8884d8"
                  dataKey="value"
                >
                  {dataObj.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            {dataObj
              ? dataObj?.map((obj) => (
                  <div className="text-xs md:text-sm text-slate-800 w-full flex gap-3 text-start my-1 0">
                    <div className="flex items-center gap-2">
                      {/* <div className={` w-3 h-3  bg-[${obj?.color}]  `} /> */}

                      <div
                        className="w-3 h-3"
                        style={{
                          backgroundColor: obj.color ? obj?.color : "green",
                        }}
                      />

                      {obj?.name}
                    </div>
                    <div className="font-medium">
                      {obj?.value || obj?.value === 0 ? (
                        obj?.value
                      ) : (
                        <>
                          <div className="pt-1">
                            <RiRefreshLine
                              size={14}
                              className={"animate-spin delay-300"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
    </>
  );
}
