/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../../authentication/hooks/useAuth";
import AddDefaulterDocs from "./addDefaulterDocs.component";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import DefaulterDocsList from "./defaulterDocsList.componenet";
import { DEFAULTER_DOCS_URL } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import showToast from "../../../../utilities/notification/NotificationModal";
import { Paging } from "../../../../reusable-components/paging/Paging";
import { IoReturnDownBack } from "react-icons/io5";
import { FaFileAlt, FaFilePdf } from "react-icons/fa";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function ConfigDefaulterDocs() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [showAddForm, setShowAddForm] = useState(false);
  const [defaulterDocsList, setDefaulterDocsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [viewDefaulterDocs, setViewDefaulterDocs] = useState(false);

  const [defaulterDocsDetails, setDefaulterDocsDetails] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const [defaulterDocsId, setDefaulterDocsId] = useState();

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  const getDefaulterDocsList = async (page_no, page_size) => {
    try {
      const response = await axiosPrivate.get(
        `${DEFAULTER_DOCS_URL}`,
        {
          params: {
            pgno: page_no || pageNo || 1,
            pagesize: page_size || pageSize?.value || 10,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setDefaulterDocsList(
          response?.data?.defaulterDocsList[0]?.get_defaulter_docs_details
            ?.defaulter_docs
        );
      }

      if (response?.status === 202) {
        showToast("No defaulter List found in the system", "error");
      }

      const totalNumberOfDocsList =
        +response?.data?.defaulterDocsList[0]?.get_defaulter_docs_details
          ?.listcount?.list_count;

      // getting total Number Of pages
      setNumberOfPages(
        Math.ceil(totalNumberOfDocsList / (pageSize?.value || 10))
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const deleteDefaulterDocs = async () => {
    try {
      const data = {};
      data.defaulter_docs_id = defaulterDocsId;
      const response = await axiosPrivate.post(
        `${DEFAULTER_DOCS_URL}/delete`,
        data,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        showToast("Defaulter Document deleted", "success");
      }
      if (response.status === 202) {
        showToast(
          "Processing request, if the document is not deleted please try again later.",
          "warning"
        );
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getDefaulterDocsList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    getDefaulterDocsList(pageNo, pageSize?.value);
  }, [pageNo, pageSize]);

  return (
    <>
      <Dashboard sidebarType={auth?.rolename}>
        <ToastContainer />

        <GenericModal
          open={showAddForm}
          setOpen={setShowAddForm}
          title="Upload Defaulters Document"
          isAdd={true}
          isLarge={true}
        >
          <AddDefaulterDocs
            setShowAddForm={setShowAddForm}
            defaulterDocsList={defaulterDocsList}
            getDefaulterDocsList={getDefaulterDocsList}
          />
        </GenericModal>

        {viewDefaulterDocs ? (
          <>
            <div className="flex justify-between">
              <p className="font-semibold text-xl underline underline-offset-2 text-secondary">
                View Document Details{" "}
              </p>
              <p
                className="flex justify-between items-center border p-2 rounder-md gap-2 cursor-pointer hover:underline hover:bg-slate-100"
                onClick={() => setViewDefaulterDocs(false)}
              >
                <span>
                  <IoReturnDownBack />
                </span>
                <span className="">back</span>{" "}
              </p>
            </div>

            <div className="mt-6 border p-6 bg-slate-100">
              <p className="text-sm font-semibold">
                Date : {defaulterDocsDetails?.date}
              </p>
              <p className="text-sm font-semibold pt-3">
                Particulars : {defaulterDocsDetails?.particulars}
              </p>
              <p className="text-sm font-semibold pt-3">
                Defaulter Duration : {defaulterDocsDetails?.defaulter_type}
              </p>
              <p className="flex items-center gap-4 text-sm font-semibold pt-3">
                Document :{" "}
                <a
                  href={`${defaulterDocsDetails?.document_url}`}
                  target="_blank"
                >
                  <FaFileAlt size={60} className="p-2" />
                  <span className="text-sm italic underline hover:text-blue-800">
                    (click to view/download)
                  </span>
                </a>
              </p>
            </div>
          </>
        ) : (
          <>
            <MasterConfigHeaderWithoutDeleteWrapper
              headerTitle={"Defaulter Document"}
              setShowAddForm={setShowAddForm}
            />
            <div className="grid grid-cols-1">
              <DefaulterDocsList
                defaulterDocsList={defaulterDocsList}
                setViewDefaulterDocs={setViewDefaulterDocs}
                setDefaulterDocsDetails={setDefaulterDocsDetails}
                setDefaulterDocsId={setDefaulterDocsId}
                setShowDelete={setShowDelete}
              />

              <Paging
                maxMenuHeight={100}
                selected={pageSize}
                setSelected={setPageSize}
                pageNo={pageNo}
                onPageChangeHandler={onPageChangeHandler}
                numberOfPages={numberOfPages}
              />
            </div>

            <DeleteModal
              open={showDelete}
              setOpen={setShowDelete}
              message={
                "Defaulter Document would be deleted permenantly. Are you sure?"
              }
              onDelete={deleteDefaulterDocs}
              setShowDelete={setShowDelete}
            />
          </>
        )}
      </Dashboard>
    </>
  );
}
