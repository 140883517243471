import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdRemoveRedEye } from "react-icons/md";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import { BiChevronLeft } from "react-icons/bi";
import { BsDot } from "react-icons/bs";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import OTSNoticeTemplate from "./OTSNoticeTemplate";
import { useEffect } from "react";
import { useState } from "react";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import { useForm } from "react-hook-form";

export default function OtsNoticeList({
  loaneeDetails,
  setShowOTSNoticeList,
  setShowGenerateOTSNotice,
  noticeList,
  setViewNotice,
  setRefNumber,
  setSelectedDate,
  viewNotice,
  selectedDate,
  refNumber,
  setRefresh,
  otsLoaneeDetails,
  currentFiscalYear,
  otsLoaneeDue,
  selectedGuarantor,
  setSelectedGuarantor,
  guarantorNamesDD,
  copyToExist,
  setCopyToExist,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const handleviewIndirectDeptNoticeList = (loaneeDetails) => {
    setViewNotice(true);
    setRefNumber(loaneeDetails?.reference_number);
    setSelectedDate(loaneeDetails?.generated_date);
  };

  // some days ahead to selectedDate or few days ahead from notice generated date
  const [daysAheadToSelectedDate, setDaysAheadToSelectedDate] =
    useState(selectedDate);

  // Function to add days to a date

  const addDaysToDate = (dateString, daysToAdd) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  };

  // Function to handle adding 15 days to the selected date
  const handleAdd15Days = () => {
    const newDate = addDaysToDate(selectedDate, 15);
    setDaysAheadToSelectedDate(newDate.toISOString().split("T")[0]); // Convert Date to string in 'yyyy-mm-dd' format
  };

  useEffect(() => {
    handleAdd15Days();
  });

  return (
    <>
      {viewNotice ? (
        <>
          <div className="flex justify-between">
            <H2withIcon>
              <MdAssignmentTurnedIn />
              <span className="pl-1.5">One Time Settlement Notice</span>
            </H2withIcon>

            <div className="flex items-center gap-4 ">
              <PrintReport
                reportTitle={"One Time Settlement Notice"}
                buttonName={"One Time Settlement Notice"}
              >
                <OTSNoticeTemplate
                  refNumber={refNumber}
                  selectedDate={selectedDate}
                  loaneeDetails={loaneeDetails}
                  otsLoaneeDetails={otsLoaneeDetails}
                  currentFiscalYear={currentFiscalYear}
                  daysAheadToSelectedDate={daysAheadToSelectedDate}
                  otsLoaneeDue={otsLoaneeDue}
                  selectedGuarantor={selectedGuarantor}
                  setSelectedGuarantor={setSelectedGuarantor}
                  guarantorNamesDD={guarantorNamesDD}
                  copyToExist={copyToExist}
                  setCopyToExist={setCopyToExist}
                />
              </PrintReport>

              <div
                className="flex items-center cursor-pointer hover:underline underline-offset-2 "
                onClick={() => {
                  setViewNotice(false);
                  setRefNumber(null);
                  setRefresh((prev) => !prev);
                  setSelectedGuarantor(null);
                  setCopyToExist(null);
                }}
              >
                <BiChevronLeft size={24} /> back
              </div>
            </div>
          </div>

          <div className="py-4">
            <>
              <Dropdown
                defaultName="copyTo"
                register={register}
                labelname="Copy to"
                required={false}
                pattern={null}
                errors={errors}
                classes={`rounded-sm  py-2 text-sm w-[25%] `}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={guarantorNamesDD}
                defaultValue={null}
                setValue={setValue}
                setSelected={setSelectedGuarantor}
                selected={selectedGuarantor}
                maxMenuHeight={120}
              />

              <OTSNoticeTemplate
                refNumber={refNumber}
                selectedDate={selectedDate}
                loaneeDetails={loaneeDetails}
                otsLoaneeDetails={otsLoaneeDetails}
                currentFiscalYear={currentFiscalYear}
                daysAheadToSelectedDate={daysAheadToSelectedDate}
                otsLoaneeDue={otsLoaneeDue}
                selectedGuarantor={selectedGuarantor}
                setSelectedGuarantor={setSelectedGuarantor}
                guarantorNamesDD={guarantorNamesDD}
                copyToExist={copyToExist}
                setCopyToExist={setCopyToExist}
              />
            </>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between">
            <H2withIcon>
              <MdAssignmentTurnedIn />
              <span className="pl-1.5">One Time Notice Details</span>
            </H2withIcon>

            <div className="flex items-center ">
              <div className="-mb-4 mr-3 ">
                {loaneeDetails?.loan_status === "Active" ? (
                  <div className="flex gap-4">
                    <AddButton
                      label="Generate One Settlement Notice"
                      onclick={() => {
                        setShowGenerateOTSNotice(true);
                        setRefNumber(null);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className="flex items-center cursor-pointer hover:underline underline-offset-2 "
                onClick={() => {
                  setRefNumber(null);
                  setShowOTSNoticeList(false);
                  setRefresh((prev) => !prev);
                  // setNoticeList(null);
                }}
              >
                <BiChevronLeft size={24} /> back
              </div>
            </div>
          </div>

          {/* -- Loanee Info-- */}
          <div className="my-4">
            <div className=" text-sm flex items-center flex-wrap gap-1">
              <div>
                Loan Id:{" "}
                <span className="font-semibold">
                  {loaneeDetails?.loanee_id}
                </span>
              </div>
              <BsDot size={14} />
              <div>
                Loan Number:{" "}
                <span className="font-semibold">
                  {loaneeDetails?.loan_number}
                </span>
              </div>
              <BsDot size={14} />
              <div>
                Loanee Name:{" "}
                <span className="font-semibold">
                  {loaneeDetails?.applicantname}
                </span>
              </div>
            </div>
          </div>

          {loaneeDetails?.loan_status === "Active" ? (
            <>
              <div className="pt-5">
                <TableWrapper>
                  <thead className="bg-white text-sm sticky top-0 z-0">
                    <tr className="bg-slate-200 ">
                      <th
                        scope="col"
                        className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                      >
                        SL.No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                      >
                        Reference Number
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                      >
                        Generated Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                      >
                        Notice Type
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
                    {noticeList?.length > 0 &&
                      noticeList?.map((noticeDetailsObj, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 1 ? "bg-slate-100" : null}
                        >
                          <td className="px-6 py-2 whitespace-nowrap text-sm  text-slate-800 font-medium ">
                            <div className=" px-3 py-1 ">{index + 1}</div>
                          </td>

                          <td className="px-6 py-2 whitespace-nowrap text-sm">
                            {noticeDetailsObj?.reference_number}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap text-sm">
                            {noticeDetailsObj?.generated_date}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap text-sm">
                            {noticeDetailsObj?.notice_type}
                          </td>

                          <td className="px-6 py-2 whitespace-nowrap text-sm w-24 ">
                            <span
                              className="cursor-pointer "
                              onClick={() =>
                                handleviewIndirectDeptNoticeList(
                                  noticeDetailsObj
                                )
                              }
                            >
                              <span className="text-primary">
                                <MdRemoveRedEye size={18} />
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </TableWrapper>
              </div>
            </>
          ) : (
            <div>
              <p className="border p-3 bg-slate-100">Inactive Loanee </p>
            </div>
          )}
        </>
      )}
    </>
  );
}
