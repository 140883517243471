import { useEffect, useState } from "react";
import useAxiosPrivate from "../../authentication/hooks/useAxiosPrivate";

const useGetWithoutParameter = (url) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(url, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setResponse(response?.data);
      }
      response.status === 202 && setError("Data not found in the system");
    } catch (error) {
      console.error("Error on list", error);
      if (!error?.response) {
        setError("No Server Response");
      } else if (error.response.status === 422) {
        setError("Some of the required inputs were not provided");
      } else {
        setError(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getData();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url]);

  return { response, error, loading };
};

export default useGetWithoutParameter;
