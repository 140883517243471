import React from "react";
import useAuth from "../../authentication/hooks/useAuth";
import Dashboard from "../../common/Dashboard";
import DashboardConfig from "./Dashboard/DashboardConfig";

export default function SystemAdminDashboard() {
  const { auth } = useAuth();
  return (
    <Dashboard sidebarType={"Super Admin"}>
      <DashboardConfig />
    </Dashboard>
  );
}
