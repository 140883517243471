import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function SubMenu({ items }) {
  const location = useLocation();
  return (
    <ul className="transition-all duration-200 ease-in transform origin-center -translate-x-2 -translate-y-3 group-hover:translate-y-0 group-hover:translate-x-0 absolute invisible opacity-0 group-hover:opacity-100 rounded-lg pt-3 group-hover:visible max-w-max w-screen text-left shadow-xl rounded-md">
      {items &&
        items.map((item, index) => (
          <li className="" key={index}>
            {item.url.includes("http") ? (
              <a
                className="bg-white hover:bg-secondary py-2 px-4 block whitespace-no-wrap text-sm text-black hover:text-white"
                href={item.url}
                target="_blank"
              >
                {item.title}
              </a>
            ) : (
              <Link to={`${item.url}`}>
                <div
                  className={`bg-slate-200  py-2 px-6 block whitespace-no-wrap text-xs  ${
                    location.pathname === item.url
                      ? "text-primary"
                      : "text-secondary"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {item.title}
                </div>
              </Link>
            )}
          </li>
        ))}
    </ul>
  );
}
