// developed by Jesson

import React, { useEffect } from "react";
import { useState } from "react";

import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

import samplealbumcover from "../../../assets/officemeetsample.jpg";
import PhotosCard from "../../../reusable-components/cards/PhotosCard";
import PhotoViewInside from "./PhotoViewInside";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../authentication/hooks/useAuth";
import showToast from "../../../utilities/notification/NotificationModal";
import { PHOTOGALLERY_LIST_URL } from "../../../api/api_routing_urls";

const MediaPhotoGallery = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();
  const [selectedPhotoTitle, setSelectedPhotoTitle] = useState("");
  const [selectedPhotoArray, setSelectedPhotoArray] = useState({});
  const [mediumAlbumPhotos, setMediaAlbumPhotos] = useState([]);

  const getMediaAlbumPhotos = async () => {
    try {
      const response = await axiosPrivate.get(
        `${PHOTOGALLERY_LIST_URL}/mediaPhotos`,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setMediaAlbumPhotos(response?.data?.mediaAlbumPhotos?.media);
      }
      response?.status === 202 &&
        showToast(
          "No Media Photos Found list in the System found in the system",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getMediaAlbumPhotos();
  }, []);

  const photoGalleryObj = [
    {
      photo_title: "Sample Title 1",
      photo_description: "Sample Description should be less than 20 words.",
      photo_image: { samplealbumcover },
      photos_list: [
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
      ],
    },
    {
      photo_title: "Sample Title 2",
      photo_description: "Sample Description should be less than 20 words.",
      photo_image: { samplealbumcover },
      photos_list: [
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
      ],
    },
    {
      photo_title: "Sample Title 3",
      photo_description: "Sample Description should be less than 20 words.",
      photo_image: { samplealbumcover },
      photos_list: [
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
      ],
    },
    {
      photo_title: "Sample Title 4",
      photo_description: "Sample Description should be less than 20 words.",
      photo_image: { samplealbumcover },
      photos_list: [
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
        { samplealbumcover },
      ],
    },
  ];

  return (
    <>
      <div className="bg-white">
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
            <div className="text-center">
              <H1>Media Gallery</H1>
            </div>
          </div>
        </HeroBanner>
        {/* {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container mx-auto my-6">
          <div className="leading-6" />
          Photo Gallery
        </div>
      )} */}
        <div className="container mx-auto my-20">
          <div className="">
            {selectedPhotoTitle === "" ? (
              <>
                <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31] pb-10">
                  Photo Albums
                </div>
                <div className="grid lg:grid-cols-3  gap-8 px-6 py-6">
                  {mediumAlbumPhotos?.length > 0 ? (
                    mediumAlbumPhotos?.map((photo) => (
                      <PhotosCard
                        photo_title={photo?.media_album_title}
                        photo_description={photo?.media_album_description}
                        photo_image={
                          photo?.media_item_url_obj?.length > 0
                            ? photo?.media_item_url_obj[0]?.media_item_url
                            : null
                        }
                        photos_list={
                          photo?.media_item_url_obj?.length > 0
                            ? photo?.media_item_url_obj
                            : null
                        }
                        setSelectedPhotoArray={setSelectedPhotoArray}
                        setSelectedPhotoTitle={setSelectedPhotoTitle}
                      />
                    ))
                  ) : (
                    <>
                      <p>No Image Gallery found</p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <PhotoViewInside
                photo_title={selectedPhotoTitle}
                selectedPhotoArray={selectedPhotoArray}
                setSelectedPhotoTitle={setSelectedPhotoTitle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPhotoGallery;
