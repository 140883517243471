import React from "react";
import { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { LEDGER_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import H5 from "../../../../reusable-components/headings/H5";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function InterestLedgerList({
  ledgerList,
  setInterestLedgerList,
  setRefreshPage,
  ref,
}) {
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const { auth } = useAuth();

  const [list, setList] = useState(ledgerList);
  const [showDelete, setShowDelete] = useState(false);
  const [ledgerDetailToDelete, setLedgerDetailToDelete] = useState();
  const [transectionAmount, setTransectionAmount] = useState({});

  const getTotalTransectionAmount = () => {
    let totalInterestAmount = 0;
    let totalIntPayAmount = 0;
    let totalPenalAmount = 0;
    let totalPenalPayAmount = 0;
    let totalIOIAmount = 0;
    let totalIOIPayAmount = 0;
    ledgerList?.length > 0 &&
      ledgerList?.map((ledger) => {
        totalInterestAmount += +ledger?.int_debit;
        totalIntPayAmount += +ledger?.int_credit;
        totalPenalAmount += +ledger?.int_pidebit;
        totalPenalPayAmount += +ledger?.int_picredit;
        totalIOIAmount += +ledger?.interest_on_interest;
        totalIOIPayAmount += +ledger?.interest_on_interest_pay;
      });
    setTransectionAmount({
      totalInterestAmount: totalInterestAmount,
      totalIntPayAmount: totalIntPayAmount,
      totalPenalAmount: totalPenalAmount,
      totalPenalPayAmount: totalPenalPayAmount,
      totalIOIAmount: totalIOIAmount,
      totalIOIPayAmount: totalIOIPayAmount,
    });
  };

  const deleteLedgerHandler = async () => {
    try {
      const dataObj = {};
      const delUrl = LEDGER_CONFIG_URL + "/deleteIntLedger";
      dataObj.interest_ledger_id = ledgerDetailToDelete?.interest_ledger_id;
      dataObj.deletedby = auth?.userid;

      const response = await axiosPrivate.post(delUrl, dataObj, {
        signal: controller.signal,
      });

      if (response.status === 200 || response?.data?.ledgerDel === true) {
        let tempList = undefined;
        tempList = list?.filter(
          (obj) =>
            obj.interest_ledger_id !== ledgerDetailToDelete?.interest_ledger_id
        );
        setList(tempList);
        setInterestLedgerList(tempList);
        setRefreshPage((prev) => !prev);

        showToast("Ledger detail has been deleted successfully", "success");
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setList(ledgerList);
      getTotalTransectionAmount();
    }
    return () => {
      isMounted = false;
    };
  }, [ledgerList]);

  return (
    <>
      <TableWrapper>
        <thead className="bg-slate-200 text-sm my-3 sticky top-0" ref={ref}>
          <tr>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              TR No
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              Interest
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              Interest Paid
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              Penal Interest
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              PI Paid
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              IOI
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              IOI Paid
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5"
            >
              Particulars
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider leading-5 w-[10%]"
            >
              Mode
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
          {list?.length > 0 ? (
            list?.map((intLedger, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-2 whitespace-nowrap">
                  {intLedger?.interest_ledger_id}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(intLedger?.tr_date)}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.int_debit) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.int_credit) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.int_pidebit) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.int_picredit) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.interest_on_interest) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {formatINRCurrency(intLedger?.interest_on_interest_pay) || 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {intLedger?.tr_particulars || "---"}
                </td>
                <td className="px-3 py-2 whitespace-nowrap flex justify-between">
                  {intLedger?.pay_mode || "---"}

                  {auth?.rolename === "Assistant Manager" &&
                  +list?.length === +index + 1 ? (
                    <div
                      className="cursor-pointer text-red-500 hover:text-red-600"
                      onClick={() => {
                        setLedgerDetailToDelete(intLedger);
                        setShowDelete(true);
                      }}
                    >
                      <BiTrash size={16} />
                    </div>
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message={`The ledger with following detail
         would be deleted. Are you sure?`}
        onDelete={deleteLedgerHandler}
      >
        <div className="text-xs text-slate-700 flex flex-wrap gap-x-6 bg-slate-50 p-2 border mt-3">
          <div>
            Date:{" "}
            <span className="font-medium text-sm">
              {getFormattedDateDDMMMYYYYDate(ledgerDetailToDelete?.tr_date)}
            </span>
          </div>
          <div>
            Mode:{" "}
            <span className="font-medium text-sm">
              {ledgerDetailToDelete?.pay_mode}
            </span>
          </div>
          <div>
            Int Pay:{" "}
            <span className="font-medium text-sm">
              ₹{" "}
              {+ledgerDetailToDelete?.int_credit ||
                0 + +ledgerDetailToDelete?.int_picredit ||
                0 + +ledgerDetailToDelete?.interest_on_interest_pay ||
                0}
            </span>
          </div>
        </div>
      </DeleteModal>

      <section className="p-3 bg-ternary bg-opacity-30 text-sm mt-6 grid md:grid-cols-2">
        <div className="col-span-2">
          <H5>Interest Ledger Details</H5>
        </div>
        <div className="grid grid-cols-1">
          <p>
            Total Interest Amount:
            <span className="font-semibold ml-2">
              {formatINRCurrency(transectionAmount?.totalInterestAmount)}
            </span>
          </p>
          <p>
            Total Interest Pay Amount:
            <span className="font-semibold ml-2">
              {formatINRCurrency(transectionAmount?.totalIntPayAmount)}
            </span>
          </p>
          {/* <p>
          Total Penal Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalPenalAmount}
          </span>
        </p> */}
          <p>
            Total Penal Pay Amount:
            <span className="font-semibold ml-2">
              {formatINRCurrency(transectionAmount?.totalPenalPayAmount)}
            </span>
          </p>
          {/* <p>
          Total IOI Amount:
          <span className="font-semibold ml-2">
            ₹{transectionAmount?.totalIOIAmount}
          </span>
        </p> */}
          <p>
            Total IOI Pay Amount:
            <span className="font-semibold ml-2">
              {formatINRCurrency(transectionAmount?.totalIOIPayAmount)}
            </span>
          </p>
        </div>
      </section>
    </>
  );
}
