import React from "react";
import { back_btn } from "../../theme/lightTheme";
import { MdOutlineArrowBackIos } from "react-icons/md";

export default function BackButton({ label, onclick }) {
  return (
    <button onClick={onclick} className={back_btn}>
      <MdOutlineArrowBackIos size={16} />
      <span className="ml-1 hover:underline underline-offset-2">{label}</span>
    </button>
  );
}
