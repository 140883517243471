import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { CSVLink } from "react-csv";

export default function MonthlyReportList({ monthlyReportList, ref }) {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200 text-sm leading-5 sticky top-0"
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              SI No
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            {/* <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Loan No
          </th> */}
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Payment Mode
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Paid
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Interest Paid
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y text-xs divide-slate-100 text-slate-600">
          {monthlyReportList?.length > 0 ? (
            monthlyReportList?.map((monthlyObj, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.loanee_id}
                </td>
                {/* <td className="px-6 py-2 whitespace-nowrap">
                {monthlyObj?.loan_number}
              </td> */}
                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.applicantname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.pay_mode}
                </td>

                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.tr_amount}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {monthlyObj?.int_credit}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={monthlyReportList}
          target="_blank"
          filename={`SABCCO-MonthlyReportList-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}
