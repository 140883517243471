import React, { Fragment, useState } from "react";
import { IconContext } from "react-icons";
import { FaFilePdf, FaUser } from "react-icons/fa";
import Button from "../../../reusable-components/buttons/Button";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { cancel_button, linkTextPrimary } from "../../../theme/lightTheme";
import { FiUser } from "react-icons/fi";
import { MdEmail, MdLocationPin } from "react-icons/md";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../utilities/dateFunctions/formatdate";

export default function FeedbackList({ feedbackList }) {
  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr className="bg-slate-200">
            <th
              scope="col"
              className="px-3 py-3 text-left font-medium text-slate-900 tracking-wider w-2/6"
            >
              Feedback From
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left font-medium text-slate-900 tracking-wider"
            >
              Feedback Message
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left font-medium text-slate-900 tracking-wider w-1/6"
            >
              Dated
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {feedbackList.length > 0 &&
            feedbackList.map((feedback, index) => (
              <tr key={index}>
                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  <p className="flex items-center gap-1">
                    <FaUser size={14} /> {feedback?.fullname}
                  </p>
                  <p className="flex items-center gap-1 text-xs text-slate-500">
                    <MdEmail size={14} /> {feedback?.emailaddress}
                  </p>
                  <p className="flex items-center gap-1 text-xs text-slate-500">
                    <MdLocationPin size={14} /> {feedback?.fulladdress}
                  </p>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  {feedback?.feedmessage}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  <p className="text-xs">
                    {getFormattedDateDDMMMYYYYDate(feedback?.createdat)}
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </Fragment>
  );
}
