import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  SCHEME_CONFIG_URL,
  USER_MANAGEMENT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import { button, delete_button } from "../../../../theme/lightTheme";
import { createRemarkObj } from "../../../../utilities/generalFunctions/createObject/createRemarkObj";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import H5 from "../../../../reusable-components/headings/H5";
import Dropzone from "react-dropzone";
import { AiOutlineCloudUpload, AiOutlineFile } from "react-icons/ai";
import { useMemo } from "react";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";

const LoaneeSanctionForm = ({
  applicantDetail,
  setViewApplicantDetail,
  setRefresh,
  isPostSacntion,
  loaneeSanctionDetail,
}) => {
  const loan_due_amount =
    +loaneeSanctionDetail?.sanctionDetail?.loanappliedamt -
    +loaneeSanctionDetail?.sanctionDetail?.disbursed_amount;

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const { auth } = useAuth();

  let FORWARD_TO_ROLES = [
    // { value: 3, label: "Data Entry Operator" },
    { value: 4, label: "Deputy General Manager" },
    { value: 5, label: "General Manager" },
    { value: 6, label: "Managing Director" },
    { value: 7, label: "Legal" },
    { value: 9, label: "Record Keeper" },
    { value: 10, label: "Assistant Manager" },
    { value: 11, label: "DGM Accounts" },
    { value: 12, label: "A.M -II" },
    { value: 13, label: "Sr.O.A-II" },
    // { value: 8, label: "Accounts" },
  ];

  FORWARD_TO_ROLES = FORWARD_TO_ROLES.filter(
    (obj) => obj.label != auth.rolename
  );

  const [forwardedTo, setForwardedTo] = useState();
  const [notifyTo, setNotifyTo] = useState();

  const [interestRate, setInterestRate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState(true);
  const [accountsFiles, setAccountsFiles] = useState();
  const [files, setFiles] = useState();
  const [usernameList, setUserNameList] = useState();
  const [usernameListDD, setUserNameListDD] = useState();
  const [approvalDataObj, setApprovalDataObj] = useState({});

  const [userNameRoleWise, setUserNameRoleWise] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const getUserList = async () => {
    try {
      const response = await axiosPrivate.get(
        `${USER_MANAGEMENT_CONFIG_URL}/getUserEmail`,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.users?.length > 0) {
          let userNameDD = [];
          response?.data?.users?.map((userNameObj) => {
            let userNameObjDD = {
              value: userNameObj.username,
              label: userNameObj.employeename,
              roles: userNameObj.rolename,
            };

            userNameDD.push(userNameObjDD);
          });
          setUserNameList(userNameDD);
        } else {
          setUserNameList([]);
        }
      }

      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error("get district", error);
    }
  };

  const getInterestRateForCategoryDetails = async () => {
    try {
      const response = await axiosPrivate.get(
        `${SCHEME_CONFIG_URL}/interestRateWithCategoryConfig`,
        {
          params: {
            schemeid: applicantDetail?.schemeid,
            categoryid: applicantDetail?.categoryid,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.data) {
        setInterestRate(response?.data?.interestrate?.interestrate);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  function accountsFilesHandler() {
    if (accountsFiles) {
      let files_list = accountsFiles?.map((file) => (
        <li key={file.path} className={"flex gap-1"}>
          <AiOutlineFile size={18} /> {file.path} - {file.size} bytes
        </li>
      ));
      setFiles(files_list);
    }
  }
  const onSubmit = async (data) => {
    try {
      if (data?.remarks !== "" || auth?.rolename === "DGM Accounts") {
        setRemarkError(false);
      } else {
        setRemarkError(true);
      }

      if (auth?.rolename === "DGM Accounts") {
        setApprovalDataObj(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  async function updateApplicantDocument() {
    let file_url = "";
    let file_list = [];

    if (accountsFiles) {
      return await Promise.all(
        accountsFiles?.map(async (file) => {
          file_url = await uploadFile(file);
          await file_list.push(
            createFileObj(file?.name, "DGM Accounts", file_url)
          );
        })
      ).then(async () => {
        return await file_list;
      });
    }
  }

  const approvalFunction = async () => {
    let formDataObj = {};
    formDataObj.approval_details = null;
    formDataObj.account_document = null;

    if (auth.rolename === "DGM Accounts") {
      let file_List = await updateApplicantDocument();

      formDataObj.account_document = JSON.stringify(file_List);

      let accountsApprovalObj = {};
      accountsApprovalObj = {
        chequeid: approvalDataObj?.chequeid,
        chequedate: approvalDataObj?.chequedate,
        disbursmentamount: approvalDataObj?.disbursmentamount,
        interestrate: interestRate,
        tenure_period: 5,
      };

      formDataObj.approval_details = JSON.stringify(accountsApprovalObj);
    }

    //remark
    const remarkObject = createRemarkObj(isPostSacntion, remark);

    formDataObj.remark = remarkObject;
    formDataObj.loanee_id = applicantDetail?.loanee_id;
    formDataObj.updatedby = auth?.userid;
    formDataObj.rolename = auth?.rolename;
    formDataObj.forwarded_to = forwardedTo?.label;
    formDataObj.notify_to = notifyTo?.value || "None";

    if (auth.rolename === "Managing Director") {
      setForwardedTo({
        value: "Managing Director",
        label: "Managing Director",
      });
      formDataObj.forwarded_to = "Managing Director";
    }

    if (formDataObj?.forwarded_to) {
      try {
        let response = await axiosPrivate.post(
          `${APPLICANT_PERSONAL_DETAILS_CONFIG_URL}/loanApprovalConfig`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );

        if (response?.status === 200) {
          setRefresh((prev) => !prev);
          setViewApplicantDetail(false);
          showToast(
            "Applicant Detail has been forwarded successfully",
            "success"
          );
        }
        response.status === 202 &&
          showToast("No state list found in the system", "error");
        setShowModal(false);
      } catch (error) {
        setShowModal(false);
        console.error(error);
        console.error(error.message);
        if (!error?.response) {
          showToast("No Server Response", "error");
        } else if (error.response.status === 422) {
          showToast("Some of the required inputs were not provided", "error");
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
      }
    }
  };

  const revertApplication = async () => {
    let formDataObj = {};
    try {
      formDataObj.loanee_id = +applicantDetail?.loanee_id;

      //remark
      const remarkObject = createRemarkObj(isPostSacntion, remark);

      formDataObj.updatedby = auth?.userid;
      formDataObj.rolename = auth?.rolename;
      formDataObj.remark = remarkObject;

      let response = await axiosPrivate.put(
        `${APPLICANT_PERSONAL_DETAILS_CONFIG_URL}/revertApplicationConfig`,
        formDataObj,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setRefresh((prev) => !prev);
        showToast(
          "Applicant has been reverted to Data Entry Operator successfully",
          "success"
        );
        setViewApplicantDetail(false);
        setShowModal(false);
      }
      response?.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      setShowModal(false);
      console.error(error.message);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useMemo(() => accountsFilesHandler(), [accountsFiles]);

  useEffect(() => {
    getInterestRateForCategoryDetails();
    console.log("use effect");
    getUserList();
  }, []);

  const onForwardChangeHandler = (e) => {
    let SelectedDesignation = FORWARD_TO_ROLES?.filter(
      (roles) => +roles?.value === +e
    )[0]?.label;

    try {
      let userNameDD = [...usernameList];

      console.log({ userNameDD });
      userNameDD = userNameDD.filter(
        (userObj) => userObj.roles === SelectedDesignation
      );
      setUserNameListDD(userNameDD);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ConfirmationModal
        open={showModal}
        setOpen={setShowModal}
        message={
          auth.rolename === "Managing Director"
            ? `Are you sure to sanction the application?`
            : `Are you sure to forward the application ?`
        }
        btnName={"Confirm"}
        onConfirm={() => approvalFunction()}
      >
        {auth.rolename === "Managing Director" ||
        loaneeSanctionDetail?.is_postsanction_approved === true ? null : (
          <div className="mb-9 h-52">
            <Dropdown
              defaultName="roleid"
              register={register}
              labelname="Forward to"
              required={
                auth.rolename === "Managing Director" ||
                loaneeSanctionDetail?.is_postsanction_approved === true
                  ? false
                  : true
              }
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={(e) => onForwardChangeHandler(e)}
              control={control}
              data={FORWARD_TO_ROLES}
              defaultValue={null}
              setValue={setValue}
              setSelected={setForwardedTo}
              selected={forwardedTo}
              maxMenuHeight={140}
            />

            <Dropdown
              defaultName="roleid"
              register={register}
              labelname="Notify to"
              required={
                auth.rolename === "Managing Director" ||
                loaneeSanctionDetail?.is_postsanction_approved === true
                  ? false
                  : true
              }
              pattern={null}
              errors={errors}
              classes={`rounded-sm text-sm w-full z-10  `}
              setError={setError}
              clearError={clearErrors}
              onChangeInput={null}
              control={control}
              data={usernameListDD}
              defaultValue={null}
              setValue={setValue}
              setSelected={setNotifyTo}
              selected={notifyTo}
              maxMenuHeight={140}
            />
          </div>
        )}
      </ConfirmationModal>
      <ConfirmationModal
        open={showRevertModal}
        setOpen={setShowRevertModal}
        message={
          "Are you sure to revert the application to Data Entry Operator?"
        }
        btnName={"Revert"}
        onConfirm={() => revertApplication()}
        color={"red"}
      />{" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        {auth.rolename === "DGM Accounts" ? (
          <>
            <div className="flex flex-col md:flex-row md:gap-2 md:w-2/3 mt-6">
              <Input
                defaultName="disbursmentamount"
                register={register}
                name="Disbursement Amount"
                required={auth.rolename === "DGM Accounts" ? true : false}
                pattern={null}
                errors={errors}
                placeholder=""
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full `}
                onChangeInput={null}
                defaultValue={loan_due_amount}
                setValue={setValue}
                max={loan_due_amount}
              />
              <Input
                defaultName="interestrate"
                register={register}
                name="Interest Rate"
                required={false}
                pattern={null}
                errors={errors}
                placeholder=""
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full bg-slate-100 cursor-not-allowed`}
                onChangeInput={null}
                defaultValue={interestRate}
                setValue={setValue}
                disabled={true}
              />
              <Input
                defaultName="tenure_period"
                register={register}
                name="Tenure Period (Years)"
                required={false}
                pattern={null}
                errors={errors}
                placeholder=""
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full bg-slate-100 cursor-not-allowed`}
                onChangeInput={null}
                defaultValue={5}
                setValue={setValue}
                disabled={true}
              />
            </div>
            <div className="flex flex-col md:flex-row md:gap-2 md:w-2/3 mb-3">
              <Input
                defaultName="chequeid"
                register={register}
                name="Cheque Id"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque id"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={null}
                setValue={setValue}
              />
              <Input
                defaultName="chequedate"
                register={register}
                name="Cheque / Disbursment Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter cheque id"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={null}
                setValue={setValue}
                max={getFormattedDateInputDate(new Date())}
              />
            </div>

            <div className="mb-9 text-slate-600 text-sm">
              <p className="text-slate-700 font-medium text-base">
                Upload Supporting Files
              </p>
              <Dropzone
                onDrop={(acceptedFiles) => setAccountsFiles(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="h-40 border-dashed border-2 border-slate-500 rounded 
                  bg-slate-50 flex justify-center items-center"
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="text-center flex justify-center items-center flex-col text-slate-500 text-sm">
                        <AiOutlineCloudUpload size={40} />
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {files ? (
                <aside className="mt-3 text-primary">
                  <ul>{files}</ul>
                </aside>
              ) : null}
            </div>
          </>
        ) : (
          <div className="max-w-2xl mb-3">
            <TextArea
              defaultName="remarks"
              register={register}
              name="Remarks"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter remarks"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
              onChangeInput={(e) => {
                if (e !== "") {
                  setRemark(e);
                  setRemarkError(false);
                } else {
                  setRemarkError(true);
                }
              }}
              defaultValue={""}
              setValue={setValue}
            />
          </div>
        )}

        {/* <div className="border md:gap-2 md:w-2/3 mt-6 bg-slate-50 p-3">
          <H5>Upload Documents:</H5>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3">
            <Input
              defaultName="documentname"
              register={register}
              name="Document Name"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter cheque id"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={null}
              setValue={setValue}
            />
            <Input
              defaultName="document"
              register={register}
              name="Document"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter cheque id"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="file"
              classes={`rounded-sm px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={null}
              setValue={setValue}
              max={getFormattedDateInputDate(new Date())}
            />
          </div>
        </div> */}

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={
              auth.rolename === "Managing Director"
                ? "Sanction Application"
                : auth.rolename === "General Manager" &&
                  auth.rolename === forwardedTo?.label
                ? "Process Loan"
                : "Forward Application"
            }
            className={button}
            onClick={() => {
              if (remarkError) {
                setShowModal(false);
              } else {
                setShowModal(true);
              }
            }}
          />
          {auth.rolename !== "Data Entry Operator" &&
            auth.rolename !== "DGM Accounts" &&
            auth.rolename !== "Record Keeper" && (
              <SubmitButton
                isSubmitting={isSubmitting}
                label={"Revert Application"}
                className={delete_button}
                value={"Revert"}
                onClick={() => {
                  if (remarkError) {
                    setShowRevertModal(false);
                  } else {
                    setShowRevertModal(true);
                  }
                }}
              />
            )}
        </div>
      </form>
    </>
  );
};

export default LoaneeSanctionForm;
