import { useForm } from "react-hook-form";
import SubmitButton from "../reusable-components/buttons/SubmitButton";
import InputCheckbox from "../reusable-components/inputs/InputCheckBox/InputCheckbox";
import Input from "../reusable-components/inputs/InputTextBox/Input";
import { full_width_button } from "../theme/lightTheme";
import useAuth from "../authentication/hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import { LOGIN_URL, RAS_KEY_URL } from "../api/api_routing_urls";
import ROLES from "../api/roles_list";
import Captcha from "../utilities/captcah/Captcha";
import rsa from "../utilities/rsaFunction/rsaEncryption";
import Dropdown from "../reusable-components/inputs/Dropdowns/Dropdown";
import useGetUserRoleList from "../hook/getApi/useGetUserRoleList";
import Error from "../reusable-components/outputs/Error";

const { rsaEncrypt } = rsa;

const LoginForm = () => {
  const { auth, setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [rsaPublicKey, setRsaPublicKey] = useState();

  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");

  const [selectedRole, setSelectedRole] = useState();
  const [userName, setUserName] = useState("");

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const defaultValues = {
    username: "",
    password: "",
    persistuser: false,
    roleid: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RAS_KEY_URL);

      if (response?.data?.status === 201) {
        return response?.data?.rsa_detail;
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    console.log("test");
    const { publicKey, session_uuid } = await getPublicKeyRsa();

    if (captchaSuccess && publicKey) {
      data.encryptedPassword = rsaEncrypt(data?.password, publicKey);

      try {
        const response = await axios.post(
          LOGIN_URL,
          {
            username: data?.username,
            pwd: data?.encryptedPassword,
            captcha: captchaInput,
            roleid: data?.roleid?.value,
            session_uuid: session_uuid,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.data?.status !== 202 && response.data?.errMsg === "") {
          let userdetails = {
            username: response.data.userdetails.username,
            role: response.data.userdetails.roleid,
            accessToken: response.data.userdetails.accesstoken,
            photo: response.data.userdetails.photo,
            rolename: response.data.userdetails.rolename,
            fullname: response.data.userdetails.fullname,
            userid: response.data.userdetails.userid,
          };

          setAuth((prev) => ({ ...prev, ...userdetails }));
          // setUser("");
          // setPwd("");
          const roleID = parseInt(response.data.userdetails.roleid);

          // storing roleid in local storage
          localStorage.setItem("roleid", roleID);

          const roleRoutes = {
            [ROLES["Super Admin"]]: "/sysadmin/dashboard",
            [ROLES["Data Entry Operator"]]: "/deoadmin/dashboard",
            [ROLES["Deputy General Manager"]]: "/dvadmin/dashboard",
            [ROLES["General Manager"]]: "/gmadmin/dashboard",
            [ROLES["Managing Director"]]: "/mdadmin/dashboard",
            [ROLES["Legal"]]: "/legaladmin/dashboard",
            [ROLES["DGM Accounts"]]: "/dgmaccountsadmin/dashboard",
            [ROLES["A.M -II"]]: "/am-ii/dashboard",
            [ROLES["Record Keeper"]]: "/recordkeeper/dashboard",
            [ROLES["Assistant Manager"]]: "/assistantmanager/dashboard",
            [ROLES["Content Admin"]]: "/contentadmin/dashboard",
            [ROLES["Cash Counter"]]: "/cashcounter/dashboard",
            [ROLES["Sr.O.A-II"]]: "/sr-oa-ii/dashboard",
            [ROLES["Cashier"]]: "/cashier/dashboard",
          };

          const from = roleRoutes[roleID] || "/unauthorized";

          navigate(from, { replace: true });
        } else {
          setErrMsg(response.data?.errMsg);
          errRef.current.focus();
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 422) {
          setErrMsg("Invalid Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      }
    } else {
      console.error("Captcah is not valid");
    }
    let form = document.getElementById("loginForm");
    setCaptchaReset((prev) => !prev);
    form.reset();
  };

  const onChangeRememberMe = () => {
    setPersist((prev) => !prev);
  };

  const hideError = (e) => {
    setErrMsg("");
  };

  const onUsernameLoad = (e) => {
    setUserName(e?.target?.value);
  };

  const onUserNameChange = (e) => {
    setUserName(e?.target?.value);
  };

  const { data, error, loading } = useGetUserRoleList(userName);

  // useEffect(() => {
  //   getPublicKeyRsa();
  // }, []);

  useEffect(() => {
    const roleRoutes = {
      [ROLES["Super Admin"]]: "/sysadmin/dashboard",
      [ROLES["Data Entry Operator"]]: "/deoadmin/dashboard",
      [ROLES["Deputy General Manager"]]: "/dvadmin/dashboard",
      [ROLES["General Manager"]]: "/gmadmin/dashboard",
      [ROLES["Managing Director"]]: "/mdadmin/dashboard",
      [ROLES["Legal"]]: "/legaladmin/dashboard",
      [ROLES["DGM Accounts"]]: "/dgmaccountsadmin/dashboard",
      [ROLES["Record Keeper"]]: "/recordkeeper/dashboard",
      [ROLES["Assistant Manager"]]: "/assistantmanager/dashboard",
      [ROLES["Content Admin"]]: "/contentadmin/dashboard",
      [ROLES["Cash Counter"]]: "/cashcounter/dashboard",
      [ROLES["A.M -II"]]: "/am-ii/dashboard",
      [ROLES["Sr.O.A-II"]]: "/sr-oa-ii/dashboard",
    };

    if (Object.keys(auth).length > 0) {
      const roleID = parseInt(auth.role);
      const from = roleRoutes[roleID] || "/unauthorized";
      navigate(from, { replace: true });
    }
  }, [auth]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id="loginForm">
        <p
          ref={errRef}
          className={errMsg ? "px-2 py-1 bg-red-100 text-red-800" : "hidden"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Input
          defaultName="username"
          register={register}
          name="User Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter user name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-4`}
          onChangeInput={hideError}
          defaultValue={defaultValues.username}
          setValue={setValue}
          onBlur={onUserNameChange}
          onLoad={onUsernameLoad}
        />
        <Input
          defaultName="password"
          register={register}
          name="Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter password"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-2`}
          onChangeInput={hideError}
          defaultValue={defaultValues.password}
          setValue={setValue}
        />

        <>
          <Dropdown
            defaultName="roleid"
            register={register}
            labelname="User Role"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={loading ? [] : data}
            defaultValue={defaultValues.roleid}
            setValue={setValue}
            setSelected={setSelectedRole}
            selected={selectedRole}
            maxMenuHeight={160}
          />
          {error && <Error message={error}></Error>}
          {/* <InputCheckbox
          defaultName="persistuser"
          register={register}
          name="Remember Me"
          required={false}
          pattern={null}
          errors={errors}
          placeholder=""
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="checkbox"
          classes={`rounded shadow-lg text-sm mr-2 `}
          onChangeInput={onChangeRememberMe}
        /> */}
          <Captcha
            setCaptchaSuccess={setCaptchaSuccess}
            captchaReset={captchaReset}
            setCaptchaInput={setCaptchaInput}
          />
          <SubmitButton
            isSubmitting={isSubmitting}
            label="Login"
            className={`${full_width_button} mt-6`}
            isDisabled={!captchaSuccess}
          />
        </>

        <Link to="/">
          <div className="mt-4 w-full flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-slate-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <label className="text-slate-500 cursor-pointer">
              &nbsp;Go to Home
            </label>
          </div>
        </Link>
      </form>
    </>
  );
};

export default LoginForm;
