import React from "react";

import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import ContactUsImage from "../../../assets/contactus.jpg";
import { CiLocationOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";

export default function ContactUs() {
  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Contact Us</H1>
          </div>
        </div>
      </HeroBanner>

      <section className="mx-4 md:mx-10 md:my-16">
        <div className="md:hidden">
          <img
            src={ContactUsImage}
            alt=""
            className="object-cover rounded-full"
          />
        </div>

        <div className="grid md:grid-cols-2 md:gap-8">
          <div className="flex flex-col gap-6 max-w-lg mx-auto">
            {/* Visit Us */}
            <div className="border p-6 bg-slate-100 rounded-md">
              <div className="flex items-center gap-4">
                <span className="border border-red-400 rounded-full p-2">
                  <CiLocationOn size={20} color="red" />
                </span>
                <p className="font-semibold text-sm md:text-lg">Visit us</p>
              </div>
              <p className="text-xs md:text-sm mt-2">
                Sikkim SC, ST and OBC Development Corporation Ltd.
              </p>
              <p className="text-xs md:text-sm">Sonam Tshering Marg,</p>
              <p className="text-xs md:text-sm">Gangtok, Sikkim - 737101</p>
            </div>

            {/* Call/Write to Us */}
            <div className="border p-6 bg-slate-100 rounded-md mb-8">
              <div className="flex items-center gap-2">
                <span className="border border-red-400 rounded-full p-2">
                  <IoCallOutline size={20} color="red" />
                </span>
                <p className="font-semibold text-sm md:text-lg">
                  Call/Write to us
                </p>
              </div>
              <p className="text-xs md:text-sm mt-2">Phone no: 89765-89765</p>
              <p className="text-xs md:text-sm">
                E-mail: info[at]sabcco[dot]com
              </p>
            </div>
          </div>

          {/* Image for larger screens */}
          <div className="md:visible mx-4">
            <img
              src={ContactUsImage}
              alt=""
              className="object-cover rounded-full w-[70%]"
            />
          </div>
        </div>
      </section>
    </>
  );
}
