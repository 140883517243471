import React from "react";
import { useState } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import {
  MdCheck,
  MdOutlinePendingActions,
  MdPhoneIphone,
  MdRemoveRedEye,
} from "react-icons/md";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { LOANEE_DEATH_CASE } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  linkTextPrimary,
  linkTextSecondary,
} from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";
import { SubMenu } from "../applicant-components/SubMenu";

export default function DeathCaseList({
  loaneeDeathCaseList,
  setViewDeathCaseDetail,
  setDeathCaseDetail,
  setLoaneeId,
  setApplicantPersonalDetailName,
  setShowAddGuarantorForm,
  setEditApplicantDetails,
  setShowAddDocumentsForm,
  getDeathCaseList,
}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [showloaneeDeathCase, setShowLoaneeDeathCase] = useState(false);
  const [loanNumber, setLoanNumber] = useState(); //  sets a loan Id namming not changed yet
  const [deathCaseStatus, setDeathCaseStatus] = useState(false);

  const onClickDeathCase = (loanee_id, status) => {
    setLoanNumber(loanee_id);
    setDeathCaseStatus(status);
    setShowLoaneeDeathCase(true);
  };

  const onClickAddGuarantor = (loanee_ID, applicantName) => {
    setLoaneeId(loanee_ID);
    setApplicantPersonalDetailName(applicantName);
    setShowAddGuarantorForm(true);
  };

  // const onClickEdit = (applicantDetail) => {

  //   setEditApplicantDetails(applicantDetail);
  //   setShowAddForm(true);
  // };

  const onClickDocumentEdit = (applicantDetail) => {
    setEditApplicantDetails(applicantDetail);
    setShowAddDocumentsForm(true);
  };

  const convertToDeathCase = async () => {
    try {
      let SubmitObj = {
        loanee_id: loanNumber,
        isloaneedeathcase: deathCaseStatus,
        updatedby: auth.userid,
      };

      const response = await axiosPrivate.post(
        `${LOANEE_DEATH_CASE}/convertToDeathcase`,
        SubmitObj,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (deathCaseStatus === true)
          showToast("Applicant converted to DEATH CASE", "success");
        if (deathCaseStatus === false)
          showToast("Applicant reverted from DEATH CASE", "success");

        getDeathCaseList();
      }
      if (response.status === 202) {
        showToast("No death Case list found in the system", "error");
      }
    } catch (error) {
      console.error(error.message);
      setShowLoaneeDeathCase(false);
    }
    setShowLoaneeDeathCase(false);
  };

  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-xs  md:text-sm z-60">
          <tr className="bg-slate-200 sticky top-0">
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loanee ID
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium flex flex-col min-w-44"
            >
              <span>Applicant Name</span>{" "}
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Contact
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Scheme
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loan Amount
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Sanction Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Approval Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Applicant Guarantors
            </th>
            <th scope="col" className="px-3 py-2 text-center font-medium">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {loaneeDeathCaseList?.length > 0 ? (
            loaneeDeathCaseList?.map((loaneeDeathCaseDetail, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                  <p>{loaneeDeathCaseDetail?.loanee_id}</p>
                </td>
                <td className="px-3 py-1.5 text-xs 2xl:text-sm ">
                  <p className="flex flex-col text-left ">
                    <span>{loaneeDeathCaseDetail?.applicantname}</span>

                    <span className="font-semibold text-xs 2xl:text-sm">
                      ({loaneeDeathCaseDetail?.categoryname})
                    </span>
                  </p>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm ">
                  <span className="flex gap-0.5">
                    <span className="mt-0.5 text-primary">
                      <MdPhoneIphone size={15} />
                    </span>

                    {loaneeDeathCaseDetail?.contactno > 0
                      ? loaneeDeathCaseDetail?.contactno
                      : "---"}
                  </span>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {/* {`${applicantDetail?.schemename} (${applicantDetail?.schemeshortname})`} */}
                  {`${
                    loaneeDeathCaseDetail?.schemename ||
                    loaneeDeathCaseDetail?.schemeshortname ||
                    "---"
                  }`}
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {loaneeDeathCaseDetail?.loanappliedamt >=
                  loaneeDeathCaseDetail?.loan_amount
                    ? loaneeDeathCaseDetail?.loanappliedamt
                    : loaneeDeathCaseDetail?.loan_amount}
                </td>

                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                  <div className="flex w-12 justify-between py-0.5">
                    Pre:
                    {loaneeDeathCaseDetail?.is_presanction_revert === true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Reverted</span> */}
                        <TbArrowBackUp size={18} />
                      </span>
                    ) : loaneeDeathCaseDetail?.is_presanction_approved ===
                        true || loaneeDeathCaseDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Pending</span> */}
                        <MdOutlinePendingActions size={18} />
                      </span>
                    )}
                  </div>
                  <div className="flex w-12 justify-between py-0.5">
                    Post:
                    {loaneeDeathCaseDetail?.is_postsanction_revert === true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        <TbArrowBackUp size={18} />
                        {/* <span className="text-slate-700">Reverted</span> */}
                      </span>
                    ) : loaneeDeathCaseDetail?.is_postsanction_approved ===
                        true || loaneeDeathCaseDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        <MdOutlinePendingActions size={18} />
                        {/* <span className="text-slate-700">Pending</span> */}
                      </span>
                    )}
                  </div>
                </td>
                {auth.rolename !== "Content Admin" && (
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                    {loaneeDeathCaseDetail?.is_presanction_approved !== true &&
                    loaneeDeathCaseDetail?.loanee_id > 7761 ? (
                      <>
                        {auth.rolename !==
                          loaneeDeathCaseDetail?.pre_forwarded_to &&
                        loaneeDeathCaseDetail?.pre_forwarded_to !== null ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {loaneeDeathCaseDetail?.is_postsanction_approved ===
                          true || loaneeDeathCaseDetail?.loanee_id <= 7761 ? (
                          <>
                            <span className="text-green-600 flex gap-1 items-center">
                              <span className="text-slate-700">Disbursed</span>
                              <MdCheck size={18} />
                            </span>
                          </>
                        ) : (auth.rolename !==
                            loaneeDeathCaseDetail?.post_forwarded_to &&
                            loaneeDeathCaseDetail?.post_forwarded_to !==
                              null) ||
                          auth.rolename === "Managing Director" ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    )}
                  </td>
                )}
                <td className="px-3 py-1.5 whitespace-nowrap text-xs  md:text-sm ">
                  {loaneeDeathCaseDetail?.guarantordetails !== null ? (
                    <>
                      <ul>
                        {loaneeDeathCaseDetail?.guarantordetails?.map(
                          (guarantorDetail, index) => (
                            <li
                              key={index}
                              className={`py-0.5 text-xs  flex 
                              whitespace-nowrap ${
                                (auth.rolename === "Data Entry Operator" ||
                                  auth.rolename === "Super Admin") &&
                                !loaneeDeathCaseDetail?.is_presanction_approved &&
                                ` cursor-pointer `
                              }`}
                              //   onClick={() => {
                              //     (auth.rolename === "Data Entry Operator" ||
                              //       auth.rolename === "Super Admin") &&
                              //       !loaneeDeathCaseDetail?.is_presanction_approved &&
                              //       viewGuarantorDetail(guarantorDetail);
                              //   }}
                            >
                              <span className="mt-0.5">
                                <FaUser size={12} />
                              </span>
                              <div
                                className={` flex text-xs justify-between w-full  ${
                                  (auth.rolename === "Data Entry Operator" ||
                                    auth.rolename === "Super Admin") &&
                                  !loaneeDeathCaseDetail?.is_presanction_approved
                                    ? `${linkTextSecondary}  `
                                    : `text-slate-600  px-2`
                                }`}
                              >
                                <div>
                                  {guarantorDetail?.guarantorname.substring(
                                    0,
                                    19
                                  )}{" "}
                                  ...
                                </div>
                                {/* {!loaneeDeathCaseDetail?.is_presanction_approved && (
                                  <>
                                    {(auth.rolename === "Data Entry Operator" ||
                                      auth.rolename === "Super Admin") &&
                                      !loaneeDeathCaseDetail?.is_presanction_approved && (
                                        <FaEdit size={14} />
                                      )}
                                  </>
                                )} */}
                              </div>
                            </li>
                          )
                        )}
                      </ul>{" "}
                    </>
                  ) : (
                    "---"
                  )}
                </td>

                <td className="px-3 py-1.5 whitespace-nowrap text-sm">
                  <div className="flex justify-end ">
                    <Button
                      onClick={() => {
                        setDeathCaseDetail(loaneeDeathCaseDetail);
                        setViewDeathCaseDetail(true);
                      }}
                      aria-label="View Sub menu"
                      className={linkTextPrimary}
                    >
                      <span className="text-primary">
                        <MdRemoveRedEye size={18} />
                      </span>
                    </Button>

                    {((auth.rolename === "Data Entry Operator" ||
                      auth.rolename === "Super Admin") &&
                      (loaneeDeathCaseDetail?.is_presanction_approved !==
                        true ||
                        loaneeDeathCaseDetail?.is_postsanction_approved !==
                          true ||
                        loaneeDeathCaseDetail?.is_presanction_revert === true ||
                        loaneeDeathCaseDetail?.is_postsanction_revert ===
                          true)) ||
                    ((auth.rolename === "Assistant Manager" ||
                      auth.rolename === "General Manager" ||
                      auth.rolename === "Deputy General Manager" ||
                      auth.rolename === "Managing Director") &&
                      loaneeDeathCaseDetail?.loanee_id <= 7761 &&
                      loaneeDeathCaseDetail?.loan_status === "Active") ||
                    loaneeDeathCaseDetail?.is_postsanction_approved === true ? (
                      <>
                        <SubMenu
                          loaneeDeathCaseDetail={loaneeDeathCaseDetail}
                          setDeathCaseDetail={setDeathCaseDetail}
                          setViewDeathCaseDetail={setViewDeathCaseDetail}
                          setShowLoaneeDeathCase={setShowLoaneeDeathCase}
                          onClickDeathCase={onClickDeathCase}
                          setLoaneeId={setLoaneeId}
                          onClickDocumentEdit={onClickDocumentEdit}
                          onClickAddGuarantor={onClickAddGuarantor}
                        />
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr className="m-auto text-center">
                <td colSpan={9} className="py-3">
                  No List Found
                </td>
              </tr>
            </>
          )}
        </tbody>
      </TableWrapper>

      <>
        <ConfirmationModal
          open={showloaneeDeathCase}
          setOpen={setShowLoaneeDeathCase}
          message={`Are you sure you want revert the Death Case Detail`}
          btnName={"Confirm"}
          onConfirm={() => convertToDeathCase()}
        ></ConfirmationModal>
      </>
    </>
  );
}
