import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  AREA_CONFIG_URL,
  BANKBRANCH_CONFIG_URL,
  BANK_CONFIG_URL,
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  LOANEE_DETAILS_CONFIG,
  SCHEME_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import {
  applicantRelativeDD,
  areaDD,
  genderDD,
} from "../../../../utilities/constants/constants";
import {
  getAgeFromDOB,
  getFormattedDateInputDate,
  getMinimumAgeDOB,
} from "../../../../utilities/dateFunctions/formatdate";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import {
  numberOnly,
  pinCode,
  reContact,
  reEmail,
} from "../../../../utilities/constants/regularexpressions";
import PDFImageUploaderAWS from "../../../../reusable-components/inputs/FileUploader/PDFUploaderAWS";
import InputCheckbox from "../../../../reusable-components/inputs/InputCheckBox/InputCheckbox";
import CapturePhoto from "../../../../reusable-components/inputs/FileUploader/CapturePhoto";
import { useMemo } from "react";
import { useRef } from "react";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";
import ApplicantDocumentPreview from "../applicant-components/ApplicantDocumentPreview";

export default function AddApplicantForm({
  // getApplicantDetailList,
  editApplicantDetails,
  setEditApplicantDetails,
  setShowAddForm,
  getSearchedApplicantList,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editApplicantDetails).length > 0;

  const [isPostSacntion, setIsPostSacntion] = useState(false);

  const passPhotoRef = useRef(null);

  const [applicationNo, setApplicationNo] = useState();
  const [selectedRelativeType, setSelectedRelativeType] = useState();
  const [selectedGenderType, setSelectedGenderType] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedScheme, setSelectedScheme] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedConstituency, setSelectedConstituency] = useState();
  const [selected_LoaneeArea, setSelected_LoaneeArea] = useState();
  const [selectedSubDivision, setSelectedSubDivision] = useState();

  const [selectedPoliceStation, setSelectedPoliceStation] = useState();
  const [selectedBankBranch, setSelectedBankBranch] = useState();
  const [selectedIfsc, setSelectedIfsc] = useState();
  const [selectedBank, setSelectedBank] = useState();

  const [age, setAge] = useState();

  const minDOB = getMinimumAgeDOB(16);
  const maxDOB = getMinimumAgeDOB(100);

  const [categoryList, setCategoryList] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [constituencyListDistrictWise, setConstituencyListDistrictWise] =
    useState([]);

  const [areaListDistrictWise, setAreaListDistrictWise] = useState([]);

  const [loaneeArea_List, setLoaneeArea_List] = useState([]);

  const [bankList, setBankList] = useState([]);

  const [gramPanchayatDetail, setGramPanchayatDetail] = useState();

  // const [passphoto, setPassphoto] = useState("");

  const [sikkimSubjectDoc, setSikkimSubjectDoc] = useState("");
  const [castDoc, setCastDoc] = useState("");
  const [salaryDoc, setSalaryDoc] = useState("");
  const [tradeLicenseDoc, setTradeLicenseDoc] = useState("");
  const [incomeCertDoc, setIncomeCertDoc] = useState("");
  const [adharCardDoc, setAdharCardDoc] = useState("");
  const [voterCardDoc, setVoterCardDoc] = useState("");
  const [bankPassbookDoc, setBankPassbookDoc] = useState("");
  const [nocParchaDoc, setNocParchaDoc] = useState("");
  const [disabilityCertDoc, setDisabilityCertDoc] = useState("");

  const [sikkimSubjectChecked, setSikkimSubjectChecked] = useState(false);
  const [castCertificateChecked, setCastCertificateChecked] = useState(false);
  const [salaryDocChecked, setSalaryDocChecked] = useState(false);
  const [tradeLicenceChecked, setTradeLicenceChecked] = useState(false);
  const [incomeCertificateChecked, setIncomeCertificateChecked] =
    useState(false);
  const [adharCardChecked, setAdharCardChecked] = useState(false);
  const [voterCardChecked, setVoterCardChecked] = useState(false);
  const [bankPassbookChecked, setBankPassbookChecked] = useState(false);
  const [nocParchaDocChecked, setNocParchaDocChecked] = useState(false);
  const [disabilityCertificateChecked, setDisabilityCertificateChecked] =
    useState(false);

  // const [sameAsPermanentAddress, setSameAsPermanentAddress] = useState(false);
  const [permanentAddress, setPermanentAddress] = useState("");

  const [userPassPhoth, setUserPassPhoto] = useState();

  //sam-dev useStates

  const [bankNamesDD, setBankNamesDD] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState("");
  const [bankBranchList, setBankBranchList] = useState([]);
  const [branchNamesDD, setBranchNamesDD] = useState([]);

  const [selectedIfsccode, setSelectedIfsccode] = useState();

  const defaultValues = {
    applicationno: !isEdit ? "" : editApplicantDetails?.applicationno,
    applicantname: !isEdit ? "" : editApplicantDetails?.applicantname,
    applicantrelativename: !isEdit
      ? ""
      : editApplicantDetails?.applicantrelativename,
    applicantdob: !isEdit
      ? ""
      : getFormattedDateInputDate(editApplicantDetails?.applicantdob),
    applicantage: !isEdit ? "" : editApplicantDetails?.applicantage,
    permanentaddress: !isEdit ? "" : editApplicantDetails?.permanentaddress,
    temporaryaddress: !isEdit ? "" : editApplicantDetails?.temporaryaddress,
    education: !isEdit ? "" : editApplicantDetails?.education,
    annualincome: !isEdit ? "" : editApplicantDetails?.annualincome,
    contactno: !isEdit ? "" : editApplicantDetails?.contactno,
    loanappliedamt: !isEdit
      ? ""
      : editApplicantDetails?.loanappliedamt >=
        editApplicantDetails?.loan_amount
      ? editApplicantDetails?.loanappliedamt
      : editApplicantDetails?.loan_amount,
    policestation: !isEdit ? "" : editApplicantDetails?.policestation,
    postoffice: !isEdit ? "" : editApplicantDetails?.postoffice,
    pincode: !isEdit ? "" : editApplicantDetails?.pincode,
    applicantlocation: !isEdit ? "" : editApplicantDetails?.applicantlocation,
    employmentgeneration: !isEdit
      ? ""
      : editApplicantDetails?.employmentgeneration,

    accountname: !isEdit ? "" : editApplicantDetails?.accountname,
    accountnumber: !isEdit ? "" : editApplicantDetails?.accountnumber,
    ifsccode: !isEdit ? "" : editApplicantDetails?.ifsccode,
    phoneno: !isEdit ? "" : editApplicantDetails?.phoneno,
    applicantrelative: !isEdit
      ? ""
      : {
          label: editApplicantDetails?.applicantrelative,
          value: editApplicantDetails?.applicantrelative,
        },

    applicantgender: !isEdit
      ? ""
      : {
          label: editApplicantDetails?.applicantgender,
          value: editApplicantDetails?.applicantgender,
        },
    // applicantarea: !isEdit
    //   ? ""
    //   : editApplicantDetails?.applicantarea
    //   ? {
    //       label: editApplicantDetails?.applicantarea?.value,
    //       value: editApplicantDetails?.applicantarea?.value,
    //     }
    //   : "",

    applicantarea: !isEdit
      ? ""
      : {
          label: editApplicantDetails?.applicantarea,
          value: editApplicantDetails?.applicantarea,
        },

    categoryid: !isEdit ? "" : selectedCategory,
    schemeid: !isEdit ? "" : selectedScheme,
    districtid: !isEdit ? "" : selectedDistrict,
    constituencyid: !isEdit ? "" : selectedConstituency,

    // subdivisionid: !isEdit ? "" : selectedSubDivision,
    // grampanchayatid: !isEdit ? "" : selectedGramPanchayat,
    bankname: !isEdit ? "" : selectedBank,
    bankbranch: !isEdit ? "" : selectedBankBranch,

    applicantimage: !isEdit ? "" : editApplicantDetails?.applicantimage,
    sikkimsubject: !isEdit ? "" : editApplicantDetails?.sikkimsubject,
    castcertificate: !isEdit ? "" : editApplicantDetails?.castcertificate,
    salarycertificate: !isEdit ? "" : editApplicantDetails?.salarycertificate,
    tradelicense: !isEdit ? "" : editApplicantDetails?.tradelicense,
    applicantemail: !isEdit ? "" : editApplicantDetails?.applicantemail,
    bankid: !isEdit ? "" : selectedBank,
    subdivisionname: !isEdit ? "" : editApplicantDetails?.subdivisionname,
    grampanchayatname: !isEdit ? "" : editApplicantDetails?.grampanchayatname,
    nagarpanchayatname: !isEdit ? "" : editApplicantDetails?.nagarpanchayatname,
    municipalcorporationname: !isEdit
      ? ""
      : editApplicantDetails?.municipalcorporationname,
    municipalward: !isEdit ? "" : editApplicantDetails?.municipalward,
    grampanchayatward: !isEdit ? "" : editApplicantDetails?.grampanchayatward,
    area_id: !isEdit ? "" : selected_LoaneeArea,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  // for uploading document on digital ocean s3
  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  const getApplicantNo = async () => {
    try {
      const response = await axiosPrivate.get(
        `${APPLICANT_PERSONAL_DETAILS_CONFIG_URL}/getApplicantNo`,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setApplicationNo(parseInt(response?.data?.applicationNo?.max) + 1);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  const getCategoryList = async (category_id) => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);

            if (categoryOBJ.categoryid === category_id) {
              setSelectedCategory({
                value: categoryOBJ.categoryid,
                label: categoryOBJ.categoryname,
              });
            }
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  const getSchemeList = async (scheme_id) => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.schemeList?.length > 0) {
          let SchemeDD = [];
          response?.data?.schemeList?.map((schemeObj) => {
            let SchemeObj = {
              value: schemeObj.schemeid,
              label: `${schemeObj.schemename}
              ${" "}(${schemeObj.schemeshortname})`,
            };
            SchemeDD.push(SchemeObj);

            if (schemeObj.schemeid === scheme_id) {
              setSelectedScheme({
                value: schemeObj.schemeid,
                label: schemeObj.schemename,
              });
            }
          });
          setSchemeList(SchemeDD);
        } else {
          setSchemeList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  const getDistrictList = async (district_id) => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];

          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);

            if (districtObj.districtid === district_id) {
              setSelectedDistrict({
                value: districtObj.districtid,
                label: districtObj.districtname,
              });
            }
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error("get district", error);
    }
  };

  const getBankList = async (bank_name) => {
    try {
      const response = await axiosPrivate.get(BANK_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        let bankNamesDD = [];
        if (response?.data?.bankList?.length > 0) {
          response?.data?.bankList?.map((bankObj) => {
            bankNamesDD.push({
              label: bankObj?.bankname,
              value: bankObj?.bankid,
            });
            if (bankObj?.bankname === bank_name) {
              setSelectedBank({
                value: bankObj.bankid,
                label: bankObj.bankname,
              });
            } else {
              setBankList([]);
            }
          });
        }
        setBankNamesDD(bankNamesDD);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get bank branch detaisl
  const getBankBranchList = async (bank_branch) => {
    try {
      const response = await axiosPrivate.get(BANKBRANCH_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response.status === 200) {
        setBankBranchList(response.data.BankBranchList);

        if (isEdit) {
          response.data.BankBranchList?.filter(
            (branchObj) => branchObj.bankbranch === bank_branch
          ).map((obj) => {
            setSelectedBankBranch({
              value: obj.bankbranchid,
              label: obj.bankbranch,
            });
          });
        }
      }
      response.status === 202 &&
        showToast("No bank branch list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getConstituencyList = async (constituency_id) => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
              districtid: constituencyObj.districtid,
            };
            constituencyDD.push(constituencyObjDD);

            if (constituencyObj.constituencyid === constituency_id) {
              setSelectedConstituency({
                value: constituencyObj.constituencyid,
                label: constituencyObj.constituencyname,
                districtid: constituencyObj.districtid,
              });
            }
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error("getConstituencyList", error);
    }
  };

  const getAreaList = async (area_id) => {
    try {
      const response = await axiosPrivate.get(AREA_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.areaList?.length > 0) {
          let areaDD = [];
          response?.data?.areaList?.map((areaObj) => {
            let areaObjDD = {
              value: areaObj.area_id,
              label: areaObj.area_name,
              districtid: areaObj.districtid,
            };
            areaDD.push(areaObjDD);

            if (areaObj.area_id === area_id) {
              setSelected_LoaneeArea({
                value: areaObj.area_id,
                label: areaObj.area_name,
                districtid: areaObj.districtid,
              });
            }
          });
          setLoaneeArea_List(areaDD);
        } else {
          setLoaneeArea_List([]);
        }
      }
      response.status === 202 &&
        showToast("No  Area List found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const sameAsPermanentAddressHandler = (defaultName, e) => {
    if (e?.target?.checked === true) {
      if (isEdit && !permanentAddress) {
        setValue(defaultName, editApplicantDetails?.permanentaddress, {
          shouldTouch: true,
        });
      } else {
        setValue(defaultName, permanentAddress, { shouldTouch: true });
      }
    } else {
      setValue(defaultName, "", { shouldTouch: true });
    }
  };

  async function updateApplicantDocument() {
    let file_url = "";
    let file_list = [];

    if (sikkimSubjectDoc) {
      file_url = await uploadFile(sikkimSubjectDoc);
      file_list.push(
        createFileObj(sikkimSubjectDoc?.name, "Sikkim Subject", file_url)
      );
    }
    if (castDoc) {
      file_url = await uploadFile(castDoc);
      file_list.push(
        createFileObj(castDoc?.name, "Cast Certificate", file_url)
      );
    }
    if (salaryDoc) {
      file_url = await uploadFile(salaryDoc);
      file_list.push(
        createFileObj(salaryDoc?.name, "Salary Certificate", file_url)
      );
    }
    if (tradeLicenseDoc) {
      file_url = await uploadFile(tradeLicenseDoc);
      file_list.push(
        createFileObj(tradeLicenseDoc?.name, "Trade Licence", file_url)
      );
    }
    if (incomeCertDoc) {
      file_url = await uploadFile(incomeCertDoc);
      file_list.push(
        createFileObj(incomeCertDoc?.name, "Income Certificate", file_url)
      );
    }
    if (adharCardDoc) {
      file_url = await uploadFile(adharCardDoc);
      file_list.push(createFileObj(adharCardDoc?.name, "Adhar Card", file_url));
    }
    if (voterCardDoc) {
      file_url = await uploadFile(voterCardDoc);
      file_list.push(createFileObj(voterCardDoc?.name, "Voter Card", file_url));
    }
    if (bankPassbookDoc) {
      file_url = await uploadFile(bankPassbookDoc);
      file_list.push(
        createFileObj(bankPassbookDoc?.name, "Bank Passbook", file_url)
      );
    }
    if (nocParchaDoc) {
      file_url = await uploadFile(nocParchaDoc);
      file_list.push(createFileObj(nocParchaDoc?.name, "NOC Parcha", file_url));
    }
    if (disabilityCertDoc) {
      file_url = await uploadFile(disabilityCertDoc);
      file_list.push(
        createFileObj(
          disabilityCertDoc?.name,
          "Disability Certificate",
          file_url
        )
      );
    }

    return file_list;
  }

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  function checkListHandler() {
    if (editApplicantDetails) {
      editApplicantDetails?.filechecklist?.map((checkListObj) => {
        if (checkListObj?.file === "Sikkim Subject") {
          setSikkimSubjectChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Salary Certificate") {
          setSalaryDocChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Income Certificate") {
          setIncomeCertificateChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Adhar Card") {
          setAdharCardChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Voter Card") {
          setVoterCardChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "NOC Parcha") {
          setNocParchaDocChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Cast Certificate") {
          setCastCertificateChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Trade Licence") {
          setTradeLicenceChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Bank Passbook") {
          setBankPassbookChecked(checkListObj?.check);
        }
        if (checkListObj?.file === "Disability Certificate") {
          setDisabilityCertificateChecked(checkListObj?.check);
        }
      });
    }
  }

  function createFileChecklistObj(file, check) {
    let fileObj = {
      file: file,
      check: check,
    };
    return fileObj;
  }

  async function applicantDocChecklistHandler() {
    let presanctionfileschecklist = [];

    presanctionfileschecklist.push(
      createFileChecklistObj("Sikkim Subject", sikkimSubjectChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Cast Certificate", castCertificateChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Salary Certificate", salaryDocChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Trade Licence", tradeLicenceChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Income Certificate", incomeCertificateChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Adhar Card", adharCardChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Voter Card", voterCardChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("Bank Passbook", bankPassbookChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj("NOC Parcha", nocParchaDocChecked)
    );
    presanctionfileschecklist.push(
      createFileChecklistObj(
        "Disability Certificate",
        disabilityCertificateChecked
      )
    );

    return presanctionfileschecklist;
  }

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const onSubmit = async (data) => {
    try {
      let formDataObj = {};
      let presanctionfileschecklist = await applicantDocChecklistHandler();
      let fileList = await updateApplicantDocument();

      let response = "";

      data.sanctionfiles = JSON.stringify(fileList);
      data.filechecklist = JSON.stringify(presanctionfileschecklist);
      data.applicantarea = data?.applicantarea?.value;
      data.applicantgender = data?.applicantgender?.value;
      data.applicantrelative = data?.applicantrelative?.value;
      data.categoryid = data?.categoryid?.value;
      data.constituencyid = data?.constituencyid?.value;
      data.districtid = data?.districtid?.value;
      data.schemeid = data?.schemeid?.value;

      // data.bankname = data?.bank?.label;
      // data.bankid = data?.bankid?.value;

      if (userPassPhoth) {
        data.applicantimage = await uploadFile(userPassPhoth);
      } else {
        data.applicantimage = editApplicantDetails?.applicantimage;
      }

      formDataObj.applicationno = applicationNo;
      formDataObj.applicantname = data?.applicantname;
      formDataObj.applicantrelative = data?.applicantrelative;
      formDataObj.applicantrelativename = data?.applicantrelativename;
      formDataObj.applicantdob = data?.applicantdob;
      formDataObj.applicantage = age;
      formDataObj.applicantgender = data?.applicantgender;
      formDataObj.permanentaddress = data?.permanentaddress;
      formDataObj.temporaryaddress = data?.temporaryaddress;
      formDataObj.applicantarea = data?.applicantarea;
      formDataObj.categoryid = data?.categoryid;
      formDataObj.education = data?.education;
      formDataObj.annualincome = data?.annualincome;
      formDataObj.contactno = data?.contactno;
      formDataObj.schemeid = data?.schemeid;
      formDataObj.loanappliedamt = data?.loanappliedamt;
      formDataObj.districtid = data?.districtid;
      formDataObj.constituencyid = data?.constituencyid;
      formDataObj.subdivisionname = data?.subdivisionname;
      formDataObj.grampanchayatname = data?.grampanchayatname;
      formDataObj.grampanchayatward = data?.grampanchayatward;
      formDataObj.policestation = data?.policestation;
      formDataObj.postoffice = data?.postoffice;
      formDataObj.pincode = data?.pincode;
      formDataObj.applicantlocation = data?.applicantlocation;
      formDataObj.employmentgeneration = data?.employmentgeneration;
      formDataObj.bankname = data?.bankname?.label || selectedBankName?.label;
      formDataObj.bankbranch =
        data?.bankbranch?.label || selectedBankBranch?.label;
      formDataObj.accountname = data?.accountname;
      formDataObj.accountnumber = data?.accountnumber || null;
      formDataObj.ifsccode = data?.ifsccode;
      formDataObj.phoneno = data?.phoneno || null;
      formDataObj.applicantimage = data?.applicantimage;
      formDataObj.sanctionfiles = data?.sanctionfiles;
      formDataObj.filechecklist = data?.filechecklist;
      formDataObj.applicantemail = data?.applicantemail;
      formDataObj.alternativecontactno = data?.alternativecontactno || null;
      formDataObj.nagarpanchayatname = data?.nagarpanchayatname;
      formDataObj.municipalcorporationname = data?.municipalcorporationname;
      formDataObj.municipalward = data?.municipalward;
      formDataObj.area_id = data?.area_id?.value;

      if (!isEdit) {
        formDataObj.createdby = auth.userid;

        response = await axiosPrivate.post(
          `${LOANEE_DETAILS_CONFIG}/save`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (isEdit) {
        formDataObj.loanee_id = editApplicantDetails?.loanee_id;
        formDataObj.updatedby = auth.userid;

        response = await axiosPrivate.post(
          `${LOANEE_DETAILS_CONFIG}/update`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditApplicantDetails({});
          showToast(
            "Applicant Detail has been updated successfully",
            "success"
          );
        } else {
          showToast("Applicant Detail has been added successfully", "success");
        }
        getSearchedApplicantList();
        // getApplicantDetailList();

        setShowAddForm(false);
        setEditApplicantDetails({});
        reset();
        setShowAddForm(false);
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
    } catch (error) {
      console.error("error while registering applicant", error);

      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      // setShowAddForm(false);
    }
  };

  useMemo(() => {
    let isMounted = true;
    if (isMounted) {
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [selectedBank]);

  useMemo(() => {
    let isMounted = true;
    if (isMounted) {
      // getPoliceStationList();
      setSelectedPoliceStation(null);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [selectedSubDivision]);

  useMemo(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedSubDivision(null);
      setSelectedPoliceStation(null);
      // getSubDivisionList();
      // getPoliceStationList();
    }
    return () => {
      isMounted = false;
    };
  }, [selectedDistrict]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      checkListHandler();

      if (isEdit) {
        getCategoryList(editApplicantDetails?.categoryid);
        getSchemeList(editApplicantDetails?.schemeid);
        getDistrictList(editApplicantDetails?.districtid);
        getConstituencyList(editApplicantDetails?.constituencyid);
        setIsPostSacntion(
          editApplicantDetails?.mdapproval?.mdPreSanctionApproval
        );
        getBankList(editApplicantDetails?.bankname);
        getBankBranchList(editApplicantDetails?.bankbranch);

        getAreaList(editApplicantDetails?.area_id);
      }
      if (!isEdit) {
        getCategoryList(0);
        getSchemeList(0);
        getDistrictList(0);
        getConstituencyList(0);
        getApplicantNo(0);
        getBankList(0);
        getBankBranchList();
        getAreaList(0);
      }
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  function constituencyHandler() {
    let filteredConstituencyList = constituencyList?.filter(
      (constituencyObj) =>
        +constituencyObj?.districtid === +selectedDistrict?.value
    );

    setConstituencyListDistrictWise(filteredConstituencyList);
  }

  function areaHandler() {
    let filteredAreaList = loaneeArea_List?.filter(
      (areaObj) => +areaObj?.districtid === +selectedDistrict?.value
    );

    setAreaListDistrictWise(filteredAreaList);
  }

  function ifsccodeHandler(selectedbranch) {
    let ifsccode = bankBranchList.filter(
      (ifscObj) => ifscObj?.bankbranchid === selectedbranch?.value
    );

    setSelectedIfsccode(ifsccode[0]?.bankifsccode);
  }

  function setBankBranchHandler(selected_Bank_Name) {
    let filteredBranchList = bankBranchList.filter(
      (branchObj) => branchObj?.bankid === selected_Bank_Name?.value
    );

    let branchNamesDD = [];

    filteredBranchList?.map((branchObj) => {
      branchNamesDD.push({
        label: branchObj.bankbranch,
        value: branchObj.bankbranchid,
      });
    });
    setBranchNamesDD(branchNamesDD);
  }

  useMemo(() => setBankBranchHandler(selectedBankName), [selectedBankName]);
  useMemo(() => ifsccodeHandler(selectedBankBranch), [selectedBankBranch]);
  useMemo(
    () => selectedDistrict?.value && constituencyHandler(selectedDistrict),
    [selectedDistrict]
  );
  useMemo(() => areaHandler(), [selectedDistrict]);

  return (
    <>
      <p className="text-slate-700 text-center text-sm">
        ( field marked with
        <span className="text-red-700 text-lg font-semibold"> * </span>
        are mandatory )
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-6 border"
        noValidate
      >
        <div className="grid grid-cols-1 lg:grid-cols-4 justify-start my-2">
          <div className="col-span-1 mb-6" ref={passPhotoRef}>
            <CapturePhoto
              handleScroll={handleScroll}
              control={control}
              register={register}
              defaultName="applicantimage"
              name="Passphoto"
              required={isEdit ? false : true}
              pattern={null}
              placeholder="Lonee Image"
              type="file"
              errors={errors}
              classes={`rounded px-3 py-2 text-sm w-full `}
              setError={setError}
              clearError={clearErrors}
              // file={passphoto}
              // setPhotoBase64={setPassphoto}
              setValue={setValue}
              oldFileOnEdit={isEdit ? defaultValues.applicantimage : ""}
              setFile={setUserPassPhoto}
            />
          </div>
          <div className="lg:col-span-3">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-3">
              <Input
                defaultName="applicantname"
                register={register}
                name="Applicant name"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter applicant name"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.applicantname}
                setValue={setValue}
              />

              <Input
                defaultName="contactno"
                register={register}
                name="Contact no "
                required={true}
                pattern={reContact}
                errors={errors}
                placeholder="Enter contact no "
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.contactno}
                setValue={setValue}
              />
              <Input
                defaultName="alternativecontactno"
                register={register}
                name="Alternative contact no "
                required={false}
                pattern={reContact}
                errors={errors}
                placeholder="Enter Alternative contact no "
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="number"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.alternativecontactno}
                setValue={setValue}
              />
              <Input
                defaultName="applicantemail"
                register={register}
                name="Email"
                required={false}
                pattern={reEmail}
                errors={errors}
                placeholder="Enter email"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={defaultValues.applicantemail}
                setValue={setValue}
              />

              <Input
                defaultName="applicantdob"
                register={register}
                name="Applicant date of birth"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter applicant date of birth"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={(e) => {
                  setAge(getAgeFromDOB(e));
                }}
                defaultValue={defaultValues.applicantdob}
                setValue={setValue}
                max={minDOB}
                min={maxDOB}
              />

              <Dropdown
                defaultName="applicantgender"
                register={register}
                labelname="Gender"
                required={true}
                pattern={null}
                errors={errors}
                classes={`rounded-sm text-sm w-full`}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={genderDD}
                defaultValue={defaultValues.applicantgender}
                setValue={setValue}
                setSelected={setSelectedGenderType}
                selected={selectedGenderType}
                maxMenuHeight={120}
              />

              <TextArea
                defaultName="permanentaddress"
                register={register}
                name="Permanent address"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter permanent address"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                onChangeInput={(e) => {
                  setPermanentAddress(e);
                }}
                defaultValue={defaultValues.permanentaddress}
                setValue={setValue}
              />
              <div>
                <TextArea
                  defaultName="temporaryaddress"
                  register={register}
                  name="Temporary address"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter temporary address"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                  onChangeInput={null}
                  defaultValue={defaultValues.temporaryaddress}
                  setValue={setValue}
                />
                <input
                  type="checkbox"
                  className="rounded-sm outline-none mr-1"
                  onChange={(e) => {
                    sameAsPermanentAddressHandler("temporaryaddress", e);
                  }}
                />
                <small className="text-slate-600">
                  Same as permanent address
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-3 content-start justify-start align-top">
          <Dropdown
            defaultName="applicantrelative"
            register={register}
            labelname="Relative Type"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={applicantRelativeDD}
            defaultValue={defaultValues.applicantrelative}
            setValue={setValue}
            setSelected={setSelectedRelativeType}
            selected={selectedRelativeType}
            maxMenuHeight={120}
          />
          <Input
            defaultName="applicantrelativename"
            register={register}
            name="Relative Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter relative name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.applicantrelativename}
            setValue={setValue}
          />

          <Dropdown
            defaultName="applicantarea"
            register={register}
            labelname="Area Type"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={areaDD}
            defaultValue={defaultValues.applicantarea}
            setValue={setValue}
            setSelected={setSelectedArea}
            selected={selectedArea}
            maxMenuHeight={120}
          />

          <Input
            defaultName="education"
            register={register}
            name="Education qualification"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter education qualification"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.education}
            setValue={setValue}
          />

          <Dropdown
            defaultName="categoryid"
            register={register}
            labelname="Category"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={categoryList}
            defaultValue={defaultValues.categoryid}
            setValue={setValue}
            setSelected={setSelectedCategory}
            selected={selectedCategory}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="schemeid"
            register={register}
            labelname="Scheme"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={schemeList}
            defaultValue={defaultValues.schemeid}
            setValue={setValue}
            setSelected={setSelectedScheme}
            selected={selectedScheme}
            maxMenuHeight={256}
          />

          <Input
            defaultName="loanappliedamt"
            register={register}
            name="Amount of loan applied"
            required={true}
            pattern={numberOnly}
            errors={errors}
            placeholder="Enter amount of loan applied"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.loanappliedamt}
            setValue={setValue}
          />

          <Input
            defaultName="annualincome"
            register={register}
            name="Annual income"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter annual income"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.annualincome}
            setValue={setValue}
          />

          <Dropdown
            defaultName="districtid"
            register={register}
            labelname="District"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={districtList}
            defaultValue={defaultValues.districtid}
            setValue={setValue}
            setSelected={setSelectedDistrict}
            selected={selectedDistrict}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="constituencyid"
            register={register}
            labelname="Constituency"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={constituencyListDistrictWise || constituencyList}
            defaultValue={defaultValues.constituencyid}
            setValue={setValue}
            setSelected={setSelectedConstituency}
            selected={selectedConstituency}
            maxMenuHeight={256}
          />
          <Dropdown
            defaultName="area_id"
            register={register}
            labelname="Area"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={areaListDistrictWise || loaneeArea_List}
            defaultValue={defaultValues.area_id}
            setValue={setValue}
            setSelected={setSelected_LoaneeArea}
            selected={selected_LoaneeArea}
            maxMenuHeight={256}
          />

          <Input
            defaultName="subdivisionname"
            register={register}
            name="Sub Division"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter sub division"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.subdivisionname}
            setValue={setValue}
          />
          {/* 
          <Dropdown
            defaultName="grampanchayatid"
            register={register}
            labelname="Gram Panchayat"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={(e) => getGramPanchayatList(e)}
            control={control}
            data={gramPanchayatList}
            defaultValue={defaultValues.grampanchayatid}
            setValue={setValue}
            setSelected={setSelectedGramPanchayat}
            selected={selectedGramPanchayat}
            maxMenuHeight={120}
          /> */}

          {/* <Dropdown
            defaultName="nagarpanchayatid"
            register={register}
            labelname="Nagar Panchayat"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={(e) => getNagarPanchayatList(e)}
            control={control}
            data={nagarPanchayatList}
            defaultValue={defaultValues.grampanchayatid}
            setValue={setValue}
            setSelected={setSelectedNagarPanchayat}
            selected={selectedNagarPanchayat}
            maxMenuHeight={120}
          /> */}

          {/* <Dropdown
            defaultName="municipalcorporationid"
            register={register}
            labelname="Municipal Corporation"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={(e) => getMunicipalCorporationList(e)}
            control={control}
            data={municipalCorporationList}
            defaultValue={defaultValues.municipalcorporationid}
            setValue={setValue}
            setSelected={setSelectedMunicipalCorporation}
            selected={selectedMunicipalCorporation}
            maxMenuHeight={120}
          /> */}

          <Input
            defaultName="nagarpanchayatname"
            register={register}
            name="Nagar Panchayat"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter nagar panchayat"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.nagarpanchayatname}
            setValue={setValue}
          />

          <Input
            defaultName="grampanchayatname"
            register={register}
            name="Gram Panchayat"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter gram panchayat"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.grampanchayatname}
            setValue={setValue}
          />
          <Input
            defaultName="grampanchayatward"
            register={register}
            name="Gram Panchayat Ward"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter gram panchayat"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.grampanchayatward}
            setValue={setValue}
          />

          <Input
            defaultName="municipalcorporationname"
            register={register}
            name="Municipal Corporation"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter municipal corporation"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.municipalcorporationname}
            setValue={setValue}
          />

          <Input
            defaultName="municipalward"
            register={register}
            name="Municipal Ward"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter municipal Ward"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.municipalward}
            setValue={setValue}
          />

          <Input
            defaultName="employmentgeneration"
            register={register}
            name="Employment generation"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter employment generation"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.employmentgeneration}
            setValue={setValue}
          />

          <Input
            defaultName="policestation"
            register={register}
            name="Police station"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter police station"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues?.policestation}
            setValue={setValue}
          />

          {/* <Dropdown
            defaultName="policestation"
            register={register}
            labelname="Police station"
            required={false}
            pattern={null}
            errors={errors}
            classes={`rounded-sm text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={(e) => getPoliceStationList(e)}
            control={control}
            data={policeStationList}
            defaultValue={defaultValues.policestation}
            setValue={setValue}
            setSelected={setSelectedPoliceStation}
            selected={selectedPoliceStation}
            maxMenuHeight={120}
          /> */}

          <Input
            defaultName="postoffice"
            register={register}
            name="Post office"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter post office"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={
              gramPanchayatDetail?.postoffice || defaultValues?.postoffice
            }
            setValue={setValue}
          />
          <Input
            defaultName="pincode"
            register={register}
            name="Pin code"
            required={true}
            pattern={pinCode}
            errors={errors}
            placeholder="Enter pin code"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-sm px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={
              gramPanchayatDetail?.pincode || defaultValues?.pincode
            }
            setValue={setValue}
          />

          <TextArea
            defaultName="applicantlocation"
            register={register}
            name="Location / Landmark"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter location"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
            onChangeInput={null}
            defaultValue={defaultValues.applicantlocation}
            setValue={setValue}
          />

          <div className="lg:col-span-4 my-3">
            <fieldset className="border">
              <legend className="text-slate-700 text-lg p-3">
                Applicant Bank Details:
              </legend>
              <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-3 p-3">
                <Dropdown
                  defaultName="bankname"
                  register={register}
                  labelname="Bank Name"
                  required={false}
                  pattern={null}
                  errors={errors}
                  classes={`rounded-lg text-sm w-full z-40`}
                  setError={setError}
                  clearError={clearErrors}
                  onChangeInput={(e) => console.table(e)}
                  control={control}
                  data={bankNamesDD}
                  defaultValue={defaultValues.bankname}
                  setValue={setValue}
                  setSelected={setSelectedBankName}
                  selected={selectedBankName}
                  maxMenuHeight={256}
                />
                <Dropdown
                  defaultName="bankbranch"
                  register={register}
                  labelname="Bank branch"
                  required={false}
                  pattern={null}
                  errors={errors}
                  classes={`rounded-sm text-sm w-full`}
                  setError={setError}
                  clearError={clearErrors}
                  onChangeInput={(e) => {
                    getBankBranchList(e);
                  }}
                  control={control}
                  data={branchNamesDD}
                  defaultValue={defaultValues?.bankbranch}
                  setValue={setValue}
                  setSelected={setSelectedBankBranch}
                  selected={selectedBankBranch}
                  maxMenuHeight={256}
                />

                {/* <Input
                  defaultName="bankbranch"
                  register={register}
                  name="Bank branch"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter bank branch"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.bankbranch}
                  setValue={setValue}
                /> */}

                <Input
                  defaultName="ifsccode"
                  register={register}
                  name="IFSC code"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter IFSC code"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.ifsccode}
                  value={selectedIfsccode}
                  setValue={setValue}
                />

                <Input
                  defaultName="accountname"
                  register={register}
                  name="Account holder name"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter account holder name"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.accountname}
                  setValue={setValue}
                />

                <Input
                  defaultName="accountnumber"
                  register={register}
                  name="Account number"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder="Enter account number"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="number"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.accountnumber}
                  setValue={setValue}
                />

                <Input
                  defaultName="phoneno"
                  register={register}
                  name="Phone no"
                  required={false}
                  pattern={reContact}
                  errors={errors}
                  placeholder="Enter phone no"
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="number"
                  classes={`rounded-sm px-3 py-2 text-sm w-full`}
                  onChangeInput={null}
                  defaultValue={defaultValues.phoneno}
                  setValue={setValue}
                />
              </div>
            </fieldset>
          </div>
        </div>

        {isEdit ? (
          <section>
            {/* <div className="bg-slate-50 border min-h-[3rem] mt-4 p-3">
              <H5>Uploaded Documents:</H5>
              <div className="flex flex-wrap"></div>
            </div> */}

            <fieldset className="border bg-slate-50 p-3 grid grid-cols-1  md:grid-cols-2 gap-x-9 gap-1 mt-3 text-sm">
              <legend className="px-2 border ml-3 rounded-xs text-xs text-slate-600  p-1">
                Previously Uploaded Documents:
              </legend>
              {editApplicantDetails?.loaneedocuments?.length > 0 ? (
                <>
                  <div className=" col-span-1 md:col-span-2  ">
                    <div className=" flex flex-wrap gap-9 pb-3">
                      {editApplicantDetails?.loaneedocuments?.map(
                        (docObj, index) =>
                          docObj?.docurl ? (
                            <ApplicantDocumentPreview
                              file_name={docObj?.docname}
                              file_type={docObj?.doctype}
                              file_url={docObj?.docurl}
                              key={index}
                            />
                          ) : null
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </fieldset>
          </section>
        ) : null}

        <div className="mt-3 pt-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3">
            <PDFImageUploaderAWS
              register={register}
              defaultName="sikkimsubject"
              name={`${
                isEdit && editApplicantDetails?.sikkimsubject
                  ? "Replace Copy of Sikkim Subject Document / Image"
                  : "Attested Copy of Sikkim Subject Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={sikkimSubjectDoc}
              setFile={setSikkimSubjectDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues?.sikkimsubject : ""}
            />

            <PDFImageUploaderAWS
              register={register}
              defaultName="castcertificate"
              name={`${
                isEdit && editApplicantDetails?.castcertificate
                  ? "Replace Copy of ST/SC/OBC Document / Image"
                  : "Attested Copy of ST/SC/OBC Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={castDoc}
              setFile={setCastDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.castcertificate : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="salarycertificate"
              name={`${
                isEdit && editApplicantDetails?.salarycertificate
                  ? "Replace Surety's Parcha/ Salary Document / Image"
                  : "Surety's Parcha/ Salary Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDoc}
              setFile={setSalaryDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.salarycertificate : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="tradelicense"
              name={`${
                isEdit && editApplicantDetails?.tradelicense
                  ? "Replace Trade Licence/ Firm Registration Document / Image"
                  : "Trade Licence/ Firm Registration Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={tradeLicenseDoc}
              setFile={setTradeLicenseDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.tradelicense : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="incomecertificate"
              name={`${
                isEdit && editApplicantDetails?.incomecertificate
                  ? "Replace Income Certificate Document / Image"
                  : "Income Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={incomeCertDoc}
              setFile={setIncomeCertDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.incomecertificate : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="adharcard"
              name={`${
                isEdit && editApplicantDetails?.adharcard
                  ? "Replace Adhar Card Document / Image"
                  : "Adhar Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={adharCardDoc}
              setFile={setAdharCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.adharcard : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="votercard"
              name={`${
                isEdit && editApplicantDetails?.votercard
                  ? "Replace Voter Card Document / Image"
                  : "Voter Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={voterCardDoc}
              setFile={setVoterCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.votercard : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="bankpassbook"
              name={`${
                isEdit && editApplicantDetails?.votercard
                  ? "Replace Bank Passbook Document / Image"
                  : "Bank Passbook Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={bankPassbookDoc}
              setFile={setBankPassbookDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.bankpassbook : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="parchanoc"
              name={`${
                isEdit && editApplicantDetails?.parchanoc
                  ? "Replace NOC of Parcha Holder Document / Image"
                  : "NOC of Parcha Holder Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={nocParchaDoc}
              setFile={setNocParchaDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.parchanoc : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="diasbilitycertificate"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Disability Certificate Document / Image"
                  : "Disability Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-sm px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={disabilityCertDoc}
              setFile={setDisabilityCertDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.diasbilitycertificate : ""}
            />
          </div>
        </div>
        <div className="mt-4 pt-4 border-t ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3">
            <InputCheckbox
              defaultName="sikkimsubjectchecked"
              register={register}
              name="Attested Copy of Sikkim Subject"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSikkimSubjectChecked}
              defaultValue={sikkimSubjectChecked}
            />

            <InputCheckbox
              defaultName="salarycertificatechecked"
              register={register}
              name="Surety's Parcha/ Salary Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSalaryDocChecked}
              defaultValue={salaryDocChecked}
            />

            <InputCheckbox
              defaultName="castcertificatechecked"
              register={register}
              name="Attested Copy of ST/SC/OBC"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setCastCertificateChecked}
              defaultValue={castCertificateChecked}
            />

            <InputCheckbox
              defaultName="tradelicencechecked"
              register={register}
              name="Trade Licence/ Firm Registration Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setTradeLicenceChecked}
              defaultValue={tradeLicenceChecked}
            />
            <InputCheckbox
              defaultName="incomecertificatechecked"
              register={register}
              name="Income Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setIncomeCertificateChecked}
              defaultValue={incomeCertificateChecked}
            />
            <InputCheckbox
              defaultName="adharchecked"
              register={register}
              name="Adhar Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setAdharCardChecked}
              defaultValue={adharCardChecked}
            />
            <InputCheckbox
              defaultName="votercardchecked"
              register={register}
              name="Voter Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setVoterCardChecked}
              defaultValue={voterCardChecked}
            />
            <InputCheckbox
              defaultName="bankpasebookchecked"
              register={register}
              name="Bank Passbook Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setBankPassbookChecked}
              defaultValue={bankPassbookChecked}
            />
            <InputCheckbox
              defaultName="nocparchachecked"
              register={register}
              name="NOC of Parcha Holder Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setNocParchaDocChecked}
              defaultValue={nocParchaDocChecked}
            />
            <InputCheckbox
              defaultName="disabilitycertificatechecked"
              register={register}
              name="Disability Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setDisabilityCertificateChecked}
              defaultValue={disabilityCertificateChecked}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditApplicantDetails({});
              setShowAddForm(false);
              reset();
            }}
            aria-label="Cancel FAQ Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}
