import React from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

import { Menu } from "@headlessui/react";

import { Fragment } from "react";
import { FiEye, FiSettings } from "react-icons/fi";
import { Transition } from "react-transition-group";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaEdit, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";

export default function PhotosList({
  // photoGalleryList,
  setEditPhotoDetails,
  setShowAddForm,
  mediaAlbumList,
  setShowUploadPhotosForm,
  setViewPhotos,
  setAlbumId,
  setMediaId,
  setShowDelete,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const onClickEdit = (photo) => {
    setEditPhotoDetails(photo);
    setShowAddForm(true);
  };

  const uploadAlbumPhoto = (photo) => {
    setEditPhotoDetails(photo);
    setShowUploadPhotosForm(true);
  };

  const handleViewPhotos = (albumid) => {
    setAlbumId(albumid);
    setViewPhotos(true);
  };

  const handleDeleteAlbum = (albumid) => {
    setMediaId(albumid);
    setShowDelete(true);
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
          >
            Album Title
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            StartDate
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            EndDate
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            Description
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {mediaAlbumList.length > 0 &&
          mediaAlbumList.map((photo, index) => (
            <tr key={index}>
              {/* <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center ">
                <img
                  src={photo.media_album_file}
                  className={"w-14 h-14 rounded-full border"}
                />
              </td> */}
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="font-medium">
                  {photo.media_album_title?.slice(0, 15)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="font-medium">
                  {getFormattedDateInputDate(photo.media_album_startdate)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="font-medium">
                  {getFormattedDateInputDate(photo.media_album_enddate)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="font-medium">
                  {photo.media_album_description?.slice(0, 15)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end ">
                  <Menu as="div" className="block text-left">
                    <div>
                      <Menu.Button className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                        <div className="flex items-center gap-3">
                          <FiSettings size={16} />
                          <p>Manage</p>
                        </div>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 origin-top-right absolute right-14 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => onClickEdit(photo)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                <div className="flex items-center gap-3 text-black hover:text-primary">
                                  <FaEdit size={16} />
                                  <p className="">Edit Album</p>
                                </div>
                              </div>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => uploadAlbumPhoto(photo)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                <div className="flex items-center gap-3 text-black hover:text-primary">
                                  <AiOutlinePlus size={16} />
                                  <p className="">Add Album Photos</p>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() =>
                                  handleViewPhotos(photo?.media_albums_id)
                                }
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                <div className="flex items-center gap-3 text-black hover:text-primary">
                                  <FiEye size={16} />
                                  <p className="">View Photos</p>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() =>
                                  handleDeleteAlbum(photo?.media_albums_id)
                                }
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                <div className="flex items-center gap-3 text-black hover:text-primary">
                                  <FaRegTrashAlt size={16} />
                                  <p className="">Delete</p>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
