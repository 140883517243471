import React from "react";
import { CiCalendar } from "react-icons/ci";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateMMMYYYY } from "../../../../utilities/dateFunctions/formatdate";
import ReportCardToPrint from "../DailyReport/DailyReport-Component/ReportCardToPrint";

export default function MonthlyReportListPrint({ monthlyReportList, ref }) {
  return (
    <>
      <>
        <div className="flex items-start justify-center gap-1 my-1">
          <CiCalendar size={18} />
          <div className="font-medium text-[14px]">
            <span>
              {monthlyReportList[0]?.tr_date
                ? getFormattedDateMMMYYYY(monthlyReportList[0]?.tr_date)
                : " -- , --"}
            </span>
          </div>
        </div>
        <section
          className={` !text-[9px] bg-slate-50 border
                      grid grid-cols-4 gap-2 p-2 mb-3`}
        >
          <div className="col-span-4 text-slate-800 flex justify-between items-center">
            <div className="flex items-center gap-6">
              <div>
                Category:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.categoryname
                    ? monthlyReportList[0]?.categoryname
                    : "N/A"}
                </span>
              </div>
              <div>
                District:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.districtname
                    ? monthlyReportList[0]?.districtname
                    : "N/A"}
                </span>
              </div>
              <div>
                Constituency:{" "}
                <span className="font-semibold ml-1">
                  {monthlyReportList[0]?.constituencyname
                    ? monthlyReportList[0]?.constituencyname
                    : "N/A"}
                </span>
              </div>
            </div>
            <div className="justify end">
              No. of Repayments:{" "}
              <span className="font-semibold ml-1">
                {monthlyReportList.length || 0}
              </span>
            </div>
          </div>

          <ReportCardToPrint
            title={"Cash"}
            principal={monthlyReportList[0]?.repay_bal_cash_amt}
            interest={monthlyReportList[0]?.repay_int_cash_amt}
          />
          <ReportCardToPrint
            title={"Cheque"}
            principal={monthlyReportList[0]?.repay_bal_cheque_amt}
            interest={monthlyReportList[0]?.repay_int_cheque_amt}
          />
          <ReportCardToPrint
            title={"Branch"}
            principal={monthlyReportList[0]?.repay_bal_branch_amt}
            interest={monthlyReportList[0]?.repay_int_branch_amt}
          />
          <ReportCardToPrint
            title={"Total"}
            principal={
              +monthlyReportList[0]?.repay_bal_cash_amt +
              +monthlyReportList[0]?.repay_bal_cheque_amt +
              +monthlyReportList[0]?.repay_bal_branch_amt
            }
            interest={
              +monthlyReportList[0]?.repay_int_cash_amt +
              +monthlyReportList[0]?.repay_int_cheque_amt +
              +monthlyReportList[0]?.repay_int_branch_amt
            }
          />
        </section>
      </>

      <table className="min-w-full divide-y divide-slate-200">
        <thead className="bg-slate-200 text-[11px] ">
          <tr>
            <th
              scope="col"
              className="px-2 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              SI No
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            {/* <th
            scope="col"
            className="px-2  text-left font-medium text-slate-900  tracking-wider"
          >
            Loan No
          </th> */}
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Payment Mode
            </th>

            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Paid
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900  tracking-wider"
            >
              Interest Paid
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y text-[10px] divide-slate-100 text-slate-800">
          {monthlyReportList?.length > 0 ? (
            monthlyReportList?.map((monthlyObj, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-2 py-1 whitespace-nowrap">{index + 1}</td>
                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.loanee_id}
                </td>
                {/* <td className="px-2 py-1 whitespace-nowrap">
                {monthlyObj?.loan_number}
              </td> */}
                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.applicantname}
                </td>
                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.applicantrelativename}
                </td>
                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.pay_mode}
                </td>

                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.tr_amount}
                </td>
                <td className="px-2 py-1 whitespace-nowrap">
                  {monthlyObj?.int_credit}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
