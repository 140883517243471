import React, { Fragment, useState } from "react";
import { IconContext } from "react-icons";
import { FaFilePdf, FaUser } from "react-icons/fa";
import Button from "../../../reusable-components/buttons/Button";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { cancel_button, linkTextPrimary } from "../../../theme/lightTheme";
import { FiUser } from "react-icons/fi";
import { MdEmail, MdLocationPin } from "react-icons/md";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
  getTimeFromDate,
} from "../../../utilities/dateFunctions/formatdate";
import moment from "moment";

export default function AuditTrailList({ auditTrailList }) {
  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr className="bg-slate-200">
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider "
            >
              User Name
            </th>
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider"
            >
              Activity Time
            </th>
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider "
            >
              User IP
            </th>
            {/* <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider "
            >
              User Host Name
            </th> */}
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider "
            >
              Login Status
            </th>
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900 tracking-wider w-1/4 "
            >
              Other Activity
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {auditTrailList.length > 0 &&
            auditTrailList.map((trail, index) => (
              <tr key={index}>
                <td className="px-3 py-4 whitespace-nowrap text-sm font-medium">
                  {trail?.username}
                </td>

                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  <p className="text-xs font-medium">
                    {getFormattedDateDDMMMYYYYDate(trail?.activitytime)}
                  </p>

                  <p className="text-xs">{trail?.recordedtime}</p>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  {trail?.userip}
                </td>
                {/* <td className="px-3 py-4 whitespace-nowrap text-sm">
                  {trail?.userhostname}
                </td> */}
                <td className="px-3 py-4 whitespace-nowrap text-sm">
                  {trail?.loginstatus ? "Logged In" : "Not Logged In"}
                </td>
                <td className="px-3 py-4 text-sm font-medium">
                  {trail?.otheractivity}
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
    </Fragment>
  );
}
