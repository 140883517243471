import React, { useMemo, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import { MdPermMedia } from "react-icons/md";
import Dropzone from "react-dropzone";
import { AiOutlineCloudUpload, AiOutlineFile } from "react-icons/ai";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../../theme/lightTheme";

import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import {
  MEDIA_CONFIG_URL,
  MEDIA_ITEMS_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function UploadPhotosforAlbums({
  setShowUploadPhotosForm,
  editPhotoDetails,
  setEditPhotoDetails,
  getMediaItemsList,
}) {
  const [uploadedPhotos, setUploadedPhotos] = useState();
  const [files, setFiles] = useState();

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();

  const isEdit = false;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    // defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const defaultValues = {
    media_item_type: "Photos",
    media_album_id: +editPhotoDetails?.media_albums_id,
  };

  function filesUploadHandler() {
    if (uploadedPhotos) {
      let files_list = uploadedPhotos?.map((file) => (
        <li key={file.path} className={"flex gap-1"}>
          <AiOutlineFile size={18} /> {file.path} - {file.size} bytes
        </li>
      ));
      setFiles(files_list);
    }
  }

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  async function updateMediaDocument() {
    let file_url = "";
    let file_list = [];

    if (uploadedPhotos) {
      return await Promise.all(
        uploadedPhotos?.map(async (file) => {
          file_url = await uploadFile(file);
          await file_list.push(
            createFileObj(file?.name, "DGM Accounts", file_url)
          );
        })
      ).then(async () => {
        return await file_list;
      });
    }
  }

  const onSubmit = async (data) => {
    let file_List = await updateMediaDocument();

    data.media_item_file = JSON.stringify(file_List);

    try {
      let response = "";
      if (!isEdit) {
        data.created_by = auth.userid;
        data.media_item_type = defaultValues?.media_item_type;
        data.media_album_id = +defaultValues?.media_album_id;
        response = await axiosPrivate.post(
          `${MEDIA_ITEMS_CONFIG_URL}/saveMediaItemsDetails`,
          data,
          {
            signal: controller.signal,
          }
        );
      } else {
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditPhotoDetails({});
          // showToast("Photo has been updated successfully", "success");
        } else {
          showToast("Photo has been added successfully", "success");
        }
        getMediaItemsList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowUploadPhotosForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useMemo(() => filesUploadHandler(), [uploadedPhotos]);

  const handleClearDocumemnt = (file_key) => {
    const fileList = files.filter((obj) => obj.key !== file_key);

    setFiles(fileList);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <MdPermMedia />
          </IconContext.Provider>
          &nbsp; Upload Photos
        </H2withIcon>

        <span
          className="flex items-center cursor-pointer hover:underline underline-offset-2 "
          onClick={() => setShowUploadPhotosForm(false)}
        >
          <BiChevronLeft size={24} /> back
        </span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-9 text-slate-600 text-sm ">
          <p className="text-slate-700 font-medium text-base">
            Upload Photos for the Selected Album
          </p>
          <Dropzone
            onDrop={(acceptedFiles) => setUploadedPhotos(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section
                className="h-40 border-dashed border-2 border-slate-500 rounded 
                  bg-slate-50 flex justify-center items-center"
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="text-center flex justify-center items-center flex-col text-slate-500 text-sm">
                    <AiOutlineCloudUpload size={40} />
                    Drag 'n' drop some files here, or click to select files
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          {files ? (
            <aside className="mt-3 text-primary">
              <ul>
                <li className="flex gap-4">
                  {files?.map((filesObj) => (
                    <>
                      <div className="flex  gap-4 items-center border p-1 bg-slate-100 hover:bg-slate-200 rounded-full">
                        <span>{filesObj?.key}</span>
                        <button
                          type="button"
                          className="border rounded-full h-6 w-6 bg-primary text-white hover:bg-red-600"
                          onClick={() => handleClearDocumemnt(filesObj.key)}
                        >
                          <span>x</span>
                        </button>
                      </div>
                    </>
                  ))}
                </li>
              </ul>
            </aside>
          ) : null}
        </div>

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Submit"}
            className={button}
          />
          <Button
            onClick={() => {
              setValue("categoryname", "");
              setShowUploadPhotosForm(false);
            }}
            aria-label="Cancel Photo Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}
