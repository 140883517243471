import React, { useEffect, useState } from "react";
import { IoReloadCircle } from "react-icons/io5";
import { CAPTCHA_URL } from "../../api/api_routing_urls";
import axios from "../../api/axios";
import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import Error from "../../reusable-components/outputs/Error";

const Captcha = ({ setCaptchaSuccess, captchaReset, setCaptchaInput }) => {
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState("");
  const [captcha, setCaptcha] = useState([]);
  const [isCaptcahLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState();

  const createCaptcha = async () => {
    //clear the contents of captcha div first
    if (document?.getElementById("captcha")?.innerHTML) {
      document.getElementById("captcha").innerHTML = "";
    }

    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 200;
    canv.height = 40;
    let ctx = canv.getContext("2d");
    ctx.font = "20px Deportees";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    setCode(captcha.join(""));

    if (document?.getElementById("captcha")) {
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    }
  };

  const validateCaptcha = (e) => {
    if (document.getElementById("cpatchaTextBox").value === code) {
      setCaptchaInput(e.target.value);
      setCaptchaSuccess(true);
    } else {
      setCaptchaSuccess(false);
    }
  };

  const getCaptchaFromServer = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(CAPTCHA_URL);

      if (response?.data?.status === 201) {
        setCaptcha(response?.data?.captcha);
        setIsLoading(false);
      } else {
        setCaptcha([]);
        setIsLoading(true);
      }
    } catch (err) {
      console.error(err);
      setCaptchaSuccess(false);
      if (err.message.includes("429")) {
        setCaptchaError(true);
        setCaptchaErrorMessage("Too Many Requests, please try again later.");
      } else {
        setCaptchaError(true);
        setCaptchaErrorMessage("Some error occured, please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    createCaptcha();
  }, [isCaptcahLoading]);

  // useEffect(() => {
  //   getCaptchaFromServer();
  // }, []);

  useEffect(() => {
    getCaptchaFromServer();
    setCaptchaSuccess(false);
  }, [captchaReset]);

  return (
    <>
      {isCaptcahLoading ? (
        <section className=" p-3 mt-3 bg-slate-50">
          <SpinnerWithText />
        </section>
      ) : captchaError ? (
        <section className="my-3">
          <Error classes={"xl:text-lg "} message={captchaErrorMessage} />
        </section>
      ) : (
        <section className=" p-3 bg-slate-50">
          <div id="captcha" className="h-[40px]" />
          <span className="captchaimg h-20 w-full"></span>
          <input
            id="cpatchaTextBox"
            type="text"
            placeholder="Captcha"
            className="rounded text-sm border border-slate-400 w-48 p-1"
            onChange={(e) => validateCaptcha(e)}
            autoComplete="off"
          />
          {/* un comment for reset */}
          <button
            id="captchaReset"
            type="reset"
            onClick={() => getCaptchaFromServer()}
            className="text-slate-500 ml-2"
          >
            <IoReloadCircle size={24} />
          </button>
        </section>
      )}
    </>
  );
};
export default Captcha;
