import React from "react";
import H3 from "../../../../reusable-components/headings/H3";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import user_image from "../../../../assets/userimage.png";
import { MdOutlineMailOutline, MdPhoneAndroid } from "react-icons/md";
import ApplicantDocumentPreview from "./ApplicantDocumentPreview";
import ApplicantDataPreview from "./ApplicantDataPreview";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

const GuarantorDetails = ({ guarantor_details }) => {
  return (
    <>
      <section className="mt-9">
        <H3>Guarantor details </H3>

        <>
          {guarantor_details[0]?.guarantorname !== null && (
            <section>
              {guarantor_details?.map((guarantorObj, index) => (
                <div key={index}>
                  <fieldset className="p-3 grid grid-cols-4 gap-1 md:grid-cols-5  items-start bg-slate-50 border text-sm mb-9">
                    <div className="col-span-4 md:col-span-1 my-1 border-b md:border-0 pb-1 ">
                      <img
                        src={
                          guarantorObj?.guarantorimage
                            ? guarantorObj?.guarantorimage
                            : user_image
                        }
                        className="h-20 md:h-28 w-20 md:w-28 rounded-sm"
                        alt="User Photo"
                      />
                      <div className="mt-2">
                        <div className="text-slate-700  font-medium leading-5">
                          {guarantorObj?.guarantorname}
                        </div>
                        <div className="text-slate-700 text-xs flex items-center gap-1 mt-1">
                          <MdPhoneAndroid size={13} />
                          {guarantorObj?.guarantorcontactno || "---"}/{" "}
                          {guarantorObj?.alternativecontactno || "---"}
                        </div>
                        <div className="text-slate-700 text-xs flex items-center gap-1">
                          <MdOutlineMailOutline size={13} />{" "}
                          {guarantorObj?.guarantoremail || "---"}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 grid grid-cols-1 gap-1 md:grid-cols-2">
                      <ApplicantDataPreview
                        label="Relationship"
                        dataValue={guarantorObj?.guarantorrelationship}
                      />

                      <ApplicantDataPreview
                        label="Guarantor Type"
                        dataValue={guarantorObj?.guarantortype}
                      />

                      <ApplicantDataPreview
                        label="Area"
                        dataValue={guarantorObj?.area || "---"}
                      />

                      <ApplicantDataPreview
                        label="Address"
                        dataValue={guarantorObj?.guarantoraddress}
                      />

                      {guarantorObj?.guarantortype
                        ?.toLowerCase()
                        ?.includes("parcha") && (
                        <>
                          <ApplicantDataPreview
                            label="Khasra No."
                            dataValue={guarantorObj?.khasranumber || "---"}
                          />
                          <ApplicantDataPreview
                            label="Khatian No."
                            dataValue={guarantorObj?.khatiannumber || "---"}
                          />
                          <ApplicantDataPreview
                            label="Reg. Under"
                            dataValue={guarantorObj?.regundername || "---"}
                          />
                          <ApplicantDataPreview
                            label="Block"
                            dataValue={
                              guarantorObj?.situatedunderblock || "---"
                            }
                          />
                        </>
                      )}
                      {(guarantorObj?.guarantortype
                        ?.toLowerCase()
                        ?.includes("gov") ||
                        guarantorObj?.guarantortype
                          ?.toLowerCase()
                          ?.includes("salaried")) && (
                        <>
                          <ApplicantDataPreview
                            label="Dept. Name"
                            dataValue={guarantorObj?.departmantname || "---"}
                          />
                          <ApplicantDataPreview
                            label="Gross Salary"
                            dataValue={
                              formatINRCurrency(guarantorObj?.grosssalary) ||
                              "---"
                            }
                          />
                          <ApplicantDataPreview
                            label="Net Salary"
                            dataValue={
                              formatINRCurrency(guarantorObj?.netsalary) ||
                              "---"
                            }
                          />
                          <ApplicantDataPreview
                            label="Retirement Date"
                            dataValue={
                              guarantorObj?.retirementdate
                                ? getFormattedDateDDMMMYYYYDate(
                                    guarantorObj?.retirementdate
                                  )
                                : "---"
                            }
                          />
                          <ApplicantDataPreview
                            label="Deduction Amt"
                            dataValue={
                              formatINRCurrency(
                                guarantorObj?.salarydeductionamount
                              ) || "---"
                            }
                          />
                          <ApplicantDataPreview
                            label="Deduction Date"
                            dataValue={
                              guarantorObj?.deductiondate
                                ? getFormattedDateDDMMMYYYYDate(
                                    guarantorObj?.deductiondate
                                  )
                                : "---"
                            }
                          />
                          <ApplicantDataPreview
                            label="Undertaking"
                            dataValue={guarantorObj?.undertaking || "---"}
                          />
                        </>
                      )}

                      {/* <span className="text-blue-500 cursor-pointer">
                      View Documents
                    </span> */}
                    </div>

                    {guarantorObj?.guarantorfiles?.length > 0 && (
                      <>
                        <div className=" col-span-5 border-t mt-3 w-full">
                          <p className="text-slate-600 text-sm py-1 text-center">
                            Digital Documents
                          </p>
                          <div className=" text-center mx-auto flex flex-wrap gap-6 ">
                            {guarantorObj?.guarantorfiles?.map(
                              (docObj, index) => (
                                <div className="col-span-1 ">
                                  <ApplicantDocumentPreview
                                    file_name={docObj?.docname}
                                    file_type={docObj?.doctype}
                                    file_url={docObj?.docurl}
                                    key={index}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {guarantorObj?.guarantorchecklist?.length > 0 && (
                      <>
                        <div className=" col-span-5 border-t mt-3 w-full">
                          <p className="text-slate-600 text-sm py-1 text-center">
                            Physical Documents List
                          </p>
                          <div className=" text-center mx-auto flex flex-wrap gap-1 ">
                            {guarantorObj?.guarantorchecklist?.map(
                              (docObj, index) => (
                                <div
                                  key={index}
                                  className="col-span-1 text-sm font-medium"
                                >
                                  {docObj?.check ? (
                                    <div className=" bg-white border text-slate-700 rounded-full px-2 py-0.5 font-semibold">
                                      {+index + 1}. {docObj?.file}
                                    </div>
                                  ) : null}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </fieldset>
                </div>
              ))}
            </section>
          )}
        </>
      </section>
    </>
  );
};

export default GuarantorDetails;
