/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from "react";
import { IoPrintOutline } from "react-icons/io5";
import ReactToPrint from "react-to-print";
import { REPAYMENT_CONFIG_URL } from "../../../../api/api_routing_urls";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import useGetAxios from "../../../../hook/useGetAxios";
import { button } from "../../../../theme/lightTheme";

export const PrintNOCApplication = (props) => {
  const componentRef = useRef();

  return (
    <>
      <div className="my-6 bg-white">
        <ReactToPrint
          trigger={() => (
            <button className={button}>
              <div className="flex gap-1 items-center">
                <IoPrintOutline size={20} />{" "}
                <span>Print / Download {props?.buttonName}</span>
              </div>
            </button>
          )}
          content={() => componentRef.current}
          pageStyle="A4"
          documentTitle={props?.reportTitle}
          bodyClass="bg-white"
        />
      </div>
      <div className="hidden">
        {/* <div className=""> */}
        <section ref={componentRef} className="bg-white ">
          {props?.children ? (
            <div className="mt-3 bg-white text-justify">{props?.children}</div>
          ) : null}
        </section>
      </div>
    </>
  );
};
