import React, { useEffect, useRef, Fragment, useState } from "react";
import { MdAssignmentTurnedIn, MdPrint } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  GET_INTEREST_LEDGER_DETAILS,
  GET_INTEREST_ON_INTEREST_DETAILS,
  GET_LEDGER_DETAILS,
  GET_PENAL_INTEREST_DETAILS,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import SearchField from "../../../../reusable-components/search/SearchField";
import LedgerList from "./LedgerList";
import InterestLedgerList from "./InterestLedgerList";
import PenalInterestList from "./PenalInterestList";
import InterestOnInterestList from "./InterestOnInterestList";
import LedgerListPrint from "./ListToPrint/LedgerListPrint";
import { PrintReport } from "../PrintReport/PrintReport";

const LedgerConfig = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [ledgerList, setLedgerList] = useState({});
  const [interestLedgerList, setInterestLedgerList] = useState({});
  const [penalInterestList, setPenalInterestList] = useState({});
  const [interestOnInterestList, setInterestOnInterestList] = useState({});

  const [selectedTab, setSelectedTab] = useState("ledger");

  const controller = new AbortController();

  const getLedgerDetail = async () => {
    setIsLoading(true);

    try {
      let responseLedger = await axiosPrivate.get(
        GET_LEDGER_DETAILS,
        {
          params: {
            loan_number: searchText || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (responseLedger.data) {
        setIsLoading(false);
        setLedgerList(responseLedger?.data?.ledgerDetails);
      }
      let responseInterestLedger = await axiosPrivate.get(
        GET_INTEREST_LEDGER_DETAILS,
        {
          params: {
            loan_number: searchText || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (responseInterestLedger.data) {
        setIsLoading(false);
        setInterestLedgerList(responseInterestLedger?.data?.ledgerDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPenalInterestDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_PENAL_INTEREST_DETAILS,
        {
          params: {
            loan_number: searchText || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setPenalInterestList(response?.data?.ledgerDetails?.pi_ledger_list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInterestOnInterestDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_INTEREST_ON_INTEREST_DETAILS,
        {
          params: {
            loan_number: searchText || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setInterestOnInterestList(
          response?.data?.ledgerDetails?.ioi_ledger_list
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLedgerDetail();
      getPenalInterestDetail();
      getInterestOnInterestDetail();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  console.log("ledgerList", ledgerList);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <>
          <LoadingModal message={"Loading"} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdAssignmentTurnedIn />{" "}
              <span className="pl-1.5">Ledger Details</span>
            </H2withIcon>

            <>
              <section className="flex justify-between items-center gap-4 w-1/3 mb-4">
                <SearchField
                  placeholder="Search loan number"
                  defaultValue={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                  onClick={() => {
                    getLedgerDetail(searchText || "");

                    getPenalInterestDetail(searchText || "");
                  }}
                  classes={"bg-primary"}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") {
                      getLedgerDetail(searchText || "");

                      getPenalInterestDetail(searchText || "");
                    }
                  }}
                />
              </section>
            </>
          </div>
        </>
      )}

      <section className="grid grid-cols-1">
        <div className="flex gap-1 text-primary text-sm my-1">
          <p
            className={`${
              selectedTab === "ledger"
                ? " bg-slate-700 text-white"
                : "text-slate-500 "
            } rounded-sm cursor-pointer px-3 py-1`}
            onClick={() => setSelectedTab("ledger")}
          >
            Ledger
          </p>
          <p
            className={`${
              selectedTab === "int_ledger"
                ? " bg-slate-700 text-white"
                : "text-slate-500 "
            } rounded-sm cursor-pointer px-3 py-1`}
            onClick={() => setSelectedTab("int_ledger")}
          >
            Interest Ledger
          </p>
          <p
            className={`${
              selectedTab === "int_penal"
                ? " bg-slate-700 text-white"
                : "text-slate-500 "
            } rounded-sm cursor-pointer px-3 py-1`}
            onClick={() => setSelectedTab("int_penal")}
          >
            Penal Interest
          </p>
          <p
            className={`${
              selectedTab === "int_on_interest"
                ? " bg-slate-700 text-white"
                : "text-slate-500 "
            } rounded-sm cursor-pointer px-3 py-1`}
            onClick={() => setSelectedTab("int_on_interest")}
          >
            Interest on Interest
          </p>
        </div>
        {selectedTab === "ledger" && (
          <>
            <LedgerList ledgerList={ledgerList} />
            {ledgerList?.length > 0 ? (
              <PrintReport reportTitle={"Daily Report"}>
                <LedgerListPrint ledgerList={ledgerList} />
              </PrintReport>
            ) : null}
          </>
        )}
        {selectedTab === "int_ledger" && (
          <>
            <InterestLedgerList ledgerList={interestLedgerList} />
          </>
        )}
        {selectedTab === "int_penal" && (
          <>
            <PenalInterestList penalList={penalInterestList} />
          </>
        )}
        {selectedTab === "int_on_interest" && (
          <>
            <InterestOnInterestList ioiList={interestOnInterestList} />
          </>
        )}
      </section>
    </Dashboard>
  );
};

export default LedgerConfig;
