import React from "react";

const LabelWithDetailPrint = ({ label, value, isApprox, noRecord }) => {
  if (noRecord) {
    value = 0;
  }
  return (
    <>
      <div className="flex text-[11px] items-center py-[1px]">
        <label htmlFor="lable_with_detil">{label} :</label>
        <div
          id="lable_with_detil"
          className="font-semibold text-slate-900 rounded-sm px-1 "
        >
          {value || noRecord === true ? (
            <>{isApprox ? `${value} (approx.)` : value}</>
          ) : (
            <>--</>
          )}
        </div>
      </div>
    </>
  );
};
export default LabelWithDetailPrint;
