import React, { Fragment } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../../../reusable-components/buttons/Button";
import H2 from "../../../reusable-components/headings/H2";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import { linkTextSecondary } from "../../../theme/lightTheme";

const AboutSection = ({ aboutUsSummary, isAboutLoading }) => {
  return (
    <>
      <H2>About Us</H2>
      {isAboutLoading ? (
        <SpinnerWithText />
      ) : (
        <Fragment>
          <Fragment>
            <section className="absolute top-0 z-0 left-0 overflow-clip hidden lg:block">
              <svg
                width="383"
                height="364"
                viewBox="0 0 383 364"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_452_90)">
                  <rect
                    x="96.4771"
                    y="12.707"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 96.4771 12.707)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="68.771"
                    y="40.4141"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 68.771 40.4141)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="41.0654"
                    y="68.1172"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 41.0654 68.1172)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="13.3599"
                    y="95.8223"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 13.3599 95.8223)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="151.888"
                    y="12.707"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 151.888 12.707)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="124.183"
                    y="40.4141"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 124.183 40.4141)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="96.4771"
                    y="68.1172"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 96.4771 68.1172)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="68.771"
                    y="95.8223"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 68.771 95.8223)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="41.0654"
                    y="123.529"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 41.0654 123.529)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="13.3599"
                    y="151.236"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 13.3599 151.236)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="179.594"
                    y="40.4141"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 179.594 40.4141)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="151.888"
                    y="68.1172"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 151.888 68.1172)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="124.183"
                    y="95.8223"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 124.183 95.8223)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="96.4771"
                    y="123.529"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 96.4771 123.529)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="68.771"
                    y="151.236"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 68.771 151.236)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="41.0654"
                    y="178.941"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 41.0654 178.941)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="207.3"
                    y="68.1172"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 207.3 68.1172)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="179.594"
                    y="95.8223"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 179.594 95.8223)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="151.888"
                    y="123.529"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 151.888 123.529)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="124.183"
                    y="151.236"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 124.183 151.236)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="96.4771"
                    y="178.941"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 96.4771 178.941)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="68.771"
                    y="206.646"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 68.771 206.646)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="235.005"
                    y="95.8223"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 235.005 95.8223)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="207.3"
                    y="123.529"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 207.3 123.529)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="179.594"
                    y="151.236"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 179.594 151.236)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="151.888"
                    y="178.941"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 151.888 178.941)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="124.183"
                    y="206.646"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 124.183 206.646)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="96.4771"
                    y="234.352"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 96.4771 234.352)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="262.711"
                    y="123.529"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 262.711 123.529)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="235.005"
                    y="151.236"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 235.005 151.236)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="207.3"
                    y="178.941"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 207.3 178.941)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="179.594"
                    y="206.646"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 179.594 206.646)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="151.888"
                    y="234.352"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 151.888 234.352)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                  <rect
                    x="124.183"
                    y="262.059"
                    width="9.40953"
                    height="9.40953"
                    transform="rotate(45 124.183 262.059)"
                    fill="#96E6B3"
                    fillOpacity="0.6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_452_90">
                    <rect width="383" height="364" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </section>
          </Fragment>
          <p className="text-justify leading-7 text-slate-700 text-sm">
            {Object.keys(aboutUsSummary).length > 0 &&
              aboutUsSummary.shortsummary}
          </p>
          <Link to="/about-us">
            <Button
              className={`${linkTextSecondary} flex justify-start items-center w-full -ml-2 pt-1`}
            >
              Know More &nbsp;&nbsp;
              <FaArrowRight />
            </Button>
          </Link>
        </Fragment>
      )}
    </>
  );
};

export default AboutSection;
