const getBase64 = (file, cb) => {
  if (!file) {
    console.error("No file provided.");
    return;
  }

  const reader = new FileReader();

  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (error) {
    console.error("Error: ", error);
  };

  reader.readAsDataURL(file);
};

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl?.split(","),
    mime = arr[0]?.match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr?.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

module.exports = {
  getBase64,
  dataURLtoFile,
};
