import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { MUNICIPAL_CORPORATION_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddMunicipalCorporationForm from "./AddMunicipalCorporationForm";
import MunicipalCorporationList from "./MunicipalCorporationList";

const MunicipalCorporationConfig = () => {
  const { auth } = useAuth();

  const [municipalCorporationList, setMunicipalCorporationList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editMunicipalCorporationDetails, setEditMunicipalCorporationDetails] =
    useState({});
  const isEdit =
    Object.keys(editMunicipalCorporationDetails).length > 0 ? true : false;
  const controller = new AbortController();
  const getMunicipalCorporationList = async () => {
    try {
      const response = await axiosPrivate.get(
        MUNICIPAL_CORPORATION_CONFIG_URL,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 &&
        setMunicipalCorporationList(response.data.municipalCorporationList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  useEffect(() => {
    let isMounted = true;
    getMunicipalCorporationList();
    setEditMunicipalCorporationDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Municipal Corporation"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${
          isEdit ? "Update Municipal Corporation" : "Add Municipal Corporation"
        }`}
        isAdd={true}
      >
        <AddMunicipalCorporationForm
          getMunicipalCorporationList={getMunicipalCorporationList}
          editMunicipalCorporationDetails={editMunicipalCorporationDetails}
          setEditMunicipalCorporationDetails={
            setEditMunicipalCorporationDetails
          }
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <MunicipalCorporationList
          municipalCorporationList={municipalCorporationList}
          setEditMunicipalCorporationDetails={
            setEditMunicipalCorporationDetails
          }
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default MunicipalCorporationConfig;
