const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getFormattedDateInputDate = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yy}-${mm}-${dd}`;
};

const getFormattedDateDDMMMYYYYDate = (date) => {
  let dd, mm, yy, MMM;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm;
  MMM = monthNames[mm];
  return `${dd} ${MMM}, ${yy}`;
};

function getDifferenceInDays(endate, startdate) {
  const diffInMs = Math.abs(endate - startdate);

  return diffInMs / (1000 * 60 * 60 * 24);
}

function getAgeFromDOB(dobdate) {
  const dateYear = new Date(dobdate);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const age = currentYear - dateYear.getFullYear();
  return age;
}

const getFormattedDateMMMYYYY = (date) => {
  let mm, yy, MMM;

  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  mm = mm;
  MMM = monthNames[mm];
  return `${MMM}, ${yy}`;
};

const getMinimumAgeDOB = (minAge) => {
  let date = new Date();
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear() - minAge;
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yy}-${mm}-${dd}`;
};

const getTimeFromDate = (timestamp) => {
  // var date = new Date(timestamp * 1000);
  // var hours = date.getHours();
  // var minutes = date.getMinutes();
  // var seconds = date.getSeconds();

  // var time = new Date();
  // return time.setHours(hours, minutes, seconds);
  const date = new Date(timestamp);
  return date;
};

const getFormattedDateYYYY = (date) => {
  let yy;

  yy = new Date(date).getFullYear();

  return `${yy}`;
};

const getFormattedDateDDMMYYYY = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}.${mm}.${yy}`;
};

module.exports = {
  getFormattedDateInputDate,
  getFormattedDateDDMMMYYYYDate,
  getDifferenceInDays,
  getAgeFromDOB,
  getFormattedDateMMMYYYY,
  getMinimumAgeDOB,
  getTimeFromDate,
  getFormattedDateYYYY,
  getFormattedDateDDMMYYYY,
};
