import React, { useEffect, useState, Fragment } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { AWARDS_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import AddAwardsForm from "./AddAwardsForm";
import AwardsList from "./AwardsList";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import { FaAward } from "react-icons/fa";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import useAuth from "../../../../authentication/hooks/useAuth";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function AwardsConfig() {
  const { auth } = useAuth();

  const [awardsList, setAwardsList] = useState([]);
  const [editAwardsDetails, setEditAwardsDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [publicationId, setPublicationId] = useState();

  const controller = new AbortController();
  const isEdit = Object.keys(editAwardsDetails).length > 0 ? true : false;
  const getAwardsList = async () => {
    try {
      const response = await axiosPrivate.get(AWARDS_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setAwardsList(response.data.awardsList);
      response.status === 202 &&
        showToast("No awards list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const deleteAwardWithId = async () => {
    try {
      const data = {};
      data.publicationid = publicationId;
      const response = await axiosPrivate.post(
        `${AWARDS_CONFIG_URL}/delete`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Award deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the award is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getAwardsList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    getAwardsList();
    setEditAwardsDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaAward />
          </IconContext.Provider>
          &nbsp; Awards &amp; Appreciations
        </H2withIcon>
        {!showAddForm && (
          <AddButton label="Add Award" onclick={() => setShowAddForm(true)} />
        )}
      </div>
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Awards" : "Add Awards"}`}
        isAdd={true}
      >
        <AddAwardsForm
          getAwardsList={getAwardsList}
          editAwardsDetails={editAwardsDetails}
          setEditAwardsDetails={setEditAwardsDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <ContainerShadow>
        {awardsList?.length === 0 ? (
          <p>Awards &amp; Appreciations yet to be added</p>
        ) : (
          <div className="grid grid-cols-1">
            <AwardsList
              awardsList={awardsList}
              setEditAwardsDetails={setEditAwardsDetails}
              setShowAddForm={setShowAddForm}
              setPublicationId={setPublicationId}
              setShowDelete={setShowDelete}
            />
          </div>
        )}
      </ContainerShadow>
      <Fragment>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteAwardWithId}
        />
      </Fragment>
    </Dashboard>
  );
}
