import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  BANKBRANCH_CONFIG_URL,
  BANK_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddBankBranchForm from "./AddBankBranchForm";
import BankBranchList from "./BankBranchList";

export default function BankBranchConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [bankBranchList, setBankBranchList] = useState([]);
  const [bankNamesDD, setBankNamesDD] = useState([]);
  const [editBankBranchDetails, setEditBankBranchDetails] = useState({});
  const [branchId, setBranchId] = useState();

  const isEdit = Object.keys(editBankBranchDetails).length > 0 ? true : false;

  const getBankBranchList = async () => {
    try {
      const response = await axiosPrivate.get(BANKBRANCH_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 &&
        setBankBranchList(response.data.BankBranchList);
      response.status === 202 &&
        showToast("No bank branch list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getBankList = async () => {
    try {
      const response = await axiosPrivate.get(BANK_CONFIG_URL, {
        signal: controller.signal,
      });

      let bankNamesDD = [];

      if (response?.status === 200) {
        response?.data?.bankList?.map((bankObj) => {
          bankNamesDD.push({
            label: bankObj.bankname,
            value: bankObj.bankid,
          });
        });
        setBankNamesDD(bankNamesDD);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteBranch = async () => {
    try {
      const data = {};
      data.bankbranchid = branchId;
      const response = await axiosPrivate.post(
        `${BANKBRANCH_CONFIG_URL}/delete`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Branch deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getBankBranchList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getBankBranchList();
    getBankList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Bank Branch"}
          setShowAddForm={setShowAddForm}
        />

        <GenericModal
          open={showAddForm}
          setOpen={setShowAddForm}
          title={` ${isEdit ? "Update Bank" : "Add Bank"}`}
          isAdd={true}
        >
          <AddBankBranchForm
            setShowAddForm={setShowAddForm}
            bankNamesDD={bankNamesDD}
            getBankBranchList={getBankBranchList}
            setEditBankBranchDetails={setEditBankBranchDetails}
            editBankBranchDetails={editBankBranchDetails}
          />
        </GenericModal>

        <BankBranchList
          bankBranchList={bankBranchList}
          setEditBankBranchDetails={setEditBankBranchDetails}
          setShowAddForm={setShowAddForm}
          setShowDelete={setShowDelete}
          setBranchId={setBranchId}
          editBankBranchDetails={editBankBranchDetails}
          bankNamesDD={bankNamesDD}
        />

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteBranch}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
