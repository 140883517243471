import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaDownload } from "react-icons/fa";
import { AWARDS_LIST_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import Button from "../../../reusable-components/buttons/Button";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import H1 from "../../../reusable-components/headings/H1";
import H2 from "../../../reusable-components/headings/H2";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextPrimary, linkTextSecondary } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AwardsAppreciations = () => {
  const controller = new AbortController();
  const [awardsList, setAwardsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAwardsList = async () => {
    try {
      const response = await axios.get(`${AWARDS_LIST_URL}`, {
        signal: controller.signal,
      });

      response.status === 200 && setAwardsList(response.data.awardsList);
      response.status === 202 &&
        showToast("No about us summary found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getAwardsList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Awards &amp; Appreciations</H1>
          </div>
        </div>
      </HeroBanner>
      {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container mx-auto max-w-7xl mb-4">
          <ContainerShadow>
            {awardsList?.length > 0 ? (
              <TableWrapper>
                <thead className="bg-slate-300 text-sm">
                  <th
                    scope="col"
                    className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
                  >
                    Sl. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center font-medium text-slate-900 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
                  >
                    Download
                  </th>
                </thead>
                <tbody>
                  {awardsList.map((awards, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {parseInt(index + 1)}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm">
                          {awards.publicationtitle}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="flex justify-end">
                            <Button className={linkTextSecondary}>
                              <a
                                href={awards.publicationdoc}
                                download={awards.publicationtitle}
                              >
                                <FaDownload />
                              </a>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TableWrapper>
            ) : (
              <p className="text-center">
                No awards &amp; appreciations have been uploaded yet. Stay
                tuned.
              </p>
            )}
          </ContainerShadow>
        </div>
      )}
    </div>
  );
};

export default AwardsAppreciations;
