import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import {
  MEDIA_CONFIG_URL,
  PHOTO_GALLERY_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import ImageUploader from "../../../../reusable-components/inputs/FileUploader/ImageUploader";
import CapturePhoto from "../../../../reusable-components/inputs/FileUploader/CapturePhoto";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";
import NewImageUploader from "../../../../reusable-components/inputs/FileUploader/NewImageUploader";

import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";

export default function AddPhotoForm({
  getMediaAlbumList,
  editPhotoDetails,
  setEditPhotoDetails,
  setShowAddForm,
  showUploadPhotosForm,
}) {
  const [photoDoc, setPhotoDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");
  const [passphoto, setPassphoto] = useState("");

  const [fileUploadError, setFileUploadError] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editPhotoDetails).length > 0;

  const defaultValues = {
    media_album_title: !isEdit ? "" : editPhotoDetails?.media_album_title,
    media_album_type: "Photos",

    media_album_description: !isEdit
      ? ""
      : editPhotoDetails?.media_album_description,
    media_album_startdate: !isEdit
      ? ""
      : getFormattedDateInputDate(editPhotoDetails?.media_album_startdate),
    media_album_enddate: !isEdit
      ? ""
      : getFormattedDateInputDate(editPhotoDetails?.media_album_enddate),

    media_albums_id: !isEdit ? "" : editPhotoDetails?.media_albums_id,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const onSubmit = async (data) => {
    try {
      let response = "";

      if (!isEdit) {
        data.created_by = auth.userid;
        data.media_album_type = defaultValues.media_album_type;
        response = await axiosPrivate.post(
          `${MEDIA_CONFIG_URL}/saveMediaAlbumDetails`,
          data,
          {
            signal: controller.signal,
          }
        );
      } else {
        data.updated_by = auth.userid;
        data.media_album_type = defaultValues.media_album_type;
        data.media_albums_id = editPhotoDetails?.media_albums_id;
        response = await axiosPrivate.post(
          `${MEDIA_CONFIG_URL}/updateMediaAlbumDetails`,
          data,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditPhotoDetails({});
          showToast("Photo has been updated successfully", "success");
        } else {
          showToast("Photo has been added successfully", "success");
        }
        getMediaAlbumList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            defaultName="media_album_title"
            register={register}
            name="Title"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter Title"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.media_album_title}
            setValue={setValue}
          />

          <div className="flex justify-between items-center">
            <Input
              defaultName="media_album_startdate"
              register={register}
              name="Start Date"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter start date"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="date"
              classes={`rounded-lg px-3 py-2 text-sm w-3/4`}
              onChangeInput={null}
              defaultValue={defaultValues.media_album_startdate}
              setValue={setValue}
            />

            <Input
              defaultName="media_album_enddate"
              register={register}
              name="End Date"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter start date"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="date"
              classes={`rounded-lg px-3 py-2 text-sm w-3/4`}
              onChangeInput={null}
              defaultValue={defaultValues.media_album_enddate}
              setValue={setValue}
            />
          </div>

          {/* <ImageUploader
          register={register}
          defaultName="mediafile"
          name={`${
            isEdit && editPhotoDetails.mediafile ? "Replace Photo" : "Photo"
          }`}
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Photo"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={photoDoc}
          setBase64={setPhotoDoc}
          setFilename={setDocFilename}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.mediafile : ""}
        /> */}

          <Input
            defaultName="media_album_description"
            register={register}
            name="Description"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter Description"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.media_album_description}
            setValue={setValue}
          />

          <div className="flex justify-between">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                setEditPhotoDetails({});
                setValue("categoryname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Photo Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
