/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../../reusable-components/headings/H1";
import { useSelector } from "react-redux";
import axios, { axiosPrivate } from "../../../../api/axios";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import {
  CREATE_ORDER_API,
  GET_INTEREST_ON_INTEREST_DETAILS,
  GET_PENAL_INTEREST_DETAILS,
  PUBLIC_REPAYMENT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
  REPAYMENT_LOGOUT_URL,
  REPAYMENT_lOGIN_URL,
} from "../../../../api/api_routing_urls";

import LabelWithDetail from "../../../system-admin/LoanEntry/Repayment/Repayment-Components/LabelWithDetail";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

import useAuth from "../../../../authentication/hooks/useAuth";
import { button } from "../../../../theme/lightTheme";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { useForm } from "react-hook-form";

import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";

import { useNavigate } from "react-router-dom";

import { CiPower } from "react-icons/ci";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import moment from "moment";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import BillDeskSdk from "../paymentGateway/billDeskSDK.component";

export default function LoaneeRepaymentDetails() {
  const navigate = useNavigate();

  const { completeLoaneeDetails } = useSelector((state) => state.loaneeDetails);
  const [loaneeBasicDetails, setLoaneeBasicDetails] = useState();
  const [loaneeInterestLedgerDetails, setLoaneeInterestLedgerDetails] =
    useState();
  const [loaneeLedgerDetails, setLoaneeLedgerDetails] = useState();
  const [loaneePaymentDetails, setLoaneePaymentDetails] = useState();

  const [refreshPage, setRefreshPage] = useState(false);

  const [repaymentAmount, setRepaymentAmount] = useState(false);
  const [maximumPayAmount, setMaximumPayAmount] = useState();
  const [principlePaidAmount, setPrinciplePaidAmount] = useState(0);
  const [interestPaidAmount, setInterestPaidAmount] = useState(0);

  const [activeTab, setActiveTab] = useState("repayment");
  const [showSuccessPaymentPage, setShowSuccessPaymentPage] = useState(false);

  const [interestOnInterestList, setInterestOnInterestList] = useState({});
  const [ioiTotalDue, setIoiTotalDue] = useState(0);

  const [orderObj, setOrderObj] = useState({});
  const [lunchSDK, setLunchSDK] = useState(false);
  const [showSDK, setShowSDK] = useState(false);
  const [fingerprintId, setFingerprintId] = useState(null);

  const [penalInterestList, setPenalInterestList] = useState({});
  const [penalTotalDue, setPenalTotalDue] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loaneeTxnDetails, setLoaneeTxnDetails] = useState();

  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    // defaultValues: principleDueWithIntData,
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;

  const getCompleteLoaneeDetails = async () => {
    try {
      const response = await axios.post(
        `${REPAYMENT_lOGIN_URL}/getCompleteDetails`,
        {
          loan_number: completeLoaneeDetails?.loan_number,
        }
      );

      if (response.status === 200) {
        // basic loanee details is store here
        setLoaneeBasicDetails(
          response?.data?.loaneeDetailsForRepayment
            ?.get_complete_loanee_details_for_online_repayment
            ?.loanee_basic_details?.[0]
        );

        // loanee payment detail is stored here

        setLoaneePaymentDetails(
          response?.data?.loaneeDetailsForRepayment
            ?.get_complete_loanee_details_for_online_repayment
            ?.loanee_payment_details?.[0]
        );

        // loanee ledger details is stored here

        setLoaneeLedgerDetails(
          response?.data?.loaneeDetailsForRepayment
            ?.get_complete_loanee_details_for_online_repayment
            ?.loanee_ledger_details
        );

        // laonee interest ledger is stored here

        setLoaneeInterestLedgerDetails(
          response?.data?.loaneeDetailsForRepayment
            ?.get_complete_loanee_details_for_online_repayment
            ?.loanee_interestledger_details
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const transactionDetailsWithLoanId = async () => {
    console.log("transactionDetailsWithLoanId");
    try {
      const loanId = +completeLoaneeDetails?.loanee_id;
      console.log("loan_id:", loanId);

      const response = await axios.post(
        `${REPAYMENT_lOGIN_URL}/getLoanTransationDetails`,
        {
          loan_id: loanId,
        }
      );

      setLoaneeTxnDetails(
        response?.data?.loaneeDetailsForRepayment
          ?.get_payment_transaction_details_by_loanee_id
      );

      console.log("response for txn details", response);
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the range of 2xx
        console.log("Response error:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request error:", error.request);
      } else {
        // Something else happened in setting up the request
        console.log("Error message:", error.message);
      }
    }
  };

  useEffect(() => {
    if (completeLoaneeDetails?.loan_number) {
      getCompleteLoaneeDetails();
      getPenalInterestDetail();
      getInterestOnInterestDetail();
      transactionDetailsWithLoanId();
    }
  }, [completeLoaneeDetails?.loan_number]);

  useEffect(() => {
    if (completeLoaneeDetails?.loanee_id) {
      transactionDetailsWithLoanId();
    }
  }, [completeLoaneeDetails?.loanee_id]);

  const repaymentAmountChangeHandler = () => {
    if (
      repaymentAmount !== null &&
      repaymentAmount !== undefined &&
      repaymentAmount !== ""
    ) {
      let repayAmount = +repaymentAmount;
      let interestDueAmount = loaneePaymentDetails?.interest_due_amount;

      if (repayAmount > interestDueAmount) {
        setInterestPaidAmount(() => interestDueAmount);
        setPrinciplePaidAmount(() => repayAmount - interestDueAmount);
      }
      if (repayAmount <= interestDueAmount) {
        setInterestPaidAmount(() => repayAmount);
        setPrinciplePaidAmount(0);
      }
    } else {
      setPrinciplePaidAmount(0);
      setInterestPaidAmount(0);
    }
  };

  useMemo(() => {
    repaymentAmountChangeHandler();
  }, [repaymentAmount]);

  useMemo(() => {
    setMaximumPayAmount(
      loaneePaymentDetails?.interest_due_amount +
        loaneePaymentDetails?.principle_outstanding_amount
    );
  }, [repaymentAmount]);

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprintId(result.visitorId);
    };

    loadFingerprint();
  }, []);

  const createOrderApi = async (data) => {
    const timestamp = moment().format("YYYYMMDDHHmmss");
    // const ORDERNO = `${timestamp}8056`;
    const ORDERNO = `${loaneeBasicDetails?.loanee_id}${timestamp}`;

    try {
      const payload = {
        orderid: ORDERNO,
        amount: parseFloat(data?.repaymentamount).toFixed(2),
        currency: "356",
        ru: process.env.REACT_APP_PAYMENT_RESPONSE_URL,
        additional_info: {
          additional_info1: `${loaneeBasicDetails?.loanee_id}`,
          additional_info2: `${loaneeBasicDetails?.applicantname}`,
          additional_info3: `${loaneeBasicDetails?.contactno}`,
          additional_info4: `${loaneeBasicDetails?.loan_number}`,
          additional_info5: principlePaidAmount,
          additional_info6: interestPaidAmount,
          additional_info7: loaneePaymentDetails?.interest_debit,
          additional_info8: activeTab,
          additional_info9: penalTotalDue,
          additional_info10: ioiTotalDue,
        },
        device: {
          init_channel: "internet",
          user_agent: userAgent,
          accept_header: "text/html",
          browser_tz: new Date().getTimezoneOffset().toString(),
          browser_color_depth: window.screen.colorDepth.toString(),
          fingerprintid: fingerprintId,
          browser_java_enabled: "false",
          browser_screen_height: window.screen.height.toString(),
          browser_screen_width: window.screen.width.toString(),
          browser_language: language,
          browser_javascript_enabled: navigator.userAgent.includes("JavaScript")
            ? "true"
            : "false",
        },
      };

      const response = await axiosPrivate.post(
        CREATE_ORDER_API,
        {
          payload: JSON.stringify(payload),
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content-type header
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        const ordObj = {
          merchantId: response?.data?.createOrderAPI?.mercid,
          bdOrderId: response?.data?.createOrderAPI?.bdorderid,
          authToken:
            response?.data?.createOrderAPI?.links[1]?.headers?.authorization,
        };
        setOrderObj(ordObj);
        setLunchSDK(true);
      }
    } catch (error) {
      console.log("error", error);
      console.error("createOrderApi", error);
      setLunchSDK(false);
      // navigation.navigate("ErrorScreen");
    }
  };

  const onSubmit = async (data) => {
    createOrderApi(data);
  };

  useEffect(() => {
    if (lunchSDK) {
      setShowSDK(true);
    }
  }, [orderObj, lunchSDK]);

  const handleSignOut = async (loanNumber) => {
    await axios.get(REPAYMENT_LOGOUT_URL, {
      withCredentials: true,
      params: {
        loan_number: loanNumber,
      },
    });

    navigate("/online-repayment-login");
  };

  const getPenalInterestDetail = async () => {
    setIsLoading(true);
    let penlaValue = undefined;

    try {
      let response = await axiosPrivate.get(
        GET_PENAL_INTEREST_DETAILS,
        {
          params: {
            loan_number: completeLoaneeDetails?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      console.log("response for penal interest", response);

      if (response.data) {
        setIsLoading(false);
        setPenalInterestList(response?.data?.ledgerDetails?.pi_ledger_list);

        penlaValue = +response?.data?.ledgerDetails?.penal_due_amount;
        setPenalTotalDue(penlaValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInterestOnInterestDetail = async () => {
    setIsLoading(true);
    let ioiValue = undefined;

    try {
      let response = await axiosPrivate.get(
        GET_INTEREST_ON_INTEREST_DETAILS,
        {
          params: {
            loan_number: completeLoaneeDetails?.loan_number || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setInterestOnInterestList(
          response?.data?.ledgerDetails?.ioi_ledger_list
        );
        ioiValue = +response?.data?.ledgerDetails?.ioi_due_amount;

        setIoiTotalDue(ioiValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("loaneeTxnDetails", loaneeTxnDetails);
  return (
    <>
      {showSDK && orderObj ? (
        <>
          <HeroBanner>
            <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
              <div className="text-center">
                <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                  Repayment
                </p>
              </div>
            </div>
          </HeroBanner>
          <div className="flex items-center justify-center w-full mx-auto bg-white">
            <BillDeskSdk
              orderObj={orderObj}
              responseUrl={process.env.REACT_APP_PAYMENT_RESPONSE_URL}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <HeroBanner>
              <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
                <div className="text-center">
                  <H1>Online Repayment</H1>
                </div>
              </div>
            </HeroBanner>
          </div>

          <>
            <div
              className="flex justify-end"
              onClick={() => {
                handleSignOut(loaneeBasicDetails?.loan_number);
              }}
            >
              <span className="flex items-center gap-2 text-xs md:text-base  border p-1 md:p-2 bg-slate-200 mx-4 md:mx-10 cursor-pointer hover:bg-slate-300">
                <CiPower size={20} color={"red"} />
                Logout
              </span>
            </div>

            <section className="mx-10">
              <fieldset className="border p-3  my-6 mx-3 md:mx-0 grid grid-cols-1 md:grid-cols-3 bg-slate-100 relative text-xs md:text-base">
                <legend className="ml-3 text-slate-700">
                  <div className="flex flex-col bg-slate-50 border px-3 py-1 rounded-sm ">
                    <span>
                      Loan ID:
                      <span className="font-semibold ml-3  text-slate-900">
                        {loaneeBasicDetails?.loanee_id}
                      </span>
                    </span>
                    <span>
                      Loan No:
                      <span className="font-semibold ml-2  text-slate-900">
                        {loaneeBasicDetails?.loan_number}
                      </span>
                    </span>
                  </div>
                </legend>
                <LabelWithDetail
                  label={"Name"}
                  value={loaneeBasicDetails?.applicantname}
                />
                <LabelWithDetail
                  label={"Loan Amount"}
                  value={formatINRCurrency(loaneeBasicDetails?.loanappliedamt)}
                />
                <LabelWithDetail
                  label="Principle Due"
                  value={formatINRCurrency(loaneePaymentDetails?.principle_due)}
                />
                <LabelWithDetail
                  label="Interest Due"
                  value={formatINRCurrency(
                    loaneePaymentDetails?.interest_due_amount
                  )}
                />
                <LabelWithDetail
                  label="Total Due Amount"
                  value={formatINRCurrency(
                    loaneePaymentDetails?.principle_due +
                      loaneePaymentDetails?.interest_due_amount
                  )}
                />

                {(loaneeBasicDetails?.categoryname === "OBC" ||
                  loaneeBasicDetails?.categoryname === "obc") && (
                  <>
                    <LabelWithDetail
                      label="Penal Due"
                      value={formatINRCurrency(penalTotalDue)}
                      noRecord={+penalTotalDue === -1}
                      isApprox={true}
                    />
                    <LabelWithDetail
                      label="IOI Due"
                      value={formatINRCurrency(ioiTotalDue)}
                      noRecord={+ioiTotalDue === -1}
                      isApprox={true}
                    />
                  </>
                )}
              </fieldset>
            </section>

            <div className="mx-10 my-10">
              <div className="flex justify-start items-center gap-6">
                <span
                  className={`cursor-pointer text-xs md:text-base mx-3 md:mx-0 ${
                    activeTab === "repayment"
                      ? "text-white font-normal border border-[#334155] p-2 bg-[#334155]"
                      : " transition-all duration-300 text-[#001D31] font-medium border border-[#001D31] p-2 "
                  }`}
                  onClick={() => setActiveTab("repayment")}
                >
                  Make Repayment
                </span>

                <span
                  className={` cursor-pointer text-xs md:text-base  ${
                    activeTab === "history"
                      ? "text-white font-normal border border-[#001D31] p-2 bg-[#334155] "
                      : "text-[#001D31] font-medium border border-[#001D31] p-2 transition-all duration-300 "
                  }`}
                  onClick={() => setActiveTab("history")}
                >
                  Repayment History
                </span>

                {(loaneeBasicDetails?.categoryname === "OBC" ||
                  loaneeBasicDetails?.categoryname === "obc") && (
                  <>
                    <span
                      className={`cursor-pointer text-xs md:text-base ${
                        activeTab === "penal"
                          ? "text-white font-normal border border-[#001D31] p-2 bg-[#334155]"
                          : "text-[#001D31] font-medium border border-[#001D31] p-2 transition-all duration-300"
                      }`}
                      onClick={() => setActiveTab("penal")}
                    >
                      Add Penal Interest
                    </span>

                    <span
                      className={`cursor-pointer text-xs md:text-base ${
                        activeTab === "ioi"
                          ? "text-white font-normal border border-[#001D31] p-2 bg-[#334155]"
                          : "text-[#001D31] font-medium border border-[#001D31] p-2 transition-all duration-300"
                      }`}
                      onClick={() => setActiveTab("ioi")}
                    >
                      Add Interest on Interest
                    </span>
                  </>
                )}
              </div>
            </div>

            {activeTab === "repayment" && (
              <>
                <>
                  <div className="mx-10 my-10 ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2 text-xs md:text-base">
                        <Input
                          defaultName="repaymentamount"
                          register={register}
                          name="Repayment Amount"
                          required={true}
                          pattern={null}
                          errors={errors}
                          placeholder="Enter repayment amount"
                          setError={setError}
                          clearError={clearErrors}
                          autoComplete="off"
                          type="number"
                          classes={`rounded-sm px-3 py-2 text-sm w-full`}
                          onChangeInput={(e) => {
                            setRepaymentAmount(e);
                          }}
                          defaultValue={null}
                          setValue={setValue}
                          max={maximumPayAmount}
                          min={0}
                        />
                      </fieldset>

                      <div className="flex justify-between mt-6 ">
                        <SubmitButton
                          isSubmitting={isSubmitting}
                          label={"Proceed to pay"}
                          className={`${button} text-xs md:text-base `}
                        />
                      </div>
                    </form>
                  </div>
                </>
              </>
            )}
            {activeTab === "history" && (
              <section className="mx-4 my-10  overflow-y-hidden ">
                <table className="min-w-full divide-y divide-slate-200 overflow-y-hidden">
                  <thead className="bg-slate-200 text-sm ">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                      >
                        SI No
                      </th>
                      <th
                        scope="col"
                        className="px-3  text-left font-medium text-slate-900  tracking-wider"
                      >
                        TR No
                      </th>
                      <th
                        scope="col"
                        className="px-3  text-left font-medium text-slate-900  tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3  text-left font-medium text-slate-900  tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3  text-left font-medium text-slate-900  tracking-wider w-[10%]"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3  text-left font-medium text-slate-900  tracking-wider w-[10%]"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-slate-400 text-slate-600">
                    {loaneeTxnDetails?.length > 0 ? (
                      loaneeTxnDetails?.map((txnDetails, index) => (
                        <tr key={index}>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            {index + 1}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            {txnDetails?.orderno}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            {txnDetails?.txn_json_data?.transaction_date ||
                              "--"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            {txnDetails?.txn_json_data?.amount || "--"}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            {txnDetails?.txn_json_data?.auth_status ===
                            "0300" ? (
                              <p className="text-green-500">Success</p>
                            ) : txnDetails?.txn_json_data?.auth_status ===
                              "0002" ? (
                              <p className="text-orange-500"> Pending</p>
                            ) : txnDetails?.txn_json_data?.auth_status ===
                              "0399" ? (
                              <p className="text-red-500"> Failed</p>
                            ) : (
                              <p className="text-orange-500"> Pending</p>
                            )}
                          </td>

                          <td className="px-3 py-2 whitespace-nowrap text-xs text-center">
                            {txnDetails?.txn_json_data?.auth_status ===
                            "0300" ? (
                              <FaRegCircleCheck />
                            ) : txnDetails?.txn_json_data?.auth_status ===
                              "0002" ? (
                              <p className="border text-sm  p-2">
                                Check Status
                              </p>
                            ) : txnDetails?.txn_json_data?.auth_status ===
                              "0399" ? (
                              <RxCrossCircled />
                            ) : (
                              <p className="border text-sm px-4 py-2">
                                Check Status
                              </p>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={8} className="p-4 text-center">
                        {" "}
                        No Record Found
                      </td>
                    )}
                  </tbody>
                </table>
              </section>
            )}

            {(loaneeBasicDetails?.categoryname === "OBC" ||
              loaneeBasicDetails?.categoryname === "obc") && (
              <>
                {activeTab === "penal" && (
                  <>
                    <div className="mx-10 my-10 ">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="col-span-3 p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2 text-xs md:text-base">
                          <Input
                            defaultName="repaymentamount"
                            register={register}
                            name="Repayment Amount (penal Interest)"
                            required={true}
                            pattern={null}
                            errors={errors}
                            placeholder="Enter repayment amount (penal interest)"
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="number"
                            classes="rounded-sm px-3 py-2 text-sm w-full"
                            onChangeInput={(e) => setRepaymentAmount(e)}
                            defaultValue={null}
                            setValue={setValue}
                            max={penalTotalDue}
                            min={0}
                          />
                        </fieldset>
                        <div className="flex justify-between mt-6">
                          <SubmitButton
                            isSubmitting={isSubmitting}
                            label="Proceed to pay"
                            className={`${button} text-xs md:text-base`}
                          />
                        </div>
                      </form>
                    </div>
                  </>
                )}

                {activeTab === "ioi" && (
                  <>
                    <div className="mx-10 my-10 ">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="col-span-3 p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2 text-xs md:text-base">
                          <Input
                            defaultName="repaymentamount"
                            register={register}
                            name="Repayment Amount (Interest on Interest)"
                            required={true}
                            pattern={null}
                            errors={errors}
                            placeholder="Enter repayment amount (interest on interest)"
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="number"
                            classes="rounded-sm px-3 py-2 text-sm w-full"
                            onChangeInput={(e) => setRepaymentAmount(e)}
                            defaultValue={null}
                            setValue={setValue}
                            max={ioiTotalDue}
                            min={0}
                          />
                        </fieldset>
                        <div className="flex justify-between mt-6">
                          <SubmitButton
                            isSubmitting={isSubmitting}
                            label="Proceed to pay"
                            className={`${button} text-xs md:text-base`}
                          />
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
    </>
  );
}
