const createRemarkObj = (isPostSacntion, remark) => {
  let remarkOBJ = {};
  remarkOBJ = {
    isRemarkPost: isPostSacntion,
    remark: remark || " - ",
  };
  const postapproval = JSON.stringify(remarkOBJ);
  return postapproval;
};

module.exports = { createRemarkObj };
