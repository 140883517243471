import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { PUBLICATIONS_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import {
  button,
  cancel_button,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import PDFUploader from "../../../../reusable-components/inputs/FileUploader/PDFUploader";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";

export default function AddPublicationsForm({
  getPublicationsList,
  editPublicationsDetails,
  setEditPublicationsDetails,
  publicationTypeList,
  setShowAddForm,
}) {
  const [publicationDoc, setPublicationDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");
  const [selected, setSelected] = useState("");

  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editPublicationsDetails).length > 0;

  const defaultValues = {
    publicationtitle: !isEdit ? "" : editPublicationsDetails?.publicationtitle,
    publicationtype: !isEdit
      ? null
      : publicationTypeList.filter(
          (type) => type.value === editPublicationsDetails.publicationtype
        )[0],
    publicationdoc: !isEdit ? "" : editPublicationsDetails?.publicationdoc,
    publicationdesc: !isEdit ? "" : editPublicationsDetails.publicationdesc,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (typeof data.publicationdoc !== "string") {
        data.publicationdoc = publicationDoc;
      }
      if (!isEdit) {
        data.createdby = auth.userid;
        data.publicationtype = data.publicationtype.value;
        response = await axiosPrivate.post(PUBLICATIONS_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.publicationtype = data.publicationtype.value;
        data.publicationid = editPublicationsDetails?.publicationid;
        response = await axiosPrivate.put(PUBLICATIONS_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditPublicationsDetails({});
          showToast(
            "Publication Details has been updated successfully",
            "success"
          );
        } else {
          showToast(
            "Publication Details has been added successfully",
            "success"
          );
        }
        getPublicationsList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("Error:", error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${editPublicationsDetails.publicationtitle} Document`);
    setShowPDFViewer(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <Dropdown
            defaultName="publicationtype"
            register={register}
            labelname="Publication Type"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-40`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={publicationTypeList}
            defaultValue={defaultValues.publicationtype}
            setValue={setValue}
            setSelected={setSelected}
            selected={selected}
            maxMenuHeight={120}
          />
        </div>
        <Input
          defaultName="publicationtitle"
          register={register}
          name="Publication Title"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter publication title"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.publicationtitle}
          setValue={setValue}
        />
        {isEdit && editPublicationsDetails.publicationdoc && (
          <span className="flex items-center">
            Uploaded Document / Image : &nbsp;
            <Fragment>
              <IconContext.Provider
                value={{ className: "text-red-700 text-5xl" }}
              >
                <FaFilePdf />
              </IconContext.Provider>
              <Button
                className={linkTextPrimary}
                onClick={() =>
                  onClickView(editPublicationsDetails.publicationdoc)
                }
              >
                {editPublicationsDetails.publicationtitle}.pdf
              </Button>
            </Fragment>
          </span>
        )}
        <PDFUploader
          register={register}
          defaultName="publicationdoc"
          name={`${
            isEdit && editPublicationsDetails.publicationdoc
              ? "Replace Publication Document"
              : "Publication Document"
          }`}
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Publication Document"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={publicationDoc}
          setBase64={setPublicationDoc}
          setFilename={setDocFilename}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.publicationdoc : ""}
        />
        <TextArea
          defaultName="publicationdesc"
          register={register}
          name="Description (if any)"
          required={false}
          pattern={null}
          errors={errors}
          placeholder="Write Description Here"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full resize-none h-32`}
          onChangeInput={null}
          defaultValue={defaultValues.publicationdesc}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setPublicationDoc({});
              setShowAddForm(false);
              reset();
            }}
            aria-label="Cancel Publication Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </div>
  );
}
