import React from "react";
import Error from "../../outputs/Error";

import { fileTypeChecker } from "../../../utilities/validateDocuments/validateDocument";
import { fileNameExtension } from "../../../utilities/constants/regularexpressions";

export default function PDFImageUploaderAWS({
  register,
  defaultName,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  file,
  setFile,
  setValue,
  isEdit,
  oldFileOnEdit,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: { value: required, message: `${name} is required` },
    pattern: pattern,
  });

  const validateAndSetPDFFile = async (file) => {
    try {
      const fileType = await fileTypeChecker(file);

      if (fileType !== "unknown" && isPDFFileNameValid(file.name)) {
        setFile(file);
        if (setValue) {
          // setValue(defaultName, file); // Set the form control value
        } else {
          console.error("setValue is not defined.");
        }
      }
    } catch (error) {
      console.error("Invalid File:", error);
    }
  };

  const isPDFFileNameValid = (fileName) => {
    return fileNameExtension.test(fileName);
  };

  const onChangePDF = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (!selectedFile) {
        return;
      }

      await validateAndSetPDFFile(selectedFile);
    } catch (error) {
      console.error("Some error", error);
    }
  };

  // const onChangePDF = async (e) => {
  //   try {
  //     let fileType = "";

  //     await fileTypeChecker(e.target.files[0])
  //       .then((file) => {
  //         fileType = file;
  //       })
  //       .catch((err) => {
  //         console.error("Invalide File:", err);
  //       });

  //     if (
  //       fileType !== "unknown" &&
  //       fileNameExtension.test(e.target.files[0].name) //testing file name
  //     ) {
  //       setFile(e.target.files[0]);
  //       setValue(defaultName, e.target.files[0]);
  //     }
  //   } catch (error) {
  //     console.error("some error", error);
  //   }
  // };

  // useEffect(() => {
  //   if (file !== "") {
  //     rest.setValue(name, dataURLtoFile(file), { shouldTouch: true });
  //   }
  // }, [file]);

  return (
    <section className="flex flex-col max-full mb-2 justify-center text-sm">
      <label className="font-medium text-left text-base">
        {name} {required && <span className="text-red-700">*</span>}
      </label>
      {/* <input
        id={defaultName}
        // defaultValue={file !== "" ? dataURLtoFile(file) : null}
        // accept="application/pdf"
        onChange={(e) => {
          if (required) {
            if (e.target.value === "") {
              setError(defaultName, {
                type: "required",
                message: `${name} is required`,
              });
            } else {
              onChangePDF(e);
              clearError(defaultName);
            }
          } else {
            onChangePDF(e);
            clearError(defaultName);
          }
          onChangePDF(e);
          onChange(e);
        }}
        {...props}
        {...rest}
        className={`${classes} border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      /> */}

      <input
        type="file"
        id={defaultName}
        onChange={(e) => {
          onChangePDF(e);
          clearError(defaultName);

          if (required && e.target.value === "") {
            setError(defaultName, {
              type: "required",
              message: `${name} is required`,
            });
          }

          onChange(e);
        }}
        // defaultValue={file !== "" ? dataURLtoFile(file) : null}
        // accept="application/pdf"
        {...props}
        {...rest}
        className={`${classes} bg-green-50 p-1 border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      />

      {/* {oldFileOnEdit !== "" && (
        <Fragment>
          <p className="text-primary font-medium">
            Uploaded:{" "}
            {oldFileOnEdit
              ?.split("/")
              [oldFileOnEdit?.split("/").length - 1].replace("%20", " ")}
          </p>
        </Fragment>
      )} */}

      {errors[defaultName] && (
        <Error
          classes="flex flex-row gap-1 max-w-sm w-full mt-2"
          message={`${name} is required`}
        />
      )}
    </section>
  );
}
