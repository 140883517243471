import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { CSVLink } from "react-csv";

export default function DefaulterDetailList({ defaulterDetailList, ref }) {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200  sticky top-0 text-sm leading-5"
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              SI No.
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900 flex flex-col tracking-wider"
            >
              Applicant Name
              <span>(Category)</span>
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Amount
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Last Repayment Date
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Outstanding
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Principle Repay
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Scheme Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              District
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Constituency
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-xs divide-y divide-slate-100 text-slate-600">
          {defaulterDetailList?.length > 0 ? (
            defaulterDetailList?.map((defaulterList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-1 whitespace-nowrap ">{index + 1}</td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.loanee_id}
                </td>
                <td className="px-6 py-1 whitespace-nowrap  flex flex-col">
                  {defaulterList?.applicantname}
                  <span className="font-semibold">
                    ({defaulterList?.categoryname})
                  </span>
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.loan_amount}
                </td>

                <td className="px-6 py-1 whitespace-nowrap ">
                  {getFormattedDateDDMMMYYYYDate(
                    defaulterList?.last_repayment_date
                  )}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.principle_outstanding}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.principal_repay}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.loan_scheme}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.districtname}
                </td>

                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.constituencyname || "---"}
                </td>
                <td className="px-6 py-1 whitespace-nowrap ">
                  {defaulterList?.applicantrelativename}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={defaulterDetailList}
          target="_blank"
          filename={`SABCCO-DefaulterReportList-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}
