import React, { useState } from "react";
import "./card.css";

export default function Card({ cardImage, onClick }) {
  return (
    <>
      <section className="cardcontainer">
        <img className="image" src={cardImage} alt="" />
        <div className="overlay"></div>
        <div className="button">
          <span onClick={onClick}>Remove</span>
        </div>
      </section>
    </>
  );
}
