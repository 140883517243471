import React, { useEffect, useState } from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { button } from "../../../theme/lightTheme";
import { useForm } from "react-hook-form";
import moment from "moment";
import { CREATE_ORDER_API } from "../../../api/api_routing_urls";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import BillDeskSdk from "../Pages/paymentGateway/billDeskSDK.component";

export default function Noc_online_payment() {
  const [activeTab, setActiveTab] = useState("repayment");

  const [paymentAmount, setPaymentAmount] = useState();
  const [orderObj, setOrderObj] = useState({});
  const [lunchSDK, setLunchSDK] = useState(false);
  const [showSDK, setShowSDK] = useState(false);
  const [fingerprintId, setFingerprintId] = useState(null);

  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    // defaultValues: principleDueWithIntData,
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;

  const onSubmit = async (data) => {
    createOrderApi(data);
  };

  const createOrderApi = async (data) => {
    const timestamp = moment().format("YYYYMMDDHHmmss");
    // const ORDERNO = `${timestamp}8056`;
    // const ORDERNO = `${loaneeBasicDetails?.loanee_id}${timestamp}`;
    const ORDERNO = `A0020${timestamp}`;

    console.log("data", data?.paymentamount);

    try {
      const payload = {
        orderid: ORDERNO,
        amount: parseFloat(data?.paymentamount).toFixed(2),
        currency: "356",
        ru: process.env.REACT_APP_PAYMENT_RESPONSE_URL,
        additional_info: {
          additional_info1: `7872279905`,
          additional_info2: `Samuel Sewa`,
          additional_info3: `150`,
        },
        device: {
          init_channel: "internet",
          user_agent: userAgent,
          accept_header: "text/html",
          browser_tz: new Date().getTimezoneOffset().toString(),
          browser_color_depth: window.screen.colorDepth.toString(),
          fingerprintid: fingerprintId,
          browser_java_enabled: "false",
          browser_screen_height: window.screen.height.toString(),
          browser_screen_width: window.screen.width.toString(),
          browser_language: language,
          browser_javascript_enabled: navigator.userAgent.includes("JavaScript")
            ? "true"
            : "false",
        },
      };

      console.log({ payload });

      const response = await axiosPrivate.post(
        `${CREATE_ORDER_API}/createOrderAPI_NOCApplicant`,
        {
          payload: JSON.stringify(payload),
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content-type header
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        const ordObj = {
          merchantId: response?.data?.createOrderAPI?.mercid,
          bdOrderId: response?.data?.createOrderAPI?.bdorderid,
          authToken:
            response?.data?.createOrderAPI?.links[1]?.headers?.authorization,
        };
        setOrderObj(ordObj);
        setLunchSDK(true);
      }
    } catch (error) {
      console.log("error", error);
      console.error("createOrderApi", error);
      setLunchSDK(false);
      // navigation.navigate("ErrorScreen");
    }
  };

  useEffect(() => {
    if (lunchSDK) {
      setShowSDK(true);
    }
  }, [orderObj, lunchSDK]);

  console.log("showSDK", showSDK);
  console.log("orderObj", orderObj);

  return (
    <>
      {showSDK && orderObj ? (
        <>
          <HeroBanner>
            <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
              <div className="text-center">
                <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                  NOC Payment
                </p>
              </div>
            </div>
          </HeroBanner>
          <div className="flex items-center justify-center w-full mx-auto bg-white">
            <BillDeskSdk
              orderObj={orderObj}
              responseUrl={process.env.REACT_APP_PAYMENT_RESPONSE_URL}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <HeroBanner>
              <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
                <div className="text-center">
                  <H1>NOC Online payment</H1>
                </div>
              </div>
            </HeroBanner>
          </div>
          <div>
            <section>
              <div className="mx-10 my-10">
                <div className="flex justify-start items-center gap-6">
                  <span
                    className={`cursor-pointer text-xs md:text-base mx-3 md:mx-0 ${
                      activeTab === "payment"
                        ? "text-white font-normal border border-[#334155] p-2 bg-[#334155]"
                        : " transition-all duration-300 text-[#001D31] font-medium border border-[#001D31] p-2 "
                    }`}
                    onClick={() => setActiveTab("payment")}
                  >
                    Make payment
                  </span>

                  <span
                    className={` cursor-pointer text-xs md:text-base  ${
                      activeTab === "history"
                        ? "text-white font-normal border border-[#001D31] p-2 bg-[#334155] "
                        : "text-[#001D31] font-medium border border-[#001D31] p-2 transition-all duration-300 "
                    }`}
                    onClick={() => setActiveTab("history")}
                  >
                    Transaction Details
                  </span>
                </div>
              </div>
            </section>
          </div>

          {activeTab === "payment" && (
            <>
              <>
                <div className="mx-10 my-10 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2 text-xs md:text-base">
                      <Input
                        defaultName="paymentamount"
                        register={register}
                        name="Payment Amount"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter payment amount"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="number"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={(e) => {
                          setPaymentAmount(e);
                        }}
                        defaultValue={150}
                        setValue={setValue}
                        max={150}
                        min={0}
                      />
                    </fieldset>

                    <div className="flex justify-between mt-6 ">
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        label={"Proceed to pay"}
                        className={`${button} text-xs md:text-base `}
                      />
                    </div>
                  </form>
                </div>
              </>
            </>
          )}
        </>
      )}
    </>
  );
}
