import React, { Fragment, useEffect, useState } from "react";
import { SCHEMES_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import useAuth from "../../../../authentication/hooks/useAuth";
import { useForm } from "react-hook-form";
import {
  button,
  cancel_button,
  delete_button,
  linkTextDanger,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import RichTextArea from "../../../../reusable-components/richtexteditor/RichTextArea";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import Passphoto from "../../../../reusable-components/inputs/FileUploader/Passphoto";
import PDFUploader from "../../../../reusable-components/inputs/FileUploader/PDFUploader";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";
import { IconContext } from "react-icons";
import { FaFilePdf, FaTimesCircle } from "react-icons/fa";
import CapturePhoto from "../../../../reusable-components/inputs/FileUploader/CapturePhoto";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";

const AddSchemeForm = ({
  getSchemesList,
  editSchemeDetails,
  setEditSchemeDetails,
  categoryDDList,
  setShowAddForm,
}) => {
  const [selected, setSelected] = useState();
  const [filename, setFilename] = useState("");
  const [passphoto, setPassphoto] = useState("");
  const [schemeDoc, setSchemeDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();

  const isEdit =
    editSchemeDetails !== undefined
      ? Object.keys(editSchemeDetails).length > 0
      : false;

  const defaultValues = {
    schemetitle: isEdit ? editSchemeDetails.schemetitle : "",
    schemefor: isEdit
      ? categoryDDList.filter(
          (category) =>
            parseInt(category.value) === parseInt(editSchemeDetails.schemefor)
        )[0]
      : null,
    schemeicon: isEdit ? editSchemeDetails.schemeicon : "",
    schemedesc: isEdit ? editSchemeDetails.schemedesc : "",
    schemedoc: isEdit ? editSchemeDetails.schemedoc : "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [showRemoveDoc, setShowRemoveDoc] = useState(false);

  const [fileUploadError, setFileUploadError] = useState(false);

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${editSchemeDetails.schemetitle} Document`);
    setShowPDFViewer(true);
  };

  const emptyObj = {};
  const setDefaultValueEmpty = () => {
    setEditSchemeDetails(emptyObj);
    setShowAddForm(false);
  };

  useEffect(() => {
    if (isEdit) {
      setPassphoto(editSchemeDetails?.schemeicon);
    } else {
      setPassphoto("");
    }
  }, [editSchemeDetails?.schemeicon]);

  const onSubmit = async (data) => {
    try {
      let response = {};

      if (typeof data.schemedoc !== "string") {
        data.schemedoc = schemeDoc;
      }
      if (passphoto) {
        data.schemeicon = await uploadFile(passphoto);
      } else {
        data.schemeicon = editSchemeDetails?.schemeicon;
      }

      if (isEdit) {
        data.schemeid = editSchemeDetails.schemeid;
        data.updatedby = auth.userid;
        let schemeFor = parseInt(data.schemefor.value);
        data.schemefor = schemeFor;
        let updatedData = {
          schemeid: data.schemeid,
          schemetitle: data.schemetitle,
          schemefor: data.schemefor,
          schemeicon: data.schemeicon,
          schemedesc: data.schemedesc,
          schemedoc: data.schemedoc,
          updatedby: data.updatedby,
        };

        response = await axiosPrivate.put(SCHEMES_CONFIG_URL, updatedData, {
          signal: controller.signal,
        });
      } else {
        data.createdby = auth.userid;
        let schemeFor = parseInt(data.schemefor.value);
        data.schemefor = schemeFor;
        let savedData = {
          schemetitle: data.schemetitle,
          schemefor: data.schemefor,
          schemeicon: data.schemeicon,
          schemedesc: data.schemedesc,
          schemedoc: data.schemedoc,
          createdby: data.createdby,
        };
        response = await axiosPrivate.post(SCHEMES_CONFIG_URL, savedData, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast("Schemes Details Successfully Updated", "success");
        } else {
          showToast("Schemes Details Successfully Added", "success");
        }
        getSchemesList(true);
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
        return;
      }
      setDefaultValueEmpty();
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <ContainerShadow>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CapturePhoto
          register={register}
          defaultName="schemeicon"
          name="Icon"
          required={isEdit ? false : true}
          pattern={null}
          placeholder="logo"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100 `}
          setError={setError}
          clearError={clearErrors}
          // file={passphoto}
          // setPhotoBase64={setPassphoto}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.schemeicon : ""}
          setFile={setPassphoto}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
        />

        {/* <Passphoto
          register={register}
          defaultName="schemeicon"
          name="Icon"
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Logo"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={passphoto}
          setPhotoBase64={setPassphoto}
          setFilename={setFilename}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.schemeicon : ""}
        /> */}

        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 md:col-span-1">
            <Input
              defaultName="schemetitle"
              register={register}
              name="Title"
              required={true}
              pattern={null}
              errors={errors}
              placeholder="Enter Scheme title"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-lg px-3 py-2 text-sm w-full`}
              onChangeInput={null}
              defaultValue={defaultValues.schemetitle}
              setValue={setValue}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <div className="mt-2">
              <Dropdown
                defaultName="schemefor"
                register={register}
                labelname="Scheme For"
                required={true}
                pattern={null}
                errors={errors}
                classes={`rounded-lg text-sm w-full z-40`}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={categoryDDList}
                defaultValue={defaultValues.schemefor}
                setValue={setValue}
                setSelected={setSelected}
                selected={selected}
                maxMenuHeight={120}
              />
            </div>
          </div>
        </div>

        <RichTextArea
          defaultName={"schemedesc"}
          register={register}
          name={"Scheme Description"}
          classes={"w-full"}
          clearError={clearErrors}
          setError={setError}
          setValue={setValue}
          data={defaultValues.schemedesc}
          errors={errors}
          pattern={null}
          required={true}
        />
        {isEdit && editSchemeDetails.schemedoc && (
          <span className="flex items-center">
            Uploaded Document:
            <Fragment>
              <IconContext.Provider
                value={{ className: "text-red-700 text-5xl" }}
              >
                <FaFilePdf />
              </IconContext.Provider>
              <Button
                className={linkTextPrimary}
                onClick={() => onClickView(editSchemeDetails.schemedoc)}
              >
                {editSchemeDetails.schemetitle}.pdf
              </Button>
            </Fragment>
            <Button
              className={linkTextDanger}
              onClick={() => {
                setShowRemoveDoc(true);
              }}
            >
              <IconContext.Provider
                value={{ className: "text-red-900 text-xl" }}
              >
                <FaTimesCircle />
              </IconContext.Provider>
            </Button>
          </span>
        )}
        <PDFUploader
          register={register}
          defaultName="schemedoc"
          name={`${
            isEdit && editSchemeDetails.schemedoc
              ? "Replace Scheme Document"
              : "Scheme Document (if any)"
          }`}
          required={false}
          pattern={null}
          placeholder="Scheme Document"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={schemeDoc}
          setBase64={setSchemeDoc}
          setFilename={setDocFilename}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.schemedoc : ""}
        />
        <div className="flex items-center justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={`${isEdit ? "Update" : "Submit"}`}
            className={button}
          />
          <Button className={cancel_button} onClick={setDefaultValueEmpty}>
            Cancel
          </Button>
        </div>
      </form>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
      <GenericModal
        open={showRemoveDoc}
        setOpen={setShowRemoveDoc}
        title={`Confirmation Required`}
        isLarge={false}
        isAdd={false}
      >
        <div className="text-justify">
          <p>Are you sure you want to remove the document?</p>
        </div>
        <div className="flex justify-end">
          <Button
            className={`${delete_button} mr-3`}
            onClick={() => {
              setValue("schemedoc", "");
              setEditSchemeDetails({ ...editSchemeDetails, schemedoc: "" });
              setShowRemoveDoc(false);
            }}
          >
            Yes
          </Button>
          <Button
            className={cancel_button}
            onClick={() => {
              setShowRemoveDoc(false);
            }}
          >
            No
          </Button>
        </div>
      </GenericModal>
    </ContainerShadow>
  );
};

export default AddSchemeForm;
