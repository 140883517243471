import React, { useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { FaUser } from "react-icons/fa";
import BackButton from "../../../../reusable-components/buttons/BackButton";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import user_image from "../../../../assets/userimage.png";
import {
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  GRAMPANCHAYAT_CONFIG_URL,
  SCHEME_CONFIG_URL,
  SUBDIVISION_CONFIG_URL,
  LOANEE_DETAILS_CONFIG,
  AREA_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import Remarks from "../applicant-components/Remarks";
import { MdOutlineMail, MdOutlinePhoneAndroid } from "react-icons/md";
import GuarantorDetails from "../applicant-components/GuarantorDetails";
import ApplicantDocumentPreview from "../applicant-components/ApplicantDocumentPreview";
import ApplicantDataPreview from "../applicant-components/ApplicantDataPreview";
import useGetWithParameters from "../../../../hook/getApi/useGetWithParameters";
import LoaneeSanctionForm from "../applicant-components/LoaneeSanctionForm";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { CiLocationOn } from "react-icons/ci";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function ViewApplicantDetails({
  applicantDetail,
  setViewApplicantDetail,
  setApplicantDetail,
  roleName,
  setRefresh,
}) {
  const [showBackButton, setShowBackButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [schemeName, setSchemeName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [constituencyName, setConstituencyName] = useState("");
  const [subDivisionName, setSubDivisionName] = useState("");
  const [gramPanchayatName, setGramPanchayatName] = useState("");

  const [isPostSacntion, setIsPostSacntion] = useState(false);

  const [isLegalApproval, setIsLegalApproval] = useState(false);

  const [showSanctionForm, setShowSanctionForm] = useState(false);
  const [sanctionMessage, setSanctionMessage] = useState("");
  const [selected_Loanee, setSelected_LoaneeArea] = useState("");
  const [lonaeeArea_List, setLoaneeArea_List] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const { auth } = useAuth();

  const { response: loaneeSanctionDetail, loading: loaneeSanctionLoading } =
    useGetWithParameters(
      `${LOANEE_DETAILS_CONFIG}/getLoaneeSanctionDetail`,
      "loanee_id",
      applicantDetail?.loanee_id
    );

  const getSchemeList = async (scheme_id) => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.schemeList?.length > 0) {
          for (let i = 0; i < response?.data?.schemeList?.length; i++) {
            if (response.data.schemeList[i].schemeid === scheme_id) {
              setSchemeName(response.data.schemeList[i].schemename);
            }
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const getDistrictList = async (district_id) => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          response?.data?.districtList?.map((dis_obj) => {
            if (+dis_obj.districtid === +district_id) {
              setDistrictName(dis_obj?.districtname);
            }
          });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const getConstituencyList = async (constituency_id) => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          for (let i = 0; i < response?.data?.constituencyList?.length; i++) {
            if (
              response.data.constituencyList[i].constituencyid ===
              constituency_id
            ) {
              setConstituencyName(
                response.data.constituencyList[i].constituencyname
              );
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const getSubDivisionList = async (subdivision_id) => {
    try {
      const response = await axiosPrivate.get(SUBDIVISION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.subDivisionList?.length > 0) {
          for (let i = 0; i < response?.data?.subDivisionList?.length; i++) {
            if (
              response.data.subDivisionList[i].subdivisionid === subdivision_id
            ) {
              setSubDivisionName(
                response.data.subDivisionList[i].subdivisionname
              );
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const getGramPanchayatList = async (grampanchayat_id) => {
    try {
      const response = await axiosPrivate.get(GRAMPANCHAYAT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.grampanchayatList?.length > 0) {
          for (let i = 0; i < response?.data?.grampanchayatList?.length; i++) {
            if (
              grampanchayat_id !== 0 &&
              grampanchayat_id ===
                response.data.grampanchayatList[i].grampanchayatid
            ) {
              setGramPanchayatName(
                response.data.grampanchayatList[i].grampanchayatname
              );
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getAreaList = async (area_id) => {
    try {
      const response = await axiosPrivate.get(AREA_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.areaList?.length > 0) {
          let areaDD = [];
          response?.data?.areaList?.map((areaObj) => {
            let areaObjDD = {
              value: areaObj.area_id,
              label: areaObj.area_name,
              districtid: areaObj.districtid,
            };
            areaDD.push(areaObjDD);

            if (areaObj.area_id === area_id) {
              setSelected_LoaneeArea({
                value: areaObj.area_id,
                label: areaObj.area_name,
                districtid: areaObj.districtid,
              });
            }
          });
          setLoaneeArea_List(areaDD);
        } else {
          setLoaneeArea_List([]);
        }
      }
      response.status === 202 &&
        showToast("No  Area List found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  console.log(loaneeSanctionDetail);

  function displaySanctionFormHandler() {
    if (
      auth.rolename === "Data Entry Operator" &&
      loaneeSanctionDetail?.sanctionDetail === null
    ) {
      setShowSanctionForm(true);
    }

    if (
      applicantDetail?.is_postsanction_approved === true ||
      applicantDetail?.loanee_id <= 7761
    ) {
      setSanctionMessage("The loan has been sanctioned and disbursed.");

      if (
        (auth.rolename === "DGM Accounts" ||
          auth.rolename === "General Manager") &&
        loaneeSanctionDetail?.sanctionDetail?.disbursed_amount <
          loaneeSanctionDetail?.sanctionDetail?.loanappliedamt
      ) {
        setShowSanctionForm(true);
      }
    } else if (
      (loaneeSanctionDetail?.sanctionDetail?.post_is_reverted === true ||
        loaneeSanctionDetail?.sanctionDetail?.pre_is_reverted === true) &&
      roleName !== "Data Entry Operator"
    ) {
      setSanctionMessage(
        "The Application has been reverted to Data Entry Operator."
      );
    } else if (
      (loaneeSanctionDetail?.sanctionDetail?.is_pre_approved === false &&
        loaneeSanctionDetail?.sanctionDetail?.pre_forwarded_to ===
          auth.rolename) ||
      (loaneeSanctionDetail?.sanctionDetail?.is_pre_approved === true &&
        loaneeSanctionDetail?.sanctionDetail?.post_forwarded_to ===
          auth.rolename) ||
      (auth.rolename === "Data Entry Operator" &&
        ((loaneeSanctionDetail?.sanctionDetail?.is_pre_approved === true &&
          loaneeSanctionDetail?.sanctionDetail?.post_forwarded_to === null) ||
          loaneeSanctionDetail?.sanctionDetail?.length === 0)) ||
      (auth.rolename === "Data Entry Operator" &&
        (loaneeSanctionDetail?.sanctionDetail?.post_is_reverted === true ||
          loaneeSanctionDetail?.sanctionDetail?.pre_is_reverted === true))
    ) {
      setShowSanctionForm(true);
    } else if (
      loaneeSanctionDetail?.sanctionDetail?.is_pre_approved === true &&
      loaneeSanctionDetail?.sanctionDetail?.post_forwarded_to === null
    ) {
      setSanctionMessage(`The applicant form has been forwarded to Data Entry
      Operator for post sanction process.`);
    } else {
      setSanctionMessage(`The applicant form has been forwarded to     
      ${
        loaneeSanctionDetail?.sanctionDetail?.is_pre_approved === true
          ? loaneeSanctionDetail?.sanctionDetail?.post_forwarded_to
          : loaneeSanctionDetail?.sanctionDetail?.pre_forwarded_to
      }
       for approval.`);
    }
  }

  useMemo(
    () => displaySanctionFormHandler(),
    [applicantDetail, loaneeSanctionLoading]
  );

  useEffect(() => {
    getSchemeList(applicantDetail?.schemeid);
    getDistrictList(applicantDetail?.districtid);
    getAreaList(applicantDetail?.area_id);
    getConstituencyList(applicantDetail?.constituencyid);
    getSubDivisionList(applicantDetail?.subdivisionid);
    getGramPanchayatList(applicantDetail?.grampanchayatid);
    setIsPostSacntion(applicantDetail?.is_presanction_approved || false);
    setIsLegalApproval(
      applicantDetail?.legalapproval?.legalPostSanctionApproval || false
    );
  }, [applicantDetail, loaneeSanctionLoading]);

  return !isLoading ? (
    <>
      <div className="flex items-center justify-between ">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaUser />
          </IconContext.Provider>
          &nbsp; Applicant Details
        </H2withIcon>
        {!showBackButton && (
          <BackButton
            label="back to list"
            onclick={() => {
              setShowBackButton(true);
              setViewApplicantDetail(false);
              setApplicantDetail({});
            }}
          />
        )}
      </div>

      <section className="p-3 bg-white border rounded-sm md:p-6 text-sm md:text-base">
        <div className="text-xs xl:text-sm">
          <ApplicantDataPreview
            label="Application Date"
            dataValue={getFormattedDateDDMMMYYYYDate(
              applicantDetail?.createdat
            )}
          />
        </div>
        <section className=" grid grid-cols-1 md:grid-cols-5 gap-3 mt-3">
          <div className="flex gap-6 col-span-2 p-3 border">
            <div>
              <img
                src={
                  applicantDetail?.applicantimage
                    ? applicantDetail?.applicantimage
                    : user_image
                }
                className="w-24 md:w-36 h-24 md:h-36 rounded-sm"
                alt="User Photo"
              />
            </div>

            <div className="flex flex-col justify-between text-sm">
              <div>
                <div className="font-medium text-sm md:text-lg text-slate-700 ">
                  {applicantDetail?.applicantname}
                </div>

                <div className="flex gap-1 items-center text-slate-600">
                  <MdOutlinePhoneAndroid size={14} />
                  <span className="md:font-medium text-xs md:text-sm">
                    {applicantDetail?.contactno
                      ? applicantDetail?.contactno
                      : "---"}
                    {applicantDetail?.alternativecontactno
                      ? `/ ${applicantDetail?.alternativecontactno}`
                      : "---"}
                  </span>
                </div>

                <div className="flex gap-1 items-center text-slate-600">
                  <MdOutlineMail size={14} />
                  <span className="md:font-medium text-xs md:text-sm">
                    {applicantDetail?.applicantemail
                      ? applicantDetail?.applicantemail
                      : "---"}
                  </span>
                </div>
              </div>
              <div className="text-xs 2xl:text-sm bg-slate-100 py-1 w-full md:px-4 px-1 mt-1 rounded-sm border">
                <ApplicantDataPreview
                  label="Loan ID"
                  dataValue={
                    applicantDetail?.loanee_id
                      ? applicantDetail?.loanee_id
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Loan No"
                  dataValue={
                    applicantDetail?.loan_number
                      ? applicantDetail?.loan_number
                      : "---"
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-slate-100 col-span-3 grid grid-cols-1 md:grid-cols-2  gap-3 text-sm  border">
            <div className="  p-3 ">
              <span className=" border-b text-slate-600 font-semibold">
                General details:
              </span>
              <div className="pt-3 ">
                {/* <ApplicantDataPreview
                label="Name"
                dataValue={applicantDetail?.applicantname}
              /> */}

                <ApplicantDataPreview
                  label="Category"
                  dataValue={applicantDetail?.categoryname}
                />

                <ApplicantDataPreview
                  label="D.O.B"
                  dataValue={getFormattedDateDDMMMYYYYDate(
                    applicantDetail?.applicantdob
                  )}
                />

                <ApplicantDataPreview
                  label="Gender"
                  dataValue={applicantDetail?.applicantgender}
                />
                <ApplicantDataPreview
                  label={applicantDetail?.applicantrelative}
                  dataValue={applicantDetail?.applicantrelativename}
                />
              </div>
            </div>
            <div className=" p-3">
              <span className=" border-b text-slate-600 font-semibold">
                Scheme details:
              </span>
              <div className="pt-3 ">
                <ApplicantDataPreview
                  label="Scheme"
                  dataValue={schemeName || "---"}
                />
                <ApplicantDataPreview
                  label="Scheme short name"
                  dataValue={applicantDetail?.schemeshortname || "---"}
                />
                <ApplicantDataPreview
                  label="Interest rate"
                  dataValue={
                    applicantDetail?.loan_int_rate ||
                    applicantDetail?.scheme_int_rate ||
                    "---"
                  }
                />
                <ApplicantDataPreview
                  label="Loan applied"
                  dataValue={`${formatINRCurrency(
                    applicantDetail?.loanappliedamt >=
                      applicantDetail?.loan_amount
                      ? applicantDetail?.loanappliedamt
                      : applicantDetail?.loan_amount
                  )}`}
                />
              </div>
            </div>
          </div>
        </section>

        <fieldset className="border p-3 grid grid-cols-1  md:grid-cols-3 gap-x-9 gap-1 mt-3 text-sm">
          <legend className="px-2 border ml-3 rounded-xs text-xs text-slate-600  p-1">
            Applicant details
          </legend>

          {/* <ApplicantDataPreview
            label="Email"
            dataValue={applicantDetail?.applicantemail || "---"}
          /> */}

          <ApplicantDataPreview
            label="Annual Income"
            dataValue={formatINRCurrency(applicantDetail?.annualincome)}
          />

          <ApplicantDataPreview
            label=" Area Type"
            dataValue={applicantDetail?.applicantarea}
          />

          <ApplicantDataPreview
            label="District "
            dataValue={districtName || "---"}
          />
          <ApplicantDataPreview
            label="Constituency"
            dataValue={constituencyName || "---"}
          />

          <ApplicantDataPreview
            label="Area"
            dataValue={selected_Loanee?.label}
          />

          <ApplicantDataPreview
            label="Pin Code"
            dataValue={applicantDetail?.pincode}
          />

          <ApplicantDataPreview
            label="Post Office"
            dataValue={applicantDetail?.postoffice}
          />

          <ApplicantDataPreview
            label="Police Station"
            dataValue={applicantDetail?.policestation}
          />

          <ApplicantDataPreview
            label="Ward"
            dataValue={
              applicantDetail?.municipalward ||
              applicantDetail?.grampanchayatward ||
              "---"
            }
          />
          <ApplicantDataPreview
            label="Sub Division"
            dataValue={
              subDivisionName || applicantDetail?.subdivisionname || "---"
            }
          />

          <ApplicantDataPreview
            label="Emp. Generation"
            dataValue={applicantDetail?.employmentgeneration}
          />
          <ApplicantDataPreview
            label="Education"
            dataValue={applicantDetail?.education}
          />
          {/* 
          <ApplicantDataPreview
            label="Contact No"
            dataValue={applicantDetail?.contactno}
          /> */}

          {applicantDetail?.grampanchayatname ? (
            <ApplicantDataPreview
              label="Gram Panchayat"
              dataValue={
                gramPanchayatName || applicantDetail?.grampanchayatname || "---"
              }
            />
          ) : null}

          {applicantDetail?.nagarpanchayatname ? (
            <ApplicantDataPreview
              label="Nagar Panchayat"
              dataValue={applicantDetail?.nagarpanchayatname || "---"}
            />
          ) : null}

          <ApplicantDataPreview
            label="Location"
            dataValue={applicantDetail?.applicantlocation}
          />

          {/* <ApplicantDataPreview
            label="Location"
            dataValue={applicantDetail?.applicantlocation}
          /> */}

          {/* <div className="col-span-1 md:col-span-3 border-t mt-3 pt-3">
            <ApplicantDataPreview
              label="Permanent Address"
              dataValue={applicantDetail?.permanentaddress}
              logo={<CiLocationOn size={22} />}
            />

            <div className="py-1" />

            <ApplicantDataPreview
              label="Temporary Address"
              // dataValue={applicantDetail?.temporaryaddress}
              dataValue={applicantDetail?.temporaryaddress}
              logo={<CiLocationOn size={22} />}
            />
          </div> */}
        </fieldset>

        {applicantDetail?.bankname === null ? null : (
          <>
            <fieldset className="border p-3 grid grid-cols-1  md:grid-cols-3 gap-x-9 gap-1 mt-3 text-sm">
              <legend className="px-2 border ml-3 rounded-xs text-xs text-slate-600  p-1">
                Bank details
              </legend>
              <ApplicantDataPreview
                label="Bank Name"
                dataValue={applicantDetail?.bankname}
              />
              <ApplicantDataPreview
                label="Account Name"
                dataValue={applicantDetail?.accountname}
              />
              <ApplicantDataPreview
                label="Account No"
                dataValue={applicantDetail?.accountnumber}
              />
              <ApplicantDataPreview
                label="IFSC Code"
                dataValue={applicantDetail?.ifsccode}
              />
              <ApplicantDataPreview
                label="Phone No"
                dataValue={applicantDetail?.phoneno}
              />
            </fieldset>
          </>
        )}

        <fieldset className="border p-3 grid grid-cols-1  md:grid-cols-2 gap-x-9 gap-1 mt-3 text-sm">
          <legend className="px-2 border ml-3 rounded-xs text-xs text-slate-600  p-1">
            Documents
          </legend>
          {applicantDetail?.loaneedocuments &&
          applicantDetail?.loaneedocuments?.length > 0 ? (
            <>
              <div className=" col-span-1 md:col-span-2  ">
                <div className=" flex flex-wrap gap-9 border-b pb-3">
                  {applicantDetail?.loaneedocuments?.map((docObj, index) =>
                    docObj?.docurl ? (
                      <ApplicantDocumentPreview
                        file_name={docObj?.docname}
                        file_type={docObj?.doctype}
                        file_url={docObj?.docurl}
                        key={index}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </>
          ) : null}

          <div className="col-span-1 md:col-span-2 text-center text text-slate-600 my-1  ">
            (Physical documents list)
          </div>

          <div className="flex flex-wrap gap-3 col-span-1 md:col-span-2  text-slate-700 font-semibold">
            {applicantDetail?.filechecklist &&
              applicantDetail?.filechecklist?.map((preSacntionFileObj, index) =>
                preSacntionFileObj.check === true ? (
                  <div className="bg-slate-50 py-1  px-3 border rounded-full">
                    {index + 1}. {preSacntionFileObj.file}
                  </div>
                ) : null
              )}
          </div>
        </fieldset>

        {/* Guarantor details */}
        {applicantDetail?.guarantordetails ? (
          <GuarantorDetails
            guarantor_details={applicantDetail?.guarantordetails}
          />
        ) : null}

        {applicantDetail?.approval_details[0].approval_details
          ?.disbursmentamount > 0 ? (
          <>
            <section className="mt-9 border p-3  bg-slate-50 text-sm lg:w-10/12 lg:mx-auto">
              <div className="text-center text-primary font-semibold text-base">
                Disbursment Details
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:mt-2 lg:p-3 border bg-green-50">
                <ApplicantDataPreview
                  label="Cheque Id"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.chequeid
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.chequeid
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Cheque Issue Date"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.chequedate
                      ? `${getFormattedDateDDMMMYYYYDate(
                          applicantDetail?.approval_details[0].approval_details
                            ?.chequedate
                        )}`
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Disbursment Amount"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.disbursmentamount
                      ? new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(
                          applicantDetail?.approval_details[0].approval_details
                            ?.disbursmentamount
                        )
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Interest Rate"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.interestrate
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.interestrate
                      : "---"
                  }
                />
                <ApplicantDataPreview
                  label="Tenure Period"
                  dataValue={
                    applicantDetail?.approval_details[0].approval_details
                      ?.tenure_period
                      ? applicantDetail?.approval_details[0].approval_details
                          ?.tenure_period
                      : "---"
                  }
                />
              </div>
              <div>
                <div className=" flex flex-wrap gap-6 pt-3">
                  {applicantDetail?.loaneedocuments
                    ?.filter(
                      (objFilter) => objFilter?.doctype === "DGM Accounts"
                    )
                    ?.map((docObj, index) =>
                      docObj?.docurl ? (
                        <ApplicantDocumentPreview
                          file_name={docObj?.docname}
                          file_type={docObj?.doctype}
                          file_url={docObj?.docurl}
                          key={index}
                        />
                      ) : null
                    )}
                </div>
              </div>
            </section>
          </>
        ) : null}

        {/* remark */}
        <Remarks
          loanee_id={applicantDetail?.loanee_id}
          applicantDetail={applicantDetail}
          schemeName={schemeName}
          districtName={districtName}
          constituencyName={constituencyName}
          subDivisionName={subDivisionName}
          gramPanchayatName={gramPanchayatName}
          selected_Loanee={selected_Loanee}
        />

        {/* INPUT FOR ROLE */}
        <section className="mt-6">
          {showSanctionForm === true ? (
            <>
              <LoaneeSanctionForm
                applicantDetail={applicantDetail}
                setViewApplicantDetail={setViewApplicantDetail}
                setRefresh={setRefresh}
                isPostSacntion={isPostSacntion}
                loaneeSanctionDetail={loaneeSanctionDetail}
              />
            </>
          ) : (
            <>
              <div className="text-slate-600 ">{sanctionMessage}</div>
            </>
          )}
        </section>
      </section>
    </>
  ) : (
    <LoadingModal message={"Loading"} />
  );
}
