import React from "react";
const ApplicantDataPreview = ({ label, dataValue, logo }) => {
  return (
    <>
      <div className="text-slate-700 flex gap-2 items-start">
        {label}:
        <span className="text-slate-800 font-semibold  ">
          <span className="flex gap-1 items-center">
            {logo ? logo : null} {dataValue ? dataValue : "---"}
          </span>
        </span>
      </div>
    </>
  );
};
export default ApplicantDataPreview;
