import React from "react";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export const PenalInterestToPrint = React.forwardRef(
  ({ penalInterestList }, ref) => {
    const getNoOfDaysBetweenDates = (startDate, endDate) => {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      // To calculate the time difference of two dates
      let Difference_In_Time = endDate?.getTime() - startDate?.getTime();

      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    };
    return (
      <section className="bg-white min-h-screen p-4">
        <div ref={ref} className="w-full text-xs border border-slate-600">
          <div className="p-4">
            <div className=" flex items-center  gap-4">
              <img className="h-14 w-14" src={sabccoLogo} />
              <div>
                <p className="text-center text-base font-semibold">
                  Sikkim SC, ST and OBC Development
                </p>
                <p className="text-sm -mt-1">
                  Bhanu Path, Gangtok, Sikkim 737101
                </p>
              </div>
            </div>
            <p className="text-center text-sm">(Penal Interest)</p>
            <div className=" grid grid-cols-6 gap-x-4">
              <p className="col-span-2">
                Name:{" "}
                <span className="font-semibold">
                  {penalInterestList[0]?.applicantname}
                </span>
              </p>
            </div>
          </div>

          <table className="min-w-full divide-y divide-slate-400 border-t">
            <thead className="bg-slate-100 text-xs">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Default Amount
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Repayment
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Principle Paid
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Period (start to End Date)
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Days
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Penal
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-slate-400 text-slate-600">
              {penalInterestList?.length > 0 ? (
                penalInterestList?.map((penalList, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {getFormattedDateDDMMMYYYYDate(penalList?.enddate)}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {penalList?.halfyearlyinstallmentdueamount}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {penalList?.repaymentamount || 0}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {penalList?.principleamountpaid || 0}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {getFormattedDateDDMMMYYYYDate(penalList?.startdate)}
                      <span className="px-1">to</span>
                      {getFormattedDateDDMMMYYYYDate(penalList?.enddate)}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {getNoOfDaysBetweenDates(
                        penalList?.startdate,
                        penalList?.enddate
                      )}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {penalList?.penalinterestamount}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs">
                      {penalList?.totalpenalinterest}
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={8} className="p-4 text-center">
                  {" "}
                  No Record Found
                </td>
              )}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
);
