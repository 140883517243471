import React from "react";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FaUser } from "react-icons/fa";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import BackButton from "../../../../reusable-components/buttons/BackButton";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";

import { useEffect } from "react";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import { getFormattedDateDDMMYYYY } from "../../../../utilities/dateFunctions/formatdate";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { button, delete_button } from "../../../../theme/lightTheme";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  VERIFY_NOC_APPLICATION_URL,
} from "../../../../api/api_routing_urls";

export default function ViewNocApplication({
  nocApplicationDetail,
  setViewNocApplicationDetail,
  setNocApplicationDetail,
  setRefresh,
}) {
  const [showBackButton, setShowBackButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [remark, setRemark] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [remarkError, setRemarkError] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      if (data?.remarks !== "") {
        setRemarkError(false);
      } else {
        setRemarkError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyNocApplication = async ({ is_loanee }) => {
    let formDataObj = {};
    try {
      formDataObj.noc_serial_no = nocApplicationDetail.noc_serial_no;
      formDataObj.isverified = true;
      formDataObj.remark = remark;
      formDataObj.isloanee = is_loanee;

      let response = await axiosPrivate.post(
        `${VERIFY_NOC_APPLICATION_URL}`,
        formDataObj,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setRefresh((prev) => !prev);
        showToast("Applicant has been verified successfully", "success");
        setViewNocApplicationDetail(false);
        setShowModal(false);
      }
      response?.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      setShowModal(false);
      console.error(error.message);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    if (nocApplicationDetail) setIsLoading(false);
  }, [nocApplicationDetail]);

  return !isLoading ? (
    <>
      <div className="flex items-center justify-between ">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaUser />
          </IconContext.Provider>
          &nbsp; Loanee Death Case Details
        </H2withIcon>
        {!showBackButton && (
          <BackButton
            label="back to list"
            onclick={() => {
              setShowBackButton(true);
              setViewNocApplicationDetail(false);
              setNocApplicationDetail({});
            }}
          />
        )}
      </div>
      <section className=" text-[14px] p-9 text-justify bg-yellow-50 border">
        <div className="w-full">
          <div className="flex gap-3 items-center my-3 w-fit mx-auto">
            <img className="h-16 w-16" src={sabccoLogo} />
            <div>
              <p className="text-center text-[16px] font-semibold">
                Sikkim SC / ST / OBC Development Corporation.Ltd. (SABCCO)
              </p>
              <p className="text-[14px]  -mt-1">
                Sonam Tshering Marg, Gangtok - 737101
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {nocApplicationDetail.noc_serial_no ? (
            <>
              <p className="font-semibold">
                Serial No.: {nocApplicationDetail.noc_serial_no}
              </p>
            </>
          ) : null}

          {nocApplicationDetail.createdat ? (
            <>
              <p className="font-semibold">
                Date:{" "}
                {getFormattedDateDDMMYYYY(nocApplicationDetail?.createdat)}
              </p>
            </>
          ) : null}
        </div>

        <div className="mb-9">
          <p className="font-semibold text-center mb-6">
            TO WHOM IT MAY CONCERN
          </p>
          <p>
            This is to certify that Shri/Smt/Kumari
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_name}{" "}
            </span>
            S/o, D/o, W/o
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_relative_name}{" "}
            </span>
            resident of
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_resident}{" "}
            </span>
            does not have any loan till date or outstanding as reflected in the
            books. Hence this Corporation does not have any objection for grant
            of Job/Regularisation in
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_department_name}{" "}
            </span>
            Department in respect of the applicant.
          </p>

          <p className="pt-3">
            This certificate is issued without prejudice to the Corporation's
            right to recover the loan amount if it is found at any later point
            of time that he/she owes any amount to this Corporation.
          </p>

          <p className="pt-3">
            This certificate is valid for a period of 6 (Six) months from the
            date of issue.
          </p>
        </div>

        <div className="flex flex-row justify-between pt-6">
          <p className="font-semibold">{formatINRCurrency(150)}/-</p>
          <div>
            <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-2xl mb-3">
          <TextArea
            defaultName="remarks"
            register={register}
            name="Remarks"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter remarks"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
            onChangeInput={(e) => {
              if (e !== "") {
                setRemark(e);
                setRemarkError(false);
              } else {
                setRemarkError(true);
              }
            }}
            defaultValue={""}
            setValue={setValue}
          />
        </div>

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Applicant Not Loanee"}
            className={button}
            onClick={() => {
              if (remarkError) {
                setShowModal(false);
              } else {
                setShowModal(true);
              }
            }}
          />
          {
            <SubmitButton
              isSubmitting={isSubmitting}
              label={"Applicant is Loanee"}
              className={delete_button}
              value={"Revert"}
              onClick={() => {
                if (remarkError) {
                  setShowRevertModal(false);
                } else {
                  setShowRevertModal(true);
                }
              }}
            />
          }
        </div>
      </form>
      <ConfirmationModal
        open={showModal}
        setOpen={setShowModal}
        message={`Are you sure the applicant is not a loanee?`}
        btnName={"Confirm"}
        onConfirm={() => verifyNocApplication({ is_loanee: false })}
      />
      <ConfirmationModal
        open={showRevertModal}
        setOpen={setShowRevertModal}
        message={"Are you sure the applicant is a loanee?"}
        btnName={"Confirm"}
        onConfirm={() => verifyNocApplication({ is_loanee: true })}
        color={"red"}
      />{" "}
    </>
  ) : (
    <LoadingModal message={"Loading"} />
  );
}
