import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { BROACHER_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import { MdPermMedia } from "react-icons/md";
import AddPhotoForm from "./AddPhotoForm";
import PhotosList from "./PhotosList";
import useAuth from "../../../../authentication/hooks/useAuth";

export default function BroacherConfig() {
  const { auth } = useAuth();

  const [photoGalleryList, setPhotoGalleryList] = useState([]);
  const [editPhotoDetails, setEditPhotoDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editPhotoDetails).length > 0 ? true : false;
  const getPhotoGalleryList = async () => {
    try {
      const response = await axiosPrivate.get(BROACHER_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setPhotoGalleryList(response.data.photosList);
      response.status === 202 &&
        showToast("No photo list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getPhotoGalleryList();
    setEditPhotoDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <MdPermMedia />
          </IconContext.Provider>
          &nbsp; Brochure
        </H2withIcon>
        {!showAddForm && (
          <AddButton
            label="Add Brochure"
            onclick={() => setShowAddForm(true)}
          />
        )}
      </div>
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={`${isEdit ? "Update Brochure" : "Add Brochure"}`}
        isAdd={true}
      >
        <AddPhotoForm
          getPhotoGalleryList={getPhotoGalleryList}
          editPhotoDetails={editPhotoDetails}
          setEditPhotoDetails={setEditPhotoDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <ContainerShadow>
        {photoGalleryList.length === 0 ? (
          <p>Brochure yet to be added</p>
        ) : (
          <div className="grid grid-cols-1">
            <PhotosList
              photoGalleryList={photoGalleryList}
              setEditPhotoDetails={setEditPhotoDetails}
              setShowAddForm={setShowAddForm}
            />
          </div>
        )}
      </ContainerShadow>
    </Dashboard>
  );
}
