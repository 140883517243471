import React, { useEffect, useState } from "react";
import { getBase64 } from "../../../utilities/fileFunctions/imageFunctions";
import Error from "../../outputs/Error";

export default function PDFUploader({
  register,
  defaultName,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  file,
  setBase64,
  setFilename,
  setValue,
  isEdit,
  oldFileOnEdit,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: { value: required, message: `${name} is required` },
    pattern: pattern,
  });

  const onChangePDF = (e) => {
    getBase64(e.target.files[0], (result) => {
      setValue(defaultName, result);
      setBase64(result);
    });
    setFilename(e.target.files[0].name);
  };

  //   useEffect(() => {
  //     if (file !== "") {
  //       rest.setValue(name, dataURLtoFile(file), { shouldTouch: true });
  //     }
  //   }, [file]);

  return (
    <div className="flex flex-col max-full mb-4 justify-center">
      <p className="font-medium text-left">
        {name} {required && <span className="text-red-700">*</span>}
      </p>
      <input
        id={defaultName}
        // defaultValue={file !== "" ? dataURLtoFile(file) : null}
        accept="application/pdf"
        onChange={(e) => {
          if (required) {
            if (e.target.value === "") {
              setError(defaultName, {
                type: "required",
                message: `${name} is required`,
              });
            } else {
              onChangePDF(e);
              clearError(defaultName);
            }
          } else {
            onChangePDF(e);
            clearError(defaultName);
          }
          onChangePDF(e);
          onChange(e);
        }}
        {...props}
        {...rest}
        className={`${classes} border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      />
      {/* <img src */}

      {errors[defaultName] && (
        <Error
          classes="flex flex-row gap-1 max-w-sm w-full mt-2"
          message={`${name} is required`}
        />
      )}
    </div>
  );
}
