import React, { useEffect, useState } from "react";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdRemoveRedEye } from "react-icons/md";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import { BiChevronLeft } from "react-icons/bi";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { BsDot } from "react-icons/bs";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import DirectDeptNoticeTemplate from "./DirectDeptNoticeTemplate";
import { useForm } from "react-hook-form";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function DirectDeptNoticeList({
  loaneeDetails,
  setShowDirectDeptNoticeList,
  setShowGenerateDirectDeptNoticePage,
  noticeList,
  setNoticeList,
  selectedDate,
  refNumber,
  setRefNumber,
  setSelectedDate,
  setRefresh,
  // refresh,
  guarantorListForNotice,
  setGuarantorListForNotice,
  guarantorNamesDD,
  setSelectedGuarantor,
  selectedGuarantor,
  salariedGuarantorExist,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [viewNotice, setViewNotice] = useState(false);
  const [toString, setToString] = useState();

  const handleviewDirectDeptNoticeList = (loaneeDetails) => {
    setViewNotice(true);
    setRefNumber(loaneeDetails?.reference_number);
    setSelectedDate(loaneeDetails?.generated_date);
  };

  const to_string = `${loaneeDetails?.applicantname}
${loaneeDetails?.applicantrelative}: ${loaneeDetails?.applicantrelativename}
${loaneeDetails?.permanentaddress}`;

  useEffect(() => {
    setToString(to_string);
  }, [to_string]);

  return (
    <>
      <section>
        {!viewNotice ? (
          <>
            <div className="flex justify-between">
              <H2withIcon>
                <MdAssignmentTurnedIn />
                <span className="pl-1.5">Direct Departmental Notice List</span>
              </H2withIcon>

              <div className="flex items-center ">
                <div className="-mb-4 mr-3 ">
                  {loaneeDetails?.loan_status === "Active" &&
                  salariedGuarantorExist ? (
                    <div className="flex gap-4">
                      <AddButton
                        label="Generate Direct Departmental Notice"
                        onclick={() => {
                          setShowGenerateDirectDeptNoticePage(true);
                          setRefNumber(null);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className="flex items-center cursor-pointer hover:underline underline-offset-2 "
                  onClick={() => {
                    setRefNumber(null);
                    setShowDirectDeptNoticeList(false);
                    setRefresh((prev) => !prev);
                    // setNoticeList(null);
                    setGuarantorListForNotice(null);
                  }}
                >
                  <BiChevronLeft size={24} /> back
                </div>
              </div>
            </div>

            {/* -- Loanee Info-- */}
            <div className="my-4">
              <div className=" text-sm flex items-center flex-wrap gap-1">
                <div>
                  Loan Id:{" "}
                  <span className="font-semibold">
                    {loaneeDetails?.loanee_id}
                  </span>
                </div>
                <BsDot size={14} />
                <div>
                  Loan Number:{" "}
                  <span className="font-semibold">
                    {loaneeDetails?.loan_number}
                  </span>
                </div>
                <BsDot size={14} />
                <div>
                  Loanee Name:{" "}
                  <span className="font-semibold">
                    {loaneeDetails?.applicantname}
                  </span>
                </div>
              </div>
            </div>
            {/* {list} */}
            {salariedGuarantorExist ? (
              <>
                <section>
                  <div className="pt-5">
                    <TableWrapper>
                      <thead className="bg-white text-sm sticky top-0 z-0">
                        <tr className="bg-slate-200 ">
                          <th
                            scope="col"
                            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                          >
                            SL.No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                          >
                            Reference Number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                          >
                            Generated Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
                          >
                            Notice Type
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
                        {noticeList?.length > 0 &&
                          noticeList?.map((noticeDetailsObj, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 1 ? "bg-slate-100" : null
                              }
                            >
                              <td className="px-6 py-2 whitespace-nowrap text-sm  text-slate-800 font-medium ">
                                <div className=" px-3 py-1 ">{index + 1}</div>
                              </td>

                              <td className="px-6 py-2 whitespace-nowrap text-sm">
                                {noticeDetailsObj?.reference_number}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm">
                                {getFormattedDateDDMMMYYYYDate(
                                  noticeDetailsObj?.generated_date
                                )}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm">
                                {noticeDetailsObj?.notice_type}
                              </td>

                              <td className="px-6 py-2 whitespace-nowrap text-sm w-24 ">
                                <span
                                  className="cursor-pointer "
                                  onClick={() =>
                                    handleviewDirectDeptNoticeList(
                                      noticeDetailsObj
                                    )
                                  }
                                >
                                  <span className="text-primary">
                                    <MdRemoveRedEye size={18} />
                                  </span>
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </TableWrapper>
                  </div>
                </section>
              </>
            ) : (
              <p className="border p-4 bg-slate-50 ">
                Guarantor is not a State/Central Government Employee
              </p>
            )}
          </>
        ) : (
          /* ViewNotice form the Diret Departmental Notice List */
          <>
            <div className="flex justify-between">
              <H2withIcon>
                <MdAssignmentTurnedIn />
                <span className="pl-1.5">View Direct Departmental Notice</span>
              </H2withIcon>

              <div className="flex items-center gap-4 ">
                <PrintReport
                  reportTitle={"Direct Departmental  Notice"}
                  buttonName={"Direct Departmental  Notice"}
                >
                  <DirectDeptNoticeTemplate
                    selectedDate={selectedDate}
                    refNumber={refNumber}
                    loaneeDetails={loaneeDetails}
                    guarantorListForNotice={guarantorListForNotice}
                    selectedGuarantor={selectedGuarantor}
                    toString={toString}
                    setToString={setToString}
                    to_Print={true}
                  />
                </PrintReport>

                <div
                  className="flex items-center cursor-pointer hover:underline underline-offset-2 "
                  onClick={() => {
                    setViewNotice(false);
                    setRefNumber(null);
                    // setRefresh((prev) => !prev);
                  }}
                >
                  <BiChevronLeft size={24} /> back
                </div>
              </div>
            </div>

            <div className="py-4">
              <Dropdown
                defaultName="copyTo"
                register={register}
                labelname="Copy to"
                required={false}
                pattern={null}
                errors={errors}
                classes={`rounded-sm  py-2 text-sm w-[25%] `}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={guarantorNamesDD}
                defaultValue={null}
                setValue={setValue}
                setSelected={setSelectedGuarantor}
                selected={selectedGuarantor}
                maxMenuHeight={120}
              />

              <DirectDeptNoticeTemplate
                selectedDate={selectedDate}
                refNumber={refNumber}
                loaneeDetails={loaneeDetails}
                guarantorListForNotice={guarantorListForNotice}
                selectedGuarantor={selectedGuarantor}
                toString={toString}
                setToString={setToString}
                to_Print={false}
              />
            </div>
          </>
        )}
      </section>
    </>
  );
}
