/* eslint-disable no-whitespace-before-property */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import Section from "../../../../reusable-components/section/Section";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { number_in_words } from "../../../../utilities/generalFunctions/numberToWrod";
import footerDesign from "../../../../assets/footer_Design.png";
import { IoCutOutline } from "react-icons/io5";

export default function Receipt_for_cheque_generation({
  addRepaymentDetails,
  formData,
  receiptDetails,
  to_print,
}) {
  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  console.log({ formData });

  return to_print ? (
    <>
      <ReceiptSection
        receiptDetails={receiptDetails}
        addRepaymentDetails={addRepaymentDetails}
        formData={formData}
      />

      <p className="text-center py-3 flex items-center justify-center">
        ---------------------------------------------------------{" "}
        <IoCutOutline />{" "}
        ---------------------------------------------------------
      </p>

      <ReceiptSection
        receiptDetails={receiptDetails}
        addRepaymentDetails={addRepaymentDetails}
        formData={formData}
      />
    </>
  ) : (
    <>
      <section className="py-4 border border-slate-200">
        <header className="bg-white pt-3">
          <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
            <div className="flex justify-center gap-3 items-center">
              <img className="h-28 w-28" src={sabccoLogo} alt="SABCCO Logo" />
            </div>
          </div>
          <div>
            <p className="text-center text-[14px] font-semibold">
              SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
              <br />
              CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
              <br />
              <span className="text-center text-[12px] font-semibold">
                SONAM TSHERING MARG, GANGTOK
              </span>
            </p>
          </div>
        </header>
        <div className="pt-5 bg-white mx-6">
          <div className="pt-2">
            <p className="text-center font-bold underline underline-offset-4 text-sm">
              CHEQUE RECEIPT
            </p>
            <div className="flex justify-between items-center pt-3 mx-6">
              <p>Sl. No: {receiptDetails?.cheque_receipt_id || null}</p>
              <p>
                Date:{" "}
                {receiptDetails?.created_at
                  ? getFormattedDateDDMMMYYYYDate(receiptDetails?.created_at)
                  : null}
              </p>
            </div>
            <div className="mt-3 flex justify-center items-center tracking-widest leading-6">
              <p className="pt-4 mx-6">
                Received with thanks from Mr/Ms{" "}
                <span className="font-semibold capitalize">
                  {capitalizeWords(addRepaymentDetails?.applicantname)}
                </span>{" "}
                <span className="font-semibold">
                  {capitalizeWords(addRepaymentDetails?.applicantrelative)}
                  &nbsp;
                </span>
                <span className="font-semibold capitalize">
                  {capitalizeWords(addRepaymentDetails?.applicantrelativename)}
                  &nbsp;
                </span>
                resident of{" "}
                <span className="font-semibold capitalize">
                  {addRepaymentDetails?.permanentaddress}&nbsp;
                </span>
                Loan ID No{" "}
                <span className="px-1 font-semibold">
                  {addRepaymentDetails?.loanee_id}{" "}
                </span>
                a sum of Rs
                <span className="px-1 font-semibold">
                  {" "}
                  {formatINRCurrency(
                    receiptDetails?.cheque_amount || formData?.cheque_amount
                  )}
                  /-
                </span>
                ( Rupees
                <span className="font-semibold">
                  {number_in_words(
                    receiptDetails?.cheque_amount || formData?.cheque_amount
                  )}
                </span>
                ) only on account of SABCCO loan by Cheque/D.D. No{" "}
                <span className="font-bold px-1">
                  {receiptDetails?.cheque_number || formData?.cheque_number}
                </span>{" "}
                dated
                <span className="font-semibold">
                  {" "}
                  &nbsp;
                  {getFormattedDateDDMMMYYYYDate(
                    receiptDetails?.cheque_date || formData?.cheque_date
                  )}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
        <footer className="mt-9 mx-12 text-sm">
          <div className="flex justify-end items-center pt-20">
            <p>For Sikkim SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
          </div>
          <div className="flex justify-start items-center pt-1">
            <p>*Subject to realization of cheque</p>
          </div>
          <div className="pt-3">
            <img src={footerDesign} alt="" srcSet="" />
            <div className="text-center">
              <p className="text-center">
                Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                Classes Development Corporation Ltd.
              </p>
              <p className="text-center">
                Contact No: 7908335935 & 9434863433 / Website:
                www.sikkimsabcco.com
              </p>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}

const ReceiptSection = ({ receiptDetails, addRepaymentDetails, formData }) => {
  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return (
    <div className="text-[12px]">
      <header className=" bg-white ">
        <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
          <div className="flex justify-center gap-3 items-center">
            <img className="h-24 w-24" src={sabccoLogo} alt="SABCCO Logo" />
          </div>
        </div>
        <div>
          <p className="text-center text-[12px] font-semibold">
            SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
            <br />
            CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
            <br />
            <span className="text-center text-[12px] font-normal">
              SONAM TSHERING MARG, GANGTOK
            </span>
          </p>
        </div>
      </header>

      <Section className=" ">
        <body className="pt-3 bg-white">
          <div className="pt-1">
            <p className="text-center font-bold underline underline-offset-4 text-sm">
              CHEQUE RECEIPT
            </p>
            <div className="flex justify-between items-center pt-3 mx-14">
              <p>Sl. No: {receiptDetails?.cheque_receipt_id || null}</p>
              <p>
                Date:{" "}
                {receiptDetails?.created_at
                  ? getFormattedDateDDMMMYYYYDate(receiptDetails?.created_at)
                  : null}
              </p>
            </div>
            <div className="mt-1 flex justify-center items-center">
              <div className="tracking-widest leading-6 mx-14 text-center">
                <p className="pt-3 text-justify">
                  Received with thanks from Mr/Ms{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.applicantname)}
                  </span>{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.applicantrelative)}
                    &nbsp;
                  </span>
                  <span className="font-semibold capitalize">
                    {capitalizeWords(
                      addRepaymentDetails?.applicantrelativename
                    )}
                    &nbsp;
                  </span>
                  Resident of{" "}
                  <span className="font-semibold capitalize">
                    {addRepaymentDetails?.permanentaddress}&nbsp;
                  </span>
                  Loan ID No{" "}
                  <span className="px-1 font-semibold">
                    {addRepaymentDetails?.loanee_id}{" "}
                  </span>
                  a sum of Rs{" "}
                  <span className="px-1 font-semibold">
                    {formatINRCurrency(receiptDetails?.cheque_amount)}
                  </span>
                  /- ( Rupees{" "}
                  <span className="font-bold">
                    {number_in_words(receiptDetails?.cheque_amount)}
                  </span>{" "}
                  ) only on account of SABCCO loan by Cheque/D.D. No{" "}
                  <span className="font-bold px-1">
                    {receiptDetails?.cheque_number || formData?.cheque_number}{" "}
                  </span>{" "}
                  dated &nbsp;
                  <span className="font-semibold">
                    {getFormattedDateDDMMMYYYYDate(
                      receiptDetails?.cheque_date || formData?.cheque_date
                    )}
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </body>
      </Section>

      <footer className="mt-6 mx-14">
        <div className="flex justify-end items-center pt-6 text-[11px]">
          <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
        </div>
        <div className="flex justify-start items-center text-xs pt-1">
          <p>*Subject to realization of cheque</p>
        </div>
        <div className="pt-1">
          <img
            className="opacity-90 w-full"
            src={footerDesign}
            alt="dash"
            srcset=""
          />
          <div className="text-center text-[11px]">
            <p className="text-center">
              Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
              Classes Development Corporation Ltd.
            </p>
            <p className="text-center">
              Contact No: 7908335935 & 9434863433 / Website:
              www.sikkimsabcco.com
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
