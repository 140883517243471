import React from "react";
import { useState } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import {
  MdCheck,
  MdOutlinePendingActions,
  MdPhoneIphone,
  MdRemoveRedEye,
} from "react-icons/md";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { LOANEE_DEATH_CASE } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  linkTextPrimary,
  linkTextSecondary,
} from "../../../../theme/lightTheme";

export default function NocApplicationList({
  loaneeNocApplicationList,
  setViewNocApplicationDetail,
  setNocApplicationDetail,
}) {
  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-xs  md:text-sm z-60">
          <tr className="bg-slate-200 sticky top-0">
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Serial No
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium flex flex-col min-w-44"
            >
              <span>Applicant Name</span>{" "}
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Relative Name
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Department Name
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Status
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Is Loanee
            </th>

            <th scope="col" className="px-3 py-2 text-center font-medium">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {loaneeNocApplicationList?.length > 0 ? (
            loaneeNocApplicationList?.map(
              (loaneeNocApplicationDetail, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? "bg-slate-100" : null}
                >
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>{loaneeNocApplicationDetail?.noc_serial_no}</p>
                  </td>
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>{loaneeNocApplicationDetail?.noc_name}</p>
                  </td>
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>{loaneeNocApplicationDetail?.noc_relative_name}</p>
                  </td>

                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>{loaneeNocApplicationDetail?.noc_department_name}</p>
                  </td>
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>
                      <>
                        {loaneeNocApplicationDetail?.isverified &&
                        loaneeNocApplicationDetail?.isverified !== null ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span>Verified</span>
                            <MdCheck size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span>Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    </p>
                  </td>
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                    <p>
                      {loaneeNocApplicationDetail?.isverified &&
                      loaneeNocApplicationDetail?.isverified !== null ? (
                        <>
                          {loaneeNocApplicationDetail?.isloanee &&
                          loaneeNocApplicationDetail?.isloanee !== null ? (
                            <span className="text-yellow-600 flex gap-1 items-center">
                              <span>Loanee</span>
                            </span>
                          ) : (
                            <span className="text-green-600 flex gap-1 items-center">
                              <span>Not Loanee</span>
                              <MdCheck size={18} />
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="text-yellow-600 flex gap-1 items-center">
                          <span>Pending</span>
                          <MdOutlinePendingActions size={18} />
                        </span>
                      )}
                    </p>
                  </td>

                  <td className="px-3 py-1.5 whitespace-nowrap text-sm">
                    <div className="flex justify-center ">
                      <Button
                        onClick={() => {
                          setNocApplicationDetail(loaneeNocApplicationDetail);
                          setViewNocApplicationDetail(true);
                        }}
                        aria-label="View Sub menu"
                        className={linkTextPrimary}
                      >
                        <span className="text-primary">
                          <MdRemoveRedEye size={18} />
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            )
          ) : (
            <>
              <tr className="m-auto text-center">
                <td colSpan={9} className="py-3">
                  No List Found
                </td>
              </tr>
            </>
          )}
        </tbody>
      </TableWrapper>
    </>
  );
}
