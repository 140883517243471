import { createContext, useState } from "react";

const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [notificationDetail, setNotificationDetail] = useState({});

  return (
    <NotificationContext.Provider
      value={{
        notificationDetail,
        setNotificationDetail,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
