import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { AUDIT_TRAIL_CONFIG_URL } from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import AuditTrailList from "./AuditTrailList";
import useAuth from "../../../authentication/hooks/useAuth";
import { AiOutlineAudit } from "react-icons/ai";
import Dashboard from "../../../common/Dashboard";

export default function AuditTrailConfig() {
  const { auth } = useAuth();

  const [auditTrailList, setAuditTrailList] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const controller = new AbortController();

  const getAuditTrailList = async () => {
    try {
      const response = await axiosPrivate.get(AUDIT_TRAIL_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 &&
        setAuditTrailList(response?.data?.auditTrailList);
      response.status === 202 &&
        showToast("No feedback & events list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getAuditTrailList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex gap-2 items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <AiOutlineAudit />
          </IconContext.Provider>
          <spam className="pl-2"> Audit Trail</spam>
        </H2withIcon>
      </div>

      <ContainerShadow>
        <div className="grid grid-cols-1">
          {auditTrailList?.length > 0 ? (
            <AuditTrailList auditTrailList={auditTrailList} />
          ) : (
            <p>Audit Trail Not Found</p>
          )}
        </div>
      </ContainerShadow>
    </Dashboard>
  );
}
